import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class EditManageCopyrightService {
  editcopyright: any;
  updatecopyright: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getCopyrightDetail(id) {
    this.editcopyright = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.editcopyright, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getCopyrightDetail`,
      { copyright_id: id },
      {
        headers
      }
    );
  }

  updateCopyright(data) {
    this.updatecopyright = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.updatecopyright, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/updateCopyright`, data, {
      headers
    });
  }
}
