import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as crypto from 'crypto-js';
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/authentication.service";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  usersdata: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getUsers(data?: {limit: number, skip?: number, search?: string}) {
    this.usersdata = localStorage.getItem("currentUser")
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    return this.http.post(this.base + `/getUsers`, data);
  }

  getAuthors() {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(this.base + `/getAuthors`, null, {
      headers
    });
  }

  UpdateUserStatus(data) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(this.base + `/UpdateUserStatus`, data, {
      headers
    });
  }
}
