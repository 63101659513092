<div class="col-md-1 col-sm-1 col-lg-1 d-flex flex-row-reverse ml-5 text-right">
  <div class="layout-modes">
    <div
      (click)="setColumn('4', '1:2')"
      [ngClass]="{
        'layout-btn btn-grid': true,
        active: InitialColumn
      }"
      title="Grid"
    >
      <i class="porto-icon-mode-grid"></i>
    </div>
    <div
      (click)="setColumn('1', '420px')"
      [ngClass]="{
        'layout-btn btn-grid': true,
        active: !InitialColumn
      }"
      title="List"
    >
      <i class="porto-icon-mode-list"></i>
    </div>
  </div>
</div>
