import { ChapterAddService } from './../write-chapter/chapter-add.service';
import { Component, ElementRef, OnInit, Pipe, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup, NgForm, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddStoriesService } from './add-stories.service';
import { HandleEditService } from '../add-book-cover/handle-edit.service';
import { HelperService } from '../helper.service';
import { NavbarService } from '../nav-sidebar/navbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { AppComponent } from '../../../src/app/app.component';
import Quill from 'quill';
import ResizeModule from "@botom/quill-resize-module";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


Quill.register("modules/resize", ResizeModule);

// import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
// Quill.register('modules/blotFormatter', BlotFormatter);

import _ from "lodash";
import { EditstoriesService } from '../edit-stories/editstories.service';
import { BaseUrl } from '../base-url';
import { QuillModule } from 'ngx-quill';
import { VideoDetails } from './video-details';

declare var $: any;

export interface tag {
  name: string;
}

@Component({
  selector: 'app-write',
  templateUrl: './write.component.html',
  styleUrls: ['./write.component.sass'],
})
export class WriteComponent implements OnInit {
  public selected = {value: '5ea1389319e995193b5e6b1d', name: 'Action'}
  myForm:FormGroup;
  closeResult: string;
  writeTitle = '';
  writeSentence = '';
  writeDescription = '';
  writeContentType = '';
  writeCopyright = '';
  writeGenre: any;
  chuseWriteCapter = false;
  public _settings: IDropdownSettings;
  book_id: string;
  bookData: any;
  bookSubmit: FormGroup;
  chapterIsPaid: boolean;
  priceOfChapter: string;
  selectedFiles: any;
  currentFileUpload: any;
  chapterimage: any = '';
  updateForm: any;

  results: VideoDetails[];
  message = '';

  youtubeInput = '';
  safeUrl: any;
  rightURL: any = '';
  youtube_iframe_base_url: string = 'https://www.youtube.com/embed';

  tags: tag[] = [];
  isLoading: boolean = false;
  isSubmitLoading: boolean = false;
  defaultSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 25,
    allowSearchFilter: true
  }

  descriptionMaxCharacter: number = 500;
  chapterSubmit = this.fb.group({
    chapterName: '',
    content: '',
    chapterImage: '',
  });

  quillModule: any = {
    resize: {
      locale: {
        // change them depending on your language
        altTip: "Hold down the alt key to zoom",
        floatLeft: "Left",
        floatRight: "Right",
        center: "Center",
        restore: "Restore",
      },
    },
  }
  constructor(
    private modalService: NgbModal,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private editStoriesService: EditstoriesService,
    private addtype: ChapterAddService,
    private addStoriesService: AddStoriesService,
    private appComponent: AppComponent,
    private toastr: ToastrService,
    private snackbar: MatSnackBar,
    private handleEdit: HandleEditService,
    private helper: HelperService,
    private navbarService: NavbarService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {}


  writeChupter(){
    this.chuseWriteCapter = true;
  }

  ngOnDestroy() {
    this.navbarService.setNavbar(true);
  }

  editorStyle = {
   'min-height': '70vh',
  }

  /**
   * START - Edit Book Cover variables
   */
  public canvas = true;
  public baseurl = BaseUrl.image;
  public imageCanvasUrl: any;
  public noChapters: boolean;
  public bookTitle = !this.canvas;
  // public bookTitle = !this.canvas;
  public finalCanvas = !this.canvas;
  /**
   * END - Edit Book Cover variables
   */

  mature = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  matureContent = false;
  genreList: any;

  public url: any = 'assets/img/rectangle1.svg';
  selectedFile = null;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  uploadedBookLink: string;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push({ name: value.trim() });
    }

    let newStrTags = ''

    for (let i = 0; i < this.tags.length; i++) [
      newStrTags = newStrTags + this.tags[i].name + ','
    ]

    localStorage.setItem('writeTags', newStrTags)

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
  genreError: boolean;
  fruits: string[] = ['Lemon'];
  readURL(file) {
      let infoArea = document.getElementById( 'upload-label' );
      let input = file.srcElement;
      let fileName = input.files[0].name;
      infoArea.textContent = 'File name: ' + fileName;
      this.addStoriesService.upload_file(file).subscribe((result: any) => {
        if (result.status) {
          this.uploadedBookLink = result.data
        }
        // this.contentType = result.data.content;
      });
  }
  submitted = false;

  handleTitleChange(event) {
    localStorage.setItem('writeTitle', event);
  }

  handleSentenceChange(event) {
    localStorage.setItem('writeSentence', event)
  }

  handleDescriptionChange(event) {
    localStorage.setItem('writeDescription', event)
  }

  handleContentTypeChange(event) {
    localStorage.setItem('writeContentType', event)
  }

  handleCopyright(event) {
   localStorage.setItem('writeCopyright', event)
  }

  handleGenre(event) {
   let newStrList = ''

   for (let i = 0; i < event.length; i++) {
      newStrList = newStrList + event[i].value + ','
   }

   localStorage.setItem('writeGenre', newStrList)
  }

  setMature() {
   this.mature = !this.mature;
  }

  youtoubeSearch(){
    this.safeUrl = this.sanitizer.bypassSecurityTrustHtml(this.youtubeInput);
    // this.rightURL = this.safeUrl.changingThisBreaksApplicationSecurity.replace('watch?v=', 'embed/')

    const url = new URL(this.safeUrl.changingThisBreaksApplicationSecurity);
    const video_id: string = url.searchParams.get("v");
    this.rightURL = `${this.youtube_iframe_base_url}/${video_id}`;
  }

  selectedOption(event : any) {

  }
  submitChapter(event): void {
    console.log(this.chapterimage)
    this.chapterSubmit.value['title'] = this.chapterSubmit.value.chapterName;
    this.chapterSubmit.value['book_id'] = this.book_id;
    this.chapterSubmit.value['is_paid'] = this.chapterIsPaid ? this.chapterIsPaid : 'false';
    this.chapterSubmit.value['amount'] = this.chapterIsPaid ? environment.default_chapter_amount : 0;
    this.chapterSubmit.value['is_published'] = $(event.submitter).attr('id') === 'publish-book' ? 1 : 2;
    this.chapterSubmit.value['is_completed'] = 'true';
    this.chapterSubmit.value['chapter_id'] = '1';
    this.chapterSubmit.value['image'] = this.chapterimage;
    this.chapterSubmit.value['video'] = this.rightURL;
    console.log(this.chapterSubmit.value)

  if($(event.submitter).attr('id') !== 'publish-book'){
    this.route.navigate(['/profile/mybookdetails/'+ this.book_id])
  }
  console.log(this.chapterSubmit.value);

  this.isSubmitLoading = true;
  this.addtype.addChapter(this.chapterSubmit.value).subscribe(
  (result: any) => {
    if (result.status) {
        console.log('result', result);
        this.snackbar.open(
          `${result.message}`,
          'Ok',
          { duration: 7000 }
        );
        this.chapterSubmit.reset();
        this.chapterIsPaid = false;
        this.priceOfChapter = '0.00';
        this.chapterimage='';
        this.rightURL = '';
    } else {
        this.snackbar.open(
          `${result.message}`,
          'Error',
          { duration: 4000 }
        );
        console.log('error', result.message)
      }
      this.isSubmitLoading = false;
    }, (err) => {
      this.toastr.error(err?.message || 'Something went wrong');
      this.isSubmitLoading = false;
    })
  }

  handleChapterPay() {
    this.chapterIsPaid = !this.chapterIsPaid
  }

  changePriceOfChapter(event) {
    this.priceOfChapter = event.target.value
  }

  submitBook(bookSubmit: NgForm): void {
    console.log(bookSubmit.controls['bookTags'])
    if(bookSubmit.value['title'].replaceAll(' ','').length == 0){
      bookSubmit.controls['title'].setErrors({'incorrect': true});
    }
    if(bookSubmit.value['tagline'].replaceAll(' ','').length == 0){
      bookSubmit.controls['tagline'].setErrors({'incorrect': true});
    }
    if(bookSubmit.value['description'].replaceAll(' ','').length == 0){
      bookSubmit.controls['description'].setErrors({'incorrect': true});
    }
    if(this.tags.length == 0){
      bookSubmit.controls['bookTags'].setErrors({'incorrect': true});
    }
      if (bookSubmit.valid) {
        let newArry = []
        let tagsArray = []

        bookSubmit.value['not_for_below_eighteen'] = this.mature;
        bookSubmit.value['cover_image'] = this.url == 'assets/img/rectangle1.svg' ? 'uploads/assets/img/rectangle1.svg' : this.url;
        bookSubmit.value['is_published'] = 2;

        for(let i = 0; i < bookSubmit.value.genre.length; i++ ){
           if (bookSubmit.value.genre[i].value) {
              newArry.push(bookSubmit.value.genre[i].value)
           }
        }

        bookSubmit.value['genre'] = newArry;

        for(let i = 0; i < this.tags.length; i++ ){
           tagsArray.push(this.tags[i].name)
        }

        bookSubmit.value['tags'] = tagsArray;


        this.addStoriesService.addStory(bookSubmit.value).subscribe(
           (res: any) => {
           if (res.status) {
              this.snackbar.open(res.message, 'Ok', { duration: 2000 });
              localStorage.setItem('mybooks', JSON.stringify(res.data));
              this.book_id = res.data._id
              localStorage.setItem('writeTitle', '');
              localStorage.setItem('writeSentence', '');
              localStorage.setItem('writeDescription', '');
              localStorage.setItem('writeContentType', '');
              localStorage.setItem('writeCopyright', '');
              localStorage.setItem('writeGenre', '');
              localStorage.setItem('writeTags', '');
              this.route.navigate(['/write/' + res.data._id]);
           } else {
              console.log('res', res.message);
           }
           }),
           (error) => {
           console.log('error', error);
        }
     } else {
        this.submitted = true;
        console.log('доходить')
        console.log(this.tags)
        if (this.genreVal.length === 0) {
           this.genreError = true
        } else {
           this.genreError = false
        }
     }
  }
  /**
   * START - Get Image Canvas
   */

  getImageCanvas(event: any) {
    this.imageCanvasUrl = event;
    this.canvas = false;
    this.bookTitle = !this.canvas;
  }

  getFinalImageCanvas(event: any) {
    this.imageCanvasUrl = event;
    this.canvas = false;
    this.bookTitle = false;
    this.finalCanvas = !this.bookTitle;
  }

  editBook;

  /**
   * END - Get Image Canvas
   */

  contentTypeObservable;
  public contentType = <any>{};
  dropdownSettings = {};

  sketchBookId: string = environment.sketchBookId;
  videoBookId: string = environment.videoBookId;

  genreVal = [];

  onItemSelect(item: any) {
    let selectedValue = item?.value;
    this.genreVal.push(selectedValue)
  }

  onDeSelectAll () { this.genreVal = [] }

  onSelectAll(items: any) { _.filter(items, (itm) => { this.genreVal.push(itm.value) }) }

  onItemDeSelect = (item: any) => {
    let selectedValue = item?.value;
    _.remove(this.genreVal, function(n) { return n === selectedValue})
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true });
    this.chuseWriteCapter = false;
  }

  openVerticallyCenteredUploadVideo(videContent: any) {
    this.modalService.open(videContent, { centered: true });
  }

  ngOnInit(): void {
    this.book_id = this.activatedRoute.snapshot.paramMap.get('id');
    // If book id is found then get book data for edit operations`
    if(this.book_id) {
      this.getEditBook();
    }

    this.genreError = false
    this.dropdownSettings = this.defaultSettings
    this.genreList = [
      { value: "5ea1389319e995193b5e6b1d", name: 'Action' },
      { value: "5eade0f737fe886eae35c7d4", name: 'Adventure' },
      { value: "5eade10437fe886eae35c7d5", name: 'ChickLit' },
      { value: "5eade10c37fe886eae35c7d6", name: 'Fanfiction' },
      { value: "5eade11a37fe886eae35c7d7", name: 'Fantasy' },
      { value: "5eade12137fe886eae35c7d8", name: 'General Fiction' },
      { value: "5eade13d37fe886eae35c7d9", name: 'Historical Fiction' },
      { value: "5eade14737fe886eae35c7da", name: 'Horror' },
      { value: "5eade15237fe886eae35c7db", name: 'Humor' },
      { value: "5eade15b37fe886eae35c7dc", name: 'Mystery / Thriller' },
      { value: "5eade16537fe886eae35c7dd", name: 'Non-Fiction' },
      { value: "5eade17237fe886eae35c7de", name: 'Paranormal' },
      { value: "5eade1e237fe886eae35c7df", name: 'Poetry' },
      { value: "5eade1ee37fe886eae35c7e0", name: 'Random' },
      { value: "5eade1f237fe886eae35c7e1", name: 'Romance' },
      { value: "5eade1fc37fe886eae35c7e2", name: 'Science Fiction' },
      { value: "5eade20437fe886eae35c7e3", name: 'Short Story' },
      { value: "5eade20e37fe886eae35c7e4", name: 'Spiritual' },
      { value: "5eade21837fe886eae35c7e5", name: 'Vampire' },
      { value: "5eade23d37fe886eae35c7e6", name: 'Teen Fiction' },
      { value: "5eade25937fe886eae35c7e7", name: 'Werewolf' },
    ];

    this.writeTitle = localStorage.getItem('writeTitle');
    this.writeSentence = localStorage.getItem('writeSentence');
    this.writeDescription = localStorage.getItem('writeDescription');
    this.writeContentType = localStorage.getItem('writeContentType') ? localStorage.getItem('writeContentType') : '5ea29e78fa235a61a420b9f4';
    this.writeCopyright = localStorage.getItem('writeCopyright') ? localStorage.getItem('writeCopyright') : '5ea1388219e995193b5e6b1c';

    let tempGenre = localStorage.getItem('writeGenre');

    let genreListArrays = Object.entries(this.genreList);

    const filtered = genreListArrays.filter((key: any, hmm: any) => {
      return (tempGenre?.includes(key[1].value) || false)
    });

    let newArrayGenre = []

    for (let i = 0; i < filtered.length; i++) {
      newArrayGenre.push(filtered[i][1])
    }

    this.genreVal = newArrayGenre

    let tempTags = localStorage.getItem('writeTags')
    let arrTags = tempTags?.split(',') || [];

    let newArrayTags = []

    for (let i = 0; i < (arrTags?.length || 0); i++) {
      if (arrTags[i] !== '') {
         newArrayTags.push({ name: arrTags[i]})
      }
    }

    this.tags = newArrayTags

    this.navbarService.setNavbar(true);
    this.handleEdit.bookImage.subscribe((res: any) => {
      var { cover_image, editable } = this.helper.appendBaseUrlToImage(res);
      if (this.appComponent.getPreviousUrl() === '/create-stories') {
        this.url = 'assets/img/rectangle1.svg'
      } else {
        this.url = cover_image;
        this.editBook = editable;
      }
    });

    this.handleEdit.setMode('write');
    this.addStoriesService.getContentType().subscribe((result: any) => {
      this.contentType = result.data.content;
    });
  }


  getEditBook() {
    this.isLoading = true;
    this.editStoriesService.getEditBook({ book_id: this.book_id }).subscribe(
      (res: any) => {
        if (res.status) {
          this.bookData = res.data;
          console.log(this.bookData)
          let published_chapters = this.bookData.published_chapters.length
          let draft_chapters = this.bookData.draft_chapters.length
          if(published_chapters == 0 && draft_chapters == 0){
            console.log(true)
            this.noChapters = true
            this.chuseWriteCapter = false
            console.log('user  have no chapters')
          }else{
            console.log(false)
            this.noChapters = false
            this.chuseWriteCapter = true;
            console.log('user have chapters')
          }

        }
        this.isLoading = false;
      }, (err) => {
        console.log("ERROR =>", err);
        this.toastr.error(err.message || 'Something went wrong');
      }
    )
  }

  onSelectCoverImage(event) {
    // if (event.target.files.length > 0) {
    //   console.log('event: ', event.target.files);
    //   this.showLoader = true;
    //   const formData = new FormData();
    //   this.selectedFiles = event.target.files;
    //   this.currentFileUpload = this.selectedFiles.item(0);
    //   formData.append('image', this.currentFileUpload);
    //   // this.addtype.chapter_image(formData).subscribe(
    //   //   (res: any) => {
    //   //     if (res) {
    //   //       if (res.status) {
    //   //         $('.hello').show();
    //   //         $('#abc_frame').hide();
    //   //         this.chapterimage = res.data;
    //   //         this.updateForm.controls['image'].setValue(res.data);
    //   //         this.showLoader = false;
    //   //       }
    //   //     }
    //   //   },
    //   //   (error) => {
    //   //     console.log('ERROR', error);
    //   //   }
    //   // );
    // }

    if (event.target.files.length > 0) {
      // this.showLoader = true;
      const formData = new FormData();
      this.selectedFiles = event.target.files;
      this.currentFileUpload = this.selectedFiles.item(0);
      formData.append('image', this.currentFileUpload);
      this.addtype.chapter_image(formData).subscribe(
        (res: any) => {
          if (res) {
            if (res.status) {
              // $('.hello').show();
              this.chapterimage = res.data;
              this.updateForm.controls['image'].setValue(res.data);
              // this.showLoader = false;
            }
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
    }
  }

  removeChapterImage(){
    this.chapterimage = ''
  }

  @ViewChild('hiddenText') textEl: ElementRef;

  minWidth: number = 280;
  width: number = this.minWidth;

  resize() {
    setTimeout(() => {
      const minWidth = 64;
      var maxWidth = 0
      if (this.textEl.nativeElement.offsetWidth > minWidth) {
        this.width = this.textEl.nativeElement.offsetWidth;
        maxWidth = this.textEl.nativeElement.offsetWidth;
      } else if( this.width < maxWidth) {
        this.width = this.textEl.nativeElement.offsetWidth - 2;
      }
    });
  }

  resizeIframe(obj: any) {
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
  }
  updateResults(results: VideoDetails[]): void {
    this.results = results;
    if (this.results.length === 0) {
      this.message = 'Not found...';
    } else {
      this.message = 'Top 10 results:';
    }
  }
  removeUploadVideo(){
    this.rightURL =''
  }

  quillEditorRef: any;
  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = this.quillEditorRef.getModule('toolbar');
    toolbar.addHandler('image', this.uploadImageHandler);
  }

  uploadImageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files?.length ? input.files[0] : null;
      if(!file) {
        // TODO implement nice popup
        alert('File not found, please try again');
        return;
      }
      const range = this.quillEditorRef.getSelection();

      const formData = new FormData();
      formData.append('image', file);

      this.addtype.chapter_image(formData).subscribe((res : any) => {
        console.log(res);
        if(res?.status){
          const image_url = `${environment.imageBaseUrl}${res?.data}`;
          this.quillEditorRef.insertEmbed(range.index, 'image',image_url);
        }
      });
    }
  }
}

