import { Component, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { ViewManageContentService } from '../view-manage-content/view-manage-content.service';
import { BaseUrl } from 'src/app/base-url';

@Component({
  selector: 'app-published-book-view',
  templateUrl: './published-book-view.component.html',
  styleUrls: ['./published-book-view.component.sass']
})
export class PublishedBookViewComponent implements OnInit {
  getBook: any;
  data: any;
  // tslint:disable-next-line: variable-name
  book_id: any;
  baseimage: any;
  temp = false;
  showLoader = false;
  constructor(
    private manageservice: ViewManageContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.book_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadBook();
  }

  loadBook() {
    this.manageservice.getBookDetail(this.book_id).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        this.getBook = res.data;
        this.temp = true;
        this.showLoader = false;
      },
      () => {
        console.log("ERROR");
      }
    );
  }

}

