import { Injectable } from "@angular/core";
import { BaseUrl } from "./../../../base-url";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class AdminSubscriptionService {
  subdata: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getSubscriptions() {
    this.subdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.subdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/getSubscriptions`, null, {
      headers
    });
  }

  updateStatus(data) {
    this.subdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.subdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(this.base + `/updatesubcriptionstatus`, data, {
      headers
    });
  }
}
