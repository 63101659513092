<div class="wrapper d-flex justify-content-center">
  <div class="col-lg-10 p-0">
    <div class="">
      <div class="">
        <div class="d-flex {{imageUrl.length === 0 ? 'upload-photo-container' : 'upload-photo-container-after' }} p-0">
          <div *ngIf="!book_title" class="w-100">
            <div *ngIf="!book_title" class="content-container" >
              <div class="remove_books d-flex justify-content-center" >
                <div class="d-flex flex-column " style="margin-top: 50px;">
                  <div class="h-100 d-flex flex-column align-items-center mt-5 mt-md-0 ">
                    <h1 class="page-label mb-2">Add cover image</h1>
                    <h4 class="m-0 mb-3">(You can overlay up to 2 images)</h4>
                  </div>
                  <div class="d-flex">
                    <div
                    class="remove-image d-flex justify-content-center ml-5"
                    *ngFor="let data of imageUrl; let i = index"
                    >
                      <div class="d-flex justify-content-around remove-item-button ml-3 p-2">
                        {{ i + 1 }}
                        <img
                          (click)="removeImage(i)"
                          class="ml-2 close-button-desktop"
                          src="../../assets/images/removeImg.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mb-4">
                <div class="d-flex justify-content-center" style="width: 271px;">
                  <div (click)="file.value = null; file.click()" class="parent">
                    <div class="d-flex justify-content-center pluss">
                      <img loading="lazy" src="../../assets/images/plusPhoto.svg" class="pluss-img"/>
                    </div>
                    <input
                      style="display: none"
                      [disabled]="twoImages"
                      type="file"
                      (change)="onSelectFile($event)"
                      #file
                    />
                    <img
                      *ngIf="imageUrl.length === 0"
                      class="book__image shadow"
                      [src]="url"
                      onerror="this.src = 'assets/images/rectangle1.svg'"
                      alt=""
                    />
                  </div>
                  <div class="parent mt-3" (click)="file.value = null; file.click()">
                    <img
                      class="book_cover_image shadow"
                      *ngFor="let url of imageUrl; let i = index; let last = last"
                      [class.superimpose-image]="last && twoImages"
                      [style.opacity]="last && twoImages ? value : 1"
                      [style.filter]="
                        last && twoImages
                          ? 'brightness(' + brightness + '%)'
                          : 'brightness(100%)'
                      "
                      [src]="url"
                      alt=""
                      onerror="this.src = 'assets/images/rectangle1.svg'"
                    />
                    <div *ngIf="!uploadImage" class="d-flex justify-content-center pluss-relative">
                      <img loading="lazy" src="../../assets/images/plusPhoto.svg" class="pluss-img"/>
                    </div>
                  </div>
                </div>
              </div>





              <div
                *ngIf="!uploadImage"
                class="plus_icon_img text-center mt-4 upload-canvas-image"
                id="first_browse"
              >

              </div>

              <canvas *ngIf="showCanvas" style="height: 0px" #canvas></canvas>

              <div
                class="brightness-transparency mb-4"
                *ngIf="transparency"
              >

                <div class="adjust__image w-100 d-flex justify-content-center">
                  <div>
                    <h3 class="m-0 text-center input-name">Brightness</h3>
                    <div class="input_range m-0 p-0">
                    <input
                      type="range"
                      (change)="changeBrightness($event)"
                      min="0"
                      max="100"
                      class="slider1 mt-4"
                      value="{{ brightness }}"
                      style="height: 8px;"
                    />
                      <div class="lines">
                        <ul class="d-flex">
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                        </ul>
                      </div>
                    </div>
                    <h3 class=" input-name" style="margin-top: 30px;">Transparency</h3>
                    <div class="input_range m-0 p-0">
                      <input
                        type="range"
                        (change)="chnageTransparency($event)"
                        min="0"
                        max="1"
                        step="0.1"
                        class="slider1 mt-4"
                        value="{{ value }}"
                        style="height:8px;"
                      />
                      <div class="lines">
                        <ul class="d-flex">
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                          <li><span></span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-buttons">
               <button
                 class="write-button-back {{imageUrl.length == 0 ? 'write-button-back-discard': ''}}"
                 color="primary"
                 (click)="reset()"
                 style="border-radius: 0 0 0 10px;"
               >
                 Back
               </button>
               <br />
               <button
                 *ngIf="imageUrl.length !== 0"
                 class="write-button-save"
                 color="primary"
                 (click)="writeText()"
                 style="border-radius: 0 0 10px 0;"
               >
                 Next
               </button>
             </div>
          </div>
          <div *ngIf="book_title" class="w-100 {{book_title ? 'mt-5' : ''}}">
            <app-image-editor-book-title
              [canvasUrl]="canvasUrl"
              (book_title)="setBookTitle($event)"
              [callbackFunction]="goBack"
            ></app-image-editor-book-title>
          </div>
        </div>
        <div class="row">

        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
