import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { InviteFriendsComponent } from '../invite-friends/invite-friends.component';
import { BookshelfService } from '../notification/bookshelf/bookshelf.service';
import { NotificationService } from '../notification/notification.service';
import { UserProfileService } from '../user-profile/user-profile.service';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    private helper: HelperService,
    private notificationService: NotificationService,
    private navbarService: NavbarService,
    private bookShelfService: BookshelfService,
    private toastrService: ToastrService
  ) {
    this.baseurl = BaseUrl.image;
  }

  viewMode: 'desktop' | 'tablet' | 'mobile' = 'desktop';
  baseurl;
  searchByInput: string = '';
  newNotificationCount: number = 0;
  bookShelfCount: number = 0;
  bookShelf: any;
  userData: any;
  notificationData: any;

  logout() {
    localStorage.clear();
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('role');
    this.router.navigate(['/login']);
  }

  showInviteOptions() {
    this.dialog.open(InviteFriendsComponent, {
      height: '242px',
      width: '700px',
      panelClass: environment.dialogBoxPanelClass,
    });
  }

  goToProfile() {
    this.userProfileService.setTabData(0);
    this.router.navigate(['authorprofile', this.userData._id]);

  }
  ngOnInit(): void {
    this.userData = this.helper.getUserData().data;

    if(this.userData?.username && this.userData?.username?.includes(':')) {
      this.userData.username = this.userData?.username?.split(':')[0]
    }
    this.getNotificationData();
    this.getBookShelfData();

    this.helper.updateUserData.subscribe(() => {
      this.userData = this.helper.getUserData().data;
    })

    this.navbarService.updateBookShelf.subscribe(() => {
      this.getNotificationData();
    })
    this.navbarService.refetchNotification.subscribe(() => {
      this.getNotificationData();
    })

  }

  search(e: any) {
    if (e.charCode === 13) {
      this.searchByInput = e?.target?.value
      this.router.navigate(['/search'], {
        queryParams: {
          search: this.searchByInput
        },
        queryParamsHandling: 'merge',
      });
    }

  }
  clickOnSearch() {
    this.router.navigate(['/search'], {
      queryParams: {
        search: this.searchByInput
      },
      queryParamsHandling: 'merge',
    });
  }
  getNotificationData() {
    this.notificationService.getNotification().subscribe((res: any) => {
      this.notificationData = res;

      this.newNotificationCount =   this.notificationData?.total_unread_items; //this.notificationService.getNewNotificationCount(res?.data);
      // this.bookShelfCount = this.notificationData?.bookshelfData?.count;
    });
  }

  getBookShelfData() {
    this.bookShelfService.getBookShelf().subscribe(
      (res: any) => {
        if (res.status) {
          this.bookShelf = res;
          this.bookShelfCount = this.bookShelf?.total_unread_items || 0;
        }
      },
      (error) => {
        console.log('ERROR', error);
        this.toastrService.error(error?.message || 'Something went wrong');
      }
    );
  }

  windowSize: { width?: number; height?: number } = {};
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowSize = {
      width: event.target.innerWidth,
      height: event.target.innerHeight,
    };
    this._updateViewMode()
  }

  private _updateViewMode() {
    if (this.windowSize.width <= 832) {
      this.viewMode = 'tablet';
    } else {
      this.viewMode = 'desktop'
    }
  }

}
