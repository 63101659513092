import { NgModule } from '@angular/core';
import { ProfileLayoutComponent } from './profile-layout.component';
import { ProfileDescComponent } from './component/profile-desc/profile-desc.component';
import { ProfileDetailsComponent } from './component/profile-details/profile-details.component';
import { ProfileChangePasswordComponent } from './component/profile-change-password/profile-change-password.component';

import { ProfileBookSalesComponent } from './component/profile-book-sales/profile-book-sales.component';
import { ProfilePublishedStoriesComponent } from './component/profile-published-stories/profile-published-stories.component';


// Swiper
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ProfileDraftedStoriesComponent } from './component/profile-drafted-stories/profile-drafted-stories.component';
import { ProfileWallComponent } from './component/profile-wall/profile-wall.component';
import { ProfileCreatePostComponent } from './component/profile-create-post/profile-create-post.component';
import { AppRoutingModule } from '../app-routing.module';
import { BreadcrumbsComponent } from './component/breadcrumbs/breadcrumbs.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { MybookdetailsComponent } from './component/mybookdetails/mybookdetails.component';
import { ProfileComponent } from './component/profile/profile.component';

import { UserProfileRoutingModule } from './user-profile-routing.module';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { WithdrawalComponent } from './component/withdrawal/withdrawal.component';
import { ReadingSettingsComponent } from './component/reading-settings/reading-settings.component';
import { WithdrawMoneyComponent } from './component/withdrawal/withdraw-money/withdraw-money.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ProfileLayoutComponent,
    ProfileDescComponent,
    ProfileDetailsComponent,
    ProfileChangePasswordComponent,
    ProfileBookSalesComponent,
    ProfilePublishedStoriesComponent,
    ProfileDraftedStoriesComponent,
    ProfileWallComponent,
    ProfileCreatePostComponent,
    BreadcrumbsComponent,
    MybookdetailsComponent,
    ProfileComponent,
    FeedbackComponent,
    WithdrawalComponent,
    ReadingSettingsComponent,
    WithdrawMoneyComponent
  ],
  imports: [
    SharedModule,
    SwiperModule,
    NgxSkeletonLoaderModule,
    BreadcrumbModule,
    AppRoutingModule,
    UserProfileRoutingModule,
    NgxPayPalModule,
    NgxSkeletonLoaderModule,
  ],
  bootstrap: [ProfileLayoutComponent],
  exports: [BreadcrumbsComponent],
})
export class UserProfileModule { }
