import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { ReadChapterService } from '../read-chapter/read-chapter.service';
import { PaymentMethodService } from './payment-method.service';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.sass'],
})
export class PaymentMethodComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private chapterService: ReadChapterService,
    private paymentMethodService: PaymentMethodService,
    private router: Router,
    private helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.baseimage = BaseUrl.image;
    this.headers = this.helper.getHeader();
  }

  public payPalConfig?: IPayPalConfig;
  public amount: number = 10;

  book_id: string;
  chapter_id: string;

  bookData: any;
  baseimage: string;
  private headers: HttpHeaders;

  private paymentDataSub$: Subscription;
  private chapterDataSub$: Subscription;

  private sub: Subscription = new Subscription();
  ngOnInit(): void {
    this.paymentDataSub$ = this.paymentMethodService.paymentData.subscribe(
      (data: any) => {
        // this.
        if (data) {
        //   this.router.navigate(['/home']);
        // } else {
          this.book_id = data.book_id;
          this.chapter_id = data.chapter_id;
        }
      }
    );
    // this.getChapter({ book_id: this.book_id, chapter_id: this.chapter_id });
    this.chapterDataSub$ = this.chapterService
      .getChapter({ book_id: this.book_id, chapter_id: this.chapter_id })
      .subscribe((result: any) => {
        console.log('resu;lt: ', result);
        this.bookData = result.data;
      });
    this.chapterService
      .getChapter({
        book_id: this.book_id,
        chapter_id: this.chapter_id,
      })
      .pipe(pluck('data', 'currentChapter', 'amount'))
      .subscribe((result: any) => {
        this.amount = result;
      });

    this.sub.add(this.paymentDataSub$).add(this.chapterDataSub$);
    this.initConfig();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  initConfig = () => {
    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.PAYPAL_CLIENT_ID,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: String(this.amount),
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: String(this.amount),
                  },
                },
              },
              // payee: { email_address: 'sb-umqxq7711335@personal.example.com' },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: String(this.amount),
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        actions.order.get().then((details) => {});
      },
      onClientAuthorization: (data) => {
        this.http
          .post(
            `${BaseUrl.frontend}/sendPayouts`,
            {
              amount: this.amount,
              chapterDetails: this.bookData.currentChapter._id,
              bookDetails: this.bookData.currentChapter.book_id,
            },
            {
              headers: this.headers,
            }
          )
          .subscribe((result: any) => {
            if (result.status) {
              // this.thisDialogBox.close({ paid: 'Success' });
              this.snackbar.open(result.message, 'OK', { duration: 2000 });
            } else {
              console.log('result error', result.error);
            }
          });
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {},
      onError: (err) => {
        console.log(err);
        // this.anyError = true;
        // this.errorMessage = err;
      },
      onClick: (data, actions) => {
        // this.thisDialogBox.close({ error: data });
      },
    };
  };
}
