import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NavbarService } from './nav-sidebar/navbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private navbarService: NavbarService
  ) {}
  is_subscribed = false;
  userdata;

  userLoggedin = () => {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const dencryptedUser = JSON.parse(
        crypto.AES.decrypt(currentUser, environment.secretKey).toString(
          crypto.enc.Utf8
        )
      );
      if (dencryptedUser && dencryptedUser.status) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const dencryptedUser = JSON.parse(
        crypto.AES.decrypt(currentUser, environment.secretKey).toString(
          crypto.enc.Utf8
        )
      );
      if (dencryptedUser && dencryptedUser.status) {
        this.navbarService.setNavbar(true);
        return true;
      } else {
        this.navbarService.setNavbar(false);
        this.router.navigate(['/login']);
        return false;
      }
    } else {
      this.navbarService.setNavbar(false);
      this.router.navigate(['/login']);
      return false;
    }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

  // userdata: any;
  // is_subscribed = false;
  // constructor(
  //   private router: Router,
  //   private authenticationService: AuthenticationService,
  //   private toaster: ToastrService
  // ) {}
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   this.userdata = JSON.parse(localStorage.getItem('currentUser'));
  //   if (this.userdata) {
  //     this.authenticationService.checkUserStatus().subscribe(
  //       (res: any) => {
  //         if (res.status) {
  //           if (res.is_subscribed) {
  //             this.is_subscribed = res.is_subscribed;
  //           }
  //           if (!this.userdata.data.is_completed) {
  //             if (res.data.role != 1) {
  //               this.router.navigate(['/myprofile/editprofile']);
  //               // return;
  //             }
  //           }
  //         } else {
  //           localStorage.removeItem('currentUser');
  //           // not logged in so redirect to login page with the return url
  //           this.router.navigate(['/login'], {
  //             queryParams: { returnUrl: state.url },
  //           });
  //           // return false;
  //         }
  //       },
  //       (error) => {
  //         if (error.status == 405) {
  //           $('.loader').hide();
  //           // $("#accountblock").modal('show');
  //           this.toaster.error(
  //             'Your account has been temporarily suspended. Kindlly contact the support team.'
  //           );
  //           setTimeout(function () {
  //             localStorage.removeItem('currentUser');
  //             // not logged in so redirect to login page with the return url
  //             window.location.href = '/';
  //             return false;
  //           }, 4000);
  //         }
  //       }
  //     );
  //   }

  //   const currentUser = this.authenticationService.currentUserValue;
  //   if (currentUser) {
  //     if (
  //       route.data.roles &&
  //       route.data.roles.indexOf(currentUser) === 'admin'
  //     ) {
  //       // role not authorised so redirect to home page
  //       this.router.navigate(['/dashboard']);
  //       return false;
  //     }
  //     // authorised so return true
  //     return true;
  //   }

  //   // not logged in so redirect to login page with the return url
  //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //   return false;
  // }
}
