import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as crypto from 'crypto-js';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ViewManagePageService {
  viewpages: any;
  datapage: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getPageDetail(id) {
    this.viewpages = localStorage.getItem("currentUser")
    let token = JSON.parse( crypto.AES.decrypt(this.viewpages, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getPageDetail`,
      { page_id: id },
      {
        headers
      }
    );
  }

  updatePageDetail(data) {
    this.datapage = localStorage.getItem("currentUser")
    let token = JSON.parse( crypto.AES.decrypt(this.datapage, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/updatePageDetail`, data, {
      headers
    });
  }
}
