<div class="wrapper mb-5" style="background-image: url(../../assets/img/renders-reviews-bg.png);">
  <div id="content-page" class="content-page p-0" style="margin: auto;">
    <div class="col-lg-12 renders-reviews">
      <h1 class="renders-reviews-title">Reviews</h1>


      <div class="iq-card iq-card-block iq-card-stretch iq-card-height" style="border-radius: 10px;">
        <mat-accordion style="border-radius: 10px;">
          <mat-expansion-panel [expanded]="expandPanel" (opened)="getBookReview()" style="border-radius: 10px;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="card-title mb-0 mt-0 section-heading">Reviews</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row gap-5 gap-lg-0">
              <ng-container *ngIf="reviewers?.length && !showLoader">
                <div class="col-12 col-md-12 col-lg-6" *ngFor="let data of reviewers">
                  <div class="renders-reviews-card">
                    <div class="reviews-profile d-flex mt-3">
                      <div routerLink="/authorprofile/{{
                  data.user_id._id
                }}" class="cursor-pointer">
                        <img loading="lazy" *ngIf="data.user_id.image === ''" class="profile-pic"
                          style="border-radius: 50%;"
                          src="https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png" />

                        <img loading="lazy" *ngIf="data.user_id.image !== ''" class="profile-pic"
                          style="border-radius: 50%;" src="{{ baseimage }}{{ data.user_id.image }}" alt="" />
                      </div>
                      <div>
                        <h3 routerLink="/authorprofile/{{
                    data.user_id._id
                  }}" class="user-name m-0">
                          @<span class="font-italic">{{
                            data.user_id.username
                            }}</span>
                        </h3>
                        <span *ngIf="!data.edit">
                          <i style="color: #FEBF00" *ngFor="let star of [1, 2, 3, 4, 5]" class="fa-star" [ngClass]="
                        star <= data.stars ? 'fas' : 'far'
                      "></i>
                        </span>
                      </div>
                    </div>
                    <div class="discription">
                      <p class="discription-text">{{data?.reviewComment}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                  <div class="renders-reviews-card overflow-hidden d-flex justify-content-between flex-column">
                    <div class="container">
                      <div class="d-flex justify-content-between align-items-center mb-2 mb-md-5">
                        <div class="reviews-count">
                          {{ bookRating?.averageRating }}
                        </div>
                        <p class="row pt-5">
                          Average from {{ bookRating?.totalReviewers }} reviews
                        </p>
                      </div>
                      <div class="container" *ngIf="!noData">
                        <div class="row pl-2 mt-3">
                          <span class="col-lg-3">
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                          </span>
                          <div class="col-lg-9 mt-2" matTooltip="{{ bookRating?.star_5 }}" matTooltipPosition="above">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ bookRating?.star_5 }}"
                                aria-valuemin="0" attr.aria-valuemax="{{
                            bookRating?.totalReviewers
                          }}" [style.width]="
                            (bookRating?.star_5 /
                              bookRating?.totalReviewers) *
                              100 +
                            'px'
                          "></div>
                            </div>
                          </div>
                        </div>
                        <div class="row pl-2 mt-3">
                          <span class="col-lg-3">
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                          </span>
                          <div class="col-lg-9 mt-2" matTooltip="{{ bookRating?.star_4 }}" matTooltipPosition="above">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ bookRating?.star_4 }}"
                                aria-valuemin="0" attr.aria-valuemax="{{
                            bookRating?.totalReviewers
                          }}" [style.width]="
                            (bookRating?.star_4 /
                              bookRating?.totalReviewers) *
                              100 +
                            'px'
                          "></div>
                            </div>
                          </div>
                        </div>
                        <div class="row pl-2 mt-3">
                          <span class="col-lg-3">
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                          </span>
                          <div class="col-lg-9 mt-2" matTooltip="{{ bookRating?.star_3 }}" matTooltipPosition="above">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ bookRating?.star_3 }}"
                                aria-valuemin="0" attr.aria-valuemax="{{
                            bookRating?.totalReviewers
                          }}" [style.width]="
                            (bookRating?.star_3 /
                              bookRating?.totalReviewers) *
                              100 +
                            'px'
                          "></div>
                            </div>
                          </div>
                        </div>
                        <div class="row pl-2 mt-3">
                          <span class="col-lg-3">
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                          </span>
                          <div class="col-lg-9 mt-2" matTooltip="{{ bookRating?.star_2 }}" matTooltipPosition="above">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ bookRating?.star_2 }}"
                                aria-valuemin="0" attr.aria-valuemax="{{
                            bookRating?.totalReviewers
                          }}" [style.width]="
                            (bookRating?.star_2 /
                              bookRating?.totalReviewers) *
                              100 +
                            'px'
                          "></div>
                            </div>
                          </div>
                        </div>
                        <div class="row pl-2 mt-3">
                          <span class="col-lg-3">
                            <i style="color: #FEBF00" class="fas fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                            <i style="color: #FEBF00" class="far fa-star"></i>
                          </span>
                          <div class="col-lg-9 mt-2" matTooltip="{{ bookRating?.star_1 }}" matTooltipPosition="above">
                            <div class="progress">
                              <div class="progress-bar" role="progressbar" style="width: 25%"
                                attr.aria-valuenow="{{ bookRating?.star_1 }}" aria-valuemin="0" attr.aria-valuemax="{{
                            bookRating?.totalReviewers
                          }}" [style.width]="
                            (bookRating?.star_1 /
                              bookRating?.totalReviewers) *
                              100 +
                            'px'
                          "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button (click)="writeAReview()" class="write-review-btn">
                      Write a review
                    </button>
                  </div>
                </div>
              </ng-container>
              <div class="col-12 col-md-12">
                <form *ngIf="writeReview" (ngSubmit)="onSubmit(review)" class=" w-100 mt-5" [formGroup]="review">
                  <div class="renders-reviews-card overflow-hidden d-flex justify-content-between flex-column">
                    <div class="container">
                      <div class="col-sm-12 d-flex justify-content-center">
                        <ul class="list-inline rating-list" style="display: inline-block"
                          *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                          <li class="cursor-pointer" style="font-size: 30px;" (click)="countStar(star)"
                            id="{{ 'starId' + i }}" [ngClass]="{ selected: star <= selectedValue }"
                            (mouseover)="addClass(star)" (mouseout)="removeClass(star)">
                            ★
                          </li>
                        </ul>
                      </div>

                      <mat-form-field class="w-50" appearance="outline">
                        <textarea class="w-100" name="write__review" cols="30" rows="10" matInput
                          formControlName="reviewText" placeholder="Enter your review" [class.border-danger]="
                            review.get('reviewText').invalid &&
                            (review.get('reviewText').dirty ||
                              review.get('reviewText').touched)
                          "></textarea>
                      </mat-form-field>
                      <small class="text-danger" *ngIf="
                          review.get('reviewText').invalid &&
                          (review.get('reviewText').dirty ||
                            review.get('reviewText').touched)
                        ">
                        Enter a review
                      </small>
                      <div class="d-flex justify-content-center w-75 mx-auto mt-5">
                        <button class="write-review-btn" type="submit" [disabled]="!review.valid">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div *ngIf="!reviewers?.length && !showLoader && !writeReview">
              <div align="center">
                <h3>Not any review yet!</h3>
                <img src="assets/images/undraw_not_found.svg" alt="No review image" class="no-review-image shadow-sm">
                <button (click)="writeAReview()" class="write-review-btn mt-4">
                  Write a review
                </button>
              </div>
            </div>

            <div *ngIf="showLoader">
              <div class="mx-3">
                <app-loader type="placeholder" placeholderType="grid" placeholderLength="2" height="450px" width="45%">
                </app-loader>
              </div>
            </div>


          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>