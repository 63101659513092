<div
  class="iq-card-header d-flex justify-content-between align-items-center mb-0"
>
  <div class="iq-header-title">
    <h1 class="card-title mb-0">My Drafted Stories</h1>
  </div>
  <div class="iq-card-header-toolbar d-flex align-items-center">
    <div class="dropdown">
      <span
        class="dropdown-toggle text-primary"
        id="dropdownMenuButton05"
        data-toggle="dropdown"
      >
        <i class="ri-more-fill"></i>
      </span>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton05"
      >
        <a class="dropdown-item" href="#"
          ><i class="ri-eye-fill mr-2"></i>View</a
        >
        <a class="dropdown-item" href="#"
          ><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a
        >
        <a class="dropdown-item" href="#"
          ><i class="ri-pencil-fill mr-2"></i>Edit</a
        >
        <a class="dropdown-item" href="#"
          ><i class="ri-printer-fill mr-2"></i>Print</a
        >
        <a class="dropdown-item" href="#"
          ><i class="ri-file-download-fill mr-2"></i>Download</a
        >
      </div>
    </div>
  </div>
</div>
<div class="iq-card-body book-slider-container">
  <ul class="swiper-container drafted-books-slider perfomer-lists m-0">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide rafted-published"
        *ngFor="let data of draftedStories"
      >
        <div>
          <!-- <img
            *ngIf="data.cover_image"
            class="image-tile"
            [routerLink]="['../mybookdetails', data._id]"
            [src]="data.cover_image"
          /> -->

          <a
            *ngIf="data.cover_image !== ''"
            [routerLink]="['../mybookdetails', data._id]"
          >
            <img
              *ngIf="data.cover_image !== ''"
              class="image-tile"
              src="{{ baseurl }}{{ data.cover_image }}"
              alt=""
            />
            <div *ngIf="data.is_blocked" class="block_txt">
              <h3>Blocked</h3>
            </div>
          </a>
          <a
            *ngIf="data.cover_image === ''"
            [routerLink]="['../mybookdetails', data._id]"
          >
            <div
              *ngIf="data.cover_image == ''"
              class="image-tile patahani-cover"
            >
              <h2 class="patahani-h2">{{ data.title }}</h2>
            </div></a
          >
          <div class="p-3">
            <div
              class="product-single-details"
              [class.no-cover-image]="data.cover_image === ''"
            >
              <!-- <h1 class="product-title">Wendy Walker</h1> -->
              <h1 class="product-title">{{ data.title }}</h1>
              <h6 class="product-category" style="margin-top: -12px">
                {{ data.genre }}
              </h6>
              <div class="ratings-container">
                <div class="product-details text-center">
                  <div class="price-box updated-on">
                    <span class="last-updated-on">
                      Last Updated On: {{ data.chapter_updated_at }}
                      <div class="product-details mt-3">
                        <div class="category-wrap">
                          <div class="ratings-container rating-stars">
                            <div class="product-ratings">
                              <span class="ratings" style="width: 100%">
                                ></span
                              >
                            </div>
                            <a href="#" class="rating-link">( 6 Reviews )</a>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="ratings-container likes-views-desc-book">
              <div class="product-details text-center book-icons">
                <div class="ratings-container" style="display: flex">
                  <div class="book-interation drafted-book-interactions">
                    <img
                      class="book-details-icons"
                      src="../../assets/Slider/images/icons/eye.svg"
                      alt=""
                    />
                    <span class="views-hearts-shares-bookshelves">
                      {{ data.views }}
                    </span>
                  </div>
                  <div class="book-interation drafted-book-interactions">
                    <img
                      class="book-details-icons"
                      src="../../assets/Slider/images/icons/heart.svg"
                      alt=""
                    />
                    <span class="views-hearts-shares-bookshelves">
                      {{ data.likes }}
                    </span>
                  </div>
                  <div class="book-interation drafted-book-interactions">
                    <img
                      class="book-details-icons"
                      src="../../assets/Slider/images/icons/share.svg"
                      alt=""
                    />
                    <span class="views-hearts-shares-bookshelves">
                      {{ data.no_of_shares }}
                    </span>
                  </div>
                  <div class="book-interation drafted-book-interactions">
                    <img
                      class="book-details-icons"
                      src="../../assets/Slider/images/icons/open-book.svg"
                      alt=""
                    />
                    <span class="views-hearts-shares-bookshelves">
                      {{ data.no_of_chapter }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-stories-slider-button">
      <div class="swiper-button-prev">
        <i class="fas fa-chevron-left previous-button-icon"></i>
      </div>
      <div class="swiper-button-next">
        <i class="fas fa-chevron-right next-button-icon"></i>
      </div>
    </div>
  </ul>
</div>
