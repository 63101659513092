import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class BackendFeedbackService {
  feedbackdata: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getFeedbacks() {
    this.feedbackdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.feedbackdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/getFeedbacks`, null, {
      headers
    });
  }

  deleteFeedback(feedback_id) {
    this.feedbackdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.feedbackdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/deleteFeedback`, feedback_id, {
      headers
    });
  }
}
