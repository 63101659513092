import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Swiper, { SwiperOptions } from 'swiper';
import { nonCoverflowSwiperConfig } from '../swiper-config';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { GlobalService } from '../global.service';
import { Router } from '@angular/router';
import { BookDataService } from '../services/book-data.service';
import { BookDetailService } from '../book-detail/book-detail.service';
import { BaseUrl } from '../base-url';
import * as crypto from 'crypto-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavbarService } from '../navbar/navbar.service';

interface tagDataObject {
  data: tagData;
  relatedBooks: any
}

interface tagData {
  author_id: string;
  _id: string;
  imageSrc: string;
  author: string;
  LastUpdatedOn: string;
  Bookshelves: number;
  Shares: number;
  Hearts: number;
  Views: number;
  numberOfbooks: number;
  genre: string;
  BackgroundImage: string;
  author_username: string;
}

interface tags {
  _id: string;
  name: string;
}
@Component({
  selector: 'app-newly-added-slider',
  templateUrl: './newly-added-slider.component.html',
  styleUrls: ['./newly-added-slider.component.sass']
})
export class NewlyAddedSliderComponent implements OnInit {

  public slides = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];

  public slideConfigpr = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    infinite: true,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1445,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      // {
      //   breakpoint: 400,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   }
      // },
      // {
      //   breakpoint: 500,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //   }
      // },
      // {
      //   breakpoint: 500,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   }
      // }
    ]
  };

  public addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }
  public removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  public slickInit(e: any) {
    console.log('slick initialized');
  }
  public breakpoint(e: any) {
    console.log('breakpoint');
  }
  public afterChange(e: any) {
    console.log('afterChange');
  }
  public beforeChange(e: any) {
    console.log('beforeChange');
  }


  public bookData;
  public baseimage;
  public bookDetails = [];

  public showView: boolean = false;

  public imageBaseUrl = environment.imageBaseUrl

  constructor(
    private dialog: MatDialog,
    private globalService: GlobalService,
    private router: Router,
    private bookDetailService: BookDetailService,
    private bookDataService: BookDataService,
    private navbarService: NavbarService,
    private _snackBar: MatSnackBar,
  ) {
    this.baseimage = BaseUrl.image;
  }

  showQuickView() {
    if (this.showView) {
      this.showView = false;
    } else {
      this.showView = true;
    }
  }

  openBookDescription(book: any) {
    console.log("books => ", book);
    localStorage.setItem('descriptionData', JSON.stringify(book));
    this.router.navigate(['book', 'explore', book?.data?._id, book?.relatedBooks?.genre_id]);

  }

  ngAfterViewInit() {
    var nely_swiper = new Swiper('.nely-added-swiper', {
      slidesPerView: 4,
      spaceBetween: 20,
      grabCursor: true,
      breakpoints:{
        "2000": {
          slidesPerView: 4,
        },
        "1600": {
          slidesPerView: 3.5,
        },
        "1400": {
          slidesPerView: 3,
        },
        "700": {
          slidesPerView: 2.5,
        },
        "572":{
          slidesPerView: 2,

        },
        "501":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "450":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "400":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "375":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "350":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
      slidesPerGroup: 1,
      observer: true,
      navigation: {
        nextEl: '.newly-added-swiper-button-next',
        prevEl: '.newly-added-swiper-button-prev',
      },
    });
  }

  tags: tags[];
  tagData: tagDataObject[];
  bookDataByTag: any;

  ngOnInit(): void {
    this.getNewlyAddedBooks()
  }

  getNewlyAddedBooks = () => {
    let token = localStorage.getItem('currentUser')
    const decryptedUser = crypto.AES.decrypt(
      token,
      environment.secretKey
    ).toString(crypto.enc.Utf8)
    let user = JSON.parse(decryptedUser)?.data?._id
    this.bookDataService.getNewlyAddedBooks(10,0).subscribe((result: any) => {
      this.bookDetails = result.data
      console.log('bookDetails', this.bookDetails)
    });
  }
  gotoBookDetails(index: number) {
    this.bookDetailService.setSliderData({
      data: this.bookDataByTag[index].data,
      index: 0,
    });
    this.router.navigate(['/book']);
  }
  addToBookShelf(book: any) {
    console.log('book', book)
    this.bookDetailService.addToBookShelf({ book_id: book?._id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = true;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          if (res.message === 'Book already in bookshelf.') {
            this.removeBookShelf(book)
          } else {
            this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          }
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  removeBookShelf(book: any) {
    this.bookDetailService.removeToBookShelf({ book_id: book?._id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = false;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

}
