import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class EditManageGenreService {
  editgenre: any;
  updategenre: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getGenreDetail(id) {
    this.editgenre = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.editgenre, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getGenreDetail`,
      { genre_id: id },
      {
        headers
      }
    );
  }

  updateGenre(data) {
    this.updategenre = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.updategenre, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/updateGenre`, data, {
      headers
    });
  }
}
