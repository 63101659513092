import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ExploreService {
  baseUrl: String;
  baseurl: String;
  headers: HttpHeaders;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseUrl = BaseUrl.admin;
    this.baseurl = BaseUrl.frontend
    this.headers = helper.getHeader();
  }

  getGenreDetails() {
    return this.http.post(`${this.baseUrl}/getGenres`, null, {
      headers: this.headers,
    });
  }

  explore(postData) {
    return this.http.post(this.baseurl + `/getBookListing`, postData, {
      headers: this.headers,
    });
  }
  loadMoreBooks(postData) {
    return this.http.post(this.baseurl + `/loadMoreBooks`, postData, {
      headers: this.headers,
    });
  }
}
