<div class="profile-header">
  <div class="cover-container text-center">
    <img
      *ngIf="userData.image !== ''"
      src="{{ baseurl }}{{ userData.image }}"
      alt="profile-bg"
      class="rounded-circle img-fluid"
      style="margin-left: 39%; width: 100px; height: 100px"
    />
    <img
      *ngIf="userData.image === ''"
      src="https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png"
      alt="profile-bg"
      class="rounded-circle img-fluid"
      style="margin-left: 39%; width: 100px; height: 100px"
    />
    <div class="profile-detail mt-3">
      <h1 style="font-weight: 500; font-size: 1.93em">
        {{ userData.fullname }}
      </h1>
      <h3>@{{ userData.username }}</h3>
      <p style="font-size: 19px">Web designer</p>
      <p>Hi my name is {{ userData.fullname }} and this is my bio.</p>
    </div>
    <div class="iq-social d-inline-block align-items-center">
      <ul class="list-inline d-flex p-0 mb-0 align-items-center">
        <li>
          <a href="#" class="avatar-40 rounded-circle bg-primary mr-2 facebook"
            ><i class="fa fa-facebook" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a href="#" class="avatar-40 rounded-circle bg-primary mr-2 twitter"
            ><i class="fa fa-twitter" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a href="#" class="avatar-40 rounded-circle bg-primary mr-2 youtube"
            ><i class="fa fa-youtube-play" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a href="#" class="avatar-40 rounded-circle bg-primary pinterest"
            ><i class="fa fa-pinterest-p" aria-hidden="true"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</div>
