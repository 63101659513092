import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const bookdata = localStorage.getItem("currentUser")
    if(bookdata) {
      let token = JSON.parse(crypto.AES.decrypt(bookdata, environment.secretKey).toString(crypto.enc.Utf8)).data.token
      request = request.clone({  setHeaders: { "x-access-token": token || '' }});
    }

    return next.handle(request);
  }
}
