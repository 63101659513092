import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { ImageDialogComponent } from 'src/app/image-dialog/image-dialog.component';
import { Post } from '../profile-create-post/profile-create-post.component';

@Component({
  selector: 'app-profile-wall',
  templateUrl: './profile-wall.component.html',
  styleUrls: ['./profile-wall.component.sass'],
})
export class ProfileWallComponent implements OnInit {
  @Input() Post: any;

  public baseurl: string;

  public postArray: any;
  constructor(private helper: HelperService, private dialog: MatDialog) {
    this.baseurl = BaseUrl.image;
  }

  userData;
  showLoader: boolean = true;

  // deletePost(id: number) {
  //   const deleteThisPost = (post) => post._id === id;

  //   const postIndex = this.postArray.findIndex(deleteThisPost);
  //   this.postArray.splice(postIndex, 1);
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.Post.currentValue) {
      this.showLoader = false;
      this.postArray = changes.Post.currentValue;
    }
  }

  ngOnInit(): void {
    this.userData = this.helper.getUserData().data;
  }

  imageDialog(imageUrl: string) {
    this.dialog.open(ImageDialogComponent, {
      data: { url: imageUrl, uploadedImage: true, crop: false },
      height: 'auto',
      width: 'auto',
    });
  }
}
