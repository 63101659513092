import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { WriteBookService } from '../services/write-book.service';
import * as $ from 'jquery';
import { drawImage } from 'canvas-object-fit';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ImageService } from '../image-editor-canvas/image.service';
import { ThisReceiver } from '@angular/compiler';
import { filter } from 'rxjs/operators';
import { HandleEditService } from './handle-edit.service';
import { NavbarService } from '../nav-sidebar/navbar.service';
import {GlobalService} from '../global.service'
declare const Dropbox: any;

@Component({
  selector: 'app-add-book-cover',
  templateUrl: './add-book-cover.component.html',
  styleUrls: ['./add-book-cover.component.sass'],
})
export class AddBookCoverComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('dropbox__container') dropbox__container: ElementRef;
  @Output() public imageCanvas: EventEmitter<any> = new EventEmitter();

  public url = 'https://testing.librums.com/assets/img/rectangle1.svg';

  private returnTo: string = '';
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private imageService: ImageService,
    private handleEdit: HandleEditService,
    private navbarService: NavbarService,
    private globalService: GlobalService

  ) {
    console.log('events url', router.events);
    // var dbx = new Dropbox()
    this.handleEdit.modeVal.subscribe((res) => {
      this.returnTo = res;
    });
  }

  /**
   * START -  Add Multiple Images
   */
  public getWrite = this.globalService.globalWriteState
  public imageUrl: string[] = [];
  public twoImages: boolean = false;
  public transparency: boolean = false;
  public brightnessAndTransparency = true;
  public book_title = false;
  public uploadImage = false;

  reset() {
    // const dailogRef = this.dialog.open(ConfirmationModalComponent, {
    //   data: { cancel: true },
    //   width: '500px',
    //   height: '190px',
    // });

    // dailogRef.afterClosed().subscribe((result) => {
    //   if (result) {
    //     this.imageService.reset();
    //     this.router.navigate([`/${this.returnTo}`]);
    //   }
    // });
    this.router.navigate([`/${this.returnTo}`]);
    this.canvasUrl = ''
  }

  changeWriteState(){
    this.getWrite == true
  }

  ImageDialogClosed(dialogRef: MatDialogRef<ImageDialogComponent>) {
    dialogRef.afterClosed().subscribe((result: any) => {
      if(!result) return;
      this.imageService.f(result.data);
      this.twoImages = this.imageUrl.length === 2 ? true : false;
      this.uploadImage = this.twoImages;
      this.transparency = this.twoImages;
      this.brightnessAndTransparency = !this.twoImages;
    });
  }

  onSelectFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dialogRef = this.dialog.open(ImageDialogComponent, {
        data: { url: reader.result, uploadedImage: true, crop: true },
        height: '370',
        width: '260',
      });

      // dialogRef.afterClosed().subscribe((result: any) => {});
      this.ImageDialogClosed(dialogRef);
    };
    reader.readAsDataURL(file);
  }
  /**
   * END -  Add Multiple Images
   */

  /**
   * START -  Multiple Image editor
   */
  autoTicks = false;
  disabled = false;
  invert = false;

  max = 1;
  min = 0;
  value = 0.5;
  tickInterval = 0.1;

  showTicks = false;
  step = 0.1;
  thumbLabel = true;

  max_brightness = 100;
  min_brightness = 1;
  brightness = 50;
  tickInterval_brightness = 1;
  step_brightness = 1;

  vertical = false;

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }
  /**
   * END - Mutliple Image Editor
   */

  /**
   * Create Canvas
   */

  public showCanvas = true;
  public canvasUrl: any;
  createCanvas(nativeElement: any) {
    var canvas: HTMLCanvasElement = nativeElement;
    var context: CanvasRenderingContext2D = <CanvasRenderingContext2D>(
      canvas.getContext('2d')
    );
    if (this.imageUrl.length === 2) {
      let img1 = new Image();
      img1.src = this.imageUrl[0];

      let img2 = new Image();
      img2.src = this.imageUrl[1];

      img1.onload = () => {
        canvas.width = 270;
        canvas.height = 370;
        context.drawImage(img1, 0, 0, 270, 370);
      };

      img2.onload = () => {
        context.globalAlpha = 1.0;
        drawImage(context, img1, 0, 0, 270, 370, { objectFit: 'cover' });
        context.globalAlpha = this.value; //Remove if pngs have alpha
        context.filter = `brightness(${this.brightness}%)`;

        drawImage(context, img2, 0, 0, 270, 370, { objectFit: 'cover' });

        // this.imageCanvas.emit(canvas.toDataURL());
        this.canvasUrl = canvas.toDataURL();
      };
    } else {
      let img1 = new Image();
      img1.src = this.imageUrl[0];

      img1.onload = () => {
        canvas.width = 270;
        canvas.height = 370;

        drawImage(context, img1, 0, 0, 270, 370, { objectFit: 'cover' });

        this.canvasUrl = canvas.toDataURL();
      };
    }
  }
  /**
   * END - Create Canvas
   */

  /**
   * START - Write Text
   */
  public text = false;
  public bookTitle: string = '';
  writeText() {
    this.showCanvas = true;
    this.createCanvas(this.canvas.nativeElement);
    this.text = true;
    this.transparency = false;
    this.book_title = !this.book_title;
  }
  /**
   * END - Write Text
   */

  /**
   * Create cover image
   */

  createCoverImage() {
    this.uploadImage = !this.uploadImage;
    this.twoImages = false;
  }

  removeImage(index: number) {
    this.twoImages = false;
    // this.imageUrl.splice(index, 1);
    // this.imageUrl = this.imageService.removeImage(index);
    this.imageService.rf(index);
    this.transparency = false;
  }

  // brightnessValue = 50;
  // opacity = 0.5;

  changeBrightness(size) {
    size = size.target.value;
    this.brightness = size;
  }

  chnageTransparency(val) {
    val = val.target.value;
    this.value = val;
  }

  setBookTitle(event: any) {
    this.book_title = event;
    if (this.imageUrl.length != 2) {
      this.twoImages = false;
    }
    this.transparency = false;
    this.brightnessAndTransparency = true;
    this.uploadImage = false;
  }

  ngAfterViewInit() {
    var self = this;
    var options = {
      success: function (files) {
        var thumb = files[0].thumbnailLink;
        thumb = thumb.replace('bounding_box=75', 'bounding_box=2048');
        thumb = thumb.replace('mode=fit', 'mode=crop');
        console.log('fileS', files[0].thumbnailLink);
        const dialogRef = self.dialog.open(ImageDialogComponent, {
          data: { url: thumb },
          height: 'auto',
          width: 'auto',
        });
        self.ImageDialogClosed(dialogRef);
      },
      bounding_box: 2048,
    };
    var button = Dropbox.createChooseButton(options);
    document.getElementById('Dropbox-Container').appendChild(button);
  }

  addToResult(file: any) {
    var link = file.link;
    var filename = file.name;
    console.log('link', link);
    console.log('filename', filename);
    console.log('file', file);
    // var a = document.createElement('a');
    // a.href = link;
    // a.target = '_blank';
    // a.text = filename;
    // document.getElementById('result').appendChild(a);
  }

  ngOnInit(): void {
    this.navbarService.setNavbar(true);
    this.imageService.image.subscribe((result) => {
      this.imageUrl = result;
      this.twoImages = this.twoImages =
        this.imageUrl.length === 2 ? true : false;
    });
  }

  goBack = () => {
    this.book_title = false
  }

  ngOnDestroy() {
    this.navbarService.setNavbar(true);
  }
}
