import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from '../helper.service';
import { BaseUrl } from '../base-url';

@Injectable({
  providedIn: 'root',
})
export class CreateStoriesService {
  private tabIndex = new BehaviorSubject<number>(0);
  public tabIndexValue = this.tabIndex.asObservable();

  private storyType = new BehaviorSubject<string>('Published');
  public storyTypeData = this.storyType.asObservable();
  userdata: string;

  setTabIndex(tabValue: number) {
    this.tabIndex.next(tabValue);
  }
  setStoryType(st: string) {
    this.storyType.next(st);
  }

  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
    this.baseurladmin = BaseUrl.admin;
  }
  baseurl;
  baseurladmin;

  getUploadedBooks() {
    this.userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(crypto.AES.decrypt(this.userdata, environment.secretKey).toString(crypto.enc.Utf8));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': decryptUser.data.token,
    });
    return this.http.get(this.baseurl + `/getUploadedBook`, { headers } );
  }

  upload_file(event: any, bookId: string) {
    this.userdata = localStorage.getItem('currentUser');
    const token = JSON.parse(crypto.AES.decrypt(this.userdata, environment.secretKey).toString(crypto.enc.Utf8)).data.token

    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    let formData:FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('bookId', bookId);

    const headers = new HttpHeaders({
      'x-access-token': token,
    });
    return this.http.post(`${environment.baseUrl}/uploadBook`, formData, {
      headers,
    });
  }
}
