import { HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthGuard } from '../auth.guard';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { ReadChapterService } from '../read-chapter/read-chapter.service';

type chapterData = {
  data: any;
  status: boolean;
  message: string;
};
@Component({
  selector: 'app-report-an-issue',
  templateUrl: './report-an-issue.component.html',
  styleUrls: ['./report-an-issue.component.sass'],
})
export class ReportAnIssueComponent implements OnInit {
  getreportissue: any;
  userdata: any;
  reportForm: FormGroup;
  loading = false;
  submitted = false;
  showMsg = false;
  _baseURL: any;
  baseimage: string;
  value = 'Select your issue';
  loggedIn: boolean;
  selectedType = ''
  selectedTypeError = false
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private _snackbar: MatSnackBar,
    private helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private chapterService: ReadChapterService,
    private authGarud: AuthGuard
  ) {
    this._baseURL = BaseUrl.frontend;
    this.baseimage = BaseUrl.image;
    this.loggedIn = this.authGarud.userLoggedin();
  }

  selectIssue(val) {
    this.selectedType = val
    this.value = val;
  }

  private bookId: string;
  private chapterId: string;
  ngOnInit() {
    if (this.authGarud.userLoggedin()) {
      this.activatedRoute.url.subscribe((url: any) => {
        this.reportForm = this.formBuilder.group({
          // type: ['', Validators.required],
          content: ['', Validators.required],
          book_id: [
            localStorage.getItem('book_id')
              ? localStorage.getItem('book_id')
              : '',
          ],
        });

        this.value = this.activatedRoute.snapshot.queryParamMap.get('report')
          ? this.activatedRoute.snapshot.queryParamMap.get('report')
          : this.value;
        this.bookId = this.activatedRoute.snapshot.queryParamMap.get('bookId');
        this.chapterId =
          this.activatedRoute.snapshot.queryParamMap.get('chapterId');

        this.loadBook();
        // if (this.value && this.value === 'content') {
        // }
        this.loadReport();
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  public loadBookObservable: any;
  public loadBookData: chapterData;

  private loadBook = () => {
    if (this.bookId || this.chapterId) {
      this.loadBookObservable = this.chapterService.getChapter({
        book_id: this.bookId,
        chapter_id: this.chapterId,
      });

      this.loadBookObservable.subscribe((res: any) => {
        if (res.status) {
          this.loadBookData = res;
          const formData = {
            type: 'content',
            content: '',
            book_id: this.bookId,
          };
          this.reportForm.patchValue(formData);
        }
      });
    } else {
      this.loadBookData = {
        data: '',
        status: false,
        message: '',
      };
    }
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.reportForm.controls;
  }
  // submit issue form
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportForm.invalid) {
      return;
    }
    if(this.selectedType == ''){
      this.selectedTypeError = true
      return;
    }else{
      this.selectedTypeError = false
    }

    this.loading = true;

    const user = this.helper.getUserData().data;

    this.reportForm.value['type'] = this.selectedType;
    this.reportForm.value['book_id'] = this.bookId;
    this.reportForm.value['chapter_id'] = this.chapterId;
    this.reportForm.value['reporteeName'] = user.fullname;
    this.reportForm.value['bookName'] = this.loadBookData.data.title;
    this.reportForm.value['user_id'] = this.loadBookData.data.author_id;
    const headers = this.helper.getHeader();
    this.http
      .post(this._baseURL + `/reportIssue`, this.reportForm.value, {
        headers: headers,
      })
      .pipe(first())
      .subscribe(
        (data) => {
          this.showMsg = true;
          localStorage.removeItem('book_id');
          this.reset();
          this._snackbar.open('Reported', 'Ok', { duration: 2000 });
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  // reset form data
  private reset() {
    this.reportForm = this.formBuilder.group({
      type: [''],
      content: [''],
    });
  }

  //get report issue listing
  private loadReport() {
    const headers = this.helper.getHeader();
    this.http
      .post(this._baseURL + `/getIssueType`, null, {
        headers: headers,
      })
      .subscribe(
        (res: any) => {
          const mapped = Object.entries(res.data).map(([type, value]) => ({
            type,
            value,
          }));
          this.getreportissue = mapped;
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }
}
