import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import party from 'party-js';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private _snackBar: MatSnackBar) { }

  public showConfetti (id: string = 'party-element', option: Partial<party.ConfettiConfiguration> = { count: party.variation.range(60, 100) }) {
    setTimeout(() => {
      party.confetti(document.getElementById(id), option);
    }, 500);
  }

  openSanckbar (payload: {title: string, actionText?: string, duration?: number}) {
    this._snackBar.open(payload.title, payload?.actionText || '', {
      duration: payload.duration || 2000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom'
    })
  }
}
