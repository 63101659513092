<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
<div class="page-main">
    <!-- sidebar area -->
    <div class="app-content my-3 my-md-5 ">
        <div class="page-container">
            <div class="page-header ">
                <!-- <h4 class="page-title ">View Manage Genre</h4> -->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Notifications
                    </li>
                </ol>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 ">
                    <div class="card ">
                        <div class="card-header justify-content-between">
                            <div class="card-title "> Notifications</div>
                            <a [routerLink]="['/backend-notifications']" class="add_content pl-4 pr-4"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="card-body ">
                            <div class="">
                                <div id="example_wrapper " class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view">
                                    <div class="row ">
                                        <div class="col-sm-12 ">
                                            <table id="notificationtable" datatable class="table row-border hover " *ngIf="temp"  role="grid " aria-describedby="example_info ">
                                                <thead>
                                                    <tr role="row ">
                                                        <th class="wd-15p sorting_asc " tabindex="0 " aria-controls="example " rowspan="1 " colspan="1 " aria-sort="ascending " aria-label="First name: activate to sort column descending
                                        " style="width: 30px; ">
                                                            #
                                                        </th>
                                                        <th class="wd-15p unsort " tabindex="0 " aria-controls="example " rowspan="1 " colspan="1 " aria-label="Last name: activate to sort column ascending " style="width: 770px; ">
                                                            Message
                                                        </th>
                                                        <th class="wd-15p sorting " tabindex="0 " aria-controls="example " rowspan="1 " colspan="1 " aria-label="Last name: activate to sort column ascending " style="width: 100px; ">
                                                            Created On
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row " class="odd " *ngFor="let generdata of getGener;let i = index">
                                                        <td class="sorting_1 ">{{ i + 1 }}</td>
                                                        <td> {{ generdata.message }} </td>
                                                        <td>{{ generdata.created_at | date: "MMM d, y" }}</td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- table-wrapper -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>