import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swiper from 'swiper';
import { BaseUrl } from '../base-url';
import { BookDataService } from '../services/book-data.service';

@Component({
  selector: 'app-similar-books',
  templateUrl: './similar-books.component.html',
  styleUrls: ['./similar-books.component.sass'],
})
export class SimilarBooksComponent implements OnInit {
  public title : string = "Similar Books";
  public bookId : string = this.activatedRoute.snapshot.paramMap.get('id');
  constructor(
    private bookDataService: BookDataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.bookId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.bookId)
  }

  baseUrl: string = BaseUrl.image;

  bookDetails = [
    {
      image: '../../assets/Home/images/favorite/01.jpg',
      description: 'The Book of treasure Island find...',
      author: 'Tara Zona',
    },
    {
      image: '../../assets/Home/images/favorite/02.jpg',
      description: 'Set For Lifr Being Scott Trench..',
      author: 'Anna Rexia',
    },
    {
      image: '../../assets/Home/images/favorite/03.jpg',
      description: 'A Birth and Evolutions of the Soul...',
      author: 'Bill Emia',
    },
    {
      image: '../../assets/Home/images/favorite/04.jpg',
      description: 'The Nature of world Beautiful Places.',
      author: 'Hal Appeno',
    },
    {
      image: '../../assets/Home/images/favorite/05.jpg',
      description: 'The mackup magazine find books..',
      author: 'Zack Lee',
    },
  ];

  ngAfterViewInit() {
    // nonCoverflowSwiperConfig('.container-swiper', true, 5, 30, true);
    var swiper = new Swiper('.similar-books-swiper-container', {
      loop: true,
      autoplay: {
        delay: 10000,
      },
      spaceBetween: 10,
      speed: 350,
      grabCursor: true,
      slidesPerGroup: 1,
      // observer: true,
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        500: {
          slidesPerView: 3,
        },
      },

      navigation: {
        prevEl: '.previous-button-icon',
        nextEl: '.next-button-icon',
      },
    });
    setTimeout(() => {
      // swiper.update();
    }, 100);
  }

  bookData: Array<any>
  ngOnInit(): void {
    // this.bookDataService.getBookRecommendation(this.bookId).subscribe((books: any) => {
    //   this.bookData = books?.data;
    // })
  }
}
