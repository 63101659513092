import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from 'src/app/base-url';
import { ManageContentService } from './manage-content.service';
declare var $:any;
@Component({
  selector: 'app-manage-content',
  templateUrl: './manage-content.component.html',
  styleUrls: ['./manage-content.component.sass']
})
export class ManageContentComponent implements OnInit {
  public manageContent: any;
  public temp: any;
  data: any;
  baseimage: any;
  blockTest: any;
  bookid: string;
  bookId: any;
  showLoader = false;
  constructor(
    private manage: ManageContentService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService
  ) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.bookid = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadManage();
  }

  openPopup(event) {
    $("#block_book").modal('show');
    this.bookId = event;
    if (event.status == "true") {
      this.blockTest = "block";
    } else {
      this.blockTest = "unblock";
    }
  }

  onClick(event) {
    this.manage.updateBookStatus(event).subscribe(
      (res: any) => {
        if (event) {
          if (event.status == 'true') {
            this.toaster.success('Book blocked successfully.')
          } else {
            this.toaster.success('Book unblocked successfully.')
          }
          this.loadManage();
        } else {
          this.toaster.success('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

  loadManage() {
    this.manage.manageContent().subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        //console.log(mapped);
        this.manageContent = mapped.reverse();
        $(document).ready(function() {
          $('#bookstable').DataTable();
        })
        this.temp = true;
        this.showLoader = false;
      },
      () => {
        console.log("ERROR");
      }
    );
  }

  onClose(){
    this.loadManage();
  }
}