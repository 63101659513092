<div class="">
  <div class="modal-header w-100">
    <h3 class="modal-header-text font-poppins fw-600 m-0">
      <ng-container *ngIf="controls.room_name?.value?.length">{{ controls.room_name?.value }}</ng-container>
      <ng-container *ngIf="!controls.room_name?.value?.length">Name your book club</ng-container>
    </h3>
    <button [disabled]="isFormSubmit" class="bg-transparent border-0 close-button" (click)="dialogRef.close(false)">
      <mat-icon class="text-color-black-200">close</mat-icon>
    </button>
  </div>
</div>
<div class="container">
  <div class="d-flex">
    <div>
      <h4 class="mb-0">
        Basic Information
      </h4>
      <div class="row pt-3" [formGroup]="roomForm">

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Club Name <span class="required-field-icon">*</span></mat-label>
          <input type="name" matInput formControlName="room_name">
          <mat-error *ngIf="controls.room_name.hasError('required')">
            Name is required field
          </mat-error>

        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Select a book <span class="required-field-icon">*</span></mat-label>
          <input type="text" matInput formControlName="book" [matAutocomplete]="bookSearchAutoComplete">
          <mat-error *ngIf="controls.room_name.hasError('required')">
            Book is required field
          </mat-error>

          <mat-autocomplete autoActiveFirstOption #bookSearchAutoComplete="matAutocomplete"
            [displayWith]="bookDisplayFunction">
            <mat-option *ngFor="let option of booksFilteredOptions | async" [value]="option">
              {{option?.title}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>

        <h4 class="mb-0">
          Invite upto 8 users by email
        </h4>

        <div class="w-100 p-3" [class.invitees]="controls.invited_user_emails?.value?.length">
          <mat-form-field class="example-chip-list w-100" appearance="outline">
            <mat-label>Enter emails</mat-label>
            <input formControlName="invite_user_input_text" type="text" matInput
              [readonly]="controls.invited_user_emails.value?.length >= 8" (keyup.enter)="addUser($event)">
          </mat-form-field>
          <div class="d-flex justify-content-between text-secondary" style="font-size: 13px; margin-top: -24px">
            <div>Hit Enter to add more</div>
            <div>{{controls.invited_user_emails?.value?.length ||0 }}/8 emails selected </div>
          </div>

          <div class="mt-2" *ngIf="controls.invited_user_emails?.value?.length">
            <hr>

            <!-- removeUser(user?.email) -->
            <mat-list role="list" class="mat-list">
              <mat-list-item *ngFor="let user of controls.invited_user_emails?.value" role="listitem">
                <div class="d-flex align-items-center w-100">
                  <div class="flex-fill">
                    {{ user?.email }}
                    <div *ngIf="data?.mode === 'edit' && !newUser?.includes(user?.email)" class="badge ml-2" [ngClass]="{
                    'badge-primary': user?.status === 'invited',
                    'badge-warning' : user?.status === 'active',
                    'badge-danger' : user?.status === 'declined'
                }">
                      {{ user?.status | titlecase }}
                    </div>
                  </div>
                  <div>
                    <button mat-stroked-button *ngIf="data?.mode === 'edit' && !newUser?.includes(user?.email)"
                      (click)="inviteAgain(user)"
                      [disabled]="invitingAgain?.includes(user?.email) || invitedAgain?.includes(user?.email)">
                      <ng-container
                        *ngIf="!invitingAgain?.includes(user?.email) && !invitedAgain?.includes(user?.email)">
                        Invite Again</ng-container>
                      <ng-container *ngIf="invitingAgain?.includes(user?.email)">Inviting...</ng-container>
                      <ng-container *ngIf="invitedAgain?.includes(user?.email)">Invitation Sent</ng-container>
                    </button>
                  </div>
                  <div class="mr-4 ml-2">
                    <button mat-icon-button (click)="removeUser(user?.email)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </div>

        <div class="w-100 mt-3">
          <button class="btn btn-cyan p-3 w-100 fw-600" (click)="createRoom()" [disabled]="isLoading || !isFormValid"
            *ngIf="data?.mode === 'create'">
            <ng-container *ngIf="isLoading">Creating...</ng-container>
            <ng-container *ngIf="!isLoading">Create</ng-container>
          </button>
          <button class="btn btn-cyan p-3 w-100 fw-600" (click)="updateRoom()" [disabled]="isLoading || !isFormValid"
            *ngIf="data?.mode === 'edit'">
            <ng-container *ngIf="isLoading">Updating...</ng-container>
            <ng-container *ngIf="!isLoading">Update</ng-container>
          </button>
        </div>
      </div>
    </div>

    <div class="vertical-line mx-5 pt-3"></div>
    <div class="w-100 side-image-container">
      <div class="text-center mt-4">
        <h1 class="font-poppins fw-600">
          <ng-container *ngIf="controls.room_name?.value?.length">{{ controls.room_name?.value }}</ng-container>
          <ng-container *ngIf="!controls.room_name?.value?.length">Name your book club</ng-container>
        </h1>
        <div class="mt-5 pt-3">
          <img *ngIf="controls?.book?.value?.cover_image" class="book__image shadow br-8" style="height: 232px"
            [src]="imageBaseUrl + controls?.book?.value?.cover_image" />
            <img *ngIf="!controls?.book?.value?.cover_image" style="height: 232px" src="assets/images/undraw_club_reading.svg" alt="">
        </div>
        <div class="mt-2" *ngIf="controls?.book?.value?.title">
          <div class="ratings-container d-flex justify-content-center">
            <div>
              <mat-icon class="fs-16 metrics-icons color-cyan-secondary">visibility</mat-icon>
              <span class="fs-13 views-hearts-shares-bookshelves">{{ controls?.book?.value?.views }}</span>
            </div>
            <div>
              <mat-icon class="fs-16 metrics-icons color-cyan-secondary">favorite</mat-icon>
              <span class="fs-13 views-hearts-shares-bookshelves">{{ controls?.book?.value?.likes }}</span>
            </div>
            <div>
              <mat-icon class="fs-16 metrics-icons color-cyan-secondary">share</mat-icon>
              <span class="fs-13 views-hearts-shares-bookshelves">{{ controls?.book?.value?.no_of_shares }}</span>
            </div>
            <div>
              <mat-icon class="fs-16 metrics-icons color-cyan-secondary">menu_book</mat-icon>
              <span class="fs-13 views-hearts-shares-bookshelves">{{ controls?.book?.value?.no_of_chapter }}</span>
            </div>
          </div>
          <h2 class="font-poppins mb-0 mt-2">{{ controls?.book?.value?.title }}</h2>
        </div>
      </div>
    </div>
  </div>
</div>
