<div class="iq-top-navbar top-navbar">
  <div class="header-top w-100 bg-promotion" style="height: 25px">
    <!-- <div class="col-md-4 bg-promotion">
      <i class="porto-icon-shipping"></i>
      <h2>Free Shipping on Orders Over $99</h2>
    </div>
    <div class="col-md-4 bg-promotion middle-bar">
      <i class="porto-icon-money"></i>
      <h2>Up to 40% OFF on Selected Items</h2>

    </div>
    <div class="col-md-4 bg-promotion">
      <i class="Simple-Line-Icons-graduation"></i>
      <h2>15% OFF Student Discount</h2>
    </div> -->
  </div>
  <div class="logo3 px-5 text-center w-100 top-navbar-2 w-100 mt-3">
    <a routerLink="/" class="mt-4"><img loading="lazy" class="" src="assets/images/logo.png" alt="" style="height: 65px;" /></a>
    <div class="
        align-middle
        d-flex
        justify-content-start
        align-items-center
        ml-4
        list-unstyled
      ">
      <a class="text-decoration-none text-muted" routerLink="/home">
        <a routerLinkActive="active-link" style="font-weight: 600 !important;" [routerLinkActiveOptions]="{ exact: true }"
          class="navigation-links text-uppercase d-flex">
          <!-- <i class="ri-home-5-fill mr-2"></i> -->
          <ng-container *ngIf="viewMode === 'desktop'">Home</ng-container>
        </a>
      </a>
      <a class="text-decoration-none text-muted" routerLink="/create-stories">
        <div>
          <a routerLinkActive="active-link" style="font-weight: 600 !important;" [routerLinkActiveOptions]="{ exact: false }"
            class="navigation-links text-uppercase d-flex">

            <!-- <i class="ri-search-line mr-2"></i> -->
            <ng-container *ngIf="viewMode === 'desktop'">Write</ng-container>
          </a>
        </div>
      </a>
      <a class="text-decoration-none text-muted" routerLink="/search">
        <a routerLinkActive="active-link" style="font-weight: 600 !important;" [routerLinkActiveOptions]="{ exact: true }"
          class="navigation-links text-uppercase d-flex font-weight-bold">
          <!-- <i class="ri-home-5-fill mr-2"></i> -->
          <ng-container *ngIf="viewMode === 'desktop'">Explore</ng-container>
        </a>
      </a>

      <div class="input-group input-group-lg">
        <!-- <input type="text" class="form-control header-scarch-input" placeholder="Explore..." aria-label="Recipient's username" aria-describedby="basic-addon2"> -->
        <input type="text" class="form-control header-scarch-input" placeholder="Search..."
          aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" [(ngModel)]="searchByInput"
          (keypress)="search($event)">
        <span class="input-group-text header-scarch-button" id="inputGroup-sizing-lg" style="height: 50px;"
          (click)="clickOnSearch()"><img loading="lazy" src="../../assets/images/header-scarch-icone.png" alt=""
            class="header-scarch-icone"></span>
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <ul class="navbar-list">
        <li class="nav-item nav-icon search-content">
          <a class="search-toggle iq-waves-effect text-gray rounded">
            <i class="ri-search-line"></i>
          </a>
          <form action="#" class="search-box p-0">
            <input type="text" class="text search-input" placeholder="Type here to search..." />
            <a class="search-link"><i class="ri-search-line"></i></a>
          </form>
        </li>
        <li class="nav-item nav-icon dropdown mr-5">
          <a class="search-toggle iq-waves-effect text-gray rounded header-icons">
            <mat-icon class="nav-bar-message color-cyan">notifications</mat-icon>
            <div class="notification-text" *ngIf="newNotificationCount">
              {{newNotificationCount}}
            </div>
          </a>
          <div class="iq-sub-dropdown text-left">
            <app-user-notification *ngIf="notificationData" [notificationData]="notificationData">
            </app-user-notification>
          </div>
          <!-- <h6 class="notification__">Messages</h6> -->
        </li>
        <li class="nav-item nav-icon dropdown align-items-left mr-3">
          <a class="search-toggle iq-waves-effect text-gray rounded header-icons">
            <!-- <mat-icon class="color-cyan-secondary mr-2 mb-2">turned_in_not</mat-icon> -->
            <mat-icon class="material-symbols-outlined notification-icons color-cyan">
              bookmarks
            </mat-icon>
            <div class="notification-text"  *ngIf="bookShelfCount">
              {{ bookShelfCount }}
            </div>
          </a>
          <div class="iq-sub-dropdown">
            <app-navbar-bookshelf *ngIf="bookShelf" [bookShelf]="bookShelf">
            </app-navbar-bookshelf>
          </div>
          <!-- <h6 class="notification__">My Bookshelf</h6> -->
        </li>
        <li class="line-height">
          <a class="search-toggle d-flex align-items-center profile-avatar-card p-3 ml-3">
            <div>
              <img loading="lazy" [src]="baseurl + userData.image"
              onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
              class="" style="border-radius: 50%; height: 50px; width: 50px;" alt="user" />
            </div>
            <div class="d-flex caption text-left ml-2">
              <h6 class="mb-0">@{{ userData?.username }}</h6>
              <p class="mb-0 ml-3 d-flex"> <img loading="lazy" class="image-coins" src="../../assets/images/cash-coin.png" alt=""> {{ userData?.libcoins }}</p>
            </div>
          </a>
          <div class="iq-sub-dropdown iq-user-dropdown">
            <div class="iq-card shadow-none m-0">
              <div class="iq-card-body p-0 text-left">
                <div class="bg-primary-cyan p-3">
                  <h5 class="mb-0 text-white line-height">
                    Hello @{{ userData.username }}
                  </h5>
                </div>
                <a (click)="goToProfile()" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-file-user-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <p class="mb-0 title text-capitalize">My Profile</p>
                      <p class="mb-0">View personal profile details.</p>
                    </div>
                  </div>
                </a>
                <a routerLink="create-stories" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-file-user-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">My Stories</h6>
                      <p class="mb-0">View stories you created</p>
                    </div>
                  </div>
                </a>
                <a [routerLink]="['/book-club']" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-file-user-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">Book Club</h6>
                      <p class="mb-0">Read books together</p>
                    </div>
                  </div>
                </a>
                <a routerLink="withdrawal" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <!-- <i class="ri-file-user-line"></i> -->
                      <i class="ri-wallet-3-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">Wallet</h6>
                      <p class="mb-0">Check how much you made.</p>
                    </div>
                  </div>
                </a>
                <a routerLink="reading-settings" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-file-user-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">
                        Reading Settings
                      </h6>
                      <p class="mb-0">Manage how you read books</p>
                    </div>
                  </div>
                </a>
                <a (click)="showInviteOptions()" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-file-user-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">Invite Friends</h6>
                      <p class="mb-0">Get your friends on board</p>
                    </div>
                  </div>
                </a>
                <a routerLink="feedback" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-lock-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">
                        Feedback and Suggestions
                      </h6>
                      <p class="mb-0">Let us know how we are doing</p>
                    </div>
                  </div>
                </a>
                <a routerLink="report-an-issue" class="iq-sub-card iq-bg-primary-hover">
                  <div class="media align-items-center">
                    <div class="rounded iq-card-icon iq-bg-primary">
                      <i class="ri-lock-line"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h6 class="mb-0 title text-capitalize">Report</h6>
                      <p class="mb-0">Report an issue</p>
                    </div>
                  </div>
                </a>
                <div class="d-inline-block w-100 text-center p-3">
                  <button class="btn btn-cyan fw-600" role="button" (click)="logout()">
                    Sign out <i class="ri-login-box-line ml-2"></i></button>
                </div>
                <div class="bg-primary-cyan p-4" style="border-radius: 0 0 4px 4px !important; height: 39px">
                  <h5 class="mb-0 text-white">
                    <small class="badge badge-light float-right pt-1"></small>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
