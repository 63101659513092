import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class WriteBookService {
  constructor(private dialog: MatDialog) {}

  public bookData: any;

  public book = new BehaviorSubject<string>(null);
  public bookCover = this.book.asObservable()

  setbookImage(image: string){
    this.book.next(image)
  }

  setValues(data: any) {
    this.bookData = data;
  }

  getValues() {
    return this.bookData;
  }
}
