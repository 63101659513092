import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReviewService } from './review.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewHandlerService {
  constructor(private reviewService: ReviewService) {}

  addEditToggle = (reviews: any) => {
    if (reviews.status == 404) {
      return reviews;
    } else {
      for (const iterator of reviews.bookReview.review) {
        iterator['edit'] = false;
      }
      return reviews;
    }
  };

  getReviewByBookId = (book_id: string): Observable<any> => {
    return this.reviewService
      .getReviewByBookId(book_id)
      .pipe<Observable<any>>(map(this.addEditToggle));
  };
}
