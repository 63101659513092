import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WriteBookService } from '../services/write-book.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { CLS_ICONS } from '@syncfusion/ej2-richtexteditor';
import { HandleEditService } from '../add-book-cover/handle-edit.service';
import { BaseUrl } from '../base-url';
import { ImageService } from '../image-editor-canvas/image.service'

interface coordinates {
  xval: number;
  yval: number;
}
@Component({
  selector: 'app-image-editor-book-title',
  templateUrl: './image-editor-book-title.component.html',
  styleUrls: ['./image-editor-book-title.component.sass'],
})
export class ImageEditorBookTitleComponent implements OnInit {
  @ViewChild('textCanvas') text: ElementRef;
  @ViewChild('BookTitleElement') BookTitleElement: ElementRef;
  @Input() public canvasUrl: any;
  @Input() callbackFunction: () => void;
  @Output() public book_title = new EventEmitter();

  public baseurl: string;
  public finalCanvasUrl: string;
  public showFinal = false;
  private returnTo: string = '';
  // Variables
  public color: any = '#ff0000';
  public fontSize: number = 15;
  public aligntext: number = 0;
  public underline;
  constructor(
    private writeBookService: WriteBookService,
    private router: Router,
    private imageService: ImageService,
    private http: HttpClient,
    private dialog: MatDialog,
    private handleEdit: HandleEditService,
    private helper: HelperService
  ) {
    this.handleEdit.modeVal.subscribe((res) => {
      this.returnTo = res;
    });

    this.baseurl = BaseUrl.imageApi;
  }
  public canvasURL;

  public bookTitle: string = '';
  public bookTitleCordinates: coordinates = { xval: 100, yval: 100 };

  public showCanvas = false;
  public selectedValue = 'robotobold';

  public canvas: HTMLCanvasElement;
  createCanvas(nativeElement: any) {
    this.canvas = nativeElement;
    var context: CanvasRenderingContext2D = <CanvasRenderingContext2D>(
      this.canvas.getContext('2d')
    );

    let img1 = new Image();
    img1.src = this.canvasUrl;

    img1.onload = () => {
      this.canvas.width = 270;
      this.canvas.height = 370;

      const fontDifferent = this.fontSize - 15

      context.drawImage(img1, 0, 0, 270, 370);

      context.font = String(this.fontSize) + 'px' + ` ${this.selectedValue}`;
      context.fillStyle = this.color;

      this.bookTitleCordinates.xval = this.bookTitleCordinates.xval
        ? this.bookTitleCordinates.xval
        : 0;
      this.bookTitleCordinates.yval = this.bookTitleCordinates.yval ? this.bookTitleCordinates.yval + 16 + (fontDifferent / 2.45) : 15;

      if (this.aligned) {
        // this.bookTitleCordinates.xval = this.x;
        this.bookTitleCordinates.yval = this.y;
        context.textBaseline = 'middle';
        context.textAlign = this.x;
      }
      if (context.textAlign === 'center') {
        context.fillText(this.bookTitle, 130, 170);
      }
      if (context.textAlign === 'end') {
        context.fillText(this.bookTitle, 265, 180);
      }
      if (this.underline) {
        this.textUnderline(
          context,
          this.bookTitle,
          this.bookTitleCordinates.xval,
          this.bookTitleCordinates.yval,
          this.color,
          this.fontSize,
          ''
        );
      }
      if (this.bold) {
        context.font = `bold ${String(this.fontSize)}px ${this.selectedValue}`;
      }
      if (this.italic) {
        context.font = `italic ${String(this.fontSize)}px ${
          this.selectedValue
        }`;
      }
      if(context.textAlign !== 'center') {
        context.fillText(
          this.bookTitle,
          this.bookTitleCordinates.xval,
          this.bookTitleCordinates.yval
        );
      }
    };

    this.showFinal = true;
    this.writeBookService.setbookImage(this.canvas.toDataURL());
  }

  textUnderline(context, text, x, y, color, textSize, align) {
    var textWidth = context.measureText(text).width;
    var startX = 0;
    var startY = y + parseInt(textSize) / 15;
    var endX = 0;
    var endY = startY;
    var underlineHeight = parseInt(textSize) / 15;

    if (underlineHeight < 1) {
      underlineHeight = 1;
    }

    context.beginPath();
    if (align == 'center') {
      startX = x - textWidth / 2;
      endX = x + textWidth / 2;
    } else if (align == 'right') {
      startX = x - textWidth;
      endX = x;
    } else {
      startX = x;
      endX = x + textWidth;
    }

    context.strokeStyle = color;
    context.lineWidth = underlineHeight;
    context.moveTo(startX, startY);
    context.lineTo(endX, endY);
    // context.strokeStyle = 'blue';
    context.stroke();
  }

  getXYCord(event: any) {
    this.alignItems = false;
    // this.aligned = false;
    this.bookTitleCordinates = event;
  }
  Next() {
    this.showCanvas = true;
    this.createCanvas(this.text.nativeElement);
  }

  activeSize: any;

  changeFontSize(size) {
    this.fontSize = size.target.value;
  }

  changeFont() {
    // console.log('element ref', this.BookTitleElement);
  }

  bold: boolean = false;
  italic: boolean = false;
  alignItems = false;
  justifyContent = 'center';
  aligned = false;
  x;
  y: number = 0;

  textBold(event: any) {
    this.bold = !this.bold;
    $(event.target).toggleClass('set-background-color-red');
    $('.canvas-image-text-cover').toggleClass('set-font-weight');
  }

  textItalic(event: any) {
    this.italic = !this.italic;
    $(event.target).toggleClass('set-background-color-red');
    $('.canvas-image-text-cover').toggleClass('set-font-italic');
  }

  alignLeft(event: any) {
    this.alignItems = true;
    this.justifyContent = 'start';
    this.x = 'start';
    this.y = 180;
    this.aligned = true;
    $('.custom-align').removeClass('set-background-color-red');
    $(event.target).toggleClass('set-background-color-red');
  }
  alignMiddle(event: any) {
    this.alignItems = true;
    this.justifyContent = 'center';
    this.x = 'center';
    this.y = 180;
    this.aligned = true;
    $('.custom-align').removeClass('set-background-color-red');
    $(event.target).toggleClass('set-background-color-red');
  }
  alignRight(event: any) {
    this.alignItems = true;
    this.justifyContent = 'end';
    this.x = 'end';
    this.y = 180;
    this.aligned = true;
    $('.custom-align').removeClass('set-background-color-red');
    $(event.target).toggleClass('set-background-color-red');
  }

  underlineText(event: any) {
    $(event.target).toggleClass('set-background-color-red');
    this.underline = !this.underline;
  }
  reset(type: string) {
    if(type === 'title'){
      this.callbackFunction()
    }else if(type === 'finish'){
      this.showCanvas = false;
      this.showFinal = false;
    }
    // const dialog = this.dialog.open(ConfirmationModalComponent, {
    //   data: { cancel: true },
    //   width: '500px',
    //   height: '190px',
    // });

    // dialog.afterClosed().subscribe((result) => {
    //   if (result) {
    //     this.imageService.reset();
    //     this.router.navigate([`${this.returnTo}`]);
    //   }
    // });
    // this.showFinal = true
    // this.router.navigate([`${this.returnTo}`]);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  saveAndUpload() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { edit: false, save: true },
      width: '500px',
      height: '220px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        var userdata = localStorage.getItem('currentUser');
        const decryptUser = JSON.parse(
          crypto.AES.decrypt(userdata, environment.secretKey).toString(
            crypto.enc.Utf8
          )
        );

        const headers = new HttpHeaders({
          // 'Content-Type': 'application/json',
          'x-access-token': decryptUser.data.token,
        });

        var block = this.canvas.toDataURL().split(';');

        // Get the content type of the image
        var contentType = block[0].split(':')[1]; // In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

        var blob = this.b64toBlob(realData, contentType, '');

        // Create a FormData and append the file with "image" as parameter name
        var formDataToUpload = new FormData();
        formDataToUpload.append('image', blob);

        localStorage.setItem('image', this.canvas.toDataURL());

        // const aaaa = `${this.baseurl}book_cover_image`
        this.http
          .post(`${this.baseurl}book_cover_image`, formDataToUpload, {
            headers,
          })
          .subscribe((result: any) => {
            // var { cover_image } = this.helper.appendBaseUrlToImage(result.data);
            this.handleEdit.setImage(result.data);
            this.router.navigate([`/${this.returnTo}`]);
          });
      }
    });
  }

  editBookCover() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { edit: true },
      width: '500px',
      height: '190px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.book_title.emit(false);
      }
    });
  }
  ngOnInit(): void {}
}
