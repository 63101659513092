import { BaseUrl } from "./../../../base-url";
import { Component, OnInit } from "@angular/core";
import { AdminSubscriptionService } from "./admin-subscription.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-admin-subscription",
  templateUrl: "./admin-subscription.component.html",
  styleUrls: ["./admin-subscription.component.sass"]
})
export class AdminSubscriptionComponent implements OnInit {
  public manageContent: any;
  public temp: Object = false;
  data: any;
  baseimage: any;
  showLoader = false

  constructor(private subscription: AdminSubscriptionService,    private toaster: ToastrService) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.loadManage();
  }

  loadManage() {
    this.subscription.getSubscriptions().subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        this.manageContent = mapped;
        this.temp = true;
        this.showLoader = false
      },
      () => {
        console.log("ERROR");
      }
    );
  }


  onClick(event) {
    this.subscription.updateStatus(event).subscribe(
      (res: any) => {
        if (event) {
          if (event.status == 'true') {
            this.toaster.success('Subscription enabled successfully.')
          } else {
            this.toaster.success('Subscription disabled successfully.')
          }
          this.loadManage();
        } else {
          this.toaster.success('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

}
