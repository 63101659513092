import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
    this.baseurladmin = BaseUrl.admin;
  }
  baseurl;
  baseurladmin;

  private tabIndex = new BehaviorSubject<number>(0);
  public tabIndexData = this.tabIndex.asObservable();

  userdata;
  getMyStories() {
    this.userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(this.userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': decryptUser.data.token,
    });
    return this.http.post(this.baseurl + `/myStoriesList`, {}, { headers });
  }

  getPaginatedStories(type: number, page_no: number = 1) {
    return this.http.post(this.baseurl + '/viewAllMyStories', { type, page_no }, { headers: this.helper.getHeader() });
  }

  getGenreDetails(genre_id: string) {
    this.userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(this.userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': decryptUser.data.token,
    });
    return this.http.post(
      this.baseurladmin + `/getGenreDetail`,
      { genre_id: genre_id },
      { headers }
    );
  }

  setTabData(index: number) {
    this.tabIndex.next(index);
  }

  loadAuthorData(bookId: string) {
    const headers = this.helper.getHeader();

    return this.http.post(
      BaseUrl.frontend + `/getMyBookDetail`,
      { book_id: bookId },
      {
        headers,
      }
    );
  }
}
