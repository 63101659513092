import { Component, OnInit } from '@angular/core';
import { ManageNotificationService } from './manage-notification.service';
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.sass']
})
export class ManageNotificationComponent implements OnInit {
  temp: boolean;
  getGener: any;
  showLoader = false;
  constructor(private genreService: ManageNotificationService, private toaster: ToastrService) { }

  ngOnInit() {
    this.showLoader = true;
    this.loadGenre();
  }
  loadGenre() {
    this.genreService.getGenres().subscribe(
      (res: any) => {
        this.getGener = res.data.reverse();
        $(document).ready(function() {
          $('#notificationtable').DataTable();
        })
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

}
