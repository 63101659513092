// import { BaseUrl } from "./../../../base-url";
import { Component, OnInit, Pipe, AfterViewChecked, Renderer2, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { ReadChapterService } from './read-chapter.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
// import { SEOServiceService } from 'src/app/seoservice.service';
import { AuthGuard } from '../auth.guard';
import { SeoserviceService } from '../seoservice.service';
import { BaseUrl } from '../base-url';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { ShareBookDialogComponent } from '../share-book-dialog/share-book-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavbarService } from '../nav-sidebar/navbar.service';
import { TransactionService } from '../services/transaction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../authentication.service';
import { HelperService } from '../helper.service';
import { StarRatingComponent } from 'ng-starrating';
import { BookDataService } from '../services/book-data.service';
import { UtilityService } from '../services/utility/utility.service';
@Component({
  selector: 'app-read-chapter',
  templateUrl: './read-chapter.component.html',
  styleUrls: ['./read-chapter.component.sass'],
})
export class ReadChapterComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  public isCollapsedNew = true;
  public isCollapsed = true;
  public briefText;
  public fullText;
  public showFullText = false;
  public textIsBrief = false;
  previosChapter: any;
  currentDate = new Date();
  chapterData: any;
  previousUrl: any;
  commentForm: FormGroup;
  commentData: any;
  submitted = false;
  is_white = 'color_white';
  book_id: string;
  chapter_id: string;
  baseURL: any;
  videoURL: any;
  activeClass: any;
  xchaptermsg: any;
  errormsg = false;
  showSuccess: any;
  showError: any;
  amount: any;
  userdata1: any;
  userdata: any;
  userImage: any;
  title: any;
  created_by: any;
  like_msg: any;
  is_subscribed = false;
  timeZoneOffset: any;
  fontFamily: any;
  fontSize: any;
  bookData: any;
  firstTime = true;
  bookTitle: any;
  chapterTitle: any;
  showLoader = true;
  isLoading: boolean = false;

  is_purchased: boolean = false;
  buttonLoading: boolean = false;
  show_purchase_options: boolean = false;
  amountOfChupter: any;
  chapterList: any;
  nextChapter: any;

  activeSize: any = 36;
  a;
  @ViewChild('settings') settings: ElementRef;
  @ViewChild('settingsBlock') settingsBlock: ElementRef;
  @ViewChild('selectFonts') selectFonts: ElementRef;
  @ViewChild('background1') background1: ElementRef;
  @ViewChild('background2') background2: ElementRef;
  @ViewChild('background3') background3: ElementRef;
  @ViewChild('fontSizeText') fontSizeText: ElementRef;
  @ViewChild('labelInput') labelInput: ElementRef;
  @ViewChild('listBackgrounds') listBackgrounds: ElementRef;
  @ViewChild('labelFonts') labelFonts: ElementRef;
  @ViewChild('collapseElementBlock') collapseElementBlock: ElementRef;
  @ViewChild('buttonHandler') buttonHandler: ElementRef;
  @ViewChild('titleHandler') titleHandler: ElementRef;
  @ViewChild('imgHandler') imgHandler: ElementRef;
  constructor(
    private chapterService: ReadChapterService,
    private renderer: Renderer2,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authoService: AuthenticationService,
    private _sanitizer: DomSanitizer,
    private _snackbar: MatSnackBar,
    private authguard: AuthGuard,
    private toastr: ToastrService,
    private _seoService: SeoserviceService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private bookDataService: BookDataService,
    // private shareDialog: ShareBookDialogComponent,
    private dialog: MatDialog,
    private navbar: NavbarService,
    private router: Router,
    private readChapterService: ReadChapterService,
    private transactionService: TransactionService,
    private helper: HelperService,
    private utils: UtilityService
  ) {
    this.baseURL = BaseUrl.image;
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(this.settings.nativeElement){
        if (e.target !== this.settings.nativeElement && e.target !== this.settingsBlock.nativeElement.children
          && e.target !== this.selectFonts.nativeElement.children && e.target !== this.selectFonts.nativeElement
          && e.target !== this.background1.nativeElement.parent && e.target !== this.background1.nativeElement
          && e.target !== this.background2.nativeElement.parent && e.target !== this.background2.nativeElement
          && e.target !== this.background3.nativeElement.parent && e.target !== this.background3.nativeElement
          && e.target !== this.listBackgrounds.nativeElement.parent && e.target !== this.listBackgrounds.nativeElement
          && e.target !== this.labelInput.nativeElement.children[0] && e.target !== this.labelInput.nativeElement
          && e.target !== this.labelFonts.nativeElement.children[0] && e.target !== this.labelFonts.nativeElement
          && e.target !== this.fontSizeText.nativeElement.children[0] && e.target !== this.fontSizeText.nativeElement) {
            this.isCollapsedNew = true
        }
        if (e.target !== this.collapseElementBlock.nativeElement && e.target !== this.buttonHandler.nativeElement &&
            e.target !== this.buttonHandler.nativeElement.children && e.target !== this.titleHandler.nativeElement &&
            e.target !== this.imgHandler.nativeElement) {
              this.isCollapsed = true
        }
      }

    });
    this.is_subscribed = this.authguard.is_subscribed;
    this.a = localStorage.getItem('currentUser');
    this.a = JSON.parse(
      crypto.AES.decrypt(this.a, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
  }

  ngOnDestroy() {
    this.navbar.setNavbar(true);
  }

  print(data) {
  }

  changeCollapse() {
    this.isCollapsed = !this.isCollapsed
  }

  hendleShoweMoreText(){
    this.showFullText = true
  }
  hendleShoweLessText(){
    this.showFullText = false
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe((url: any) => {
      this.previousUrl = localStorage.getItem('previousUrl');
      this.bookData = localStorage.getItem('mybooks');
      this.bookData = JSON.parse(this.bookData);
      console.log('this.bookData', this.bookData)



      this.userdata = this.a;
      this.userdata1 = this.a;
      // this. = this.userdata.data._id;
      this.userImage = this.userdata1.data.image;


      this.helper.updateUserData.subscribe((data) => {
        this.a =this.helper.getUserData();;
        this.userdata = this.a;
        this.userdata1 = this.a;
        this.userImage = this.userdata1.data.image;
      })

      this.commentForm = this.formBuilder.group({
        comment: ['', Validators.required],
      });
      this.book_id = this.activeRoute.snapshot.paramMap.get('book_id');
      this.chapter_id = this.activeRoute.snapshot.paramMap.get('chapter_id');
      this.author_id = this.activatedRoute.snapshot.paramMap.get('author_id');
      this.getBookOverview();
      this.addBookToHistory();
      this.getChapter();
      this.getComments();
      setTimeout(function () {
        $('.medium-insert-embeds-overlay').remove();
      }, 3000);
      this.timeZoneOffset = this.timeConvert(
        this.currentDate.getTimezoneOffset() * 2
      );
    });
    // if (this.bookData.description.length > 150) {
    //   this.textIsBrief = false
    //   const splitBriefText = this.bookData.description.split('').slice(0,150)
    //   this.briefText = splitBriefText.join('')
    //   this.fullText = this.bookData.description
    // }
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue},
      New Value: ${$event.newValue},
      Checked Color: ${$event.starRating.checkedcolor},
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
  timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var total = -(rhours + rminutes);
    if (n < 0) {
      return '+' + total;
    } else {
      return '-' + total;
    }
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  }

  ngAfterViewChecked() {
    //check if reading setting is set
    let color = localStorage.getItem('color');
    let font = localStorage.getItem('font');
    let size = localStorage.getItem('size');
    if (color) {
      this.is_white = color;
      this.activeClass = color;
      //  $('body').on('load', '#cc').find('span').css('background', color);
    }
    if (size) {
      this.onFontSize(size);
      //  $('body').on('load', '#cc').find('p').css('font-size', size+'px');
      //  $('body').on('load', '#cc').find('span').css('font-size', size+'px');
    }
    if (font) {
      this.onFontChange(font);
      // $('body').on('load', '#cc').find('p').css('font-family', font);
      // $('body').on('load', '#cc').find('span').css('font-family', font);
    }
  }


  getBookOverview() {
    if(!this.book_id) {
      this.toastr.error('Invalid Book Reference, please go back and try again');
      return;
    }
    this.bookDataService.getBookOverview(this.book_id).subscribe((res: { status: boolean, data: any, message: string }) => {
      if(res?.status) {
        this.bookData = res.data;
        if(this.bookData.description.length > 150){
          const splitBriefText = this.bookData.description.split('').slice(0,150)
          this.briefText = splitBriefText.join('')
          this.fullText = this.bookData.description
        }else{
          if(this.bookData.description.length < 150){
            this.textIsBrief = true
          }
        }
      } else {
        this.toastr.error('Invalid Book Reference, please go back and try again');
      }
    }, (err) => {
      this.toastr.error(err.message || 'Something went wrong');
    }).add(() => {
      this.showLoader = false;
    })
  }

  getChapter() {
    this.userdata = this.a;
    this.isLoading = true
    this.chapterService
      .getChapter_V1({
        book_id: this.book_id,
        chapter_id: this.chapter_id,
      })
      .subscribe(
        (res: any) => {
          this.isLoading = false
          this.chapterData = res.data;
          this.chapterData.currentChapter.content = this.chapterData.currentChapter.content.replace('class="medium-insert-buttons-show"','class="medium-insert-buttons-show" style="display:none"');
          if (this.chapterData.currentChapter.video) {
            this.videoURL = this.chapterData.currentChapter.video;
            this.videoURL = this._sanitizer.bypassSecurityTrustResourceUrl(
              this.videoURL
            );
          }

          if (this.chapterData.currentChapter.amount) {
            this.amount = this.chapterData.currentChapter.amount;
            this.title =
              'Hi ' +
              this.userdata.data.fullname +
              ' has bought ' +
              this.chapterData.currentChapter.chapter_name +
              ' from your book ' +
              this.chapterData.title;
            this.created_by = this.chapterData.currentChapter.created_by;
          }

          this.bookTitle = this.chapterData.title;
          this.chapterTitle = this.chapterData.currentChapter.chapter_name;
          this._seoService.updateTitle(
            this.bookTitle + ': ' + this.chapterTitle
          );
          this.meta.addTag({
            name: 'keywords',
            content: 'read,write,share to hearts content',
          });
          this.meta.addTag({
            name: 'description',
            content:
              'AIf you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book.',
          });
          this.meta.addTag({ name: 'author', content: 'Librums' });
          this.meta.addTag({
            name: 'title',
            content: this.bookTitle + ': ' + this.chapterTitle,
          });
          this.meta.addTag({
            name: 'url',
            content:
              'https://testing.librums.com/shareBook.html?book_id=' +
              this.chapterData.book_id +
              '&genre_id=' +
              this.chapterData.genre,
          });


          this.is_purchased = this.userdata.data?.unlockedChapters?.includes(this.chapter_id);

          {this.chapterData.chapters && this.chapterData.chapters.length > 0?
            this.chapterList = this.chapterData.chapters
            :
            this.chapterList = 0
          }
          if(this.chapterData.chapters.length > 1){
            for(var i = 0; i < this.chapterData.chapters.length -1; i++){
              if(this.chapterData.currentChapter._id == this.chapterData?.chapters[i]._id){
                this.nextChapter = this.chapterData?.chapters[i+1]._id
              }
            }
          }
          if(this.chapterList.length > 0){
            for(let i = 0; i < this.chapterList.length; i++){
              let index = i-1
              if(this.chapterList[i]._id ==  this.chapterData.currentChapter._id){
                if(index < 0){
                  this.previosChapter = this.chapterList[0]._id
                }else{
                  this.previosChapter = this.chapterList[index]._id
                }
              }
            }
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
      // console.log(this.userdata)
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  getComments() {
    this.chapterService
      .getComments({ book_id: this.book_id, chapter_id: this.chapter_id })
      .subscribe(
        (res: any) => {
          this.commentData = res.data;
          console.log('this.commentData', this.commentData)
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }
  like(event) {
    this.chapterService.likeBook(event).subscribe(
      (res: any) => {
        if (res.status) {
          // this.like_msg = res.message;
          // alert(res.message);
          this.getChapter();
        } else {
        }
      },
      (error) => {}
    );
  }

  get f() {
    return this.commentForm.controls;
  }

  changeLayout(val) {
    this.firstTime = false;
    this.is_white = val;
    this.activeClass = val;
    localStorage.setItem('color', val);
    console.log(val)
    if (val == 'color_white') {
      $('.sub_details').css('background', 'white');
      $('.ql-editor').find('p').css('color', 'black');
      $('.ql-editor').find('span').css('color', 'black');
    }
    else if (val == 'color_wheat') {
      $('.sub_details').css('background', 'rgb(238, 238, 227)');
      $('#cc').find('p').css('color', 'black');
      $('#cc').find('span').css('color', 'black');
    }
    else if (val == 'color_black') {
      $('.sub_details').css('background', 'black');
      $('.ql-editor').find('p').css('color', 'white');
      $('.ql-editor').find('span').css('color', 'white');
    }
    else {
      $('.sub_details').css('background', 'white');
      $('.ql-editor').find('p').css('color', 'black');
      $('.ql-editor').find('span').css('color', 'black');
    }
   }

  onFontSize(size) {
    this.firstTime = false;
    this.activeSize = size
    if (typeof size === 'string') {
      size = size;
    } else {
      size = size.target.value;
    }
    $('.ql-editor').find('p').css('font-size', parseInt(size) + 'px');
    $('.ql-editor').find('p').css('line-height', parseInt(size) + parseInt('10') + 'px');
    $('.ql-editor').find('span').css('font-size', parseInt(size) + 'px');
    $('.ql-editor').find('span').css('line-height', parseInt(size) + parseInt('10') + 'px');
    localStorage.setItem('size', size);
  }

  onFontChange(font) {
    this.firstTime = false;
    console.log(font)
    if (typeof font === 'string') {
      font = font;
    } else {
      font = font.target.value;
    }
    if(font === 'Roboto'){
      $('.ql-editor').find('p').css('font-family', font);
      $('.ql-editor').find('p').css('font-style', 'italic');
      $('.ql-editor').find('span').css('font-family', font);
      $('.ql-editor').find('span').css('font-style', 'italic');
      localStorage.setItem('font', font);
    }else{
      $('.ql-editor').find('p').css('font-family', font);
      $('.ql-editor').find('span').css('font-family', font);
      $('.ql-editor').find('p').css('font-style', 'inherit');
      $('.ql-editor').find('span').css('font-style', 'inherit');

      localStorage.setItem('font', font);
    }
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId:
        'Af-FG-qPrC_Zau9sWip8h8OUJ68XMctAXbiljFZasxYhOhum8UIyqmcmoZIhiAvq1-pk8FlUS7Iy2bHU',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.amount,
                  },
                },
              },
              items: [
                {
                  name: 'Buy Chapter',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        actions.order.get().then((details) => {});
      },
      onClientAuthorization: (data) => {
        this.showError = '';
        this.updtaePayment({
          book_id: this.book_id,
          chapter_id: this.chapter_id,
          transaction_id: data.id,
          type: '',
          amount: this.amount,
          plan_id: '',
          purpose: this.title,
          status: 'COMPLETED',
          user_id: this.created_by,
        });
        this.toastr.success('Payment done successfully.');
      },
      onCancel: (data, actions) => {
        this.toastr.error('Something went wrong.');
      },
      onError: (err) => {
        this.toastr.error('Something went wrong.');
      },
      onClick: (data, actions) => {
        // this.showError = 'Somethin went wrong';
      },
    };
  }

  updtaePayment(postdata) {
    this.chapterService
      .savePayment(postdata)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.getChapter();
            this.toastr.success('Payment done successfully.');
            location.reload();
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  shareBook(id) {
    this.chapterService
      .shareBook({ book_id: id })
      .pipe(first())
      .subscribe(
        (res: any) => {
          // $("#forgot").modal('hide')
          this.getChapter();
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }


  postComment(){
    this.submitted = true;

    this.showLoader = true;
    if (this.f.comment.value == '' || this.f.comment.value == null) {
      this.errormsg = true;
      this.showLoader = false;
    } else {
      this.chapterService
        .addComment(this.f.comment.value, this.book_id, this.chapter_id)
        .pipe(first())
        .subscribe(
          (data: any) => {
            this.commentForm.reset();
            this.errormsg = false;
            // this.toastr.success(data.message);
            this.getComments();
            this.showLoader = false;
            this.submitted = false;
          },
          (error) => {
            console.log('Error', error);
          }
        );
    }
  }

  onPurchase() {
    this.buttonLoading = true;
    const payload = {
      chapterId: this.chapter_id,
      libcoins: this.amount
    }

    this.transactionService.purchase(payload).subscribe((res: any) => {
      // purchase successfull
      if(res?.status) {
        this.is_purchased = true;
        this.utils.showConfetti('party-element-chapter-purchase');
        this.refetchUserData()
      }
    }, (error) => {
      console.log("error => ", error);
      this._snackbar.open(error.message.slice(48), 'ok', {
        duration: 2000,
      });
    }).add(() => {
      this.buttonLoading = false;
    })
  }

  onPaymentComplete() {
    this.refetchUserData();
    this.utils.showConfetti('party-element-wallet-recharge');
  }

  refetchUserData(reload: boolean = false){
    this.helper.refetchUserData(reload === true ? 'reload' : 'none');
  }

  shareBookDialog() {
    this.dialog.open(ShareBookDialogComponent, {
      data: { route: this.activeRoute },
      height: '280px',
      width: '600px',
    });
  }

  author_id: string;

  addBookToHistory = () => {
    this.readChapterService
      .addBookHistory(this.book_id, this.chapter_id, this.author_id)
      .subscribe((res: any) => {
        if (res.status) {
          console.log('Request Success');
        } else {
          console.log('Request failed');
        }
      });
  };
}
