<div class="iq-card shadow-none m-0">
  <div class="iq-card-body p-0">
    <div class="bg-primary-cyan p-3">
      <h5 class="mb-0 notification-header text-white">
        Notifications
        <small class="float-right ml-2"> New </small>
        <small class="badge badge-light float-right pt-1">{{
          numberOfNewNotifications
        }}</small>
      </h5>
    </div>
    <!-- class="iq-sub-card" -->
    <div *ngIf="newNotifications">
      <a
        (click)="goto()"
        *ngFor="let notify of userNotification; let i = index"
      >
        <!-- <div *ngFor="let notify of Notification.data; let i = index"> -->
        <div
          *ngIf="
            (notify.type == 'New book' ||
              notify.type == 'follow' ||
              notify.type == 'like' ||
              notify.type == 'chapter') &&
            i <= 5
          "
          class="media align-items-center mt-2 ml-3 mr-3"
          [ngClass]="{ new__notification: !notify.viewed }"
        >
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'New book'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                src="{{ baseimage }}{{ notify.user_info.image }}"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'chapter'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                src="{{ baseimage }}{{ notify.user_info.image }}"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'follow'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                src="{{ baseimage }}{{ notify.user_info.image }}"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'like'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                src="{{ baseimage }}{{ notify.user_info.image }}"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </a>
    </div>
    <div *ngIf="!newNotifications" class="soft-card">No New Notification</div>
    <div
      class="bg-primary-cyan p-2"
      style="border-radius: 0 0 4px 4px !important; height: 39px"
      (click)="goto()"
    >
      <h3 class="text-white" style="margin-left: 37%; cursor: pointer">
        <u> View All </u>
        <br />
        <small class="badge badge-light float-right pt-1"></small>
      </h3>
    </div>
  </div>
</div>
