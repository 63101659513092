<section class="conatiner__wall">
  <form class="m-0" #createPost="ngForm" style="width: 100%;" (ngSubmit)="onSubmit(createPost)">
    <div class="author__write__wall">
      <div class="d-flex w-100">
        <div>
          <img
            class="author__image rounded-circle mr-2"
            src="{{ baseimage }}{{ userData.image }}"
            alt=""
            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
          />
        </div>
        <div class="mat-container">
          <mat-form-field class="mr-2 w-100" style="margin-top: -8px" appearance="outline">
            <mat-label>Type...</mat-label>
            <input
              matInput
              type="text chapter-title"
              name="content"
              #post="ngModel"
              [(ngModel)]="content"
              required
              class="w-100"
            />
            <span
              (click)="file.click()"
              matSuffix
              class="icon-border-gradient-color material-icons-round d-flex"
            >
              <img loading="lazy" class="add-photo" src="../../../assets/Slider/images/icons/photo.svg" alt="" style="width: 24px; height: 24px;">
              <!-- add_circle_outline -->
            </span>
            <mat-error *ngIf="submitted || (!post.valid && post.touched)"
              >Enter some post</mat-error
            >
          </mat-form-field>
          <label>
            <img loading="lazy" src="../../../assets/Slider/images/icons/photo.svg" alt="">
            <input
              style="display: none"
              type="file"
              (change)="onSelectFile($event)"
              #file
            />
          </label>

        </div>
      </div>
      <button
        style="border: none; background-color: white; height: 0px"
        type="submit"
      >
        <div
          class="
            author__send__button
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <img loading="lazy" class="author__send__icon" src="assets/images/send.png" alt="" />
        </div>
      </button>
    </div>
  </form>

  <app-loader *ngIf="!showImage"></app-loader>
  <div *ngIf="showImage && uploaded_image" class="uploaded-image-container">
    <div style="position: relative;">
      <img
        (click)="openImage(uploaded_image, true)"
        class="author__uploaded_image"
        [src]="baseimage + postimage"
      />
      <button type="button" class="remove-image-button" (click)="removeImage()" style="position: absolute; top: 10px; right: 10px;">
        <mat-icon class="text-color-black-200">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="author__wall__posts">
    <div>
      <div class="mt-5 author__posts" *ngFor="let data of authorData.myPosts">
        <div class="mr-2">
          <img
            class="author__wall__image rounded-circle"
            src="{{ baseimage }}{{ data.user_image }}"
            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
            alt=""
          />
        </div>
        <div class="author__post__content mt-3">
          <div class="user__name">
            {{ data.fullname }}
          </div>
          <div class="user__post__content mt-3">
            {{ data.content }}
            <div
              *ngIf="data.image"
              style="width: 100%; height: 250px"
              class="user__content__image mt-3"
            >
              <img
                (click)="openImage(data.image, false)"
                class="rounded post-image border w-100"
                src="{{ baseimage }}{{ data.image }}"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
