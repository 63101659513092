import { Component, Input, OnInit } from "@angular/core";
import { ProfileService } from "./profile.service";
import { FormBuilder, Validators, FormGroup, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';
import { SeoserviceService } from "src/app/seoservice.service";
import { HelperService } from "src/app/helper.service";
import { BaseUrl } from '../../../base-url';
import { EditProfileInfoService } from "src/app/edit-profile-info/edit-profile-info.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.sass"]
})
export class ProfileComponent implements OnInit {
  fullname: any;
  email: any;
  userid: any;
  authordata: any;
  loggedIn: any;
  userdata: any;
  updateForm: any;
  baseimage: any;
  userName: any;
  loading = false;
  submitted = false;
  showMsg = false;
  errorMsg: any;
  admin_id: any;
  usersUpdate: any;
  showLoader = false;
  @Input() userId: string;
  constructor(
    private changeadmin: ProfileService,
    private formbuilder: FormBuilder,
    private editProfileService: EditProfileInfoService,
    private router: Router,
    private helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private _seoService: SeoserviceService,
    private toaster: ToastrService,
    private profileService: ProfileService,
    private _snackbar: MatSnackBar
    ) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.admin_id = this.userId || this.activatedRoute.snapshot.paramMap.get("id");
    this.getUserData();
    this.updateForm = this.formbuilder.group({
      user_id: this.admin_id,
      fullname: ["", Validators.required],
      email: [""],
      enable_mature_content: [false]
    });

    this.activatedRoute.url.subscribe((url) => {
      this.showLoader = true;
      this.userdata = this.helper.getUserData();
      this.userid = this.userdata.data._id;
      this.loggedIn = this.userdata.data._id;
      this.userName = this.userdata.data.fullname
      if(this.userdata.data?.dob) {
        this.userdata.data.dob = new Date(this.userdata.data.dob);
      }
      this.loadAuthorData();
    });

    this.helper.updateUserData.subscribe(() => {
      this.userdata = this.helper.getUserData();
    })
  }

  async submitNewProfileInfo(editProfileInfo: NgForm): Promise<any> {
    try {
      const data: any = { ...editProfileInfo?.value };

      this.profileService.update_user_profile(data).subscribe((res: { status: boolean | any, data: any, message: string }) => {
        this._snackbar.open(res.message, 'Ok', { duration: 2000 });
        this._seoService.updateTitle(res?.data?.fullname);
        this.helper.refetchUserData();
        this.router.navigate(['/', 'authorprofile', this.userdata?.data?._id]);
      })
    } catch (err) {
      this.toaster.error('Something went wrong');
    }

  }

  getUserData() {
    this.changeadmin.getUserData(this.admin_id).subscribe(
      (res: any) => {
        if (res.status) {
          this.usersUpdate = res.data;
          if(!this.usersUpdate.hasOwnProperty('enable_mature_content')) {
            this.usersUpdate.enable_mature_content = true;
          }
          this.updateForm.patchValue({
            fullname: this.usersUpdate?.fullname,
            enable_mature_content: this.usersUpdate?.enable_mature_content
          });
          this.showLoader = false;

        }
      },
      error => {
        console.log("ERROR");
      }
    );
  }

  loadAuthorData() {
    this.editProfileService.getAuthor(this.userid).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        if (res.data.cover_image) {
          res.data.coverimage = this.baseimage + '' + res.data.cover_image;
        } else {
          res.data.coverimage = 'assets/img/bg_place_holder.jpg';
        }
        this.authordata = res.data;
        var { cover_image } = this.helper.appendBaseUrlToImage(
          this.authordata['coverimage']
        );
        this.authordata['coverimage'] = cover_image;

        var { cover_image } = this.helper.appendBaseUrlToImage(
          this.authordata['image']
        );
        this.authordata['image'] = cover_image;
        this.authordata.myLikedStories.every((elem) => {

          var { cover_image } = this.helper.appendBaseUrlToImage(
              elem['cover_image']
          );
          elem['cover_image'] = cover_image;
          return true;
        });
        this._seoService.updateTitle(this.authordata?.username || 'Librums');
        this.showLoader = false;
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  get f() {
    return this.updateForm.controls;
  }
  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key)?.value?.toString
    ()?.trim()));
  }
  onSubmit() {
    this.cleanForm(this.updateForm);
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.changeadmin.updateAdmin(this.updateForm.value).subscribe(
      (res: any) => {
        if (res) {
          this.fullname = this.updateForm.value.fullname;
          this.toaster.success(res.message);
          this.getUserData();
          const data = localStorage.getItem("currentUser")
          const userData = JSON.parse( crypto.AES.decrypt(data, environment.secretKey).toString( crypto.enc.Utf8 ) )
          userData.data.fullname = this.fullname;
          const encryptedUser = crypto.AES.encrypt(JSON.stringify(userData), environment.secretKey).toString();
          localStorage.setItem("currentUser", encryptedUser);
          // window.location.reload();
        } else {
          this.toaster.error(res.message);
        }
      },
      error => {
        this.toaster.error(error);
        this.loading = false;
      }
    );
  }
}
