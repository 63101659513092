import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DashboardService } from "./dashboard.service";
import { NotificationService } from 'src/app/notification/notification.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
declare var $:any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
  getreports: any;
  showWelcom = true;
  role: any;

  isDashboardLoading: boolean = false;
  isNotificationLoading: boolean = false;
  isFeedbackLoading: boolean = false;

  constructor(
    private router: Router,
    private dashboard: DashboardService,
    private notificationService: NotificationService,
    private feedbackService: FeedbackService,
    private utils: UtilityService
  ) {
  }

  notifications: Array<any> = [];
  notificationPage: number = 1;
  notificationItemsPerPage:number = 10;
  notificationTotalItem: number = 0;

  // feedback
  feedbacks: Array<any> = [];
  feedbackPage: number = 1;
  feedbackItemsPerPage:number = 10;
  feedbackTotalItem: number = 0;
  

  async ngOnInit() {
    this.getDashboardData();
    this.getNotificationData();
    this.getFeedbacks();
    console.log();
    this.role = localStorage.getItem('role');
    
  }

  getNotificationData(pageNo = 1) {
    this.isNotificationLoading = true;
    this.notificationPage = pageNo;
    const skip = (pageNo - 1) * this.notificationItemsPerPage;
    this.notificationService.getAdminNotification({
      limit: this.notificationItemsPerPage,
      skip: skip || 0
    }).subscribe((res: any) => {
      if (res?.status) {
        this.notifications = res?.data;
        this.notificationTotalItem = res?.total_items
      }
    }, err => {
      console.log("Get Notification Error", err);
      this.utils.openSanckbar({title: err?.message || 'Something went wrong', actionText: 'Ok'});
    }).add(() => {
      this.isNotificationLoading = false;
    })
  }

  getFeedbacks (pageNo = 1) {
    this.isFeedbackLoading = true;
    this.feedbackPage = pageNo;
    const skip = (pageNo - 1) * this.feedbackItemsPerPage;
    this.feedbackService.getFeedbacks({
      skip,
      limit: this.feedbackItemsPerPage
    }).subscribe((res) => {
      if(res?.status) {
        this.feedbacks = res?.data;
        this.feedbackTotalItem = res?.total_items;
      }
    }, err => {
      console.log("Get Feedback Error", err);
      this.utils.openSanckbar({title: err?.message || 'Something went wrong', actionText: 'Ok'});
    }).add(() => {
      this.isFeedbackLoading = false;
    })

  }

  async getDashboardData() {
    this.isDashboardLoading = true;
    this.dashboard.getReports().subscribe((res: any) => {
        this.getreports = res.data;
      },
      err => {
        console.log("Get Dashboard Error", err);
        this.utils.openSanckbar({title: err?.message || 'Something went wrong', actionText: 'Ok'});
      }
    ).add(() => {
      this.isDashboardLoading = false;
    });
  }
  viewFeedback(id){
    this.router.navigate(["/view-feedback"],{ queryParams: { id: id } });
  }

}
