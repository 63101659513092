<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="conatiner__">
      <div class="col-lg-12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <img
            style="border-radius: 10px 10px 0 0;"
            class="withdrawal__banner"
            src="assets/images/withdrawal.png"
            alt=""
          />
          <div class="withdrawal__container mt-5">
            <div></div>
            <div class="text-center withdrawal__">
              <h3 class="withdrawal__withdrawal">Withdrawal</h3>
              <div class="withdrawal__details d-flex flex-wrap mt-5 container">
                <!-- <div></div> -->
                <div class="withdrawal__wallet flex-fill p-5 m-2 m-sm-3" id="party-element-wallet-recharge">
                  <div
                    class="
                      withdrawal_waller_amount
                      d-flex
                      justify-content-between
                    "
                  >
                  <h2 class="mr-auto align-self-center">Wallet Amount</h2>
                  <h2 class="align-self-center">
                    <!-- {{userdata?.libcoins}} Libs -->
                        <span class="text-success">
                          <b>{{userdata?.libcoins}} Libs</b>
                        </span>
                  </h2>
                  <h2 class="align-self-center">
                    <button mat-icon-button (click)="open_libcoin_purchase_modal()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </h2>
                  </div>
                  <!-- <div
                    class="
                      withdrawal_subscription
                      d-flex
                      justify-content-between
                    "
                  >
                    <h3>Subscription</h3>
                    <h2>-1200$</h2>
                  </div> -->
                  <div
                    class="withdrawal_earnings d-flex justify-content-between align-self-center my-2"
                  >
                    <div class="align-self-center">Earnings</div>
                    <div>
                          <span class="text-success">
                            <b>{{ userdata?.available_earning || 0 }} Libs</b>
                          </span>
                    </div>
                  </div>
                  <div
                    class="withdrawal_spendings d-flex justify-content-between my-2"
                  >
                    <div class="align-self-center">
                      Spendings
                    </div>
                    <div>
                          <span class="text-danger">
                            <b>{{ userdata?.libcoins_spent || 0 }} Libs</b>
                          </span>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="
                      withdrawal_available_amount
                      d-flex
                      justify-content-between
                    "
                  >
                    <h2>Available Amount</h2>
                    <h2>{{ (userdata?.available_earning || 0) / 10 | currency: 'USD' }} </h2>
                  </div>
                  <span class="text-danger"> Your available amount should be at least $10 in order to withdraw </span>


                  <div class="text-right mt-3" *ngIf="!showWithdrawMoneyForm">
                    <button [disabled]="(userdata?.available_earning || 0) / 10 < 10" class="btn btn-cyan br-8" mat-button (click)="showWithdrawMoneyForm = true;">Withdraw</button>
                  </div>
                  <div *ngIf="showWithdrawMoneyForm" class="w-100">
                    <app-withdraw-money></app-withdraw-money>
                  </div>
                </div>

                <!-- TODO: Fix ui streched out issue -->
                <div class="withdrawal__spendings flex-fill p-5 m-2 m-sm-3">
                  <h2 class="text-left" style="margin-bottom: 10px;">Last Spendings</h2>
                  <div class="withdrawal__book_earnings card list-item mb-0 p-3" *ngFor="let transaction of recentTransactionHistory">
                    <div class="full-data-container" *ngIf="transaction.type != 'withdraw'">
                      <div class="d-flex justify-content-center">
                        <img loading="lazy" *ngIf="transaction?.cover_image" class="withdrawal__book_cover" [src]="_baseURL + transaction.cover_image"/>
                        <img loading="lazy" *ngIf="transaction?.cover_image == ''" class="withdrawal__book_cover" src="assets/images/rectangle1.svg"/>
                      </div>
                      <div class="withdrawal__book_details mr-auto withdrawal-data-container w-100">
                        <div class="ml-4 text-left data-text-container">
                          <ng-container *ngIf="transaction?.type === 'purchase'">
                            <div class="data-text" [title]="transaction?.chapter_name" >
                              {{ transaction?.chapter_name | slice: 0 : 35 }}<ng-container *ngIf="transaction?.chapter_name?.length > 35">...</ng-container>
                            </div>
                            <div class="font-italic data-text" align="left">
                              ~ {{ transaction?.book_name }}
                            </div>
                          </ng-container>
                          <ng-container *ngIf="transaction?.type === 'recharge'">
                            <div align="left">
                              Recharge
                            </div>
                          </ng-container>

                          <div align="left" style="font-size: 12px;" class="text-secondary data-text">{{ transaction.created_at | date: 'short' }}</div>
                        </div>
                        <div class="withdrawal__book_price" [class.text-danger]="transaction.type === 'purchase'" [class.text-success]="transaction.type === 'recharge'">
                          <span *ngIf="transaction.type === 'purchase'"> - </span>
                          <span *ngIf="transaction.type === 'recharge'"> + </span>
                          {{ transaction.libcoins }}<span style="font-size: 12px;" class="badge">libs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div></div> -->
              </div>

                <div class="container">
                  <div class="withdrawal__spendings infinite-content bg-light p-5 m-2 m-sm-3">
                    <h2 class="text-left" style="margin-bottom: 10px;">Transaction History</h2>

                    <!-- <cdk-virtual-scroll-viewport #scroller [itemSize]="10" class="content"> -->
                      <div class="withdrawal__book_earnings card mb-0 p-3 list-item" *ngFor="let transaction of transactionHistory">
                        <div class="full-data-container" *ngIf="transaction.type != 'withdraw'">
                          <div class="d-flex justify-content-center">
                            <img loading="lazy" *ngIf="transaction?.cover_image" class="withdrawal__book_cover" height="200" [src]="_baseURL + transaction.cover_image"/>
                            <img loading="lazy" *ngIf="transaction?.cover_image == ''" class="withdrawal__book_cover" src="assets/images/rectangle1.svg"/>
                          </div>
                          <div class="withdrawal__book_details mr-auto w-100 withdrawal-data-container">
                            <div class="ml-4 text-left data-text-container">
                              <ng-container *ngIf="transaction?.type === 'purchase'">
                                <div class="data-text" [title]="transaction?.chapter_name" >
                                  {{ transaction?.chapter_name | slice: 0 : 35 }}<ng-container *ngIf="transaction?.chapter_name?.length > 35">...</ng-container>
                                </div>
                                <div class="font-italic data-text" align="left">
                                  ~ {{ transaction?.book_name }}
                                </div>
                              </ng-container>
                              <ng-container *ngIf="transaction?.type === 'recharge'">
                                <div align="left">
                                  Recharge
                                </div>
                              </ng-container>

                              <div align="left" style="font-size: 12px;" class="text-secondary data-text">{{ transaction.created_at | date: 'short' }}</div>
                            </div>
                            <div class="withdrawal__book_price" [class.text-danger]="transaction.type === 'purchase'" [class.text-success]="transaction.type === 'recharge'">
                              <span *ngIf="transaction.type === 'purchase'"> - </span>
                              <span *ngIf="transaction.type === 'recharge'"> + </span>
                              {{ transaction.libcoins }}<span style="font-size: 12px;" class="badge">libs</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </cdk-virtual-scroll-viewport> -->
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
