import {
  Component,
  OnInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { BaseUrl } from '../../base-url';
import { HelperService } from '../../helper.service';
import { MyWallService } from '../../my-wall.service';
import { PostMessageService } from '../../post-message.service';
import { SeoserviceService } from '../../seoservice.service';
import { AlertService } from '../../services/alert.service';
import { AuthorProfileService } from './author-profile.service';
import Swiper from 'swiper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImagePostComponent } from '../wall/image-post/image-post.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageDialogComponent } from '../../image-dialog/image-dialog.component';
import { ImageService } from '../../image-editor-canvas/image.service';
import { HandleEditService } from 'src/app/add-book-cover/handle-edit.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { ProfileService } from 'src/app/admin/admin-modules/profile/profile.service';
declare var $: any;

@Component({
  selector: 'app-author-profile',
  templateUrl: './author-profile.component.html',
  styleUrls: ['./author-profile.component.sass'],
})
export class AuthorProfileComponent implements OnInit {
  userid: any;
  authordata: any;
  userdata: any;
  loggedIn: any;
  isCurrentUser: boolean = false; // true if provided params id is of logged in user
  showLoader = false;
  selectedFiles: FileList;
  currentFileUpload: File;
  postimage: any;
  _baseURL: any;
  cover_image: any;
  profile_image: any;
  cover_form_data: FormData = new FormData();
  profile_form_data: FormData = new FormData();
  imgSrc: any;
  SlideOptions = {
    stagePadding: 120,
    margin: 0,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        // stagePadding: 300,
        items: 1,
      },
      1200: {
        stagePadding: 300,
        items: 1,
      },
      1300: {
        stagePadding: 300,
        items: 1,
      },
      1400: {
        stagePadding: 300,
        items: 1,
      },
      1500: {
        stagePadding: 350,
        items: 1,
      },
      1600: {
        stagePadding: 380,
        items: 1,
      },
      1700: {
        stagePadding: 400,
        items: 1,
      },
      1900: {
        stagePadding: 420,
        items: 1,
      },
    },
  };
  isFollowed: boolean;
  CarouselOptions = { items: 2, dots: false, nav: false };
  baseimage: any;
  showMsg = '';
  postid: any;
  storyCount: any;
  likeCount: any;
  currentDate = new Date();
  updateForm: any;
  loading = false;
  submitted = false;
  showError = false;
  timeZoneOffset: any;
  user_id: any;
  selectedIndex = 0;
  Tabs: Array<string> = ['about', 'wall', 'edit', 'following', 'followers'];
  showSlider: boolean = false;
  public twoImages: boolean = false;
  public imageUrl: string[] = [];
  public uploadedImage: any;
  coverImageLoading: boolean = false;
  profileImageLoading: boolean = false;

  @ViewChild('profile_image') profileImageRef: ElementRef;
  @ViewChild('cover_image') coverImageRef: ElementRef;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private authorService: AuthorProfileService,
    private activatedRoute: ActivatedRoute,
    private mywalldata: MyWallService,
    private toaster: ToastrService,
    private postmessage: PostMessageService,
    private formBuilder: FormBuilder,
    private _seoService: SeoserviceService,
    private helper: HelperService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private handleEdit: HandleEditService,
    private profileService: ProfileService,
    private _snackbar: MatSnackBar,
  ) {
    this.baseimage = BaseUrl.image;
    this._baseURL = BaseUrl.image;
    this.handleEdit.modeVal.subscribe((res) => {
      this.returnTo = res;
    });
  }
  private returnTo: string = ''
  OnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      var swiper = new Swiper('.books-by', {
        // effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 2,
        // spaceBetween: 50
        // coverflowEffect: {
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: false,
        // },
      });
    }, 2000);
  }
  openImage(postImage) {
    this.dialog.open(ImagePostComponent, {
      data: { postImage },
      height: 'auto',
      width: 'auto',
      maxHeight: '700px',
      maxWidth: '800px',
    });
  }
  onMatTabChanged() {
    if (this.selectedIndex === 1) {
      setTimeout(() => {
        var swiper = new Swiper('.myMedias', {
          grabCursor: true,
          direction: 'vertical',
          slidesPerView: 'auto',
        });

        console.log('swiper', swiper);
      }, 0);
    }
    
    window.history.replaceState({}, '',`/authorprofile/${this.userid}/${this.Tabs[this.selectedIndex]}`);
  }


  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.showLoader = true;
      this.userid = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedIndex = this.Tabs.indexOf(this.activatedRoute.snapshot.paramMap.get('tab_type'));

      this.userdata = this.helper.getUserData();
      if (this.userid === this.userdata?.data?._id) this.isCurrentUser = true;
      else {
        if(this.selectedIndex === 2) {
          this.selectedIndex = 0;
          window.history.replaceState({}, '',`/authorprofile/${this.userid}/${this.Tabs[this.selectedIndex]}`);
        }
      }
      this.loggedIn = this.userdata.data._id;

      this.timeZoneOffset = this.timeConvert(
        this.currentDate.getTimezoneOffset() * 2
      );

      this.updateForm = this.formBuilder.group({
        user_id: this.userid,
        content: ['', Validators.required],
        post_image: [''],
      });
      this.loadAuthorData();
      this.loadAuthorDataCount();
    });
  }
  timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var total = -(rhours + rminutes);
    if (n < 0) {
      return '+' + total;
    } else {
      return '-' + total;
    }
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  }
  //load the auther data
  loadAuthorData() {
    this.authorService.getAuthor(this.userid).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        if (res.data.cover_image) {
          res.data.coverimage = this.baseimage + '' + res.data.cover_image;
        } else {
          res.data.coverimage = 'assets/img/bg_place_holder.jpg';
        }
        this.authordata = res.data;
        var { cover_image } = this.helper.appendBaseUrlToImage(
          this.authordata['coverimage']
        );
        this.authordata['coverimage'] = cover_image;

        var { cover_image } = this.helper.appendBaseUrlToImage(
          this.authordata['image']
        );
        this.authordata['image'] = cover_image;
        this.authordata.myLikedStories.every((elem) => {

          var { cover_image } = this.helper.appendBaseUrlToImage(
            elem['cover_image']
          );
          elem['cover_image'] = cover_image;
          return true;
        });
        this.isFollowed = this.authordata.isFollowed === 1 ? true : false;
        this._seoService.updateTitle(this.authordata?.username || 'Librums');
        this.showLoader = false;
        this.coverImageLoading = false;
        this.profileImageLoading = false;
      },
      (error) => {
        console.log('ERROR', error);
        this.coverImageLoading = false;
        this.profileImageLoading = false;
      }
    );
  }
  loadAuthorDataCount() {
    this.authorService.getAuthorCount(this.userid).subscribe(
      (res: any) => {
        this.storyCount = res.data.books;
        this.likeCount = res.data.likes;
      },
      (error) => {
        console.log('ERROR');
      }
    );
  }

  follow(e) {
    this.authorService
      .followAuther({
        status: e,
        user_id: this.authordata._id,
      })
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.isFollowed = !this.isFollowed;
            this._snackbar.open(res.message, 'ok', {
              duration: 2000,
            });
            const currentUrl = this.router.url;
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigate([currentUrl]);
              });
          }
        },
        (error) => {}
      );
  }

  deletWallPost(id) {
    this.postid = id;
  }
  deleteConfirm() {
    this.mywalldata
      .removeWallPost({ post_id: this.postid })
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.showError = false;
            this.toaster.success(res.message);
            this.loadAuthorData();
          } else {
            this.toaster.error(res.message);
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  searchTag(text) {
    localStorage.setItem('search', text);
    this.router.navigate(['/search']);
  }

  // get f() {
  //   return this.updateForm.controls;
  // }

  // onSelectFile(event) {
  //   if (event.target.files.length > 0) {
  //     this.showLoader = true;
  //     let formData = new FormData();
  //     this.selectedFiles = event.target.files;
  //     this.currentFileUpload = this.selectedFiles.item(0);
  //     formData.append('image', this.currentFileUpload);
  //     this.postmessage.post_image(formData).subscribe(
  //       (res: any) => {
  //         if (res) {
  //           this.postimage = res;
  //           if (res.status) {
  //             $('.post-image').hide();
  //             $('.details_listing_txt_img1').show();
  //             $('#post-image').show();
  //             $('#post-image').attr('src', this._baseURL + '/' + res.data);
  //             this.updateForm.controls['post_image'].setValue(res.data);
  //             this.showLoader = false;
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log('ERROR');
  //       }
  //     );
  //   }
  // }
  reset() {
    const dailogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { cancel: true },
      width: '500px',
      height: '190px',
    });

    dailogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.imageService.reset();
        this.router.navigate([`/${this.returnTo}`]);
      }
    });
  }
  // ImageDialogClosed(dialogRef: MatDialogRef<ImageDialogComponent>) {
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if(!result) return;
  //       this.imageService.f(result.data);
  //         if(result != null){
  //         this.uploadedImage == result.data
  //         let formData = new FormData();
  //         console.log(result.data)
  //         // this.currentFileUpload = this.uploadedImage.item(0);
  //         formData.append('image', this.uploadedImage);
  //         console.log(formData)
  //         this.postmessage.post_image({"image": result.data}).subscribe(
  //           (res: any) => {
  //             if (res) {
  //               this.postimage = res;
  //                 $('#post-image').attr('src', this._baseURL + '/' + res.data);
  //                 this.updateForm.controls['post_image'].setValue(res.data);
  //                 this.showLoader = false;
  //             }
  //           },
  //           (error) => {
  //             console.log('ERROR');
  //           }
  //         )
  //       }
  //   });
  // }

  uploadPhotos = async (type: 'profile' | 'cover') => {
    const data: {image?: string, cover_image?: string} = {};

    if (type === 'cover') {
      this.coverImageLoading = true;
      const cover_image_response: any = await this.profileService.upload_image(this.cover_form_data).pipe(first()).toPromise();
      if(cover_image_response?.status && cover_image_response?.data){
        data.cover_image = cover_image_response?.data;
      }
    }
    if(type === 'profile') {
      // this.coverImageLoading = true;
      this.profileImageLoading = true;
      const profile_image_response: any = await this.profileService.upload_image(this.profile_form_data).pipe(first()).toPromise();
      if(profile_image_response?.status && profile_image_response?.data){
        data.image = profile_image_response?.data;
      }
    }

    this.profileService.update_user_profile(data).subscribe((res: { status: boolean | any, data: any, message: string }) => {
      this.helper.refetchUserData();
      this.loadAuthorData();
    }, (err) => {
      this.coverImageLoading = false;
      this.profileImageLoading = false;
    })
  }

  ImageDialogClosed(dialogRef: MatDialogRef<ImageDialogComponent>, type: 'cover' | 'profile' = 'cover') {
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log("Afterclose => ", result);
      if(!result) return;
        this.imageService.f(result.data);
          if(result != null){
          const blob = this.imageService.b64toBlob(result?.data);

          if(type === 'cover') {
            this.cover_image = result?.data;
            this.cover_form_data.append('image', blob);
          } else if(type === 'profile') {
            this.profile_image = result?.data;
            this.profile_form_data.append('image', blob);
          }
        }

        this.uploadPhotos(type);
        this.imageService.reset()
    });
  }
  onSelectFile(event: any, type: 'cover' | 'profile' = 'cover') {
    const file = event.target.files[0];
    const reader = new FileReader();

    if(type === 'cover') {
      this.coverImageRef.nativeElement.value = null;
      this.cover_form_data.delete('image');
    } else if(type === 'profile') {
      this.profileImageRef.nativeElement.value = null;
      this.profile_form_data.delete('image');
    }

    reader.onload = (e) => {

      const data: any = { url: reader.result, uploadedImage: true, crop: true, aspectRatio: 4 / 1 };
      if (type == 'profile') {
        data.aspectRatio = 1/1;
        data.roundCropper = true;
      }

      const dialogRef = this.dialog.open(ImageDialogComponent, {
        data: data,
        height: '370',
        maxHeight: '370',
        width: '260',
      });
      this.ImageDialogClosed(dialogRef, type);
    };
    reader.readAsDataURL(file);
  }
  // onSelectFile(event: any) {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const dialogRef = this.dialog.open(ImageDialogComponent, {
  //       data: { url: reader.result, uploadedImage: true, crop: true },
  //       height: '370',
  //       width: '260',
  //     });
  //     this.ImageDialogClosed(dialogRef);
  //   };
  //   reader.readAsDataURL(file);
  // }
  viewImage(src) {
    this.imgSrc = src;
    $('#view-image').modal('show');
  }
  onSubmit() {
    this.loading = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.controls.content.value == '') {
      this.showError = true;
      this.loading = false;
      return;
    }
    // if (this.updateForm.invalid) {
    //   this.loading = false;
    //   return;
    // }
    this.updateForm.controls.user_id.value = this.userid;
    this.postmessage.savePost(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          // $("#myModal").modal('hide')
          $('.details_listing_txt_img1').hide();
          $('#post-image').hide();
          this.toaster.success('Post updated successfully.');
          this.loading = false;
          this.loadAuthorData();
          this.emptyForm();
          this.showError = false;
        } else {
          this.toaster.error(res.message);
        }
      },
      (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  emptyForm() {
    this.updateForm.controls['post_image'].setValue('');
    this.updateForm.controls['content'].setValue('');
  }
  CrossImage() {
    this.updateForm.controls['post_image'].setValue('');
    $('#post-image').attr('src', '');
    $('.details_listing_txt_img1').hide();
    $('#post-image').hide();
  }
}
