import { Component, OnInit } from "@angular/core";
import { ManagePagesService } from "./manage-pages.service";
import {SafeHtml } from '@angular/platform-browser';

@Component({
  selector: "app-manage-pages",
  templateUrl: "./manage-pages.component.html",
  styleUrls: ["./manage-pages.component.sass"]
})
export class ManagePagesComponent implements OnInit {
  managePages: any;
  temp: boolean;
  showLoader = false

  constructor(private manage: ManagePagesService) {}

  ngOnInit() {
    this.showLoader = true
    this.loadManage();
  }

  loadManage() {
    this.manage.getPages().subscribe(
      (res: any) => {
        this.managePages = res.data;
        this.temp = true;
        this.showLoader = false;
      },
      () => {
        console.log("ERROR");
      }
    );
  }
}
