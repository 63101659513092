import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import { IPayPalConfig } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentDialogComponent } from 'src/app/payment-dialog/payment-dialog.component';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.sass'],
})
export class WithdrawalComponent implements OnInit {
  // constructor() {}
  // ngOnInit() {}
  paymenthistory: any;
  showWithdrawMoneyForm: boolean = false;
  userdata: any;
  _baseURL: any;
  emailCheck = true;
  payPalConfig: IPayPalConfig;
  libcoinAmount: number = 10;
  phoneCheck = false;
  paypalCheck = false;
  paymentForm: any;
  submitted = false;
  loading = false;
  total: any;
  checkError = false;
  checkSuccess = false;
  checkErrorInvalid = false;
  recentTransactionHistory: Array<any> = [];
  transactionHistory: Array<any> = [];
  totalTransactionHistoryItem: number = 0;
  isTransactionLoading: boolean = false;
  public temp: Object = false;
  public imageBaseUrl;
  transactionPageNo: number = 0;

  constructor(
    // private withdrawal: WithdrawalService,
    // private toaster: ToastrService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private helper: HelperService,
    private http: HttpClient,
    private transactionService: TransactionService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private utils: UtilityService
  ) {
    // this._baseURL = environment.imageBaseUrl;
    this._baseURL = BaseUrl.image;
  }

  log(data: any) {
    console.log('data', data);
  }

  ngOnInit() {
    const usrData = localStorage.getItem('currentUser');
    this.userdata = JSON.parse(crypto.AES.decrypt(usrData, environment.secretKey).toString(crypto.enc.Utf8)).data
    // this.userdata = this.helper.getHeader().get('x-access-token');
    this.paymentForm = this.formBuilder.group({
      amount: [
        '',
        [Validators.required, Validators.min(10), Validators.max(this.total)],
      ],
      type: ['', Validators.required],
      value: ['', Validators.required],

    });

    this.getTransactionHistory();
    this.paymentHistory();
    this.refetchUserData();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // you're at the bottom of the page
      this.getTransactionHistory();
    }
  }

  getTransactionHistory() {
    const limit = 10;
    const offset = this.transactionPageNo * limit;

    // Return if offset is greater then totalavailable items
    if(this.transactionPageNo > 1 && offset > this.totalTransactionHistoryItem ) {
      return;
    }

    this.transactionPageNo += 1;
    this.isTransactionLoading = true;
    this.transactionService.getTransactionHistory({ limit, offset }).subscribe((transaction: { data: Array<any>, message: string, status: boolean, totleItems: number }) => {
      this.transactionHistory = [...this.transactionHistory, ...transaction.data];
      this.totalTransactionHistoryItem = transaction?.totleItems;
      if(this.transactionPageNo === 1) {
        this.recentTransactionHistory = this.transactionHistory.slice(0,3);
      }
      this.isTransactionLoading = false;
    }, (error) => {
      console.log("error", error);
      this.isTransactionLoading = false;
    })
  }

  paymentHistory() {
    const headers = this.helper.getHeader();
    this.http
      .post(BaseUrl.frontend + `/paymentHistory`, null, {
        headers: headers,
      })
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.paymenthistory = res.data.data;
            this.total = res.data.total;
            this.temp = true;
          } else {
            this.paymenthistory = '';
            this.total = 0;
          }
        },
        (error) => {
          console.log('ERROR');
        }
      );
  }

  onItemChange(val) {
    val = val.target.value;
    if (val == 'PAYPAL_ID') {
      this.paypalCheck = true;
      this.phoneCheck = false;
      this.emailCheck = false;
    } else if (val == 'PHONE') {
      this.phoneCheck = true;
      this.paypalCheck = false;
      this.emailCheck = false;
    } else {
      this.emailCheck = true;
      this.phoneCheck = false;
      this.paypalCheck = false;
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.paymentForm.controls;
  }

  transform(rawNum) {
    rawNum = rawNum.charAt(0) != 0 ? '0' + rawNum : '' + rawNum;

    let newStr = '';
    let i = 0;

    for (; i < Math.floor(rawNum.length / 2) - 1; i++) {
      newStr = newStr + rawNum.substr(i * 2, 2) + '-';
    }

    return newStr + rawNum.substr(i * 2);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }
    if (this.total < this.paymentForm.value.amount) {
      // this.toaster.error('Withdrawal amount must be less then wallet amount.');
      return;
    }
    if (this.paymentForm.value.type == 'PHONE') {
      this.paymentForm.value.value = this.transform(
        this.paymentForm.value.value
      );
    }

    this.loading = true;
    // this.withdrawal.withdrawalpayment(this.paymentForm.value).subscribe(
    //   (res: any) => {
    //     if (res.status) {
    //       this.toaster.success('Payment withdrawn successfully.');
    //       this.loading = false;
    //       $('#withdrawal_payment').modal('hide');
    //       this.paymentHistory();
    //     } else {
    //       this.loading = false;
    //       this.toaster.error(
    //         'Receiver is invalid or does not match with type.'
    //       );
    //     }
    //   },
    //   (error) => {
    //     this.loading = false;
    //   }
    // );
  }

  refetchUserData() {
    this.authenticationService.checkUserStatus().subscribe((res: any) => {
      if (res?.data) {
        const data = localStorage.getItem("currentUser")
        const userData = JSON.parse(crypto.AES.decrypt(data, environment.secretKey).toString(crypto.enc.Utf8))
        userData.data.unlockedChapters = res?.data?.unlockedChapters;
        userData.data.libcoins = res?.data?.libcoins;
        userData.data.libcoins_spent = res?.data?.libcoins_spent;
        userData.data.libcoins_earned = res?.data?.libcoins_earned;
        userData.data.available_earning = res?.data?.available_earning;
        this.userdata = userData.data;
        const encryptedUser = crypto.AES.encrypt(JSON.stringify(userData), environment.secretKey).toString();
        localStorage.setItem("currentUser", encryptedUser);
      }
    });
  }

  open_libcoin_purchase_modal() {
    this.dialog.open(PaymentDialogComponent, {
      data: {
        libcoins: this.userdata.libcoins
      }
    }).afterClosed().subscribe((res: { status: string }) => {
      if (res?.status === 'completed') {
        this.utils.showConfetti('party-element-wallet-recharge');
        this.refetchUserData();
      }
    })
  }

}
