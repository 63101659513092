<div *ngIf="false">
  <div class="wrapper">
    <div id="content-page" class="content-page">
      <div class="conatiner__">
        <div class="col-lg-12">
          <div class="iq-card iq-card-block iq-card-stretch iq-card-height p-4">
            <img
              class="register__banner"
              src="assets/images/withdrawal.png"
              alt=""
            />
  
            <div class="register__container mt-5">
              <div></div>
              <div class="register__form__container text-center">
                <h3 class="register__signup">Withdrawal</h3>
                <form #register="ngForm">
                  <!-- Full Name -->
                  <div
                    class="
                      register__fullname
                      d-flex
                      justify-content-between
                      mb-3
                      text-left
                    "
                  >
                    <label>Email</label>
                    <input type="checkbox" class="email" />
                  </div>
                  <hr />
                  <div
                    class="
                      register__fullname
                      d-flex
                      justify-content-between
                      mb-3
                      text-left
                    "
                  >
                    <label>Paypal ID</label>
                    <input type="checkbox" class="email" />
                  </div>
                  <hr />
                  <div
                    class="
                      register__fullname
                      d-flex
                      justify-content-between
                      mb-3
                      text-left
                    "
                  >
                    <label>Email</label>
                    <input type="checkbox" class="email" />
                  </div>
                  <hr />
  
                  <div class="register__password text-left">
                    <mat-form-field
                      class="register__matformfield"
                      appearance="outline"
                    >
                      <mat-label>Enter PayPal ID <span class="required-field-icon">*</span></mat-label>
                      <input
                        class="chapter-title"
                        matInput
                        name="password"
                        type="password"
                        required
                        #confirmpass="ngModel"
                        ngModel
                      />
                    </mat-form-field>
                    <div
                      class="error-message"
                      *ngIf="confirmpass.touched && !confirmpass.valid"
                    >
                      <small class="text-danger">Enter PayPal ID</small>
                    </div>
                  </div>
  
                  <div class="register__password text-left">
                    <mat-form-field
                      class="register__matformfield"
                      appearance="outline"
                    >
                      <mat-label>Enter Amount <span class="required-field-icon">*</span></mat-label>
                      <input
                        class="chapter-title"
                        matInput
                        name="password"
                        type="password"
                        required
                        #confirmpass="ngModel"
                        ngModel
                      />
                    </mat-form-field>
                    <div
                      class="error-message"
                      *ngIf="confirmpass.touched && !confirmpass.valid"
                    >
                      <small class="text-danger">Enter Amount</small>
                    </div>
                  </div>
  
                  <input
                    class="w-100 font-italic register__submit-button"
                    type="submit"
                    value="Withdrawal"
                  />
                  <p class="text-start withdrawal__description">
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                    amet sint. Velit officia consequat duis enim velit mollit.
                    Exercitation veniam consequat sunt nostrud amet.Amet minim
                    mollit non deserunt ullamco est sit aliqt sint. Velit officia
                    consequat duis enim velit mollit. Exercitation veniam
                    consequat sunt nostrud amet.Amet minim mollit non deserunt
                    ullamco est sit aliqua dolor do amet sint. Velit officia
                    consequat duis enim velit mollit. Exercitation veniam
                    consequat sunt nostrud amet.
                  </p>
                </form>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <app-footer></app-footer>
</div>


<div class="register__form__container text-center">
  <h3 class="register__signup">Withdrawal</h3>
  <form #register="ngForm">
    <!-- Full Name -->
    <!-- <div
      class="
        register__fullname
        d-flex
        justify-content-between
        mb-3
        text-left
      "
    >
      <label>Email</label>
      <input type="checkbox" class="email" />
    </div>
    <hr />
    <div
      class="
        register__fullname
        d-flex
        justify-content-between
        mb-3
        text-left
      "
    >
      <label>Paypal ID</label>
      <input type="checkbox" class="email" />
    </div>
    <hr />
    <div
      class="
        register__fullname
        d-flex
        justify-content-between
        mb-3
        text-left
      "
    >
      <label>Email</label>
      <input type="checkbox" class="email" />
    </div>
    <hr /> -->

    <div class="register__password text-left">
      <mat-form-field
        class="register__matformfield w-100"
        appearance="outline"
      >
        <mat-label>Enter PayPal ID <span class="required-field-icon">*</span></mat-label>
        <input
          class="chapter-title"
          matInput
          name="password"
          type="password"
          required
          #confirmpass="ngModel"
          ngModel
        />
      </mat-form-field>
      <div
        class="error-message"
        *ngIf="confirmpass.touched && !confirmpass.valid"
      >
        <small class="text-danger">Enter PayPal ID</small>
      </div>
    </div>

    <div class="register__password text-left">
      <mat-form-field
        class="register__matformfield w-100"
        appearance="outline"
      >
        <mat-label>Enter Amount <span class="required-field-icon">*</span></mat-label>
        <input
          class="chapter-title"
          matInput
          name="password"
          type="password"
          required
          #confirmpass="ngModel"
          ngModel
        />
      </mat-form-field>
      <div
        class="error-message"
        *ngIf="confirmpass.touched && !confirmpass.valid"
      >
        <small class="text-danger">Enter Amount</small>
      </div>
    </div>

    <!-- <input
      class="w-100 font-italic register__submit-button"
      type="submit"
      value="Withdrawal"
    /> -->
    <div class="withdrawal_button w-100">
      <button class="border btn w-100" color="primary" mat-button type="submit">Withdraw</button>
    </div>
    <p class="text-start withdrawal__description">
      Amet minim mollit non deserunt ullamco est sit aliqua dolor do
      amet sint. Velit officia consequat duis enim velit mollit.
      Exercitation veniam consequat sunt nostrud amet.Amet minim
      mollit non deserunt ullamco est sit aliqt sint. Velit officia
      consequat duis enim velit mollit. Exercitation veniam
      consequat sunt nostrud amet.Amet minim mollit non deserunt
      ullamco est sit aliqua dolor do amet sint. Velit officia
      consequat duis enim velit mollit. Exercitation veniam
      consequat sunt nostrud amet.
    </p>
  </form>
</div>