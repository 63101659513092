import { Component } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { HelperService } from './helper.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'Librums-Frontend';
  previousUrl: any;
  currentUrl: any;
  faCoffee = faCoffee;
  constructor(private router: Router, private helper: HelperService) {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
          pairwise()
      ).subscribe(async (e: any) => {
          this.previousUrl = await e[0].urlAfterRedirects // previous url
      });
      this.helper.refetchUserData();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }  
}
