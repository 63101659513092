<div class="page">
    <div *ngIf="showLoader" class="loader">
      <img loading="lazy" src="/assets/img/loader.svg" />
    </div>
    <div class="page-main">
      <!-- sidebar area -->
      <div class="app-content my-3 my-md-5 ">
        <div class="page-container">
          <div class="page-header ">
            <!-- <h4 class="page-title ">View Manage Content Type</h4> -->
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Content Types
              </li>
            </ol>
          </div>
          <div class="row ">
            <div class="col-md-12 col-lg-12 ">
              <div class="card ">
                <div class="card-header justify-content-between">
                  <div class="card-title ">Content Types</div>
                  <a
                    [routerLink]="['/add-manage-content-type']"
                    class="add_content pl-4 pr-4"
                    ><i class="fa fa-plus-circle" aria-hidden="true"></i></a
                  >
                </div>
                <div class="card-body ">
                  <div class=" ">
                    <div
                      id="example_wrapper "
                      class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view"
                    >
                      <div class="row ">
                        <div class="col-sm-12 ">
                          <table
                            
                            class="table row-border hover "
                           
                            *ngIf="temp"
                            role="grid "
                            aria-describedby="example_info "
                          >
                            <thead>
                              <tr role="row ">
                                <th
                                  class="wd-15p sorting_asc "
                                  tabindex="0 "
                                  aria-controls="example "
                                  rowspan="1 "
                                  colspan="1 "
                                  aria-sort="ascending "
                                  aria-label="First name: activate to sort column descending
                                              "
                                  style="width: 30px; "
                                >
                                  #
                                </th>
                                <th
                                  class="wd-15p sorting "
                                  tabindex="0 "
                                  aria-controls="example "
                                  rowspan="1 "
                                  colspan="1 "
                                  aria-label="Last name: activate to sort column ascending "
                                  style="width: 570px; "
                                >
                                  Title
                                </th>
                                <th
                                  class="wd-15p unsort "
                                  tabindex="0 "
                                  aria-controls="example "
                                  rowspan="1 "
                                  colspan="1 "
                                  aria-label="Last name: activate to sort column ascending "
                                  style="width: 70px; "
                                >
                                  Is Disabled
                                </th>
                           
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                role="row "
                                class="odd "
                                *ngFor="
                                  let contentdata of getContent;
                                  let i = index
                                "
                              >
                                <td class="sorting_1 ">{{ i + 1 }}</td>
                                <td><a    [routerLink]="[
                                  '/edit-manage-content-type',
                                  contentdata._id
                                ]"
                                >{{ contentdata.name }}</a></td>
                                <td><div   *ngIf="contentdata.isEnabled === true" class="onoffswitch">
                                  <input checked  (change)="
                                  onClick({contenttype_id: contentdata._id, status:'false'})" checked  type="checkbox" name="myonoffswitch_{{i}}"
                                      class="onoffswitch-checkbox"
                                      id="myonoffswitch_{{i}}" >
                                  <label class="onoffswitch-label" 
                                      for="myonoffswitch_{{i}}">
                                      <span class="onoffswitch-inner"></span>
                                      <span class="onoffswitch-switch"></span>
                                  </label>
                              </div>
                              <div   *ngIf="contentdata.isEnabled === false" class="onoffswitch">
                                <input   (change)="
                                onClick({contenttype_id: contentdata._id, status:'true'})"   type="checkbox" name="myonoffswitch_{{i}}"
                                    class="onoffswitch-checkbox"
                                    id="myonoffswitch_{{i}}" >
                                <label class="onoffswitch-label" 
                                    for="myonoffswitch_{{i}}">
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                            </td>
  
                                <!-- <td class="text-center">
                                  <div class="flex_fa_icons">
                                    <a
                                      [routerLink]="[
                                        '/edit-manage-content-type',
                                        contentdata._id
                                      ]"
                                      ><img
                                        src="assets/images/pencil.png "
                                        title="Edit"
                                    /></a>
                                  </div>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- table-wrapper -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  