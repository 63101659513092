import { Component, OnInit } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { ViewChapterContentService } from '../view-chapter-content/view-chapter-content.service';
import { BaseUrl } from 'src/app/base-url';

@Component({
  selector: 'app-published-book-content',
  templateUrl: './published-book-content.component.html',
  styleUrls: ['./published-book-content.component.sass']
})
export class PublishedBookContentComponent implements OnInit {

  getChapter: any;
  data: any;
  chapter_id: string;
  baseimage: any;
  safeUrl: any;
  showLoader = false;

  constructor(
    private chapterservice: ViewChapterContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer  
  ) {
    this.baseimage = BaseUrl.image;
    this.sanitizer = sanitizer;
  }

  ngOnInit() {
    this.showLoader = true;
    this.chapter_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadChapter();
  }

  getTrustedUrl(url:any){ 
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log(this.safeUrl);
   }

  loadChapter() {
    this.chapterservice.getChapterDetail(this.chapter_id).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        this.getChapter = res.data;
        this.getTrustedUrl('https://www.youtube.com/embed/'+this.getChapter.video);
        this.showLoader = false;
      },
      () => {
        console.log("ERROR");
      }
    );
  }
}

