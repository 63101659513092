<div class="modal-body">
  <div class="share_icons_flex">
    <p>
      A great book should leave you with many experiences, and slightly
      exhausted at the end. You live several lives while reading. So, let's
      share your experience with your family or friends.
    </p>
    <div *ngIf="chapterData">
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=https://testing.librums.com&quote=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book. 
              https://testing.librums.com/shareBook.html?book_id={{
          chapterData.currentChapter.book_id
        }}&genre_id={{ chapterData.genre }}&=https://testing.librums.com/"
        target="_blank"
        title="facebook"
        rel="noopener"
        (click)="shareBook(chapterData.currentChapter.book_id)"
      >
        <i class="fa fa-facebook-official" aria-hidden="true"></i>
      </a>
      <a
        href="https://twitter.com/share?url=https://testing.librums.com/shareBook.html?book_id={{
          chapterData.currentChapter.book_id
        }}&genre_id={{
          chapterData.genre
        }}&text=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book."
        onclick="window.open(this.href);return false;"
        (click)="shareBook(chapterData.currentChapter.book_id)"
        target="_blank"
      >
        <i class="fa fa-twitter" aria-hidden="true"></i>
      </a>
      <a
        href="https://www.linkedin.com/shareArticle?mini=true&url=https://testing.librums.com&title=Librums&summary=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book."
        (click)="shareBook(chapterData.currentChapter.book_id)"
        target="_blank"
      >
        <i class="fa fa-linkedin" aria-hidden="true"></i>
      </a>
      <a
        href="http://pinterest.com/pin/create/button/?url=https://testing.librums.com/shareBook.html?book_id={{
          chapterData.currentChapter.book_id
        }}&genre_id={{
          chapterData.genre
        }}&media=&description=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book."
        (click)="shareBook(chapterData.currentChapter.book_id)"
        target="_blank"
      >
        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
      </a>
      <a
        href="mailto:?Subject=Librums:: Share book with your family&amp;Body=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book. https://testing.librums.com/shareBook.html?book_id={{
          chapterData.currentChapter.book_id
        }}&genre_id={{ chapterData.genre }}"
        onclick="window.open(this.href);return false;"
        (click)="shareBook(chapterData.currentChapter.book_id)"
        target="_blank"
      >
        <i class="fa fa-envelope" aria-hidden="true"></i>
      </a>
      <a
        href="https://web.whatsapp.com/send?text=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book. https://testing.librums.com/shareBook.html?book_id={{
          chapterData.currentChapter.book_id
        }}&genre_id={{ chapterData.genre }}"
        data-action="share/whatsapp/share"
        onclick="window.open(this.href);return false;"
        (click)="shareBook(chapterData.currentChapter.book_id)"
        target="_blank"
        ><i class="fa fa-whatsapp" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</div>
