<div class="imageAlign">
  <image-cropper
    *ngIf="crop"
    [imageURL]="data.url"
    [imageChangedEvent]="imageChangedEvent"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    [aspectRatio]="aspectRatio"
    [roundCropper]="roundCropper"
  ></image-cropper>
</div>

<img loading="lazy" *ngIf="!crop" src="{{ data.url }}" alt="" />

<div class="d-flex justify-content-center">
  <button
    mat-dialog-close
    class="btn btn-sm view-more global-button load-more next__cancel"
  >
    Cancel
  </button>
  <button
    *ngIf="crop"
    class="btn btn-sm view-more global-button load-more next__cancel"
    (click)="submitCroppedImage()"
  >
    Done
  </button>
</div>
