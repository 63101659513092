import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { BaseUrl } from '../base-url';
import { BookDetailService } from '../book-detail/book-detail.service';
import { HelperService } from '../helper.service';
import { ReviewHandlerService } from './review-handler.service';
import { ReviewService } from './review.service';
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.sass'],
})
export class ReviewComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private reviewService: ReviewService,
    private reviewServiceHandler: ReviewHandlerService,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private helper: HelperService,
    private bookDetailService: BookDetailService
  ) {
    this.baseimage = BaseUrl.image;
    this.userData = this.helper.getUserData().data;
  }

  public expandPanel = false;
  // public review = new FormGroup({
  //   reviewText: new FormControl(''),
  // });

  public userData: any;

  public review = this.fb.group({
    reviewText: ['', Validators.required],
  });

  public reviewEdit = this.fb.group({
    reviewTextEdit: ['', Validators.required],
  });
  book_id: string;
  baseimage: string;
  showLoader: boolean;

  public bookRating: any;
  public reviewers: Array<any>;
  public editReview: boolean = false;

  public noData: boolean;

  public bookIdSubscription: Subscription;
  public getReviewSubscription: Subscription | null = null;
  public getReviewObservable: Observable<any>;
  public;

  ngOnDestroy() {
    this.bookIdSubscription.unsubscribe();
    if (this.getReviewSubscription != null)
      if (!this.getReviewSubscription.closed) {
        this.getReviewSubscription.unsubscribe();
      }
  }
  ngOnInit() {
    this.showLoader = true;

    this.bookIdSubscription = this.bookDetailService.bookReviewIdData.subscribe(
      (book_id) => {
        if (book_id != '') {
          this.book_id = book_id;
          this.expandPanel = false;
        } else {
          this.book_id = this.activatedRoute.snapshot.paramMap.get('id');
        }
        this.getReviewObservable = this.reviewServiceHandler.getReviewByBookId(
          this.book_id
        );
        this.getReviewSubscription = null;
        this.showLoader = false;
      }
    );
  }

  getBookReview = (): void => {
    this.expandPanel = true;
    if (!this.getReviewSubscription || !this.getReviewSubscription.closed) {
      this.showLoader = true;
      this.getReviewSubscription = this.getReviewObservable.subscribe(
        (bookReview: any) => {

          if (bookReview.status == 200) {
            this.bookRating = bookReview.bookReview;
            this.reviewers = bookReview.bookReview.review;
            this.noData = false;
            this.showLoader = false;
          } else if (bookReview.status == 404) {
            this.showLoader = false;
            this.noData = true;
          }
        }
      );
    } else {
      this.getReviewSubscription.unsubscribe();
    }
  };
  selectedValue: number = 0;

  editBookReview = (review_id: string) => {
    for (const iterator of this.reviewers) {
      if (iterator['_id'] == review_id) {
        iterator['edit'] = !iterator['edit'];
      }
    }
  };

  countStar(star) {
    this.selectedValue = star;
  }
  addClass(star) {
    let ab = '';
    for (let i = 0; i < star; i++) {
      ab = 'starId' + i;
      document.getElementById(ab).classList.add('selected');
    }
  }
  removeClass(star) {
    let ab = '';
    for (let i = star - 1; i >= this.selectedValue; i--) {
      ab = 'starId' + i;
      document.getElementById(ab).classList.remove('selected');
    }
  }

  public writeReview: boolean = false;

  writeAReview() {
    this.writeReview = !this.writeReview;
  }

  onSubmit(form: FormGroup) {
    if (form.valid && !(this.selectedValue < 1 || this.selectedValue > 5)) {
      this.showLoader = true;
      this.reviewService
        .reviewBook(
          this.book_id,
          this.selectedValue,
          this.review.value.reviewText
        )
        .subscribe((res: any) => {
          if (res.status) {
            this.review.reset();
            this.writeReview = !this.writeReview;
            this.selectedValue = 0;
            if (res.status == 200) {
              setTimeout(() => {
                this.showLoader = false;
                this.ngOnInit();
              }, 0);
            }
            if (res.status == 422) {
              this.snackbar.open(res.message, 'Ok', { duration: 2000 });
            }
            setTimeout(() => {
              this.getBookReview();
            }, 0);
          }
        });
    }
  }

  updateReview(form: FormGroup) {
    if (form.valid && this.selectedValue != 0) {
      this.showLoader = true;
      this.reviewService
        .updateReview(
          this.book_id,
          this.selectedValue,
          form.value.reviewTextEdit
        )
        .subscribe((result: any) => {
          if (result.status == 200) {
            this.snackbar.open(result.message, 'Ok', { duration: 2000 });
            setTimeout(() => {
              this.showLoader = false;
              this.editReview = false;
              this.ngOnInit();
            }, 0);
          } else {
            this.snackbar.open(result.message, 'Ok', { duration: 2000 });
          }
        });
    }
  }

  public DeleteReview = (prevStar: number) => {
    this.showLoader = true;
    this.reviewService
      .deletReview(this.book_id, prevStar)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.snackbar.open(result.message, 'Ok', { duration: 2000 });
          setTimeout(() => {
            this.showLoader = false;
            this.ngOnInit();
          }, 0);
        }
      });
  };
}
