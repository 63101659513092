<div class="wrapper">
    <div id="content-page" class="content-page mt-3">
        <div class="conatiner__">
            <div class="club container br-8 d-flex flex-column align-items-center book-club-container" style="max-width: 800px">
                <div class="d-flex justify-content-between w-100">
                    <div class="align-self-center">
                        <h1 class="align-self-center mb-0 fw-600 heading">Book Clubs</h1>
                    </div>
                    <div class="align-self-center">
                        <button class="btn btn-cyan p-3 align-self-center" (click)="open_create_room_dialog()"> Create book club </button>
                    </div>
                </div>

                <!-- Tabs -->
                <mat-tab-group
                  class="tab-menu mt-3 w-100"
                  dynamicHeight
                  dynamicLength
                  [(selectedIndex)]="selectedIndex"
                  (selectedIndexChange)="onMatTabChanged($event)"
                  animationDuration="0ms"
                >
                  <mat-tab label="My Book Clubs">
                    <ng-container *ngTemplateOutlet="book_club_list"></ng-container>
                  </mat-tab>
                  <mat-tab label="Invited">
                    <ng-container *ngTemplateOutlet="book_club_list"></ng-container>
                  </mat-tab>
                  <mat-tab label="Pending Invitation">
                    <ng-container *ngTemplateOutlet="book_club_list"></ng-container>
                  </mat-tab>
                </mat-tab-group>

                <div *ngIf="!isLoading && rooms?.length" align="right" class="mt-4">
                    <pagination-controls id="published-books-pagination" (pageChange)="getRooms($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #book_club_list>
    <div class="mt-5 p-2">
        <ngx-skeleton-loader *ngIf="isLoading" [count]="10" [theme]="{height: '55px', 'border-radius': '12px', 'z-index': 0}"></ngx-skeleton-loader>
        <ng-container *ngIf="!isLoading && rooms?.length === 0">
            <div align="center">
                <img src="assets/images/undraw_not_found.svg" alt="" style="height: 240px; opacity: 0.9;">
                <div class="text-secondary pt-5">No Results found</div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <ng-container *ngFor="let item of rooms | paginate: { id: 'published-books-pagination', itemsPerPage: 10, currentPage: page, totalItems: total_items}">
                <div class="my-3 py-2 club-fonts">
                    <mat-accordion>
                        <mat-expansion-panel class="py-2" [class.owner]="userData?._id === item?.host_id">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="fw-600 club-title">
                                {{ item.room_name }}
                            </mat-panel-title>
                            <!-- <mat-panel-description class="d-flex justify-content-end">
                                {{ item?.created_at | date: 'short'}}
                            </mat-panel-description> -->
                          </mat-expansion-panel-header>
                          <div class="club-description">

                            <div>
                                <h3 class="p-0 m-0">
                                    Book
                                </h3>
                                <div class="mt-2 d-flex" *ngIf="item?.book_info">
                                    <div>
                                        <img
                                            style="height: 94px"
                                            class="book__image shadow br-8"
                                            [src]="imageBaseUrl + item?.book_info?.cover_image"
                                            onerror="this.src = 'assets/images/rectangle1.svg'"
                                            />
                                    </div>
                                    <div class="p-3">
                                        <div class="fs-13 fw-600">{{ item?.book_info?.title }}</div>
                                        <div class="fs-13 fw-600 text-secondary">{{ item?.book_info?.description | slice: 0:50 }} <span *ngIf="item?.book_info?.description && item?.book_info?.description?.length > 50">...</span> </div>
                                    </div>
                                </div>
                                <div *ngIf="!item?.book_info">
                                    No book record found
                                </div>
                            </div>

                            <div class="mt-5" *ngIf="item?.invited_user_emails && item?.invited_user_emails?.length && selectedIndex === 0">
                                <b>Members</b>
                                <div class="mt-2">
                                    <div class="d-flex mb-2" *ngFor="let user of item.invited_user_emails">

                                        <div class="badge" [ngClass]="{
                                                'badge-primary': user?.status === 'invited',
                                                'badge-warning' : user?.status === 'active',
                                                'badge-danger' : user?.status === 'declined'
                                            }"
                                        >
                                            {{ user?.status | titlecase }}
                                        </div>
                                        <div class="mx-2 mr-auto">
                                            {{ user?.email }}
                                        </div>
                                        <!-- <div *ngIf="item?.host_id === userData?._id"><mat-icon class="text-secondary cursor-pointer remove-icon">cancel</mat-icon></div> -->

                                    </div>
                                </div>
                            </div>
                          </div>
                          <!-- <mat-action-row> -->
                          <div class="d-flex justify-content-end edit-remove-book-club" *ngIf="selectedIndex != 2 && item?.host_id === userData?._id">
                            <ng-container >
                                <ng-container>
                                    <button class="btn btn-outline-secondary p-3" (click)="open_create_room_dialog(item)" >Edit</button>
                                    <button class="btn btn-outline-danger p-3 ml-2 mr-4 remove-button" (click)="removeBookClub(item)" >Remove</button>
                                </ng-container>
                            </ng-container>
                          </div>
                          <div class="d-flex justify-content-end" *ngIf="selectedIndex != 2 && item?.host_id !== userData?._id">
                            <ng-container >
                                <ng-container>
                                    <button class="btn btn-outline-danger p-3 mx-4" (click)="leaveBookClub(item)" >Leave</button>
                                </ng-container>
                            </ng-container>
                          </div>
                          <div class="d-flex justify-content-end accept-ecline-book-club" *ngIf="selectedIndex == 2">
                            <ng-container>
                                <button class="btn accept-button p-3" (click)="room_invite_action( item?._id, 'active')" >Accept</button>
                                <button class="btn btn-outline-danger p-3 ml-2 mr-4 decline-button" (click)="room_invite_action( item?._id, 'declined')" >Decline</button>
                            </ng-container>
                          </div>
                          <!-- </mat-action-row> -->
                        </mat-expansion-panel>
                      </mat-accordion>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
