<ng-container *ngIf="type === 'spinner'">
  <div class="loader d-flex justify-content-center align-items-center" *ngIf="show === 'normal'">
    <!-- <mat-spinner></mat-spinner> -->
    <div class="spinner-border color-cyan-secondary" style="width: 6rem; height: 6rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div class="spinner-border spinner-border-sm" role="status" *ngIf="show === 'small'">
    <span class="sr-only">Loading...</span>
  </div>
  
</ng-container>

<ng-container *ngIf="type === 'placeholder'">
  <div class="col m-0 p-0" *ngIf="placeholderType === 'list'">
      <ngx-skeleton-loader [count]="placeholderLength" appearance="line" [theme]="{
            'background-color': backgroundColor,
              width: width,
              'border-radius': borderRadius,
              height: height,
              margin: '4px 0px'
          }" [animation]="animationType"></ngx-skeleton-loader>
  </div>
  <div *ngIf="placeholderType === 'grid'">
          <ngx-skeleton-loader [count]="placeholderLength" appearance="line" [theme]="{
              width: width,
              height: height,
              'border-radius': borderRadius,
              margin: '6px'
          }" [animation]="animationType"></ngx-skeleton-loader>
  </div>
</ng-container>