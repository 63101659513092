<div class="purchase-container pt-3 px-4">
    <div class="d-flex justify-content-end mt-1" *ngIf="showCloseButton">
        <mat-icon class="cursor-pointer" (click)="closePurchaseOption.emit()">cancel</mat-icon>
    </div>

    <div class="container text-left pb-3">
        <!-- Wallet amount -->
        <div>
            <b>Wallet Amount: {{ userLibcoins }} Libcoins</b>
        </div>

        <!-- Libcoin catalog -->
        <div class="my-3">
            <div class="my-2">Please choose one of the below options</div>

            <div class="catalog_item w-100 p-3 my-3 br-8 cursor-pointer" *ngFor="let item of libcoins_catalog"
            (click)="initConfig(item)">
                <div class="d-flex justify-content-between">
                    <div class="align-self-center ml-5"> {{ item.libcoins }} Libcoins </div>
                    <div class="amount p-2 br-8"> {{ item.amount | currency: 'USD' }} </div>
                </div>
                <div class="mt-3" *ngIf="selected_libcoins_price?.id === item.id">
                    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                </div>
            </div>
        </div>
    </div>
</div>
