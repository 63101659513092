import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-withdraw-money',
  templateUrl: './withdraw-money.component.html',
  styleUrls: ['./withdraw-money.component.sass'],
})
export class WithdrawMoneyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  myModel = false;
}
