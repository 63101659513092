<div class="container">
    <div class="row">
        <div class="" *ngIf="result">
            <div class="thumb_img">
                <img loading="lazy" src="{{result.thumbnailUrl}}">
            </div>
            <div class="youtube_title">
                <h4>{{result.title}}</h4>
                <a href="javascript:void(0)" (click)="handleClick(result.id)"  data-id="{{result.id}}">ADD</a>
            </div>
        </div>
    </div>
 </div>   

