import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private _baseURL: string;
  constructor(private helper: HelperService, private http: HttpClient) {
    this._baseURL = environment.baseUrl;
  }

  getreportissue() {
    const headers = this.helper.getHeader();
    return this.http.post(this._baseURL + `/getIssueType`, null, {
      headers: headers,
    });
  }
  feedback(data: any) {
    const headers = this.helper.getHeader();
    return this.http.post(environment.baseUrl + `/giveFeedback`, data, {
      headers: headers,
    });
  }
}
