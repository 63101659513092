import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AddSliderService {

  usersdata: any;
  usersimage: any;
  updateuser: any;
  baseimage: any;
  base: any;
  constructor(private http: HttpClient) {
    this.baseimage = BaseUrl.imageApi;
    this.base = BaseUrl.admin;
  }
  user_image(image) {
    this.usersimage = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersimage, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": token
    });
    return this.http.post(this.baseimage + `slider`, image, {
      headers
    });
  }
  addSlider(data) {
    this.usersimage = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersimage, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/addSlider`, data, {
      headers
    });
  }
}
