<div id="content-page" class="content-page">
   <div class="container-fluid">
      <app-loader *ngIf="loader"></app-loader>
      <div *ngIf="!loader" class="row d-flex justify-content-center">
         <div class="col-lg-10 p-0">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height m-0" style="border-radius:10px">
               <div class="iq-card-body p-0">
                  <div class="p-0">
                     <form class="m-0" #bookForm="ngForm" (ngSubmit)="onSubmit(bookForm)">
                        <div class="">
                           <div class="book-wraper mt-3">
                              <div class="row m-0" style="padding-bottom: 50px">
                                  <div class="col-sm-4 col-xl-3 px-3 add-cover-image-container">
                                    <div class="d-flex justify-content-center block-cover-image"
                                      style="border-radius: 10px">
                                      <app-image-editor-canvas *ngIf="canvas" (imageCanvas)="getImageCanvas($event)"
                                        [url]="url" [editBook]="editBook"></app-image-editor-canvas>
                                      <app-image-final-canvas [finalCanvasUrl]="finalCanvas" *ngIf="finalCanvas">
                                      </app-image-final-canvas>
                                      <img loading="lazy" routerLink="/add-book-cover" class="plus-for-add-cover" src="../../assets/images//plusPhoto.svg" alt="">
                                    </div>
                                    <div class="d-flex justify-content-center mt-2">
                                      <button type="button" class="add-cover-image-button" style="height: 59px; margin-top: 25px" routerLink="/add-book-cover" alt="">
                                        Add Book Cover Image
                                      </button>
                                    </div>
                                  </div>
                                 <div
                                    class="col-sm-7 col-xl-8 p-0 d-flex flex-column input-root-container {{!book_id ? '' : 'justify-content-between'}}  h-100">
                                    <div
                                       class="d-flex flex-column input-container align-items-center align-items-sm-start w-100">
                                       <input name="title" [(ngModel)]="loadEditBook.title" class="write-input bool-name" type="text"
                                          placeholder="Book Name">
                                       <textarea name="tagline" [(ngModel)]="loadEditBook.tagline" class="write-input book-sentence mt-2" type="text"
                                          placeholder="One sentence from book" #tagline="ngModel" style="min-height: 40px; margin-top: 30px !important; overflow: hidden;" [maxLength]="150" cdkTextareaAutosize></textarea>
                                          <div class="d-flex justify-content-end mr-4 count-of-charactrs" style="font-style: italic;"> {{ tagline?.value?.length || 0 }}/{{150}} </div>
                                       <textarea name="description" #description="ngModel" [(ngModel)]="loadEditBook.description" class="write-input-description mt-2"
                                          placeholder="Description of book" [maxLength]="500" style="min-height: 40px; margin-top: 10px; overflow: hidden;" cdkTextareaAutosize></textarea>
                                          <div class="d-flex justify-content-end mr-4" style="width: 95% !important; font-style: italic;"> {{ description?.value?.length || 0 }}/{{500}} </div>
                                       <div class="d-sm-flex justify-content-between mt-4" style="width: 95%;">
                                          <mat-form-field class="mr-md-5 select-book-type" appearance="fill">
                                             <mat-label>Select content type</mat-label>
                                             <mat-select name="content_type" [(ngModel)]="loadEditBook.content_type">
                                                <mat-option value="5ea29e78fa235a61a420b9f4">Book</mat-option>
                                                <mat-option value="{{ sketchBookId }}">Sketch Book</mat-option>
                                                <mat-option value="{{ videoBookId }}">Video Book</mat-option>
                                             </mat-select>
                                          </mat-form-field>
                                          <mat-form-field class="select-book-type" appearance="fill">
                                             <mat-label>Select Copyright</mat-label>
                                             <mat-select name="copyright" [(ngModel)]="loadEditBook.copyright">
                                                <mat-option value="5ea1388219e995193b5e6b1c">Not Specified2</mat-option>
                                                <mat-option value="5eade2a737fe886eae35c7e8">All Rights Reserved
                                                </mat-option>
                                                <mat-option value="5eade2b037fe886eae35c7e9">Public Domain</mat-option>
                                                <mat-option value="5eade2bb37fe886eae35c7ea">(CC) Attrib. NonCommercial
                                                </mat-option>
                                                <mat-option value="5eade2c437fe886eae35c7eb">Creative Commons (CC)
                                                   Attribution</mat-option>
                                                <mat-option value="5eade2d037fe886eae35c7ec">(CC) Attrib. NonComm.
                                                   NoDerivs</mat-option>
                                                <mat-option value="5eade2ef37fe886eae35c7ed">(CC) Attrib. NonComm.
                                                   ShareAlike</mat-option>
                                                <mat-option value="5eade30437fe886eae35c7ee">(CC) Attribution-ShareAlike
                                                </mat-option>
                                                <mat-option value="5eade30c37fe886eae35c7ef">(CC) (CC)
                                                   Attribution-NoDerivs</mat-option>
                                             </mat-select>
                                          </mat-form-field>
                                       </div>
                                       <div class="mb-3 select-ganre-block select-book-type">
                                          <form [formGroup]="myForm" class="w-100 m-0">
                                             <div class="multiple-selection w-100">
                                               <ng-multiselect-dropdown
                                                   required
                                                   name="city"
                                                   formControlName="city"
                                                   [placeholder]="'Select Genre'"
                                                   [settings]="dropdownSettings"
                                                   [data]="genreList"
                                                   [(ngModel)]="genreVal"
                                                   (onSelect)="onItemSelect($event)"
                                                   (onSelectAll)="onSelectAll($event)"
                                                   (onDeSelect)="onItemDeSelect($event)"
                                                   >
                                               </ng-multiselect-dropdown>
                                             </div>
                                           </form>
                                       </div>
                                       <div class="w-100 mb-3 mt-4 d-flex justify-content-center justify-content-sm-start">
                                          <mat-form-field class="example-chip-list">
                                             <mat-chip-list #chipList aria-label="tag selection">
                                                <mat-chip class="mat-chip-tag" *ngFor="let tag of tags"
                                                   [selectable]="selectable" [removable]="removable"
                                                   (removed)="remove(tag)">
                                                   {{ tag.display }}
                                                   <span matSuffix *ngIf="removable" matChipRemove style="color: white"
                                                      class="material-icons-outlined">
                                                      cancel
                                                   </span>
                                                </mat-chip>
                                                <input name="tags" ngModel placeholder="Hit enter to create new tags"
                                                   [matChipInputFor]="chipList"
                                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                   [matChipInputAddOnBlur]="addOnBlur"
                                                   (matChipInputTokenEnd)="add($event)"
                                                   [class.border-danger]="tags.length == 0" />
                                             </mat-chip-list>
                                          </mat-form-field>
                                       </div>
                                    </div>
                                    <button class="write-button-save mb-5 mb-sm-0" type="submit"
                                       style="border-radius: 10px;">
                                       Update
                                    </button>
                                 </div>
                                 <div class="col-sm-1 p-0 d-flex justify-content-center mature-access-block" style="height: 50px;">
                                    <input type="text" [value]="mature" class="d-none">
                                    <button class="p-0 button-mature-access" type="button" (click)="setMature()">
                                       <img loading="lazy" src="../../assets/images/18years.svg"
                                          [class]="mature ? 'years-age-18' : '' " alt="">
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>
