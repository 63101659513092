<!-- <div
  class="h-100 swiper-container p-5 sketch_book_container"
  *ngIf="bookImages"
>
  <div class="swiper-wrapper h-100">
    <div
      class="swiper-slide h-100"
      *ngFor="let book of bookImages.sketch.sketchs"
    >
      <div
        attr.data-background="{{ baseimage }}{{ book }}"
        style="height: 100%; background-position: center"
        class="swiper-lazy"
      >
        <div class="swiper-lazy-preloader"></div>
      </div>
    </div>
  </div>
</div> -->
<div *ngIf="data.type == 'sketchBook'; then thenBlock; else elseBlock"></div>

<ng-template #thenBlock>
  <div *ngIf="bookPages.length > 0">
    <flipbook [model]="bookData"></flipbook>
    <!-- <flipbook [model]="book"></flipbook> -->
  </div>
</ng-template>

<ng-template #elseBlock>
  <div class="swiper-container video__book__container">
    <div class="swiper-wrapper videobook-container">
      <div *ngIf="videoBooksData1 !== undefined && videoBooksData1.length !== 0; then thenBlock; else elseBlock"></div>
        <ng-template #thenBlock>
          <div *ngFor="let video of videoBooksData1; let i = index" style="padding: 2%;">
            <iframe [src]="video" id="inneriframe" scrolling="no" frameborder="0" width="500" height="460"></iframe>
            <!-- Your browser does not support the video tag. -->
          </div>
        </ng-template>

        <ng-template #elseBlock>
        <div>
          <img
              src="assets/images/no-video.png"
              style="width: 735px; height: 498px"
            />
        </div>
        </ng-template>
    </div>
  </div>
</ng-template>
<br>
<button mat-dialog-close class="close-btn">Close</button>

<!-- <video #target class="video-js" controls muted playsinline preload="none">
  <source
  src="http://localhost:2001/uploads/books/1632976232459_file_example_MP4_480_1_5MG.mp4"
  type="video/mp4"
  />
</video> -->

<!-- <app-vjs-player
  [options]="{
    autoplay: true,
    controls: true,
    sources: [
    {
      src: 'http://localhost:2001/uploads/books/1632976232459_file_example_MP4_480_1_5MG.mp4',
      type: 'video/mp4'
    }
    ]
  }"
></app-vjs-player> -->
