import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ViewchapterService {

  base: any;
  baseapi: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
    this.baseapi = BaseUrl.imageApi;
  }
  contentdata: any;
  usersimage: any;


  getChapterDetail(id) { 
    this.contentdata = localStorage.getItem("currentUser")
    let token = JSON.parse( crypto.AES.decrypt(this.contentdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getChapterDetail`,
      { chapter_id: id },
      {
        headers
      }
    );
  }
}
