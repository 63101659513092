import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ɵSWITCH_VIEW_CONTAINER_REF_FACTORY__POST_R3__,
} from '@angular/core';
import { Swiper } from 'swiper';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { GlobalService } from '../global.service';
import { BookDetailService } from '../book-detail/book-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrl } from '../base-url';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReadChapterService } from '../read-chapter/read-chapter.service';
import { ReviewHandlerService } from '../product-quick-view/product-quick-view-handler.service';
import { NavbarService } from '../navbar/navbar.service';
interface SlideVariable {
  vairable: boolean;
}

interface bookData {
  image: string;
  name: string;
  author: string;
  author_username: string;
  author_name: string;
  author_id: string;
  description: string;
  lastUpdatedOn: string;
  likes: string;
  no_of_chapter: string;
  no_of_shares: string;
  tags: string;
  views: string;
  _id: string;
  chapters: Array<any>;
  is_in_bookshelf: boolean;
  isLiked: number;
  review: number,
  rating: number,
}

@Component({
  selector: 'app-book-detail-slider',
  templateUrl: './book-detail-slider.component.html',
  styleUrls: ['./book-detail-slider.component.sass'],
})
export class BookDetailSliderComponent implements OnInit {
  public baseimage: string;
  constructor(
    private bookDetailService: BookDetailService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private readChapterService: ReadChapterService,
    private reviewServiceHandler: ReviewHandlerService,
    private navbarService: NavbarService
  ) {
    this.baseimage = BaseUrl.image;
  }
  @ViewChild('usefulSwiper') swiperContainer: any;
  @Input('authorBookDetails') authorBookDetails: any;

  public slideVaribale: SlideVariable[] = [];

  public books: Array<bookData>;
  readMoreMode: boolean = false;

  showLoader: boolean;

  private handleSlider = () => {
    var mainSliderSelector = '.main-slider',
      navSliderSelector = '.nav-slider';

    let mainSlider = new Swiper(mainSliderSelector, {
      loop: true,
      speed: 500,
      loopAdditionalSlides: 10,
      grabCursor: true,
      slidesPerGroup: 1,
      initialSlide: 0,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      parallax: true,
      on: {
        init: function () {
          this.autoplay.stop();
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            $(swiper.slides[i])
              .find('.slider-animation')
              .attr({
                'data-swiper-parallax': 0.75 * swiper.width,
              });

            $(swiper.slides[i])
              .find('.author-name')
              .attr({
                'data-swiper-parallax': 0.9 * swiper.width,
              });

            $(swiper.slides[i])
              .find('.book-description')
              .attr({
                'data-swiper-parallax': 0.6 * swiper.width,
              });
          }
        },
        slideChange: () => {
          this.readMoreMode = false;
        }
      },
    });

    mainSlider.on('slideChange', (slide) => {
      var book_id = this.books[slide.realIndex]._id;
      this.bookDetailService.setBookReviewId(book_id);
    });
    var navSlider = new Swiper('.nav-slider', {
      loop: true,
      loopAdditionalSlides: 10,
      speed: 300,
      spaceBetween: 5,
      slidesPerView: 2,
      slidesPerGroup: 1,
      centeredSlides: true,
      touchRatio: 0.2,
      initialSlide: 1,
      direction: 'vertical',
      on: {
        imagesReady: function () {
          this.el.classList.remove('loading');
        },
        click: function (swip: any) {
          mainSlider.autoplay.stop();
        },
      },
    });

    // Matching sliders
    mainSlider.controller.control = navSlider;
    navSlider.controller.control = mainSlider;

    const swiperTag = new Swiper('.swiper__container__tag', {
      slidesPerView: 1,
      spaceBetween: 10,
      grabCursor: true,
      loop: false,
      direction: 'horizontal',
      navigation: {
        nextEl: '.next-button',
        prevEl: '.prev-button',
      },
    });
  };

  public description = false;
  public tags = false;
  public chapters = false;

  popup(str: string) {
    switch (str) {
      case 'description': {
        this.description = true;
        this.chapters = !this.description;
        this.tags = !this.description;
        break;
      }

      case 'tags': {
        this.tags = true;
        this.description = !this.tags;
        this.chapters = !this.tags;
        break;
      }

      case 'chapters':
        this.chapters = true;
        this.description = !this.chapters;
        this.tags = !this.chapters;
        break;
    }
    $('.hid-box').addClass('show-popup');
    $('.slider-animation').addClass('popup-background');
  }

  closeSynopsis() {
    $('.hid-box').removeClass('show-popup');
    $('.slider-animation').removeClass('popup-background');
  }

  searchForTags(tag: string) {
    this.router.navigate(['/search'], { queryParams: { search: tag } });
  }

  bookData: any;
  initialBookIndex: number;

  bookId: string;
  type: string;
  genreId: string | Array<string>;
  ngOnInit(): void {
    // this.activatedRoute.url.subscribe((url: any) => {
    this.books = [];
    this.showLoader = true;
    this.bookId = this.activatedRoute.snapshot.paramMap.get('id');
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    const genre = this.activatedRoute.snapshot.paramMap.get('genre_id');
    this.genreId = genre.split(',');
    this.loadAuthorData();
    // navSlider.slideTo(0)
    // });
  }

  async loadAuthorData(initialLoading: boolean = true) {
    this.bookDetailService
      .getBookDetail({
        device_type: 'web',
        type: this.type,
        book_id: this.bookId,
        genre_id: this.genreId,
      })
      .subscribe(
        (res: any) => {
          if (res.data) {
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].active) {
                this.initialBookIndex = i;
              }
            }

            this.books = _.map(res.data, (elem: any) => {
              return {
                image: elem.cover_image,
                name: elem.title,
                author_name: elem.author_name,
                author_username: elem.author_username,
                author_id: elem.author_id,
                description: elem.description,
                lastUpdatedOn: elem.updated_at,
                likes: elem.likes,
                no_of_chapter: elem.no_of_chapter,
                no_of_shares: elem.no_of_shares,
                tags: elem.tags,
                views: elem.views,
                _id: elem._id,
                chapters: elem.chapters,
                is_in_bookshelf: elem.is_in_bookshelf,
                isLiked: elem.isLiked,
                genre: Array.isArray(elem.genre) ? elem.genre.join(','): elem.genre
              };
            });
            _.filter(this.books, async (item, index) => {
              let review = await this.reviewServiceHandler.getReviewByBookId(item._id)
              review.subscribe(
              async (result: any) => {
                this.books[index].review = result?.bookReview?.totalReviewers | 0;
                this.books[index].rating = parseInt(result?.bookReview?.averageRating) | 0
              })
            })

            setTimeout(() => {
              this.handleSlider();
            }, 100);
            this.showLoader = false;
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  addbookshelf: boolean = true;

  addToBookshelf(index: number, bookId: string) {
    this.bookDetailService.addToBookShelf({ book_id: bookId }).subscribe(
      (res: any) => {
        if (res.status) {
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
          this.books[index].is_in_bookshelf = true;
          this.navbarService.updateBookShelf.next();
        } else {
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  removeBookShelf(index: number, bookId: string) {
    this.bookDetailService.removeToBookShelf({ book_id: bookId }).subscribe(
      (res: any) => {
        if (res.status) {
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
          this.books[index].is_in_bookshelf = false;
          this.navbarService.updateBookShelf.next();
        } else {
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  public likeBook = (data: any, index: number) => {
    this.readChapterService.likeBook(data).subscribe(
      (result: any) => {
        this.books[index].isLiked = 1;
        // this.loadAuthorData(false);
        this._snackbar.open(result.message, 'Ok', { duration: 2000 });
      },
      (error: any) => {}
    );
  };
  public unlikeBook = (data: any, index: number) => {
    this.readChapterService.likeBook(data).subscribe(
      (result: any) => {
        // this.loadAuthorData(false);
        this.books[index].isLiked = 0;
        this._snackbar.open(result.message, 'Ok', { duration: 2000 });
      },
      (error: any) => {}
    );
  };
}
