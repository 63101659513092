import { Component, OnInit } from "@angular/core";
import { UsersService } from "./users.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from "src/app/services/utility/utility.service";
import { FormControl } from "@angular/forms";
import { debounceTime, map } from "rxjs/operators";
declare var $:any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit {
  users: Array<any> = [];
  blockTest: string;
  popId: any;
  isLoading = false;
  checked: boolean;

  itemsPerPage: number = 10;
  page: number = 1;
  totalItems: number = 0;

  searchUser: FormControl = new FormControl();

  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private utils: UtilityService
  ) {}

  ngOnInit() {
    this.getUsers();
    this.searchUser.valueChanges.pipe(debounceTime(500), map((value) => value.trim())).subscribe((res) => {
      this.getUsers();
    })
  }

  openPopup(event) {
// alert('asda');

    $("#block_user").modal('show');
    this.popId = event;
    if (event.status == "2") {
      this.blockTest = "unblock";
    } else {
      this.blockTest = "block";
    }
  }

  onClick(event) {
    this.usersService.UpdateUserStatus(event).subscribe(
      (res: any) => {
        if (res.status) {
          if (event.status === 1) {
            this.toaster.success('User blocked successfully.')
          } else {
            this.toaster.success('User unblocked successfully.')
          }
          this.getUsers();
        } else {
          this.toaster.error('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

  getUsers(page: number = 1) {
    this.isLoading = true;
    this.page = page;
    this.usersService.getUsers({
      limit: this.itemsPerPage,
      skip: (this.page - 1) * this.itemsPerPage || 0,
      search: this.searchUser?.value || ''
    }).subscribe(
      (res: any) => {
        if(res?.status) {
          const mapped = Object.entries(res.data).map(([type, value]) => ({
            type,
            value
          }));
          this.users = mapped.reverse();
          this.totalItems = res?.total_items;
        }
      },
      err => {
        this.utils.openSanckbar({title: err?.message || 'Something went wrong', actionText: 'Ok'});
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  onClose(){
    this.getUsers();
  }
}
