<div class="swiper {{fullSize + '-'}}{{listTipe}}-book-slider-added-swiper">
  <h4 *ngIf="newBookData.length != 0" class="card-title mb-5 mt-0 section-heading">{{title}}</h4>
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let book of newBookData; let i = index">
      <div *ngIf="book" class="home-product product-default inner-quickview img-border-radius m-0"
        style="background-color: inherit!important; margin-top: 0rem;">
        <figure class="browse-books-image-tile img-border-radius m-0">
          <div style="position: relative; cursor: pointer;">
            <a [routerLink]="['/singlebook',book?._id]">
              <img *ngIf="book?.cover_image !== ''" style="max-height: 370px; max-width: 270px;" loading="lazy" class="browse-books-image-tile img-border-radius" [src]="baseimage + book?.cover_image"
                onerror="this.src = 'assets/images/rectangle1.svg'" alt=""
              />
              <img *ngIf="book?.cover_image == ''" style="max-height: 370px; max-width: 270px;" loading="lazy" class="browse-books-image-tile img-border-radius" src="assets/images/rectangle1.svg"
                onerror="this.src = 'assets/images/rectangle1.svg'" alt=""
              />
            </a>
            <div class="eighteen-plus-container" *ngIf="book.not_for_below_eighteen">
              <h4 class="eighteen-plus">18+</h4>
            </div>
          </div>
          <div class="label-group">
          </div>
          <div class="btn-icons">
            <button class="btn-icon btn-add-cart" data-toggle="modal" (click)="addToBookShelf(book)">
              <i *ngIf="book.is_in_bookshelves === true" class="fa fa-bookmark bookshelve-book"></i>
              <i *ngIf="book.is_in_bookshelves !== true" class="far fa-bookmark bookshelve-book"
                aria-hidden="true"></i>
            </button>
          </div>
        </figure>
      </div>
      <div class="product-details" style="max-width: 240px">
        <h2
          class="product-title book-title m-0"
          routerLink="/book/most_liked/{{ book._id }}/{{
            book.genre
          }}"
        >
          <a class="text-underline-on-hover book-title-text">{{ book.title }}</a>
        </h2>
        <h2 class="product-title book-title" routerLink="/authorprofile/{{ book.author_id }}">
          <a class="font-italic text-underline-on-hover book-title-text">@{{ book.author_username }}</a>
        </h2>
      </div>
      <div class="ratings-container" style="max-width: 240px;">
        <div class="book-interation ">
          <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
          <span class="views-hearts-shares-bookshelves">{{book?.views}}</span>
        </div>
        <div class="book-interation ">
          <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
          <span class="views-hearts-shares-bookshelves">{{ book?.likes }}</span>
        </div>
        <div class="book-interation ">
          <mat-icon class="small color-cyan-secondary">share</mat-icon>
          <span class="views-hearts-shares-bookshelves">{{ book?.no_of_shares }}</span>
        </div>
        <div class="book-interation ">
          <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
          <span class="views-hearts-shares-bookshelves">{{ book?.no_of_chapter }}</span>
        </div>
      </div>
    </div>
  </div>
  <button *ngIf="newBookData.length != 0" class="{{fullSize + '-'}}{{listTipe}}-swiper-button-prev swiper-button-prev" (click)="swiperRef.slidePrev()" [disabled]="swiperRef.isBeginning"><mat-icon>arrow_back_ios</mat-icon></button>
  <button *ngIf="newBookData.length != 0" class="{{fullSize + '-'}}{{listTipe}}-swiper-button-next swiper-button-next" (click)="swiperRef.slideNext()" [disabled]="swiperRef.isEnd"><mat-icon>arrow_forward_ios</mat-icon></button>
</div>
