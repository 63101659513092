<div class="page">
    <div *ngIf="showLoader" class="loader">
      <img loading="lazy" src="/assets/img/loader.svg" />
    </div>
    <div class="page-main">
      <!-- sidebar area -->
      <div class="app-content my-3 my-md-5 ">
        <div class="page-container">
          <div class="page-header ">
            <!-- <h4 class="page-title ">View Manage Content Type</h4> -->
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Sliders
              </li>
            </ol>
          </div>
          <div class="row ">
            <div class="col-md-12 col-lg-12 ">
              <div class="card ">
                <div class="card-header justify-content-between">
                  <div class="card-title ">Sliders</div>
                  <a
                    [routerLink]="['/add-slider']"
                    class="add_content pl-4 pr-4"
                    ><i class="fa fa-plus-circle" aria-hidden="true"></i></a
                  >
                </div>
                <div class="card-body ">
                  <div class=" ">
                    <div
                      id="example_wrapper "
                      class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view"
                    >
                      <div class="row ">
                        <div class="col-sm-12 ">
                          <table
                            
                            class="table row-border hover "
                           
                            *ngIf="temp"
                            role="grid "
                            aria-describedby="example_info "
                          >
                            <thead>
                              <tr role="row ">
                                <th
                                  class="wd-15p sorting_asc "
                                  tabindex="0 "
                                  aria-controls="example "
                                  rowspan="1 "
                                  colspan="1 "
                                  aria-sort="ascending "
                                  aria-label="First name: activate to sort column descending
                                              "
                                  style="width: 30px; "
                                >
                                  #
                                </th>
                                <th
                                  class="wd-15p sorting "
                                  tabindex="0 "
                                  aria-controls="example "
                                  rowspan="1 "
                                  colspan="1 "
                                  aria-label="Last name: activate to sort column ascending "
                                  style="width: 570px; "
                                >
                                  Image
                                </th>
                                
                                <th class="wd-20p unsort" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" style="width: 50px;">
                                                                
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                role="row "
                                class="odd "
                                *ngFor="
                                  let contentdata of getContent;
                                  let i = index
                                "
                              >
                                <td class="sorting_1 ">{{ i + 1 }}</td>
                                <td> <img loading="lazy" id="user_cover"
                                    src="{{ baseimage }}{{ contentdata.image }}"
                                    alt=""
                                  /></td>
                              
                                  <td>
                                    <a data-toggle="modal" data-target="#delete" (click)="onClick12({slider_id: contentdata._id})">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                    </a>                                                           
                                    </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- table-wrapper -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="container block_user_popup">
  <!-- The Modal -->
  <div class="modal fade" id="delete" data-backdrop="false">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
            <!-- <button type="button" class="close" data-dismiss="modal">
        &times;
        </button> -->
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="block_user">
            <h2 style="text-align: center;">Are you sure you want to delete this record?</h2>
            <p style="text-align: center;">After you delete this record you will not able to get this.</p>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <a (click)="onClick(delId)" class="btn btn-primary pl-6 pr-6" data-dismiss="modal">
            Yes
          </a>
          <a class="btn btn-light pl-6 pr-6" data-dismiss="modal">
            No
          </a>
        </div>
      </div>
    </div>
  </div>
</div>