import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  BASE_URL = `${environment.baseUrl}/transaction`;

  getTransactionHistory(params): Observable<any> {
    return this.http.get(`${this.BASE_URL}/getTransactionHistory`, {params})
  }

  debitLibcoin(data: any){
    return this.http.post(`${this.BASE_URL}/debitLibCoins`, data);
  }

  creditLibcoin(data: any) {
    return this.http.post(`${this.BASE_URL}/creditLibCoins`, data);
  }

  purchase(data: any) {
    return this.http.post(`${this.BASE_URL}/purchase`, data);
  }
}
