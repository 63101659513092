import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  getFeedbackDetail(id) {
    return this.http.post( environment.baseAdminUrl + `/getFeedbackDetail`, { feedback_id: id });
  }

  getFeedbacks(data: { limit: number, skip: number } = {limit: 10, skip: 0}): Observable<any> {
    return this.http.post( environment.baseAdminUrl + `/getFeedbacks`, data );
  }
}
