<div class="main_header main_header_login" *ngIf="!loggedIn">
  <div class="d-flex justify-content-center">
    <div class="flex_header justify-content-center">
      <div class="logo3 px-5 text-center w-100 top-navbar">
        <a class="align-middle" routerLink="/"
          ><img loading="lazy" src="assets/images/logo.png" alt=""
        /></a>
        <!-- <div
          class="
            align-middle
            d-flex
            justify-content-center justify-content-between
            align-items-center
          "
        >
          <a class="text-decoration-none text-muted" routerLink="/contact-us"
            ><span>Contact Us</span></a
          >
          <a class="text-decoration-none text-muted" routerLink="/about-us"
            ><span>About Us</span></a
          >
          <a class="text-decoration-none text-muted" routerLink="/withdrawal"
            ><span>Withdrawal</span></a
          >
          <a class="text-decoration-none text-muted" routerLink="/contact-us"
            ><span>Subscription</span></a
          >
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <button class="mr-4 btn btn-sm view-more global-button">
            Sign Up
          </button>
          <button class="ml-4 btn btn-sm view-more global-button">
            Login
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
        <div class="wraper mt-5">
          <section class="hero_section_craousel_mr mt-0">
            <div class="container">
              <div class="about_heading follower change_password">
                <h2>Contact Us</h2>
                <form
                  [formGroup]="reportForm"
                  class="issue_report all_custom_placeholders"
                  (ngSubmit)="onSubmit()"
                >
                  <div class="label_input">
                    <label for="">Name</label>
                    <input
                      class="chapter-title"
                      type="text"
                      placeholder="Enter your name"
                      formControlName="name"
                      [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                    />
                    <div
                      *ngIf="submitted && f.name.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.name.errors.required">
                        Name field is required.
                      </div>
                    </div>
                  </div>
                  <div class="label_input">
                    <label for="">Email </label>
                    <input
                      class="chapter-title"
                      type="text"
                      placeholder="Enter your email address"
                      formControlName="email"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    />
                    <div
                      *ngIf="submitted && f.email.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.email.errors.required">
                        Email field is required.
                      </div>
                    </div>
                  </div>
                  <div class="label_input">
                    <label for="">Subject</label>
                    <input
                      class="chapter-title"
                      type="text"
                      placeholder="Enter the subject"
                      formControlName="subject"
                      [ngClass]="{
                        'is-invalid': submitted && f.subject.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.subject.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.subject.errors.required">
                        Subject field is required.
                      </div>
                    </div>
                  </div>
                  <div class="label_input">
                    <label for="">How Can We Help</label>
                    <textarea
                      class="chapter-title"
                      [ngClass]="{
                        'is-invalid': submitted && f.message.errors
                      }"
                      cols="30"
                      rows="5"
                      placeholder="Type your message here"
                      formControlName="message"
                    ></textarea>
                    <div
                      *ngIf="submitted && f.message.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.name.errors.required">
                        Message is required.
                      </div>
                    </div>
                  </div>
                  <div class="login_btn">
                    <button [disabled]="loading" class="btn btn-cyan">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
