import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  headers: HttpHeaders;
  baseURL: string;
  base: string;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.headers = this.helper.getHeader();
    this.baseURL = BaseUrl.frontend;
    this.base = BaseUrl.admin;
  }

  homeData: any;

  appendImageUrlToHomeBookData(data: any) {
    data.data.every((elem: any) => {
      if (elem.data) {
        elem.data.every((e: any) => {
          if (e['cover_image']) {
            if (e['cover_image'].split('/')[0] == 'uploads') {
              e[
                'cover_image'
              ] = `https://librums.com/backend/testing/${e['cover_image']}`;
            }
          } else {
            e['cover_image'] =
              'https://testing.librums.com/assets/img/rectangle1.svg';
          }
          return true;
        });
      }
      return true;
    });

    data.data[1].tagData.every((elem: any) => {
      elem.data.every((e: any) => {
        if (e['cover_image']) {
          if (e['cover_image'].split('/')[0] == 'uploads') {
            e[
              'cover_image'
            ] = `https://librums.com/backend/testing/${e['cover_image']}`;
          }
        } else {
          e['cover_image'] =
            'https://testing.librums.com/assets/img/rectangle1.svg';
        }
        return true;
      });

      return true;
    });
    return data;
  }

  // gethomedata() {
  //   return this.http
  //     .post(this.baseURL + `/v1/getHomeData`, {limit: 10}, {
  //       headers: this.headers,
  //     })
  // }
  getSliders() {
    return this.http.post(this.base + `/getSliders`, null, {
      headers: this.headers,
    });
  }

  getAuthorInfo() {
    return this.http.post(this.base + '/getUsers', null, {
      headers: this.headers,
    });
  }

  getFeatureBook = (type) => {
    return this.http.get(`${this.baseURL}/getFeatureBook/${type}`, {
      headers: this.headers,
    });
  }

  getFeatureWriter = (type) => {
    return this.http.get(`${this.baseURL}/getFeatureWriter/${type}?limit=5`, {
      headers: this.headers,
    });
  }
}
