<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
<div class="page-main">
    <!-- sidebar area -->
    <div class="app-content  my-3 my-md-5">
        <div class="page-container">
            <div class="page-header">
                <!-- <h4 class="page-title">View Manage Pages</h4> -->
                <ol class="breadcrumb">
                                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                                  <li class="breadcrumb-item active" aria-current="page">
                                     Pages
                                  </li>
                              </ol>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">Pages</div>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table  class="table  row-border hover"  role="grid" *ngIf="temp" aria-describedby="example_info">
                                                <!-- <table datatable class="table row-border hover" *ngIf="this.temp"> -->
                                                <thead>
                                                    <tr role="row">
                                                        <th class="wd-15p sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="First name: activate to sort column descending" style="width: 30px;">
                                                            #
                                                        </th>
                                                        <th class="wd-20p sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" style="width: 570px; font-weight: bold">
                                                            Title
                                                        </th>
                                                       
                                                        <th class="wd-10p unsort" aria-sort="none" ordering="false"  style="width: 80px;">
                                                            
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row" class="odd" *ngFor="let manage of managePages;let i = index">
                                                        <td class="sorting_1">{{ i + 1 }}</td>
                                                        <td><a [routerLink]="[
                                                            '/view-manage-page',manage._id
                                                          ]">{{ manage.title }}</a></td>
                                                        <td>
                                                          
                                                                <a [routerLink]="[
                                                                      '/edit-manage-page',manage._id
                                                                    ]">
                                                                    <i class="fa fa-pencil-square-o" aria-hidden="true" style="color: chocolate; font-size:28px"></i>
                                                                </a>
                                                              
                                                        </td>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- table-wrapper -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>