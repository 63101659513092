import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookDetailService } from './book-detail.service';

@Component({
  selector: 'app-book-detail',
  templateUrl: './book-detail.component.html',
  styleUrls: ['./book-detail.component.sass'],
})
export class BookDetailComponent implements OnInit {
  @ViewChild('usefulSwiper') swiperContainer: any;

  constructor(
    private bookDetailService: BookDetailService
  ) {}
  loader: boolean = false;

  ngAfterViewInit() {}

  authorBookDetails: any;

  ngOnDestroy(): void {
    this.bookDetailService.setBookReviewId('');
  }

  ngOnInit(): void {
    // console.log('sasssa', this.activatedRoute.snapshot.params);
    // const { authorId, bookId } = this.activatedRoute.snapshot.params;
    // console.log('author id book id', authorId, bookId);
    // this.bookDetailService
    //   .getAuthorStories({
    //     author_id: authorId,
    //     type: 'publish',
    //   })
    //   .subscribe((res: any) => {
    //     if (res.status) {
    //       this.authorBookDetails = res.data;
    //     }
    //   });
  }
}
