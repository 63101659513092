import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { BaseUrl } from './base-url';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IMG {
  cover_image: string;
  editable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private userdata;
  private baseurl: string;
  public updateUserData: Subject<any> = new Subject();
  private _snackbar: MatSnackBar
  constructor(private authenticationService: AuthenticationService) {
    this.baseurl = BaseUrl.image;
  }
  getHeader() {
    this.userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(this.userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': decryptUser.data.token,
    });
    return headers;
  }

  getUserData() {
    this.userdata = localStorage.getItem('currentUser');

    if (this.userdata) {
      const decryptUser = JSON.parse(
        crypto.AES.decrypt(this.userdata, environment.secretKey).toString(
          crypto.enc.Utf8
        )
      );

      return decryptUser;
    } else {
      return {
        data: {
          image:
            'https://iqonic.design/themes/booksto/vue/dist/img/1.11c7773b.jpg',
          fullname: 'Barry Tech',
          username: 'Barry_Tech',
        },
      };
    }
  }


  // Call this function to refetch the user data and store it into the localstorage
  refetchUserData(action: 'reload' | 'none' = 'none') {
    this.authenticationService.checkUserStatus().subscribe((res: any) => {
      if (res?.data) {
        console.log("res => ", res)
        const data = localStorage.getItem("currentUser")
        const userData = JSON.parse(crypto.AES.decrypt(data, environment.secretKey).toString(crypto.enc.Utf8))
        this.userdata = res;
        res.data.token = userData?.data.token;
        const encryptedUser = crypto.AES.encrypt(JSON.stringify(res), environment.secretKey).toString();
        localStorage.setItem("currentUser", encryptedUser);
        if(action === 'reload') location.reload();
        else{

          // Send subscription ping to across the app and listen live updates
          this.updateUserData.next(userData);
        }
      }
    });
  }

  appendBaseUrlToImage(cover_image: string): IMG {
    var editable: boolean;
    if (cover_image != 'uploads/assets/img/rectangle1.svg') {
      // if (cover_image.split('/')[0] == 'uploads') {
      //   cover_image = `${this.baseurl}${cover_image}`;
      // }
      editable = true;
    } else {
      // cover_image = '';
      editable = false;
    }

    return { cover_image, editable };
  }

  validateEmail(text: string) {
    return new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(text);
  }
}
