import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { HelperService } from 'src/app/helper.service';
import { InvitedUserEmailsEntity, Room } from 'src/app/Interface/room.interface';
import { SearchService } from 'src/app/search-page/search.service';
import { RoomService } from 'src/app/services/room/room.service';
import { environment } from 'src/environments/environment';

export interface RoomDialogData {
  mode: 'create' | 'edit',
  userData: any,
  room?: Room
}

@Component({
  selector: 'app-create-book-club-dialog',
  templateUrl: './create-book-club-dialog.component.html',
  styleUrls: ['./create-book-club-dialog.component.sass']
})
export class CreateBookClubDialogComponent implements OnInit {

  isLoading: boolean = false;
  roomForm: FormGroup = new FormGroup({
    host_id: new FormControl(this.data?.userData?._id, [Validators.required]),
    room_name: new FormControl(null, [Validators.required]),
    isUploadedBook: new FormControl(false, [Validators.required]),
    book: new FormControl(null, [Validators.required]),
    invited_user_emails: new FormControl([]),
    invite_user_input_text: new FormControl(null)
  })
  isFormSubmit: boolean = false;

  booksFilteredOptions: Observable<any[]>;
  invitingAgain: Array<string> = [];
  invitedAgain: Array<string> = [];
  newUser: Array<string> = [];
  imageBaseUrl = environment.imageBaseUrl;

  constructor(
    public dialogRef: MatDialogRef<CreateBookClubDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RoomDialogData,
    private roomService: RoomService,
    private searchService: SearchService,
    private helperService: HelperService,
    private toastrService: ToastrService
  ) {}
  ngOnInit(): void {

    this.booksFilteredOptions = this._filterBookResult('');

    if(this.data.mode === 'edit') {
      this.roomForm.patchValue({
        host_id: this.data.userData?._id,
        room_name: this.data.room?.room_name,
        book: this.data.room?.book_info,
        invited_user_emails: this.data?.room?.invited_user_emails
      }, {emitEvent: false})
    }

    this.controls.book.valueChanges.pipe(debounceTime(500)).subscribe((text) => {
      this.booksFilteredOptions = this._filterBookResult(text);
    })

    // this.roomForm.valueChanges.subscribe((res: any) => {
    //   console.log("After select", res);
    // })
    
  }

  get controls() {
    return this.roomForm.controls;
  }

  get isFormValid (): boolean {
    return this.controls?.room_name?.value?.length && this.controls?.book?.value?.cover_image ? true : false;
  }

  createRoom() {
    const formValues = this.roomForm.value;
    const payload: any = { 
      host_id: this.data.userData?._id, 
      room_name: formValues?.room_name, 
      invited_user_emails: formValues?.invited_user_emails, 
      book_id: formValues.book?._id }
    payload.isUploadedBook = formValues?.book?.is_published === 2 ? true : false;

    this.isLoading = true;
    this.roomService.createRoom(payload).subscribe((res) => {
      this.dialogRef.close(payload);
    }, (err) => {
      console.log("ERROR => ", err);
    }).add(() => {
      this.isLoading = false;
    })
  }

  updateRoom() {
    const formValues = this.roomForm.value;
    const payload: any = {
      room_id: this.data.room?._id,
      room_name: formValues?.room_name,
      invited_user_emails: formValues?.invited_user_emails,
      book_id: formValues.book?._id
    }
    payload.isUploadedBook = formValues?.book?.is_published === 2 ? true : false;

    this.isLoading = true;
    this.roomService.updateRoom(payload).subscribe((res) => {
      this.dialogRef.close(payload);
    }, (err) => {
      console.log("ERROR => ", err);
    }).add(() => {
      this.isLoading = false;
    })
    // alert('Working on api setup');
    // this.dialogRef.close();
  }
  inviteAgain(user: InvitedUserEmailsEntity) {
    this.invitingAgain.push(user.email);
    this.roomService.inviteUser({room_id: this.data.room?._id, email: user.email}).subscribe((res) => {
      if(res?.status) {
        this.toastrService.success(`Invitation sent to ${user.email}`);
        this.invitingAgain = this.invitingAgain.filter(email => email != user.email);
        this.invitedAgain.push(user.email);
      } else {
        this.toastrService.error(res?.message || 'Something went wrong');
      }
    }, (err) => {
      console.log("ERROR => ", err);
      this.toastrService.error(err?.message || 'Something went wrong');
    });

  }


  // Search related functions
  private _filterBookResult(search: any) {
    return this.searchService.searchMyBoooks({search}).pipe(map((result: any) => result?.data || []));
  }

  bookDisplayFunction(book: any): string {
    return book && book?.title ? book.title : '';
  }


  private _filterUserResult(search: any) {
    return this.searchService.searchUsers({search}).pipe(map((result: any) => result?.data || []));
  }

  addUser(event: any): void {
    const email: any = (event.target.value || '').trim();

    console.log(email);

    if(!this.helperService.validateEmail(email)) return;

    // Add our fruit
    if (email) {
      const invited_users = this.controls.invited_user_emails.value;
      invited_users.push({email, isFollower: false, status: 'invited'});
      this.controls.invited_user_emails.patchValue(invited_users);
      this.newUser.push(email);
    }

    this.controls.invite_user_input_text.patchValue(null);
  }

  removeUser(email: string): void {
    console.log("EMAIL => ", email);
    // const index = this.fruits.indexOf(fruit);
    let invited_users: Array<any> = this.controls.invited_user_emails.value;
    invited_users = invited_users.filter((invite: any) => invite?.email !== email);
    this.controls.invited_user_emails.patchValue(invited_users);
  }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   const email: any = event.option.viewValue;
  //   const invited_users = this.controls.invited_user_emails.value;
  //   invited_users.push({email, isFollower: false, status: 'invited'});

  //   this.emailInput.nativeElement.value = '';
  // }
}
