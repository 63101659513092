import { BaseUrl } from "./../../../base-url";
import { Component, OnInit } from "@angular/core";
import { BackendFeedbackService } from "./backend-feedback.service";
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-backend-feedback',
  templateUrl: './backend-feedback.component.html',
  styleUrls: ['./backend-feedback.component.sass']
})
export class BackendFeedbackComponent implements OnInit {
  public getfeedback: any;
  public temp: Object = false;
  data: any;
  baseimage: any;
  delete: any;
  delId: any;
  showLoader = false;
  constructor(private feedback: BackendFeedbackService, private toaster: ToastrService) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.loadFeedback();
  }

  onClick12(event) {
    this.delId = event;
  }
  onClick(event) {
    this.feedback.deleteFeedback(event).subscribe(
      (res: any) => {
        // tslint:disable-next-line: semicolon
        this.delete = res.status;
        this.toaster.success('Feedback deleted successfully.')
        this.loadFeedback();
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

  loadFeedback() {
    this.feedback.getFeedbacks().subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        // console.log(mapped);
        this.getfeedback = mapped.reverse();
        $(document).ready(function() {
          $('#feedbacktable').DataTable();
        })
        this.temp = true;
        this.showLoader = false
      },
      error => {
        console.log("ERROR");
      }
    );
  }
}
