<div
  class="iq-card-header d-flex justify-content-between align-items-center mb-0"
>
  <div class="iq-header-title">
    <h1 class="card-title mb-0">Book Sales</h1>
  </div>
  <div class="iq-card-header-toolbar d-flex align-items-center">
    <div class="dropdown">
      <span
        class="dropdown-toggle text-primary"
        id="dropdownMenuButton05"
        data-toggle="dropdown"
      >
        <i class="ri-more-fill"></i>
      </span>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton05"
      >
        <a routerLink="/withdrawal" class="dropdown-item book-actions"
          ><i class="fas fa-dollar-sign mr-2"></i>Withdraw</a
        >
        <a class="dropdown-item book-actions"
          ><i class="ri-file-download-fill mr-2"></i>Download</a
        >
      </div>
    </div>
  </div>
</div>
<div class="iq-card-body">
  <ul class="perfomer-lists m-0 p-0">
    <li class="d-flex mb-4 align-items-center">
      <div class="user-img img-fluid">
        <img
          class="img-fluid avatar-50 rounded-circle"
          src="../../../../assets/Home/images/user/06.jpg"
          alt=""
        />
      </div>
      <div class="media-support-info ml-3">
        <H2>Reading on the World</H2>
        <p class="mb-0">Lorem Ipsum is simply dummy test</p>
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <span class="text-dark"><b>+$82</b></span>
      </div>
    </li>
    <li class="d-flex mb-4 align-items-center">
      <div class="user-img img-fluid">
        <img
          class="img-fluid avatar-50 rounded-circle"
          src="../../../../assets/Home/images/user/07.jpg"
          alt=""
        />
      </div>
      <div class="media-support-info ml-3">
        <H2>Little Black Book</H2>
        <p class="mb-0">Lorem Ipsum is simply dummy test</p>
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <span class="text-dark"><b>+$90</b></span>
      </div>
    </li>
    <li class="d-flex align-items-center">
      <div class="user-img img-fluid">
        <img
          class="img-fluid avatar-50 rounded-circle"
          src="../../../../assets/Home/images/user/08.jpg"
          alt=""
        />
      </div>
      <div class="media-support-info ml-3">
        <H2>See the More Story</H2>
        <p class="mb-0">Lorem Ipsum is simply dummy test</p>
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-cener">
        <span class="text-dark"><b>+$85</b></span>
      </div>
    </li>
  </ul>
</div>
