import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HelperService } from 'src/app/helper.service';
import { BaseUrl } from 'src/app/base-url';
import { SeoserviceService } from 'src/app/seoservice.service';
import { UserProfileService } from '../../user-profile.service';
import { CreateStoriesService } from 'src/app/create-stories/create-stories.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ChapterAddService } from 'src/app/chapter-add/chapter-add.service';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
// import { HelperService } from '../../../helper.service';

@Component({
  selector: 'app-mybookdetails',
  templateUrl: './mybookdetails.component.html',
  styleUrls: ['./mybookdetails.component.sass'],
})
export class MybookdetailsComponent implements OnInit {
  constructor(
    private activedRoute: ActivatedRoute,
    private helperService: HelperService,
    private router: Router,
    private _seoService: SeoserviceService,
    private userProfileService: UserProfileService,
    private createStoriesService: CreateStoriesService,
    private toaster: ToastrService,
    private chapterService: ChapterAddService,
    public dialog: MatDialog,
    ) {
    this.baseurl = BaseUrl.image;
  }
  public baseurl;
  public bookId;
  public bookData;
  public userdata;
  public loader: boolean = true;
  active_tab: 'published' | 'drafted' = 'published';

  public storyType: Observable<any>;

  ngOnInit(): void {
    this.bookId = this.activedRoute.snapshot.paramMap.get('id');
    this.userdata = this.helperService.getUserData().data;
    this.loadAuthorData();

    this.storyType = this.createStoriesService.storyTypeData;
  }

  addNewChapter(id: string) {
    this.router.navigate(['/write-chapter/' + id]);
  }

  removeChapter(chapter: any) {

    this.dialog.open(ConfirmationModalComponent, {
      data: {custom: true, title: 'Remove Chapter', description: `Are you sure you want to remove chapter "${chapter?.chapter_name}" ?`}
    }).afterClosed().subscribe((flag) => {
        if(flag){
          this.loader = true;
    this.chapterService.deleteChapter({chapter_id: chapter?._id, book_id: chapter?.book_id}).subscribe((res: {status: boolean, message : string, data: any}) => {
      if(res.status) {
        this.loadAuthorData();
        this.toaster.success('Chapter removed successfully');
      } else {
        this.toaster.error(res.message || 'Something went wrong');
      }
      this.loader = false;
    }, (err) => {
      this.toaster.error(err?.message || 'Something went wrong');
      this.loader = false;
    })
        }
    })
  }

  //load my book details
  loadAuthorData()   {
    const headers = this.helperService.getHeader();
    this.userProfileService
      .loadAuthorData(this.bookId)
      .subscribe((res: any) => {
        if(res?.data?.author_id === this.userdata?._id){
          this.bookData = res.data;
          localStorage.setItem('mybooks', JSON.stringify(this.bookData));
          this.loader = false;
          this._seoService.updateTitle(this.bookData.title);

        } else {
          this.toaster.error("Access unauthorized");
          this.router.navigate([''], { replaceUrl: true });
        }
      });
  }
}
