import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  private payment = new BehaviorSubject<Object>(null);
  public paymentData = this.payment.asObservable();

  setPaymentData = (data: any) => {
    this.payment.next(data);
  };

  constructor() {}
}
