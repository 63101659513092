import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { BaseUrl } from '../base-url';
import { BookDataService } from '../services/book-data.service';
import { ToastrService } from 'ngx-toastr';
import Swiper from 'swiper';
import { OwlOptions } from 'ngx-owl-carousel-o';

interface tags {
  _id: string;
  name: string;
}
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.sass'],
})

export class SliderComponent implements OnInit {

  highlightedBooks: Array<any>;
  public baseimage: string = BaseUrl.image;
  public backgrounds: Array<string> = [
    '../../assets/Slider/images/slider/home_slides/arts-photography.png',
    '../../assets/Slider/images/slider/home_slides/business-investing.png',
    'https://portotheme.com/html/porto_ecommerce/demo_27/assets/images/slider/home_slides/literature-fiction.png',
    '../../assets/Slider/images/slider/home_slides/mystery-suspense.png',
    '../../assets/Slider/images/slider/home_slides/scifi-fantasy.png',
  ];

  public showView: boolean = false;
  isLoading: boolean = true;

  public data = [
    { id: 1, name: "P1" },
    { id: 1, name: "P2" },
    { id: 1, name: "P3" },
    { id: 1, name: "P4" },
    { id: 1, name: "P5" }
  ]
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private bookdataService: BookDataService,
    private toastrService: ToastrService
  ) { }

  showQuickView() {
    if (this.showView) {
      this.showView = false;
    } else {
      this.showView = true;
    }
  }

  // public slickInit(e: any) {
  //   console.log('slick initialized');
  // }
  // public breakpoint(e: any) {
  //   console.log('breakpoint');
  // }
  // public afterChange(e: any) {
  //   console.log('afterChange');
  // }
  // public beforeChange(e: any) {
  //   console.log('beforeChange');
  // }

  openBookDescription(book: any) {
    localStorage.setItem('descriptionData', JSON.stringify(book));
    this.router.navigate(['book', 'explore', book?.data?._id, book?.relatedBooks?.genre_id]);
  }

  // public customOptions: OwlOptions = {
  //   loop: true,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: false,
  //   navSpeed: 700,
  //   navText: ["<div class='nav-button owl-prev'>‹</div>", "<div class='nav-button owl-next'>›</div>"],
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     400: {
  //       items: 2
  //     },
  //     740: {
  //       items: 3
  //     },
  //     940: {
  //       items: 4
  //     }
  //   },
  //   nav: true
  // }


  // public slideConfig = {
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   infinite: true,
  //   variableWidth: true,
  //   responsive: [
  //     {
  //       breakpoint: 1440,
  //       settings: {
  //         slidesToShow: 3
  //       }
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3
  //       }
  //     },
  //     {
  //       breakpoint: 1025,
  //       settings: {
  //         slidesToShow: 2
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //         centerMode: false,
  //       }
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         centerMode: false,
  //       }
  //     },
  //     {
  //       breakpoint: 500,
  //       settings: {
  //         slidesToShow: 1,
  //         centerMode: false,
  //       }
  //     }
  //   ]
  // };

  // ngAfterViewInit() {
  //   this.loadSwiper();
  // }

  ngOnInit(): void {
    this.getHighlightedBooks();
    this.loadSwiper()
  }

  swiper: Swiper;
  private loadSwiper() {
    this.swiper = new Swiper('.app-swiper', {
      slidesPerView: 4,
      spaceBetween: 30,
      slidesPerGroup: 4,
      // loop: true,
      // loopFillGroupWithBlank: true,
      // lazy: true,
      // grabCursor: true,
      // mousewheel: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1000: {
          slidesPerView: 4,
          slidesPerGroup: 1,
          loop: true,
        },

        800: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },

        300: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
      resizeObserver: true,
    });
  }

  private getHighlightedBooks() {
    this.isLoading = true;
    // alert(this.isLoading);
    this.bookdataService.getHightlighetedBooks().subscribe((res: { status: boolean, data: any, message: string }) => {
      if (res?.status) {
        // this.swiper.removeAllSlides();
        const data = res?.data || [];
        // console.log("this.backgrounds ============>", this.backgrounds);
        // console.log("data ============>", data);

        this.highlightedBooks = data?.map((item, index) => {
          item.background = this.backgrounds[index % this.backgrounds.length];
          return item;
        })

      } else {
        this.toastrService.error(res?.message || 'Something went wrong');
      }
      this.isLoading = false;
      // alert(this.isLoading);
    }, (err) => {
      this.isLoading = false;
      this.toastrService.error(err?.message || 'Something went wrong');
    })
  }
}
