<section class="conatiner__">
  <div class="d-flex justify-content-center">
    <div *ngIf="!textIsBrief" class="author__bio">
      <h2 class="author__header bio-header">Biography:</h2>
      <p *ngIf="!showFullText">{{ briefText }}...<span class="showText" (click)="hendleShoweMoreText()">show more</span></p>
      <p *ngIf="showFullText">{{ fullText }}<span class="showText" (click)="hendleShoweLessText()">show less</span></p>
    </div>
    <div *ngIf="textIsBrief" class="author__bio bio-header">
      <h2 class="author__header">Biography:</h2>
      <p>{{ fullText }}</p>
    </div>
  </div>
  <div *ngIf="storiesCount == 0 && userProfile?._id == this.authorData?._id" class="d-flex flex-column align-items-center" style="max-width: 919px;" >
    <div class="myStrories__write__container create-write">
      <div class="myStories__write">
          <div
            routerLink="/write"
            class="
              myStories__add_image
              d-flex
              justify-content-center
              align-items-center
            "
            [style.backgroundImage]="'url(assets/images/dummy.png)'"
          >
            <img
              class="myStories__plus_icon"
              src="assets/images/Plus graident.svg"
              alt=""
              style="filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);"
            />
          </div>
          <div class="add__new__book">
            <h1 class="font-italic">ADD NEW BOOK</h1>
            <p class="font-italic myStories__description">
              You Do not have any Published Books
              Let's start with your masterpiece
            </p>
            <div class="add-book-button">
              <button routerLink="/write" class="btn btn-cyan fw-600 add-story-button"> Add </button>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div *ngIf="storiesCount !== 0">
    <div class="mt-5 d-flex flex-column align-items-center">
      <div class="slider-header">
        <h2 class="author__header mb-3 text-center">Stories By @{{ authorData.username }}:</h2>
      </div>
      <div class="swiper-container pt-5 px-5 author__books__container test____">
        <div class="swiper-wrapper">
          <div *ngFor="let books of authorData.myStories" class="swiper-slide d-flex flex-column align-items-center">
            <div>
              <div
                *ngIf="books.cover_image !== '' && books.cover_image !== 'uploads/assets/img/rectangle1.svg'; then thenblock; else elseblock"
              ></div>

              <ng-template #thenblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="{{ baseimage }}{{ books.cover_image }}"
                  />
                </a>
              </ng-template>
              <ng-template #elseblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                  />
                </a>
              </ng-template>
            </div>
            <h3
              class="text-truncate author__book__title text-center font-italic text-white"
            >
              <span>
                {{ books.title }}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="myLikedStoriesCount !== 0">
    <div class="mt-5  d-flex flex-column align-items-center">
      <div class="slider-header">
        <h2 class="author__header mb-3 text-center">Liked Books by: @{{ authorData.username }}</h2>
      </div>
      <div class="swiper-container pt-5 px-5 author__books__container test____">
        <div class="swiper-wrapper">
          <div
            *ngFor="let books of authorData.myLikedStories"
            class="swiper-slide d-flex flex-column align-items-center"
          >
            <div>
              <div
                *ngIf="books.cover_image !== '' && books.cover_image !== 'uploads/assets/img/rectangle1.svg'; then thenblock; else elseblock"
              ></div>

              <ng-template #thenblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="{{ baseimage }}{{ books.cover_image }}"
                  />
                </a>
              </ng-template>
              <ng-template #elseblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                  />
                </a>
              </ng-template>
            </div>
            <h3
              [class.no-book-cover]="books.cover_image === ''"
              class="text-truncate author__book__title text-center font-italic text-white"
            >
              <span [class.no-book-cover]="books.cover_image !== ''">
                {{ books.title }}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="myLikedStoriesCount == 0 && userProfile?._id == this.authorData?._id">
    <div class="mt-5  d-flex flex-column">
      <div class="swiper-container pt-5 px-5 author__books__container test____" >
        <div class="mb-3 font-italic myStories__description" style="height: auto;" >
          <p style="color: white;">You Have not liked any books, Check some of these out</p>
        </div>
        <div class="swiper-wrapper">
          <div
            *ngFor="let books of popularRead"
            class="swiper-slide d-flex flex-column align-items-center"
          >
            <div>
              <div
                *ngIf="books.cover_image !== ''; then thenblock; else elseblock"
              ></div>

              <ng-template #thenblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="{{ baseimage }}{{ books.cover_image }}"
                  />
                </a>
              </ng-template>
              <ng-template #elseblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                  />
                </a>
              </ng-template>
            </div>
            <h3
              [class.no-book-cover]="books.cover_image === ''"
              class="text-truncate author__book__title text-center font-italic text-white"
            >
              <span [class.no-book-cover]="books.cover_image !== ''">
                {{ books.title }}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="draftedStories?.length !== 0 && userProfile._id == this.authorData?._id">
    <div class="mt-5 d-flex flex-column align-items-center">
      <div class="slider-header">
        <h2 class="author__header mb-3 text-center">Drafted By @{{ authorData.username }}:</h2>
      </div>
      <div class="swiper-container pt-5 px-5 author__books__container test____">
        <div class="swiper-wrapper">
          <div *ngFor="let books of draftedStories" class="swiper-slide d-flex flex-column align-items-center">
            <div>
              <div
                *ngIf="books.cover_image !== '' && books.cover_image !== 'uploads/assets/img/rectangle1.svg'; then thenblock; else elseblock"
              ></div>

              <ng-template #thenblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="{{ baseimage }}{{ books.cover_image }}"
                  />
                </a>
              </ng-template>
              <ng-template #elseblock>
                <a class="image-link-to-book" routerLink="/singlebook/{{ books._id }}">
                  <img
                    class="author__book__image"
                    src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                  />
                </a>
              </ng-template>
            </div>
            <h3
              class="text-truncate author__book__title text-center font-italic text-white"
            >
              <span>
                {{ books.title }}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="draftedCount == 0 && userProfile?._id == this.authorData?._id" class="d-flex flex-column align-items-center" style="max-width: 919px;" >
    <div class="myStrories__write__container create-write">
      <div class="myStories__write">
          <div
            routerLink="/write"
            class="
              myStories__add_image
              d-flex
              justify-content-center
              align-items-center
            "
            [style.backgroundImage]="'url(assets/images/dummy.png)'"
          >
            <img
              class="myStories__plus_icon"
              src="assets/images/Plus graident.svg"
              alt=""
              style="filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);"
            />
          </div>
          <div class="add__new__book">
            <h1 class="font-italic">ADD NEW BOOK</h1>
            <p class="font-italic myStories__description">
              You Do not have any Drafted Books
              Let's start with your masterpiece
            </p>
            <div class="add-book-button">
              <button routerLink="/write" class="btn btn-cyan fw-600 add-story-button"> Add </button>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
