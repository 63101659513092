import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { MybookdetailsComponent } from './component/mybookdetails/mybookdetails.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ReadingSettingsComponent } from './component/reading-settings/reading-settings.component';
import { WithdrawalComponent } from './component/withdrawal/withdrawal.component';
import { ProfileLayoutComponent } from './profile-layout.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileLayoutComponent,
    data: { breadcrumb: 'Profile', url: 'pro' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'pro',
      },
      {
        path: 'pro',
        component: ProfileComponent,
      },
      {
        path: 'mybookdetails/:id',
        component: MybookdetailsComponent,
        data: { breadcrumb: 'My Stories', url: 'mybookdetails/:id' },
      },
      // {
      //   path: 'withdrawal',
      //   canActivate: [AuthGuard],
      //   component: WithdrawalComponent,
      //   data: { breadcrumb: 'Withdrawal', url: 'withdrawal' },
      // },
      // {
      //   path: 'reading-settings',
      //   canActivate: [AuthGuard],
      //   component: ReadingSettingsComponent,
      //   data: { breadcrumb: 'Reading Settings', url: 'reading-settings' },
      // },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
