import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { BookDataService } from '../services/book-data.service';

import * as _ from 'underscore';
import { GlobalService } from '../global.service';
import { ReadChapterService } from '../read-chapter/read-chapter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileHanlderService } from '../user-profile/user-profile-hanlder.service';
import { Router } from '@angular/router';
import { BookDetailService } from '../book-detail/book-detail.service';
import { BaseUrl } from '../base-url';
import { HomeService } from '../home/home.service';
import { Swiper } from 'swiper';
import { NavbarService } from '../navbar/navbar.service';
@Component({
  selector: 'app-browse-books',
  templateUrl: './browse-books.component.html',
  styleUrls: ['./browse-books.component.sass'],
})
export class BrowseBooksComponent implements OnInit {
  @Input('bookData') bookData: any;
  public browseBookDetails: any[] = [];
  private likedBooks: Object[] = [];
  public baseimage: string;
  constructor(
    private dialog: MatDialog,
    private bookDataService: BookDataService,
    private globalService: GlobalService,
    private readChapter: ReadChapterService,
    private _snackbar: MatSnackBar,
    private router: Router,
    private bookDetailService: BookDetailService,
    private homeService: HomeService,
    private _snackBar: MatSnackBar,
    private navbarService: NavbarService
  ) {
    this.baseimage = BaseUrl.image;
  }

  addToBookShelf(book: any) {
    this.bookDetailService.addToBookShelf({ book_id: book?.id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = true;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          if (res.message === 'Book already in bookshelf.') {
            this.removeBookShelf(book)
          } else {
            this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          }
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  removeBookShelf(book: any) {
    this.bookDetailService.removeToBookShelf({ book_id: book?.id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = false;
          this.navbarService.updateBookShelf.next();
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
        } else {
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  heartBook(id: String) {
    this.browseBookDetails.every((element: any) => {
      if (element['id'] == id) {
        if (element['liked']) {
          element['likes']--;
          element['liked'] = false;
          this.bookDataService
            .likeBooks(element)
            .subscribe((result: any) => {});

          // this.likedBooks.push(element);
          return false;
        } else {
          element['likes']++;
          element['liked'] = true;
          this.bookDataService
            .likeBooks(element)
            .subscribe((result: any) => {});
          this.likedBooks.push(element);
          return false;
        }
      }
      return true;
    });
  }

  likeBook(body: any, index: number) {
    this.readChapter.likeBook(body).subscribe(
      (res: any) => {
        if (res.status) {
          if (body.status === 1) {
            this.bookData.value.data[index].isLiked = 1;
            this.bookData.value.data[index].likes++;
          } else {
            this.bookData.value.data[index].isLiked = 0;
            this.bookData.value.data[index].likes--;
          }

          this._snackbar.open(res.message, 'ok', {
            duration: 2000,
          });
        } else {
        }
      },
      (error) => {}
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    var result = changes.bookData.currentValue;
    if (result) {
      // const mapped = Object.entries(result.data).map(([type, value]) => ({
      //   type,
      //   value,
      // }));
      // var mapped = result
      // const browseBookData = mapped[0].value['data'];
      // browseBookData.every((element: any) => {
      //   element['cover_image'] = element['cover_image']
      //     ? `https://librums.com/backend/testing/${element['cover_image']}`
      //     : 'https://testing.librums.com/assets/img/rectangle1.svg';
      //   return true;
      // });
      // this.browseBookDetails = browseBookData;
    }
  }

  gotoBookDetails(index: number) {
    this.bookDetailService.setSliderData({
      data: this.bookData?.value?.data,
      index: index,
    });

    this.router.navigate(['/book']);
  }

  ngOnInit(): void {
    setTimeout(() => {
      var swiper = new Swiper('.video__books__container', {
        slidesPerView: 'auto',
      });
    }, 1000);
  }
}
