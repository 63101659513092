<div class="swiper-container app-swiper">
  <div class="swiper-wrapper">
    <ng-container *ngIf="isLoading">
      <div class="swiper-slide" style="min-height: 550px;" *ngFor="let item of [1,2,3,4,5,6]">
        <ngx-skeleton-loader [count]="1" appearance="line" [theme]="{
                  width: '48rem',
                  opacity: '0.7',
                  'border-radius': '12px',
                  height: '420px'}">
        </ngx-skeleton-loader>
        <div align="center">
          <ngx-skeleton-loader [count]="1" appearance="line" [theme]="{
                    width: '22rem',
                    'border-radius': '12px',
                    'position': 'absolute',
                    background: 'no-repeat rgb(214 216 222 / 77%)',
                    'bottom': '10px',
                    height: '300px'}">
          </ngx-skeleton-loader>
            <ngx-skeleton-loader [count]="4" appearance="circle" [theme]="{
                    width: '24px',
                    height: '24px',
                    top: '100px',
                    'margin-right': '12px'}">
            </ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>

    <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"> -->

    <!-- <owl-carousel-o [options]="customOptions"> -->
      <!-- <div class="slide browse-book-tile owl-carousel w-auto p-4 img-border-radius"
          *ngFor="let highlight of highlightedBooks; let i = index"> -->
      <!-- <ng-container  class="img-border-radius owl-carousel" *ngFor="let highlight of highlightedBooks; let i = index">
        <ng-template carouselSlide class="home-slide text-center"
          style="background-image: url('{{ highlight.background }}');">
          <img loading="lazy" src="../../assets/Slider/images/lazy.png" data-src="" alt="slider image" />
          <div class="home-slide-content">
            <h2>{{ highlight?.genre?.name }}</h2>
            <span>{{ highlight?.total_books }} Books</span>
            <a [routerLink]="['/search']" [queryParams]="{ search: highlight?.genre?.name }">View all<i
                class="porto-icon-right"></i></a>
          </div>
          <div class="
                home-product
                product-default
                inner-quickview
                img-border-radius
                border-radius-10
              ">
            <figure class="img-border-radius border-radius-10  slider-image">
              <a [routerLink]="['/book', 'explore', highlight?.book?._id, highlight?.genre?._id]">

                <ImageWrapper skeletonType="strong" class="img-border-radius br-15" skeletonBorderRadius="15px"
                  [imageUrl]="baseimage + highlight?.book?.cover_image" imageErrorUrl="assets/images/rectangle1.svg">
                </ImageWrapper>
              </a>
            </figure>
            <div class="product-details text-center">
              <h2 class="product-title" [routerLink]="['/', 'authorprofile', highlight?.book?.author_id]">
                <a class="author-name font-italic text-underline-on-hover">@{{ highlight?.book?.author_username }}</a>
              </h2>
              <div class="ratings-container">
                <div class="book-interation mr-4">

                  <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{
                    highlight?.book?.views
                    }}</span>
                </div>
                <div class="book-interation mr-4">

                  <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{ highlight?.book?.likes }}</span>
                </div>
                <div class="book-interation mr-4">
                  <mat-icon class="small color-cyan-secondary">share</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{ highlight?.book?.no_of_shares }}</span>
                </div>
                <div class="book-interation mr-4">
                  <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{ highlight?.book?.no_of_chapter }}</span>
                </div>
              </div>
              <div class="price-box text-left d-flex">
                <span>Last Updated On: </span>
                <span class="text-capitalize" style="width: 88px !important"> {{ highlight?.book?.updated_at }} </span>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container> -->
      <!-- </div> -->
    <!-- </owl-carousel-o> -->
    <!-- </ngx-slick-carousel> -->


    <ng-container *ngIf="!isLoading">
      <div class="swiper-slide img-border-radius" *ngFor="let highlight of highlightedBooks; let i = index">
        <div class="home-slide text-center"
          style="background-image: url('{{ highlight.background }}');">
          <img loading="lazy" src="../../assets/Slider/images/lazy.png" data-src="" alt="slider image" />
          <div class="home-slide-content">
            <h2>{{ highlight?.genre?.name }}</h2>
            <span>{{ highlight?.total_books }} Books</span>
            <a [routerLink]="['/search']" [queryParams]="{ search: highlight?.genre?.name }">View all<i
                class="porto-icon-right"></i></a>
          </div>

          <div class="
              home-product
              product-default
              inner-quickview
              img-border-radius
              border-radius-10
            "
          >
            <figure class="img-border-radius border-radius-10  slider-image">
              <a
                [routerLink]="['/book', 'explore', highlight?.book?._id, highlight?.genre?._id]"
              >

              <ImageWrapper skeletonType="strong" style="border-radius: 10px !important;" class="img-border-radius" skeletonBorderRadius="15px" [imageUrl]="baseimage + highlight?.book?.cover_image" imageErrorUrl="assets/images/rectangle1.svg"></ImageWrapper>
              </a>
            </figure>
            <div class="product-details text-center">
              <h2 class="product-title" [routerLink]="['/', 'authorprofile', highlight?.book?.author_id]">
                <a class="author-name font-italic text-underline-on-hover">@{{ highlight?.book?.author_username }}</a>
              </h2>
              <div class="ratings-container">
                <div class="book-interation mr-4">

                  <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{
                    highlight?.book?.views
                    }}</span>
                </div>
                <div class="book-interation mr-4">

                  <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{ highlight?.book?.likes }}</span>
                </div>
                <div class="book-interation mr-4">
                  <mat-icon class="small color-cyan-secondary">share</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{ highlight?.book?.no_of_shares }}</span>
                </div>
                <div class="book-interation mr-4">
                  <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
                  <span class="views-hearts-shares-bookshelves">{{ highlight?.book?.no_of_chapter }}</span>
                </div>
              </div>
              <div class="price-box text-left d-flex">
                <span>Last Updated On: </span>
                <span class="text-capitalize" style="width: 88px !important"> {{ highlight?.book?.updated_at }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="swiper-slide img-border-radius" *ngFor="let highlight of data; let i = index">
        <h1>{{highlight.name}}</h1>
      </div> -->
    </ng-container>
  </div>
  <div class="nav-buttons">
    <div class="swiper-button-prev">
      <i class="fas fa-chevron-left previous-button-icon"></i>
    </div>
    <div class="swiper-button-next">
      <i class="fas fa-chevron-right next-button-icon"></i>
    </div>
  </div>
</div>
