<div class="" style="width: 88%; margin: auto;">
  <div class="row">
    <div class="col-lg-12 col-xl-8 mt-5">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height"
        style="border-radius: 12px; border: 2px solid #F3F3F3;">
        <div style="border-radius: 12px 12px 12px 12px;" class="p-3 p-lg-5">
          <app-loader *ngIf="showLoader"></app-loader>
          <div class="swiper-container main-slider top-slider">
            <div class="nav-buttons" style="top: 25%; height: 40px !important">
              <button class="swiper-button-prev swiper-button-white">
                <mat-icon>arrow_back_ios</mat-icon>
              </button>
              <button class="swiper-button-next swiper-button-white">
                <mat-icon>arrow_forward_ios</mat-icon>
              </button>
            </div>
            <div class="swiper-wrapper">
              <div class="swiper-slide d-flex align-items-center" *ngFor="let book of books; let i = index">

                <div class="row p-3 pr-4 data-row w-100 justify-content-between">
                  <div class="col-md-5">
                    <div class="d-flex justify-content-center">
                      <img loading="lazy" class="w-auto focused-image" [src]="baseimage+book.image"
                        onerror="this.src = 'assets/images/rectangle1.svg'" style="border-radius: 20px;" />
                    </div>
                  </div>
                  <div class="col-md-7 book-data">
                    <div class="content w-100 h-100 d-flex align-items-end">
                      <div class="book-content">
                        <p class="book-name">

                          {{ book.name | titlecase | slice: 0 : 50 }}
                          <span *ngIf="book?.name?.length > 50">...</span>
                        </p>
                        <div class="text-decoration-none ">
                          By <a routerLink="/authorprofile/{{ book.author_id }}" style="color: black;"> <b>@{{ book.author_name }}</b> </a>
                          <span class="text-secondary"> | </span>
                          <span class="last-updated-on text-shadow">Last Updated: {{ book.lastUpdatedOn }}</span>
                        </div>
                        <div class="mt-4 d-flex justify-content-between flex-wrap"
                          style="border-top: 1px solid #c8c8c8; padding-top: 10px;">
                          <div class="d-flex mt-3">
                            <div class="product-ratings">
                              <i *ngFor="let star of [1, 2, 3, 4, 5]" class="fa-star color-cyan-secondary"
                                [ngClass]="star <= book?.rating ? 'fas' : 'far'">
                              </i>
                            </div>
                            <div class="mx-1"> | </div>
                            <div class="rating-link color-cyan-secondary">
                              <span>{{ book.review }}</span> Reviews
                            </div>
                          </div>
                          <div class="d-flex mr-2 align-items-center">
                            <div class="mr-2 mt-3 d-flex flex-column">
                              <span
                                style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                                <img loading="lazy" src="./assets/images/eye-icon.png" />
                              </span>
                              <span style="text-align: center; font-size: 12px; color: #292D32;">{{book.views}}</span>
                            </div>
                            <div class="mr-2 mt-3 d-flex flex-column">
                              <span
                                style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                                <img loading="lazy" src="./assets/images/like-icon.png" />
                              </span>
                              <span style="text-align: center; font-size: 12px; color: #292D32;">{{book.likes}}</span>
                            </div>
                            <div class="mr-2 mt-3 d-flex flex-column">
                              <span
                                style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                                <img loading="lazy" src="./assets/images/sear-icon.png" />
                              </span>
                              <span
                                style="text-align: center; font-size: 12px; color: #292D32;">{{book.no_of_shares}}</span>
                            </div>

                            <!-- <div class="mx-sm-3 mx-2">
                              <mat-icon class="color-cyan-secondary">menu_book</mat-icon>{{ book.no_of_chapter}}
                            </div> -->
                            <div class="mr-2 mt-3 d-flex flex-column">
                              <!-- <mat-icon style="height: auto;">menu_book</mat-icon> -->
                              <span
                                style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                                <img loading="lazy" src="./assets/images/book-icon.png" />
                              </span>
                              <span
                                style="text-align: center; font-size: 12px; color: #292D32;">{{book.no_of_chapter}}</span>
                            </div>
                          </div>
                        </div>
                        <hr class="mt-0" />
                        <div class=" mt-3">
                          <span class="caption text-shadow myStories__description" (click)="popup('description')">
                            <ng-container *ngIf="!readMoreMode">
                              <span>{{ book.description | slice: 0: 100 }}</span>
                              <span *ngIf="book.description.length > 100 && !readMoreMode">...</span>
                            </ng-container>
                            <ng-container *ngIf="readMoreMode">
                              <span>{{ book.description }}</span>
                            </ng-container>
                          </span>
                          <u>
                            <a *ngIf="book.description.length  > 100" class="text-shadow" style="cursor: pointer"
                              (click)="readMoreMode = !readMoreMode">
                              <ng-container *ngIf="!readMoreMode">Read More...</ng-container>
                              <ng-container *ngIf="readMoreMode">Read Less...</ng-container>
                            </a>
                          </u>

                          <hr />

                          <h3 class="cursor-pointer label">Tags</h3>
                          <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mt-4
                            book__details_tag
                          ">
                            <mat-chip-list class="swiper-wrapper" cdkDropList cdkDropListOrientation="horizontal">
                              <mat-chip cdkDrag class="swiper-slide w-auto" *ngFor="let tags of book.tags"
                                style="border-radius: 5px; background-color: #F3F3F3; color: #292D32;">
                                {{tags}}
                              </mat-chip>
                            </mat-chip-list>
                          </div>
                          <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mt-4
                            book__details_tag
                          ">
                            <!-- <label class="cursor-pointer label" [matMenuTriggerFor]="menu">Chapters
                            </label> -->

                            <mat-menu #menu="matMenu">
                              <button mat-menu-item class="follower_list"
                                *ngFor="let chapters of book.chapters; let i = index">
                                <div class="follower_pic">
                                  <div class="follower_pic_name">
                                    <h3 style="color: black">
                                      <a class="text-decoration-none chapter-lists" [routerLink]="[
                                      '/readchapter',
                                      book._id,
                                      book.chapters[i]._id,
                                      book.author_id
                                    ]">
                                        Chapter {{ i + 1 }} :
                                        <span style="color: black">{{
                                          chapters.chapter_name
                                          }}</span>
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </button>
                            </mat-menu>
                          </div>

                          <div class="book-chapter-list text-shadow">
                            <div class="product-action no-text-shadow d-flex justify-content-between align-items-center">
                              <div style="width: 75%;">
                                <a *ngIf="book.chapters[0]" [routerLink]="[
                                '/readchapter',
                                book._id,
                                book.chapters[0]._id,
                                book.author_id
                              ]">
                                  <button class="btn btn-sm view-more p-4"
                                    style="background-color: #48E8E8; width: 100%; border-radius: 14px;">
                                    Start Reading
                                  </button>
                                </a>
                              </div>
                              <div>
                                <div *ngIf="book.isLiked == 0"
                                  (click)="likeBook({ book_id: book._id, status: 1 }, i)"
                                  class="book-additional-ability">
                                  <img loading="lazy" class="book-details-icons" src="../../../../assets/images/like.svg"
                                      alt="" />
                                </div>
                                <div
                                  *ngIf="book.isLiked !== 0"
                                  (click)="unlikeBook({ book_id: book._id, status: 2 }, i)"
                                >
                                  <img loading="lazy" class="book-details-icons is-liked-icon"
                                  src="../../../../assets/images/like.svg" alt="" />
                                </div>
                                <!-- <div *ngif="book.isLiked !== 0"
                                  (click)="unlikeBook({ book_id: book._id, status: 2 }, i)"
                                  class="book-additional-ability">
                                  <p>asdasdasdasd</p>

                                </div> -->
                                <!-- <i [ngClass]="{
                                fa: book.isLiked == 1,
                                far: book.isLiked == 0
                              }" (click)="
                                book.isLiked == 0
                                  ? likeBook({ book_id: book._id, status: 1 }, i)
                                  : unlikeBook({ book_id: book._id, status: 2 }, i)
                              " class="
                                color-cyan-secondary
                                fa-heart fa-2x
                                mt-3
                                ml-3
                              "></i> -->
                              </div>
                              <div>

                                <i [ngClass]="{
                                fa: book.is_in_bookshelf,
                                far: !book.is_in_bookshelf
                              }" (click)="
                                !book.is_in_bookshelf
                                  ? addToBookshelf(i, book._id)
                                  : removeBookShelf(i, book._id)
                              " class="fa-bookmark fa-2x mt-3 ml-3 color-cyan-secondary"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-xl-4 mt-5 small-slider">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height"
      style="border-radius: 12px; border: 2px solid #F3F3F3;">
        <div style="border-radius: 12px 12px 12px 12px;" class="p-5">
          <div class="swiper-container nav-slider" style="width: 100%;">
            <div class="swiper-wrapper" role="navigation">
              <div class="swiper-slide" *ngFor="let book of books; let i = index">
                <div class="row">
                  <div class="col-5">
                    <img loading="lazy" [src]="baseimage + book.image" onerror="this.src = './assets/images/rectangle1.svg'" style="border-radius: 12px;" />
                  </div>
                  <div class="col d-flex align-items-end">
                    <div class="content thumb-container">
                      <p class="book-description thumb-slider-title book-lable mb-0">
                        {{ book.name | titlecase | slice : 0 : 50 }}
                      </p>
                      <div class="thumb-slider-detail">
                        <span style="font-size: 14px;">By <span style="color: black;"> @{{ book.author_name |
                            titlecase}} </span> </span>
                        <div class="d-flex">
                          <div class="mr-2 mt-3 d-flex flex-column">
                           <span
                              style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                              <img loading="lazy" src="./assets/images/eye-icon.png" />
                            </span>
                            <span style="text-align: center; font-size: 12px; color: #292D32;">{{book.views}}</span>
                          </div>
                          <div class="mr-2 mt-3 d-flex flex-column">
                            <span
                              style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                              <img loading="lazy" src="./assets/images/like-icon.png" />
                            </span>
                            <span style="text-align: center; font-size: 12px; color: #292D32;">{{book.likes}}</span>
                          </div>

                          <div class="mr-2 mt-3 d-flex flex-column">
                            <span
                              style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                              <img loading="lazy" src="./assets/images/sear-icon.png" />
                            </span>
                            <span
                              style="text-align: center; font-size: 12px; color: #292D32;">{{book.no_of_shares}}</span>
                          </div>

                          <div class="mr-2 mt-3 d-flex flex-column">
                            <span
                              style="background-color: #F3F3F3; padding: 8px; background-clip: inherit; border-radius: 50%;">
                              <img loading="lazy" src="./assets/images/book-icon.png" />
                            </span>
                            <span style="text-align: center; font-size: 12px; color: #292D32;">{{
                              book.no_of_chapter}}</span>
                          </div>
                        </div>
                        <p class="description-text" style="font-size: 12px;">
                          {{book.description}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
