<div class="">
   <div class="container-fluid">
      <div class="row d-flex justify-content-center">
         <div class="col-lg-10 p-0">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height m-0" style="border-radius:10px">
               <div class="iq-card-body p-0">
                  <div class=" p-0">
                     <div class="">
                        <div class="book-wraper mt-3">
                           <div class="row m-0">
                              <div class="col-sm-4 col-xl-3 px-3 d-flex justify-content-center">
                                 <img loading="lazy" style="border-radius: 10px" src="{{ baseUrl }}{{ bookData.cover_image }}"
                                    alt="" />
                              </div>
                              <div
                                 class="col-sm-7 col-xl-8 p-0 pl-sm-3 pt-3 pt-sm-0 d-flex flex-column input-root-container justify-content-between h-100">
                                 <div
                                    class="d-flex flex-column book-data-container justify-content-center justify-content-sm-end">
                                    <div
                                       class="bool-name mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                       {{bookData.title}}
                                    </div>
                                    <div
                                       class="book-sentence mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                       {{bookData.tagline}}
                                    </div>
                                    <div
                                       class="block-author-book mb-3 d-flex justify-content-center justify-content-sm-start">
                                       <img loading="lazy" class="image-author-book" src="{{ baseUrl }}{{ bookData.author_image }}"
                                          alt="">
                                       <p class="m-0 ml-2 author-name-text">@{{bookData.username}}</p>
                                    </div>
                                    <div
                                       class="book-description d-flex d-sm-block justify-content-center book-field-text">
                                       {{bookData.description}}
                                    </div>
                                 </div>
                              </div>
                              <div *ngIf="book_id && chapterIsAlredyPurchase == false" class="col-sm-1 p-0 d-flex flex-column  align-items-center"
                                style="height: 50px; margin-bottom: 80px;">
                                <div class="is-free-block" mat-raised-button #tooltip="matTooltip"
                                    matTooltip="Click the button if you want this chapter to be paid">
                                    <img loading="lazy" class="image-for-pay-chapter-disabled" (click)="handleChapterPay()"
                                      *ngIf="!chapterIsPaid" src="../../assets/images/cash-coin.png" alt="">
                                    <img loading="lazy" class="image-for-pay-chapter-active" (click)="handleChapterPay()"
                                      *ngIf="chapterIsPaid" src="../../assets/images/cash-coin.png" alt="">
                                </div>
                              </div>
                              <div *ngIf="book_id && chapterIsAlredyPurchase == true" class="col-sm-1 p-0 d-flex flex-column  align-items-center"
                                style="height: 50px; margin-bottom: 80px;">
                                <div class="is-free-block" mat-raised-button #tooltip="matTooltip"
                                    matTooltip="You can not make it free anymore!">
                                    <img loading="lazy" class="image-for-pay-chapter-active"
                                      *ngIf="chapterIsPaid" src="../../assets/images/cash-coin.png" alt="">
                                </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <form style="margin-bottom: 30px;" class="issue_report" [formGroup]="chapterUpdate"
                        (ngSubmit)="updateChapter($event)">
                        <div>
                           <div class="p-3">
                              <div class="d-flex flex-column align-items-center">
                                 <div class="d-flex justify-content-center w-100">
                                    <input class="write-input bool-name text-center w-100" type="text"
                                       placeholder="Chapter Name" formControlName="chapterName"
                                       (value)="chapterData.chapter_name">
                                 </div>
                                 <quill-editor (onEditorCreated)="getEditorInstance($event)" formControlName="content" class="content-editor" [placeholder]="''" [modules]="quillModule">
                                 </quill-editor>
                                 <!-- <textarea class="write-chapter"
                                  placeholder="Type something..."
                                  formControlName="content"
                                  name="textarea">
                                </textarea> -->
                              </div>
                           </div>
                           <div class=" d-flex justify-content-between upload-chapter">
                              <button class="write-button-save drafted-chapter-button" id="drafted-book" type="submit"
                                 color="warn">
                                 Save to draft
                              </button>
                              <button class="write-button-save upload-chapter-button" id="publish-book" type="submit"
                                 color="warn">
                                 Publish
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-loader *ngIf="loadings"></app-loader>
<app-footer></app-footer>
