import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { BaseUrl } from '../base-url';
import { NavbarService } from '../nav-sidebar/navbar.service';

type PAGE_TYPE = 'web' | 'vr' | 'mobile';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.sass'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private authGaurd: AuthGuard,
    private route: ActivatedRoute,
  ) {
    this.loggedIn = this.authGaurd.userLoggedin();
  }
  public loggedIn: boolean;

  htmlPage: any;
  pageType: PAGE_TYPE = 'web';
  ngOnInit(): void {

    this.pageType = this.route.snapshot.paramMap.get('type') as PAGE_TYPE;

    this.http.get(`${BaseUrl.frontend}/privacypolicy/${this.pageType}`).subscribe(
      (result: { status: boolean, message: string, data: { _id: string, title: string, slug: string, content: string} }) => {
        if(result?.status) {
          this.htmlPage = result.data.content;
        }
      // this.htmlPage = '<h1>Privacy Policy</h1><br/ >' + this.htmlPage;
    });
  }
}
