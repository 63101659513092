import { Component, OnInit } from "@angular/core";
import { ViewManagePageService } from "./view-manage-page.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-view-manage-page",
  templateUrl: "./view-manage-page.component.html",
  styleUrls: ["./view-manage-page.component.sass"]
})
export class ViewManagePageComponent implements OnInit {
  managePages: any;
  temp: boolean;
  getPages: any;
  // tslint:disable-next-line: variable-name
  page_id: string;
  showLoader = false

  constructor(
    private manage: ViewManagePageService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.showLoader = true
    this.page_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadPages();
  }
  loadPages() {
    this.manage.getPageDetail(this.page_id).subscribe(
      (res: any) => {
        this.getPages = res.data;
        this.showLoader = false
      },
      () => {
        console.log("ERROR");
      }
    );
  }
}
