<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
    <div class="page-main">
        <!-- Sidebar menu-->
        <div class="app-content my-3 my-md-5" *ngIf="usersUpdate">
            <div class="page-container">
                <div class="author__profile">
                    <form #editProfileInfo="ngForm" (ngSubmit)="submitNewProfileInfo(editProfileInfo)"
                        class="edit-profile-info-form m-0">
                        <div class="form-group fullname edit-info-block-form input_txt">
                            <label class="text-left" for="fullname">Name <span class="required-field-icon">*</span> </label>
                            <input type="text" requared placeholder="Enter name" class="name-input" name="fullname"
                                [ngModel]="userdata.data.fullname" ngModel required #name="ngModel">
                        </div>
                        <div class="form-group edit-info-block-form input_txt">
                            <label for="biography">Biography</label>
                            <textarea type="text" placeholder="Biography" style="font-size: inherit"
                                class="form-control text-area-edit-info" [ngModel]="userdata.data.bio" name="bio"
                                ngModel #bio="ngModel"></textarea>
                        </div>
                        <div class="form-group fullname edit-info-block-form input_txt">
                            <label class="text-left" for="fullname">DOB  <span class="required-field-icon">*</span> </label>
                            <input [matDatepicker]="picker" (click)="picker.open()" type="text" requared
                                placeholder="Enter DOB" class="name-input" name="dob" [ngModel]="userdata.data.dob"
                                ngModel required #name="ngModel">
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <div class="form-group fullname edit-info-block-form input_txt">
                            <label class="text-left" for="fullname">Location <span class="required-field-icon">*</span></label>
                            <input type="text" requared placeholder="Enter location" class="name-input" name="location"
                                [ngModel]="userdata.data.location" ngModel required #location="ngModel">
                        </div>
                        <div class="form-group edit-info-block-form input_txt">
                            <label for="mature">Gender <span class="required-field-icon">*</span></label>
                            <select name="gender" [ngModel]="userdata.data.gender" ngModel required #gender="ngModel"
                                class="select-mature">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="form-group edit-info-block-form input_txt">
                            <label for="mature">Mature Content <span class="required-field-icon">*</span></label>
                            <select name="enable_mature_content" [ngModel]="userdata.data.enable_mature_content" ngModel
                                required #enable_mature_content="ngModel" class="select-mature">
                                <option [value]="true">Enabled</option>
                                <option [value]="false">Disabled</option>
                            </select>
                        </div>
                        <div class="container">
                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <div class="save text-center">
                                        <button type="submit" class="write-button-save">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
