<!-- <div *ngIf="show"> -->
<!-- <home-component></home-component> -->
<!-- </div> -->
<!-- <app-product-quick-view></app-product-quick-view> -->
<!-- Navigation Bar -->
<app-nav-sidebar></app-nav-sidebar>

<!-- Footer -->
<!-- <div class="footer-container">
  <app-footer></app-footer>
</div> -->
<!-- Footer END -->

<!-- Routing -->
