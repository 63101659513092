import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../users/users.service';
declare var $: any;

@Component({
  selector: 'app-author-list',
  templateUrl: './author-list.component.html',
  styleUrls: ['./author-list.component.sass']
})
export class AuthorListComponent implements OnInit {

  getUsers: any;
  temp = false;
  userid: any;
  userblock: any;
  userUnblock: any;
  blockTest: string;
  popId: any;
  showLoader = false;
  checked: boolean;
  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.showLoader = true;
    this.userid = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadUsers();
    
  }

  openPopup(event) {
// alert('asda');

    $("#block_user").modal('show');
    this.popId = event;
    if (event.status == "2") {
      this.blockTest = "unblock";
    } else {
      this.blockTest = "block";
    }
  }

  onClick(event) {
    this.usersService.UpdateUserStatus(event).subscribe(
      (res: any) => {
        if (res.status) {
          if (event.status === 1) {
            this.toaster.success('Author blocked successfully.')
          } else {
            this.toaster.success('Author unblocked successfully.')
          }
          this.loadUsers();
        } else {
          this.toaster.error('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

  loadUsers() {
    this.usersService.getAuthors().subscribe(
      (res: any) => {
        // console.log(res);
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        // console.log(mapped);
        this.getUsers = mapped.reverse();
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
  onClose(){
    this.loadUsers();
  }

}
