import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseUrl } from './base-url';
import { HelperService } from './helper.service';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class PostMessageService {
  postdata: any;
  postimage: any;
  _baseURL: any;
  _baseimage: any;
  headers;
  constructor(private http: HttpClient, private helper: HelperService) {
    this._baseURL = BaseUrl.frontend;
    this._baseimage = BaseUrl.imageApi;
    this.headers = this.helper.getHeader();
  }

  savePost(data) {
    return this.http.post(this._baseURL + `/savePost`, data, {
      headers: this.headers,
    });
  }

  post_image(image) {
    var userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
    const headers = new HttpHeaders({
      'x-access-token': decryptUser.data.token,
    });
    return this.http.post(this._baseimage + `post_image`, image, {
      headers,
    });
  }
}
