<div class="page">
    <div *ngIf="showLoader" class="loader">
      <img loading="lazy" src="/assets/img/loader.svg" />
    </div>
    <div class="page-main">
      <!-- Sidebar menu-->
      <div class="app-content  my-3 my-md-5" *ngIf="getBook">
        <div class="page-container">
          <div class="page-header">

            <ol class="breadcrumb mb-3">
              <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">Dashboard</a>
              </li>
              <li class="breadcrumb-item">
                <a [routerLink]="['/published-books']"> Published Books</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                List of Chapters
              </li>
            </ol>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3 col-xl-3">
              <div class="txt_book_view">
                <div class="out_side_label text-center">
                  <label for="form_pic">
                    <div
                      class="form_pic"
                      *ngIf="getBook.cover_image; else other_content"
                    >
                      <img
                        style="width:100%"
                        src="{{ baseimage }}{{ getBook.cover_image }}"
                        alt=""
                      />
                    </div>
                    <ng-template #other_content
                      ><img
                        style="width:100%"
                        src="assets/img/rectangle1.svg"
                        alt=""
                    /></ng-template>
                  </label>
                </div>
                <div class="name_txt ">
                  <h2 class="text-center">{{ getBook.title }}</h2>
                </div>
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Views</span>{{ getBook.views }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Likes</span>{{ getBook.likes }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Shares</span>{{ getBook.no_of_shares }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Chapters</span>{{ getBook.no_of_chapter }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Content type</span>{{ getBook.content_type }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Genre</span>{{ getBook.genre }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">

                  <p class="d-flex justify-content-between align-items center">
                    <span class="mr-3">Tags</span>
                    <span class="tags_auth" style="float: left;">
                        <span *ngFor="let tag of getBook.tags; let i = index" style="float: left;">
                          <span class="badge badge--info">{{ tag }}</span>
                          <!-- <span class="badge badge-pill badge-secondary" *ngIf="i < 2">{{ tag }}22</span> -->
                        </span>
                        <span  *ngIf="getBook.tags.length > 3" class="ml-2">

                            <a data-toggle="collapse" class="panel-title" data-target="#demo" style="color:#007bff">more</a>

                            <div id="demo" class="collapse">
                              <span *ngFor="let tag of getBook.tags" class="badge badge-pill badge-secondary mt-1"> {{tag}}</span>

                            </div>

                        </span>
                    </span>
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Copyright</span>{{ getBook.copyright }}
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Author</span><a [routerLink]="[
                    '/edit-users',
                    getBook.author_id
                ]">{{ getBook.author_name }}</a>
                  </p>
                </div>
                <hr class="mt-1 mb-1" />
                <div class="name_txt">
                  <p class="d-flex justify-content-between align-items center">
                    <span>Description</span>
                    <a
                      style="color:#007bff;"
                      type="button"
                      data-toggle="modal"
                      data-target="#myModal"
                      >view
                    </a>
                  </p>
                </div>

              </div>
            </div>
            <div class="col-md-6 col-lg-9 col-xl-9">
              <div class="card">
                <div class="card-body ">
                  <div
                    class="table-responsive dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <table
                      class=" table row-border hover "
                      *ngIf="temp"
                      role="grid "
                      aria-describedby="example_info "
                    >
                      <thead>
                        <tr role="row">
                          <th
                            class="wd-15p sorting_asc"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="First name: activate to sort column descending"
                            style="width: 65px;"
                          >
                            Chapter#
                          </th>
                          <th
                            class="wd-15p sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Last name: activate to sort column ascending"
                            style="width: 100px;"
                          >
                            Chapter Title
                          </th>

                          <th
                            class="wd-10p sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style="width: 100px;"
                          >
                            Status
                          </th>
                          <th
                            class="wd-10p sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style="width: 100px;"
                          >
                            Price
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          role="row "
                          class="odd "
                          *ngFor="
                            let publish of getBook.published_chapters;
                            let i = index
                          "
                        >
                          <td class="sorting_1">{{ i + 1 }}</td>
                          <td> <a
                            [routerLink]="[
                              '/published-book-content',
                              publish._id
                            ]"
                            >{{ publish.chapter_name }}</a></td>
                            <td><span class="badge badge-pill badge-success">Published</span></td>
                          <td>
                            {{
                              publish.is_paid === true
                                ? "$" + publish.amount
                                : "Free"
                            }}
                          </td>

                        </tr>
                        <tr
                          role="row "
                          class="odd "
                          *ngFor="
                            let draft of getBook.draft_chapters;
                            let i = index
                          "
                        >
                          <td class="sorting_1">
                            {{ getBook.published_chapters.length + i + 1 }}
                          </td>
                          <td><a
                            [routerLink]="[
                              '/view-chapter-content',
                              draft._id
                            ]"
                            >{{ draft.chapter_name }}</a></td>
                          <!-- <td>{{ draft.content | slice: 0:20 }}</td> -->
                          <!-- <td>{{ draft.is_paid === true ? "Paid" : "Unpaid" }}</td> -->
                          <td>Draft</td>
                          <td>
                            {{
                              draft.is_paid === true ? "$" + draft.amount : "Free"
                            }}
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- The Modal -->
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                <h4 class="modal-title">Book Name: {{ getBook.title }}</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                <p [innerHTML]="getBook.description | safeHtml: 'html'"></p>
              </div>

              <!-- Modal footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
