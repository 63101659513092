<div class="main_header main_header_login" *ngIf="!loggedIn">
  <div class="d-flex justify-content-center">
    <div class="flex_header justify-content-center">
      <div class="logo3 px-5 text-center w-100 top-navbar">
        <a class="align-middle" routerLink="/"
          ><img loading="lazy" src="assets/images/logo.png" alt=""
        /></a>
      </div>
    </div>
  </div>
</div>
<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="div-container-margin-top" style="margin-top: 35px;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-md-12">
            <!-- <mat-tab-group> -->
            <!-- <mat-tab label="Librums Privacy Policy"> -->
                <div class="col-lg-12">
                  <div
                    class="
                      iq-card iq-card-block iq-card-stretch iq-card-height
                      mt-3
                    "
                  >
                    <div
                      class="
                        iq-card-header
                        d-flex
                        justify-content-between
                        align-items-center
                        position-relative
                      "
                    >
                      <div class="container-fluid p-5">
                        <div align="center" class="mt-4 images">
                          <img loading="lazy" src="assets/images/privacy-policy.svg" style="height: 200px;" />
                        </div>
                        <div class="mx-3 mb-5">
                          <h1>Librums Privacy Policy</h1>
                        </div>
                        <!-- <div class="row"> -->
                        <!-- <div class="col-lg-3"></div> -->
                        <!-- <div class="col-lg-6" [innerHTML]="htmlPage"></div> -->
                        <div class="m-3" [innerHTML]="htmlPage"></div>
                        <!-- <div class="col-lg-3"></div> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </mat-tab> -->
              <!-- </mat-tab-group> -->
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>
