import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteReadsService {
  baseurl;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
  }
  private favoriteReadsObject = new BehaviorSubject<object>(null);
  public favoriteReads = this.favoriteReadsObject.asObservable();

  getFavoriteSectionData() {
    const headers = this.helper.getHeader();

    return this.http.post(this.baseurl + `/getHomeData`, null, { headers });
  }

  getBookHistory = (full: boolean = false, limit?: number) => {
    const headers = this.helper.getHeader();
    return this.http.post(
      `${this.baseurl}/getBookHistory`,
      { limit, full },
      { headers }
    );
  };
}
