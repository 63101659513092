import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';

@Injectable({
  providedIn: 'root',
})
export class BookshelfService {
  userdata: any;
  baseuel: string;
  headers: HttpHeaders;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseuel = BaseUrl.frontend;
    this.headers = this.helper.getHeader();
  }

  getUserHistory() {
    return this.http.post(this.baseuel + `/getHistory`, null, {
      headers: this.headers,
    });
  }

  getPopularInBookShelf() {
    return this.http.post(this.baseuel + `/getMyBookshelf`, {
      "sort": "popular"
    }, {
      headers: this.headers,
    });
  }
  getNewlyAddedInBookShelf() {
    return this.http.post(this.baseuel + `/getMyBookshelf`, {
      "sort": "newly_added"
    }, {
      headers: this.headers,
    });
  }
  getBookShelf() {
    return this.http.post(this.baseuel + `/getMyBookshelf`, null, {
      headers: this.headers,
    });
  }
  removeFromBookshelf(postData) {
    return this.http.post(this.baseuel + `/removeFromBookshelf`, postData, {
      headers: this.headers,
    });
  }

  loadMoreBooks(data) {
    return this.http.post(this.baseuel + `/getMyBookshelf`, data, {
      headers: this.headers,
    });
  }

  shareBook(data) {
    return this.http.post(this.baseuel + `/shareBook`, data, {
      headers: this.headers,
    });
  }

  viewedBookInBookshelf = (book_id: string) => {
    return this.http.post(
      this.baseuel + '/viewedBookInBookshelf',
      { book_id },
      { headers: this.headers }
    );
  };
  getBookHistory = (full: boolean = false, limit?: number) => {
    const headers = this.helper.getHeader();
    return this.http.post(
      `${this.baseuel}/getBookHistory`,
      { limit, full },
      { headers }
    );
  };
}
