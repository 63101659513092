<div class="wrapper">
  <div id="content-page" class="content-page">
    <div>
      <div class="p-0">
        <div class="
            iq-card-transparent
            iq-card-block
            iq-card-stretch
            iq-card-height
            rounded
          ">
          <app-slider></app-slider>
        </div>
      </div>
      <div class="home-container">
        <div class="home-vr-banner position-relative"
          style="overflow: hidden; border-radius: 25px; height: 292px; background-color: aqua;">
          <div class="vr-banner-block">
            <h1 class="vr-banner-text">{{VRCardData && VRCardData?.title}}</h1>
            <button class="vr-banner-btn">
              {{VRCardData &&  VRCardData?.action}}
            </button>
          </div>
          <img loading="lazy" [src]="imageBaseUrl + VRCardData && VRCardData?.image" class="vr-banner-boy" alt="">
          <img loading="lazy" src="../../assets/images/vr-vecoter left.png" class="vr-banner-left" alt="">
          <img loading="lazy" src="../../assets/images/vr-vecoter right.png" class="vr-banner-right" alt="">
        </div>
        <div class="col-lg-12 p-0 section-2">
          <div class="row">
            <div class="col-xl-7">
              <div class="popular-reads" style="background-image: url('../../assets/images/popular-deals-bg.png'); background-size: cover; border-radius: 14px;">
                <h1 class="">Popular Reads</h1>
                <!-- <img loading="lazy" class="popular-deals-bg" src="../../assets/images/popular-deals-bg.png" /> -->
                <div class="popular-reads-home-slider">
                  <app-popular-reads-slider></app-popular-reads-slider>
                </div>
              </div>
              <div class="newly-added mt-5" style="background-image: url('../../assets/images/newly-added-bg.png'); background-size: cover; border-radius: 14px;">
                <h1 class="">Newly Added</h1>
                <div>
                  <div class="newly-added-home-slider">
                    <app-newly-added-slider></app-newly-added-slider>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-5">
              <h1 class="our-picks-for-you-header text-left">
                Our Picks for You
              </h1>
              <div class="our-picks-for-you-section">
                <div class="our-picks-for-you" *ngFor="let data of topPicksForUser">
                  <div class="home-product-2
                    product-default
                    inner-quickview
                    img-border-radius
                    image-border-radius
                  ">
                    <figure class="img-border-radius image-border-radius slider-image slider-image-hoverable">
                      <div  style="position: relative; cursor: pointer;">
                        <a [routerLink]="['/book', 'explore', data._id, data?.genre[0]]">
                          <img
                            class="our-picks-for-you-img img-border-radius image-border-radius"
                            [src]="imageBaseUrl + data.cover_image"
                            onerror="this.src = 'assets/images/rectangle1.svg'" />
                          <div class="eighteen-plus-container" *ngIf="data.not_for_below_eighteen">
                            <h4 class="eighteen-plus">18+</h4>
                          </div>
                        </a>
                      </div>
                      <div class="btn-icons">
                        <button class="btn-icon btn-add-cart" data-toggle="modal" (click)="addToBookShelf(data)">
                          <i *ngIf="data.is_in_bookshelves === true" class="fa fa-bookmark bookshelve-book"></i>
                          <i *ngIf="data.is_in_bookshelves !== true" class="far fa-bookmark bookshelve-book"
                            aria-hidden="true"></i>
                        </button>
                      </div>
                    </figure>
                    <div>
                      <div class="product-details" >
                        <h2
                          class="product-title book-title m-0 text-truncate"
                          style="max-width: 140px;"
                          routerLink="/book/most_liked/{{ data._id }}/{{
                            data.genre
                          }}"
                        >
                          <a class="text-underline-on-hover book-title-text">{{ data.title }}</a>
                        </h2>
                        <h2 class="product-title book-title" routerLink="/authorprofile/{{ data.author_id }}">
                          <a class="font-italic text-underline-on-hover book-title-text author-name">@{{ data.author_username }}</a>
                        </h2>
                      </div>
                    </div>
                    <div class="ratings-container d-flex justify-content-between mt-2">
                      <div class="book-interation">
                        <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
                        <span class="views-hearts-shares-bookshelves">{{data?.views}}</span>
                      </div>
                      <div class="book-interation">
                        <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
                        <span class="views-hearts-shares-bookshelves">{{ data?.likes }}</span>
                      </div>
                      <div class="book-interation">
                        <mat-icon class="small color-cyan-secondary">share</mat-icon>
                        <span class="views-hearts-shares-bookshelves">{{ data?.no_of_shares }}</span>
                      </div>
                      <div class="book-interation">
                        <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
                        <span class="views-hearts-shares-bookshelves">{{ data?.no_of_chapter }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-container">
        <div class="duration-btn-block">
          <span class="duration-btn-1 border-right" [class.active-btn]="active_featured_duration === 'this_week'"
            (click)="fetchFeaturedSection('this_week')">This Week</span>
          <span class="duration-btn-2 border-right" [class.active-btn]="active_featured_duration === 'last_week'"
            (click)="fetchFeaturedSection('last_week')">Last Week</span>
          <span class="duration-btn-3 border-right" [class.active-btn]="active_featured_duration === 'last_month'"
            (click)="fetchFeaturedSection('last_month')">Last Month</span>
        </div>
      </div>
    </div>
    <div class="featured-books-writer-section">
      <div class="" style="width: 88%; margin: auto;">
        <div class="row featured-block m-2">
          <div class="col-xxl-6">
            <div class="featured-book-block">
              <h1>
                Featured Book
              </h1>
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div class="iq-card-body">
                  <div class="row align-items-center">
                    <div class="col-lg-5 col-md-4 pr-0">
                      <div  style="position: relative; cursor: pointer;">
                        <a href="javascript:void();"
                          routerLink="/book/explore/{{ featureBook?._id }}/{{featureBook?.genre }}"><img
                            *ngIf="featureBook?.cover_image !== null" class="img-fluid rounded w-100"
                            src="{{baseURl}}{{featureBook.cover_image}}" alt="" />
                          <img loading="lazy" class="img-fluid rounded w-100" *ngIf="featureBook?.cover_image === null"
                            src="{{baseURl}}{{noImage}}">
                        </a>
                        <div class="eighteen-plus-container" *ngIf="featureBook?.not_for_below_eighteen">
                          <h4 class="eighteen-plus">18+</h4>
                        </div>
                      </div>
                      <div class="ratings-container d-flex mt-2">
                        <div class="book-interation mr-4">
                          <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
                          <span class="views-hearts-shares-bookshelves">{{featureBook?.views}}</span>
                        </div>
                        <div class="book-interation mr-4">
                          <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
                          <span class="views-hearts-shares-bookshelves">{{featureBook?.likes}}</span>
                        </div>
                        <div class="book-interation mr-4">
                          <mat-icon class="small color-cyan-secondary">share</mat-icon>
                          <span class="views-hearts-shares-bookshelves">{{featureBook?.no_of_shares}}</span>
                        </div>
                        <div class="book-interation mr-4">
                          <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
                          <span class="views-hearts-shares-bookshelves">{{featureBook?.no_of_chapter}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-8 mt-3 mt-sm-0 fet-book-details-block">
                      <div class="book-block d-flex">
                        <img loading="lazy" src="../../assets/images/book-badge.png" />
                        <div>
                          <h4 class="mb-2">{{ featureBook?.title }}</h4>
                          <p class="mb-2">Author: {{ featureBook?.author_name }}</p>
                          <div>
                            <i style="color: #ffc107" *ngFor="let star of [1, 2, 3, 4, 5]" class="fa-star"
                              [ngClass]="star <= rating ? 'fas' : 'far'">
                            </i>
                          </div>
                        </div>
                      </div>
                      <h4>Synopsis</h4>
                      <span class="text-dark mb-3 d-block featured-book-synopsis"
                        style="padding-top: 2%; overflow: hidden; text-overflow: ellipsis;">{{
                        featureBook?.description }}</span>
                      <div class="book-info">
                        <div class="writer-info">
                          <h6>Writen by</h6>
                          <h5>{{ featureBook?.author_name }}</h5>
                        </div>
                        <div class="write-info">
                          <h6>Year</h6>
                          <h5>2019</h5>
                        </div>
                      </div>
                      <button type="submit" class="btn btn-sm learn-more" [routerLink]="[
                              '/readchapter',
                              featureBook?._id,
                              firstChapterID,
                              featureBook?.author_id
                            ]">
                        READ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-6" style="overflow: hidden;">
             <div class="swiper featured-writer-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let writer of featureWriter; let i = index">
                  <div class="featured-writer-block">
                    <h1>
                      Featured Writer
                    </h1>
                    <div class="row align-items-center feature-writer-data-container" style="padding: 20px; height: 491px;">

                      <div class="col-sm-12 col-md-12 col-lg-12  pr-0">
                        <div class="writer-block">
                          <img loading="lazy" *ngIf="writer?.image !== null" class="rounded w-100"
                            src="{{baseURl}}{{writer.image}}" alt="" />
                          <img loading="lazy" class="rounded w-100" *ngIf="writer?.image === null" src="{{baseURl}}{{noImage}}">
                          <div>
                            <h3 class="mb-2">{{ writer?.fullname }}</h3>
                            <p class="mb-2">Author: {{ writer?.fullname }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 pr-0">
                        <div class="writer-bio-block">
                          <h5>Bio</h5>
                          <p *ngIf="writer?.bio;else elseBlock ">
                            {{ writer?.bio}}
                          </p>
                          <ng-template #elseBlock>
                            <p></p>
                          </ng-template>
                        </div>
                      </div>
                      <div class="col-lg-6 pr-0">
                        <div class="writer-bio-block">
                        </div>
                        <app-books-by-writer [myStories]="writer.myStories"></app-books-by-writer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button class="featured-writer-swiper-button-prev mr-4"><mat-icon>arrow_back_ios</mat-icon></button>
                <button class="featured-writer-swiper-button-next"><mat-icon>arrow_forward_ios</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row ps-4 pe-4">
      <div class="home-container best-seller-slider swiper-book-gallery">
        <div class="col-lg-12 p-0 section-3">
          <div class="row">
            <div class="col-xl-3 p-0">
              <div class="sidebar-category px-4">
                <h3 class="sidebar-category-title">Popular Genres</h3>
                <div class="d-flex justify-content-center justify-content-sm-start flex-wrap gap-2">
                  <div class="sidebar-category-1 cursor-pointer" (click)="RedirectToExplor('Mystery')">
                    <h3>Mystery/ Thriller</h3>
                    <!-- <img loading="lazy" src="../../assets/images/item-1.png" class="sidebar-bg-1" /> -->
                  </div>
                  <div class="sidebar-category-2 cursor-pointer" (click)="RedirectToExplor('Romance')">
                    <h3>Romance</h3>
                    <!-- <img loading="lazy" src="../../assets/images/item-2.png" class="sidebar-bg-2" /> -->
                  </div>
                  <div class="sidebar-category-3 cursor-pointer" (click)="RedirectToExplor('Fantasy')">
                    <h3>Fantasy</h3>
                    <!-- <img loading="lazy" src="../../assets/images/item-3.png" class="sidebar-bg-3" /> -->
                  </div>
                  <div class="sidebar-category-4 cursor-pointer mb-2" (click)="RedirectToExplor('Werewolf')">
                    <h3>Werewolf</h3>
                    <!-- <img loading="lazy" src="../../assets/images/item-4.png" class="sidebar-bg-4" /> -->
                  </div>
                </div>
                <div class="best-seller-container sidebar-category-5" style="height: 564px; margin-bottom: 20px; padding-top: 20px; border-radius: 25px;">
                  <div class="sidebar-category-header">
                    <h1 class="mt-0">Best Seller</h1>
                    <h6>Based sales this week</h6>
                  </div>
                  <div class="">
                    <app-best-seller-slider></app-best-seller-slider>
                  </div>
                  <!-- <img loading="lazy" src="../../assets/images/item-5.png" class="sidebar-bg-5" /> -->
                </div>
                <div class="sidebar-category-6">
                  <div class="sidebar-category-6-block" style="max-width: 100%;">
                    <p>Experience reading in an immersive world with Librums VR in Occulus.</p>
                    <button>
                      LEARN MORE
                    </button>
                  </div>
                  <!-- <img loading="lazy" src="../../assets/images/item-6.png" class="sidebar-bg-6" /> -->
                </div>
              </div>
            </div>
            <div class="col-xl-9 p-0" style="position: relative;">
              <div style="position: relative; overflow: hidden;">
                <app-books-slider [title]="top_genre_list?.top_fantasy?.name" [fullSize]="" [listTipe]="'fantasy'" [id]="top_genre_list?.top_fantasy?.id" type="genre"></app-books-slider>
              </div>
              <div style="position: relative; overflow: hidden;">
                <app-books-slider [title]="top_genre_list?.top_mystery?.name" [fullSize]="" [listTipe]="'mystery'" [id]="top_genre_list?.top_mystery?.id" type="genre"></app-books-slider>
              </div>
              <div style="position: relative; overflow: hidden;">
                <app-books-slider [title]="top_genre_list?.top_romance?.name" [fullSize]="" [listTipe]="'romance'" [id]="top_genre_list?.top_romance?.id" type="genre"></app-books-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-container swiper-book-gallery mt-3">
        <div class="col-lg-12 p-0">
          <div class="">
            <app-favorite-reads></app-favorite-reads>
          </div>
        </div>
      </div>
      <div class="home-container swiper-book-gallery">
        <div style="position: relative; overflow: hidden;">
          <app-books-slider [fullSize]="'fullSize'" title="Literature and Fiction" [id]="top_genre_list?.top_literature?.id" type="genre"></app-books-slider>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- Footer END -->
<!-- color-customizer -->
<div class="iq-colorbox color-fix">
  <div class="buy-button">
    <!-- <a class="color-full" href="#"><i class="fa fa-spinner fa-spin"></i></a> -->
  </div>
  <div id="right-sidebar-scrollbar" class="iq-colorbox-inner">
    <div class="clearfix color-picker">
      <h3 class="iq-font-black">Booksto Awesome Color</h3>
      <p>
        This color combo available inside whole template. You can change on your wish, Even you can create your own with
        limitless possibilities!
      </p>
      <ul class="iq-colorselect clearfix">
        <li class="color-1 iq-colormark" data-style="color-1"></li>
        <li class="color-2" data-style="iq-color-2"></li>
        <li class="color-3" data-style="iq-color-3"></li>
        <li class="color-4" data-style="iq-color-4"></li>
        <li class="color-5" data-style="iq-color-5"></li>
        <li class="color-6" data-style="iq-color-6"></li>
        <li class="color-7" data-style="iq-color-7"></li>
        <li class="color-8" data-style="iq-color-8"></li>
        <li class="color-9" data-style="iq-color-9"></li>
        <li class="color-10" data-style="iq-color-10"></li>
        <li class="color-11" data-style="iq-color-11"></li>
        <li class="color-12" data-style="iq-color-12"></li>
        <li class="color-13" data-style="iq-color-13"></li>
        <li class="color-14" data-style="iq-color-14"></li>
        <li class="color-15" data-style="iq-color-15"></li>
        <li class="color-16" data-style="iq-color-16"></li>
        <li class="color-17" data-style="iq-color-17"></li>
        <li class="color-18" data-style="iq-color-18"></li>
        <li class="color-19" data-style="iq-color-19"></li>
        <li class="color-20" data-style="iq-color-20"></li>
      </ul>
      <a target="_blank" class="btn btn-primary d-block mt-3" href="">Purchase Now</a>
    </div>
  </div>
</div>
