import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private showNavbar = new BehaviorSubject<boolean>(true);
  currentShowNavbar = this.showNavbar.asObservable();

  setNavbar(val: boolean) {
    this.showNavbar.next(val);
  }
  getNavbar() {
    // return this.
  }

  constructor() {}
}
