import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { RoomService } from 'src/app/services/room/room.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.sass'],
})
export class NotificationPageComponent implements OnInit {
  constructor(
    private notification: NotificationService,
    private helper: HelperService,
    private userProfileService: UserProfileService,
    private router: Router,
    private notificationService: NotificationService,
    private roomService: RoomService,
    private toastService: ToastrService,
    private utils: UtilityService
  ) {
    this.baseimage = BaseUrl.image;
  }

  showLoader: boolean = true;
  baseimage: string;
  notifications: any;
  userData: any;
  page: number = 1;
  itemsPerPage:number = 10;
  total_unread_items: number = 0;
  total_items: number = 0;

  selectedTab;

  ngOnInit() {
    this.userData = this.helper.getUserData();
    this.getNotification();

    this.notification.tabIndexData.subscribe((res) => {
      this.selectedTab = res;
    });
  }

  getNotification(page: number = 1) {
    this.page = page;
    this.showLoader = true;
    this.notification.getNotification({limit: this.itemsPerPage, skip: (page - 1) * this.itemsPerPage}).subscribe(
    (res: any) => {
      if (res.status) {
        this.notifications = res?.data;
        this.total_unread_items = res?.total_unread_items;
        this.total_items = res?.total_items
        this.showLoader = false;
      }
    },
    (error) => {
      console.log('Error', error);
    }
    );
  }

  goToWall() {
    this.userProfileService.setTabData(1);
    this.router.navigate(['/profile/pro']);
  }

  viewNotification = (notification_id?: string) => {
    this.showLoader = true;
    this.notificationService.viewedNotification(notification_id).subscribe(
      (res: any) => {
        if (res.status) {
          if(!notification_id) {
            this.utils.openSanckbar({title: 'All Notifications have been marked as viewed'});
          }
          this.getNotification(notification_id ? this.page : 1);
        } else {
          this.utils.openSanckbar({title: 'Something went wrong'});
          this.showLoader = false;
        }
      },
      (err: any) => {
        this.showLoader = false;
        console.log('Error: ', err);
      }
    );
  };

  room_invite_action (notification: any, status: 'active' | 'declined') {
    this.roomService.notification_invite_room_action({ room_id: notification?.room_id, status }).subscribe((res: any) => {
      if(res?.status) {
        this.toastService.success(`Room invitation has been ${status === 'active' ? 'activated' : 'declined'} successfully`);
          this.getNotification();
        
      } else {
        this.toastService.warning(res?.message);
      }
    });
  }
}
