<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
    <div class="page-main">
        <!-- sidebar area -->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
                <div class="page-header">
                    <!-- <h4 class="page-title">View Message Board</h4> -->
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" >Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Reported Issues
                        </li>
                    </ol>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Reported Issues</div>
                            </div>
                            <div class="card-body">
                                <div class="">
                                    <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <table id="msgboardtable" datatable class="table row-border hover" *ngIf="temp"  role="grid" aria-describedby="example_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th class="wd-15p sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="First name: activate to sort column descending" style="width: 20px;">
                                                                #
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Last name: activate to sort column ascending" style="width: 100px;">
                                                                 User
                                                            </th>
                                                            <th class="wd-15p sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="First name: activate to sort column descending" style="width: 130px;">
                                                                Issue Type
                                                            </th>
                                                            <th class="wd-20p unsort" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" style="width: 420px;">
                                                                Message
                                                            </th>
                                                          
                                                            <th class="wd-15p unsort" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Start date: activate to sort column ascending" style="width: 50px;">
                                                                
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" class="odd" *ngFor="
                                                            let message of getreports;
                                                            let i = index
                                                            ">
                                                         
                                                                <td class="sorting_1">{{ i + 1 }}</td>
                                                                <td>{{ message.user_name }}</td>
                                                                <td style="text-transform: capitalize;">{{ (message.type == 'look_and_feel')?'Look and Feel':message.type }}</td>
                                                                <td><a [routerLink]="['/view-message-board',message._id]">{{ message.content | slice: 0:150 }}{{ message.content.length > 150 ? "..." : "" }}</a></td>
                                                                <td class="text-center">
                                                                        <a data-toggle="modal" data-target="#delete" (click)="onClick11({report_id: message._id})">
                                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                                        </a>
                                                                </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- table-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container block_user_popup">
    <!-- The Modal -->
    <div class="modal fade" id="delete" data-backdrop="false">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <!-- <button type="button" class="close" data-dismiss="modal">
            &times;
          </button> -->
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="block_user">
                        <h2 style="text-align: center;">Are you sure you want to delete this record?</h2>
                        <p>After you delete this record you will not able to get this.</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <a (click)="onClick(delId)" class="btn btn-primary pl-6 pr-6" data-dismiss="modal">
            Yes
          </a>
                    <a class="btn btn-light pl-6 pr-6" data-dismiss="modal">
            No
          </a>
                </div>
            </div>
        </div>
    </div>
</div>