<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
    <div class="page-main">
        <!-- sidebar area -->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
                <div class="page-header">
                    <!-- <h4 class="page-title">View Books Management</h4> -->
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Published Books
                        </li>
                    </ol>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Published Books</div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div id="example_wrapper"
                                        class="dataTables_wrapper dt-bootstrap4 no-footer mange_table_component">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="">
                                                    <table id="bookstable" datatable class="table row-border hover" *ngIf="temp"
                                                        role="grid"
                                                        aria-describedby="example_info">
                                                        <!-- <table datatable class="table row-border hover" *ngIf="this.temp"> -->
                                                        <thead>
                                                            <tr role="row">
                                                                <th class="wd-15p sorting_asc" tabindex="0"
                                                                    aria-controls="example" rowspan="1" colspan="1"
                                                                    aria-sort="ascending"
                                                                    aria-label="First name: activate to sort column descending"
                                                                    style="width: 50px;">
                                                                    # </th>
                                                                <th class="wd-15p sorting" tabindex="0"
                                                                    aria-controls="example" rowspan="1" colspan="1"
                                                                    aria-label="Last name: activate to sort column ascending"
                                                                    style="width: 672px;">
                                                                    Title
                                                                </th>
                                                              
                                                                <th class="wd-10p unsort"  style="width: 100px;">
                                                                    Is Blocked
                                                                </th>
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr role="row" class="odd" *ngFor="
                                                                    let manage of manageContent;
                                                                    let i = index
                                                                ">

                                                                <td class="sorting_1">{{ i + 1 }}</td>
                                                                <td>

                                                                    <div class="outer-div">
                                                                        <div class="left-sec">
                                                                            <template *ngIf="
                                                                            manage.cover_image;
                                                                            else note_img
                                                                          ">
                                                                                  <img loading="lazy" src="{{ baseimage }}{{
                                                                              manage.cover_image
                                                                            }}" alt="" style="height: 160px; width:120px;" />
                                                                              </template>
                                                                              <ng-template #note_img>
                                                                                  <div class="blank_dp">
                                                                                      <p>{{ manage.title }}</p>
                                                                                  </div>
                                                                              </ng-template>
                                                                        </div>
                                                                        <div class="right-sec">
                                                                    <div class="book-title-sec">
                                                                        <a
                                                                        [routerLink]="['/view-published-book',manage._id]">  {{ manage.title  }}</a>
                                                                                                                                    
                                                                    </div> 
                                                                       
                                                                        <div class="book-author">
                                                                            <div class="book-author-inner">
                                                                                <div class="book-author-name">
                                                                                   by <a [routerLink]="[
                                                                                   '/edit-users',
                                                                                   manage.author_id
                                                                               ]">{{ manage.author_name }}</a>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                </td>
                                                              
                                                                <td>    
                                                                    <div   *ngIf="manage.is_blocked === true" class="onoffswitch">
                                                                        <input   (change)="
                                                                        onClick({book_id: manage._id, status:'false'})"   type="checkbox" name="myonoffswitch_{{i}}"
                                                                            class="onoffswitch-checkbox"
                                                                            id="myonoffswitch_{{i}}" >
                                                                        <label class="onoffswitch-label" 
                                                                            for="myonoffswitch_{{i}}">
                                                                            <span class="onoffswitch-inner"></span>
                                                                            <span class="onoffswitch-switch"></span>
                                                                        </label>
                                                                    </div>
                                                                   
                                                                        <div   *ngIf="manage.is_blocked === false" class="onoffswitch">
                                                                            <input checked  (change)="
                                                                            onClick({book_id: manage._id, status:'true'})"   type="checkbox" name="myonoffswitch_{{i}}"
                                                                                class="onoffswitch-checkbox"
                                                                                id="myonoffswitch_{{i}}" >
                                                                            <label class="onoffswitch-label" 
                                                                                for="myonoffswitch_{{i}}">
                                                                                <span class="onoffswitch-inner"></span>
                                                                                <span class="onoffswitch-switch"></span>
                                                                            </label>
                                                                        </div>

                                                                </td>
                                                                
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- table-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container block_user_popup">
    <!-- The Modal -->
    <div class="modal fade" id="block_book">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="block_user">
                        <p>Are you sure want to {{blockTest}} this Book?</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <a (click)="onClick(bookId)" class="back_btn add_content" data-dismiss="modal">
                        Yes
                    </a>
                    <button (click)="onClose()" type="button" class="back_btn add_content" data-dismiss="modal">
                        No
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>