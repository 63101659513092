<div class="page">
    <div class="page-main">
        <!-- sidebar area -->
        <div class="app-content my-3 my-md-5 ">
            <div class="page-container ">
                <div class="page-header">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ">
                            <a routerLink="/dashboard">Dashboard </a>
                        </li>
                        <li class="breadcrumb-item active " aria-current="page ">
                            Users
                        </li>
                    </ol>
                </div>
                <div class="row ">
                    <div class="col-md-12 col-lg-12 ">
                        <div class="card ">
                            <div class="card-header">
                                <div class="card-title ">Users</div>
                            </div>
                            <div class="card-body">
                                <!-- <input class="w-100 p-3 form-control" style="border-radius: 6px" placeholder="Search users"> -->
                                <input class="input-box mb-4 mt-3 w-100 p-3" type="text" placeholder="Search users" maxlength="100" [formControl]="searchUser" />
                                <div id="example_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view">
                                    <div class="row ">
                                        <div class="col-sm-12">
                                            <div style="overflow-x:auto;">
                                                <table class="table row-border hover"
                                                    role="grid "
                                                    aria-describedby="example_info ">
                                                    <thead>
                                                        <tr role="row ">
                                                            <th class=" sorting_asc ">
                                                                #
                                                            </th>
                                                            <th class="sorting ">
                                                                Name
                                                            </th>
                                                            <th class="sorting ">
                                                                EMAIL
                                                            </th>
                                                            <th class="sorting">
                                                                Username
                                                            </th>
                                                            <th style="width:100px;" class="unsort">
                                                                    Is Blocked
                                                                </th>
                                                        
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="!isLoading">
                                                        <tr role="row " class="odd " *ngFor="
                                                            let usersdata of users | paginate: { id: 'user-list', itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems} ; let i = index ;">
                                                            <td class="sorting_1 ">{{ i + 1 }}</td>
                                                            <td><a [routerLink]="[
                                                                '/edit-users',
                                                                usersdata.value._id
                                                            ]">{{ usersdata.value.fullname }}</a></td>
                                                            <td>{{ usersdata.value.email }}</td>
                                                            <td>{{ usersdata.value.username }}</td>
                                                            <td> 
                                                                <div   *ngIf="usersdata.value.is_block === 2" class="onoffswitch">
                                                                    <input checked  (change)="
                                                                    onClick({
                                                                        user_id: usersdata.value._id,
                                                                        status: 1
                                                                    })"   type="checkbox" name="onoffswitch"
                                                                        class="onoffswitch-checkbox"
                                                                        id="myonoffswitch_{{i}}" >
                                                                    <label class="onoffswitch-label" 
                                                                        for="myonoffswitch_{{i}}">
                                                                        <span class="onoffswitch-inner"></span>
                                                                        <span class="onoffswitch-switch"></span>
                                                                    </label>
                                                                </div>
                                                                
                                                                <div   *ngIf="usersdata.value.is_block  ===1" class="onoffswitch">
                                                                    <input   (change)="
                                                                    onClick({
                                                                        user_id: usersdata.value._id,
                                                                        status: 2
                                                                    })"   type="checkbox" name="onoffswitch"
                                                                        class="onoffswitch-checkbox"
                                                                        id="myonoffswitch_{{i}}" >
                                                                    <label class="onoffswitch-label" 
                                                                        for="myonoffswitch_{{i}}">
                                                                        <span class="onoffswitch-inner"></span>
                                                                        <span class="onoffswitch-switch"></span>
                                                                    </label>
                                                                </div>
                                                                <div *ngIf="!usersdata.value.is_block" class="onoffswitch">
                                                                    <input  (change)="
                                                                    onClick({
                                                                        user_id: usersdata.value._id,
                                                                        status: 2
                                                                    })"   type="checkbox" name="onoffswitch"
                                                                        class="onoffswitch-checkbox"
                                                                        id="myonoffswitch_{{i}}" >
                                                                    <label class="onoffswitch-label" 
                                                                        for="myonoffswitch_{{i}}">
                                                                        <span class="onoffswitch-inner"></span>
                                                                        <span class="onoffswitch-switch"></span>
                                                                    </label>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="users?.length == 0">
                                                            <td colspan="5" class="text-center">
                                                                No users found!
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody *ngIf="isLoading">
                                                        <tr>
                                                            <td colspan="5">
                                                                <app-loader type="placeholder" height="23px"
                                                                    [placeholderLength]="itemsPerPage"
                                                                    borderRadius="4px"></app-loader>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <pagination-controls class="pagintion-data" id="user-list" (pageChange)="getUsers($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- table-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container block_user_popup">
    <!-- The Modal -->
    <div class="modal fade" id="block_user">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="block_user">
                        <p>Are you sure want to {{ blockTest }} this user?</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <a (click)="onClick(popId)" class="back_btn add_content" data-dismiss="modal">
                        Yes
                    </a>
                    <a class="back_btn add_content" (click)="onClose()" data-dismiss="modal">
                        No
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
