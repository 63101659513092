<div class="page">
    <div class="page-main">
        <!-- Sidebar menu-->
        <div class="app-content  my-3 my-md-5" *ngIf="getPage">
            <div class="page-container">
                    <div class="page-header">
                    <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/manage-pages']"> Pages</a></li>
                            <li class="breadcrumb-item active" aria-current="page">
                                {{getPage.title}}
                            </li>
                        </ol>
                    </div>
                <div class="">
                    <div class="card">
                        <div *ngIf="showMsg">
                            <p class="alert alert-success">
                                <strong>{{ showMsg }}</strong>
                            </p>
                        </div>
                        <div *ngIf="errorMsg">
                            <p class="alert alert-danger">
                                <strong>{{ errorMsg }}</strong>
                            </p>
                        </div>
                        <div class="card-header">
                                <div class="card-title">Edit Page Content</div>
                        </div>
                        <div class="card-body">
                        <form [formGroup]="updateForm" class="issue_report" (ngSubmit)="onSubmit()">
                            <div class="heading_form">
                               
                            </div>
                            <div class="form-group input_txt">
                                <input type="hidden" [ngModel]="getPage._id" placeholder="Page Id" formControlName="page_id" class="form-control" id="page_id" />
                            </div>
                            <div class="form-group input_txt" >
                                <ejs-richtexteditor  formControlName="content" [ngModel]="getPage.content"  placeholder="Click here to start writing" required [ngClass]="{ 'is-invalid': submitted && f.content.errors }">
                                    </ejs-richtexteditor>
                                <div *ngIf="submitted && f.content.errors" class="invalid-feedback">
                                    <div *ngIf="f.content.errors.required">
                                        Content is required
                                    </div>
                                </div>
                            </div>
                            <div class="container">
                                <div class="">
                                    <div class="">
                                        <div class="save text-center">
                                            <button [disabled]="loading" class="site_btn">
                        Update
                      </button>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div class="save text-right">
                                            <a [routerLink]="['/manage-pages']" class="save_btn">Back</a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>