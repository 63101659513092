<div class="mt-5 mr-5 ml-5">
  <!-- Modal Header -->
  <div>
    <button type="button" class="close" data-dismiss="modal">
      <img
        src="../../assets/Slider/images/icons/Gradient/Close button@3x.svg"
        (click)="close()"
        alt=""
      />
    </button>
  </div>
  <!-- Modal body -->
  <div style="font-family: baskervillebold">
    <h1 style="display: flex">
      <img
        class="share-it"
        src="../../assets/Slider/images/icons/Gradient/Share gradient@3x.svg"
        alt=""
      />Share
    </h1>
    <p>
      We have a great range of books for people of all age groups. If you wish
      to for example, you may want to share an interesting article, either by
      emailing it to friends or tweeting it to the world. If you have a family
      of avid readers, good news: Librums makes it pretty easy to share books
      with every member of your family.
    </p>
    <!-- <p>
        On a mobile device, sharing should be as simple as clicking the Share button, choosing an app, and choosing
        who to share with. For example, you may want to share an interesting article, either by emailing it to friends
        or tweeting it to the world.
      </p> -->
    <div class="">
      <div class="share_icons_flex">
        <a
          href="https://www.facebook.com/sharer/sharer.php?u=https://testing.librums.com&quote=I want to share the love of reading books with Librums. Go to https://testing.librums.com or download from App store/Google Play store directly."
          target="_blank"
          rel="noopener"
        >
          <i class="fa fa-facebook-official" aria-hidden="true"></i>
        </a>
        <a
          href="https://twitter.com/share?url=https://testing.librums.com&text=I want to share the love of reading books with Librums. Go to https://testing.librums.com or download from App store/Google Play store directly."
          onclick="window.open(this.href);return false;"
        >
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a
          href="https://www.linkedin.com/shareArticle?mini=true&url=https://testing.librums.com&title=Librums&summary=I want to share the love of reading books with Librums. Go to https://testing.librums.com or download from App store/Google Play store directly.&source=LinkedIn"
          onclick="window.open(this.href);return false;"
        >
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <a
          href="http://pinterest.com/pin/create/button/?url=https://testing.librums.com/&description=I want to share the love of reading books with Librums. Go to https://testing.librums.com or download from App store/Google Play store directly."
          onclick="window.open(this.href);return false;"
        >
          <i class="fa fa-pinterest-p" aria-hidden="true"></i>
        </a>
        <a
          href="mailto:?Subject=Librums:: Share book with your family&amp;Body=I want to share the love of reading books with Librums. Go to https://testing.librums.com or download from App store/Google Play store directly. https://testing.librums.com"
          onclick="window.open(this.href);return false;"
        >
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </a>
        <a
          href="https://web.whatsapp.com/send?text=I want to share the love of reading books with Librums. Go to https://testing.librums.com or download from App store/Google Play store directly. https://testing.librums.com"
          data-action="share/whatsapp/share"
          onclick="window.open(this.href);return false;"
          ><i class="fa fa-whatsapp" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <!-- <button type="button" class="site_btn2" data-dismiss="modal">
  CANCEL
</button> -->
</div>
