// tslint:disable-next-line: quotemark
import { FormGroup } from "@angular/forms";

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function WhiteSpace(controlName: string) {
  return (formGroup: FormGroup) => {
  const control = formGroup.controls[controlName];
  if (control.errors && !control.errors.whiteSpace) {
    // return if another validator has already found an error on the matchingControl
    return;
  }
  if (control.value.startsWith(' ')) {
    control.setErrors({ whiteSpace: true });
    return;
  }
  if (control.value.endsWith(' ')) {
    control.setErrors({ whiteSpace: true });
    return;
  }
  control.setErrors(null);
  return;
};
}
