import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookDataService } from '../services/book-data.service';
import { nonCoverflowSwiperConfig } from '../swiper-config';
import { Swiper } from 'swiper';
import * as _ from 'underscore';
import { SearchService } from './search.service';
import { ExploreService } from '../explore/explore.service';
import { NgForm } from '@angular/forms';
import { BaseUrl } from '../base-url';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { forEach } from 'underscore';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.sass'],
})
export class SearchPageComponent implements OnInit {
  @ViewChild('tagPill') tagPill: ElementRef;
  public Data: any;
  private result: any;
  public seachText: string;
  public selectedAll: boolean = true;
  tagHandler(index: number) {
    this.tempTagList.every((elem: any, i: number) => {
      if (elem['isActive'] === true) {
        elem['isActive'] = false;
      } else if (i === index) {
        elem['isActive'] = true;
      }
      return true;
    });
  }

  public tempTagList: any = [];

  baseimage;
  searchCriteria = '';

  constructor(
    private bookData: BookDataService,
    private searchService: SearchService,
    private exploreService: ExploreService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.baseimage = BaseUrl.image;
    this.seachText = ''
  }

  public columns: any = '2';
  public rows: any = '60em';
  public InitialColumn: boolean = true;
  public setColumnTo: any = '2';
  public tagList: Array<any> = [];
  public filterTag: String = '';
  public selectedItemGanre: string = 'all';

  setColumn(cols: any, rowHeight: any) {
    this.columns = cols;
    this.rows = rowHeight;

    if (this.columns == '2') {
      this.InitialColumn = true;
    } else {
      this.InitialColumn = false;
    }
  }

  setRows(row: String) {
    this.rows = row;
  }

  filterSearchByTag(tag: any, index: number) {
    this.Data = this.result.filter((element: any) => {
      return (
        element['tags'].filter((tags: any) => {
          if (tags['index'] === index) {
            tags['isActive'] = true;
            return tags['tagName'] === index && element;
          }
        }) && element
      );
    });

    if (tag.tagName == 'All') {
      this.bookData.getBookData().subscribe((result: any) => {
        this.result = result;
        this.Data = this.result;
        this.tagList.push('All');
        _.map(result, (element: any) => {
          this.tagList.push(...element['tags']);
        });
      });
    }
  }

  pageNumber: number = 0;

  loadMore() {
    if (this.searchByInput) {
      this.searchService
        .loadMoreBooks({
          genre_name: this.searchByInput,
          genre_id: this.searchInputId,
          page_no: this.pageNumber,
          search: this.searchByInput,
        })
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.pageNumber = this.pageNumber + 10;
              for (let i = 0; i < this.returnData.length; i++) {
                if (this.returnData[i].type == 'data') {
                  for (
                    let index = 0;
                    index < this.returnData[i].value.length;
                    index++
                  ) {
                    if (
                      this.returnData[i].value[index].tag == this.searchByInput
                    ) {
                      let resultArr = [];
                      resultArr = this.returnData[i].value[index].data.concat(
                        res.data
                      );
                      this.returnData[i].value[index].data = resultArr;
                    }
                  }
                }
              }
            }
          },
          (error) => {
            console.log('ERROR', error);
          }
        );
    } else {
      this.exploreService
        .loadMoreBooks({
          genre_name: this.searchByInput,
          genre_id: this.searchInputId,
          type: 'explore',
          page_no: this.pageNumber,
        })
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.pageNumber = this.pageNumber + 10;
              for (let i = 0; i < this.returnData.length; i++) {
                if (this.returnData[i].type == 'data') {
                  for (
                    let index = 0;
                    index < this.returnData[i].value.length;
                    index++
                  ) {
                    if (
                      this.returnData[i].value[index].tag == this.searchByInput
                    ) {
                      let resultArr = [];
                      resultArr = this.returnData[i].value[index].data.concat(
                        res.data.data
                      );
                      this.returnData[i].value[index].data = resultArr;
                    }
                  }
                }
              }
            }
          },
          (error) => {
            console.log('ERROR', error);
          }
        );
    }
  }

  ngAfterViewInit() {
    const swiper = new Swiper('.genre-slider-container', {
      // slidesPerView: 5,
      // spaceBetween: 20,
      observer: true,
      loop: true,
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        1000: {
          slidesPerView: 6,
          spaceBetween: 20,
        },
      },
    });
  }

  searchList;
  nodatafound;
  errorMsg;
  genreDetails;

  tag__: boolean;
  searchByInput: string = '';
  searchInputId: string = '';

  searchBookByGenre(search: string, id?: string) {
    this.loaded = false;
    this.tag__ = false;
    this.seachText = search
    this.searchByInput = search.toLowerCase();
    this.searchInputId = id;
    this.searchService
      .searchBook({
        device_type: 'web',
        search: search,
      })
      .subscribe((res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        this.searchResult = mapped;
        this.returnData = this.parseData(this.searchResult);
        this.loaded = true;
        this.tempTagList = res.tags;
        let tempsList = res.tags;
        let tagsList = []

        const newList = this.tempTagList.map((el)=> el.name)
        newList.forEach((el)=>{
          if(!tagsList.includes(el)){
            tagsList.push(el)
          }
        })
        this.tempTagList = tagsList.map((el)=>{
          return {name:el, isActive: false}
        })
        this.tempTagList.forEach((element: any) => {
          if (element.name === search) {
            element['isActive'] = true;
          } else {
            element['isActive'] = false;
          }
        });
        this.selectedItemGanre = search
        this.tag__ = true;
        this.nodatafound = false;
        setTimeout(() => {
          const tagSwiper = new Swiper('.tag-list-container', {
            loop: false,
            observer: true,
            spaceBetween: 20,
            breakpoints: {
              400: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1000: {
                slidesPerView: 8,
                spaceBetween: 20,
              },
            },
          });
        }, 0);
      });
  }

  onSubmit(form: NgForm) {
    if (form.value.searchInput !== '') {
      this.seachText = form.value.searchInput
      this.loaded = false;
      this.tag__ = false;
      this.searchByInput = form.value.searchInput;

      this.searchService
        .searchBook({
          device_type: 'web',
          search: form.value.searchInput,
        })
        .subscribe((res: any) => {
          const mapped = Object.entries(res.data).map(([type, value]) => ({
            type,
            value,
          }));

          this.searchResult = mapped;
          this.returnData = this.parseData(this.searchResult);
          console.log(this.returnData)
          this.loaded = true;
          this.tempTagList = res.tags;
          this.tag__ = true;
          this.nodatafound = false;

          setTimeout(() => {
            const tagSwiper = new Swiper('.tag-list-container', {
              loop: false,
              slidesPerView: 8,
              spaceBetween: 30,
              observer: true,
            });
          }, 0);
        });
    }
  }

  returnData = [];
  searchResult = [];
  listOfTegs = [];
  loaded: boolean = false;

  backgroundColor = ['rgba(216, 118, 121, 0.2)', 'rgba(99, 121, 196, 0.2)'];

  ngOnInit(): void {

    this.bookData.getBookData().subscribe((result: any) => {
      this.result = result;
      this.Data = this.result;
      this.tagList.push({ tagName: 'All', index: 0, isActive: true });
      var count = 0;
      _.map(result, (element: any) => {
        element['tags'] = _.map(element['tags'], (elem: string) => {
          count++;
          return {
            tagName: elem,
            index: count,
            isActive: false,
          };
        });
        this.tagList.push(...element['tags']);
      });
    });
    this.listOfTegs = this.tagList
    console.log('this.tagList', this.tagList)
    for(var i = 0; i<this.tagList.length; i++){
      console.log(this.tagList[i])
    }
    this.columns = window.innerWidth <= 400 ? 1 : 2;
    this.rows = window.innerWidth <= 400 ? '49em' : '30em';

    this.exploreService.getGenreDetails().subscribe((res: any) => {
      const indexOfAll = res.data.findIndex((elem: any) => elem.name === 'All');

      res.data.splice(0, 0, res.data.splice(indexOfAll, 1)[0]);
      this.genreDetails = res.data;
    });

    const { search, id } = this.activatedRoute.snapshot.queryParams;    if (!search) {
      this.loaded = true;
      this.searchBookByGenre('all', '1a')
    } else {
      this.searchByInput = search
      this.searchBookByGenre(search, id);
    }
  }
  getDataOnChanges(changes: string) {
    this.seachText = changes
    this.loaded = false;
    this.tag__ = false;
    this.searchService
      .searchBook({
        device_type: 'web',
        search: changes,
      })
      .subscribe((res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        this.searchResult = mapped;
        this.returnData = this.parseData(this.searchResult);
        this.loaded = true;
        this.tempTagList = res.tags;
        this.tag__ = true;
        this.nodatafound = false;

        setTimeout(() => {
          const tagSwiper = new Swiper('.tag-list-container', {
            loop: false,
            slidesPerView: 8,
            spaceBetween: 30,
            observer: true,
          });
        }, 0);
      });
  }

  private parseData = (data: any, tagName?: string) => {
    let abc = [];
    data.filter((NewStories) => {
      if (NewStories.type == 'data') {
        NewStories.value.filter((data) => {
          data.data.filter((d) => {
            if (tagName) {
              if (d.tags.includes(tagName)) {
                abc.push(d);
              }
            } else {
              abc.push(d);
            }
          });
        });
      }
    });

    return abc;
  };

  filterByTags(tagName: string, index: number) {
    this.loaded = false;
    if(tagName === 'all' && index === 0){
      this.selectedAll = true
      this.returnData = this.parseData(this.searchResult)
      this.tagHandler(10000);
    }
    else{
      this.selectedAll = false
      setTimeout(() => {
        this.tagHandler(index);
        this.returnData = this.parseData(this.searchResult, tagName);
      }, 350);
    }
    this.loaded = true;
  }
}
