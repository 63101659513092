import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as crypto from 'crypto-js';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ViewChapterContentService {
  base: any;
  baseapi: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
    this.baseapi = BaseUrl.imageApi;
  }
  contentdata: any;
  usersimage: any;

  getChapterDetail(id) {
    this.contentdata = localStorage.getItem("currentUser")
    let token = JSON.parse( crypto.AES.decrypt(this.contentdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getChapterDetail`,
      { chapter_id: id },
      {
        headers
      }
    );
  }

  updateChapter(data) {
    this.contentdata = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": this.contentdata.data.token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `updateChapter`, data, {
      headers
    });
  }

  // tslint:disable-next-line: align
  chapter_image(image) {
    this.usersimage = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": this.usersimage.data.token
    });
    return this.http.post(this.baseapi + `chapter_image`, image, {
      headers
    });
  }
}
