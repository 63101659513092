import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../auth.guard';
import { BaseUrl } from '../base-url';
import { NavbarService } from '../nav-sidebar/navbar.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.sass'],
})
export class AboutUsComponent implements OnInit {
  constructor(private http: HttpClient, private authGaurd: AuthGuard, private navbarService: NavbarService) {
    this.baseurl = BaseUrl.frontend;
    this.loggedIn = this.authGaurd.userLoggedin();
  }
  private baseurl: string;

  public loggedIn: boolean;

  htmlPage;
  ngOnInit(): void {
    this.http.get(`${this.baseurl}/aboutus`).subscribe((result: any) => {
      this.htmlPage = result.data.content;
      // this.htmlPage = '<h1>About Us</h1><br/ >' + this.htmlPage;
    });
  }
}
