import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { VideoDetails } from '../video-details';
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
declare var $:any;
@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {
  @Input() result: VideoDetails;
  @Output() greetMessage = new EventEmitter<string>();
  videoCode:any;
  videoURL: any;
  safeURL: any;
  constructor(private router: Router,private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  openVideo(){
    console.log(this.result.videoUrl)
  }
  handleClick(id) {
    if($("#rich-edit").val() == 'true'){
      this.videoURL="https://www.youtube.com/embed/"+id;
      var html = ' <iframe src="'+ this.videoURL+'" frameborder="0" scrolling="no"></iframe>';
      $(".e-content").append(html);
      $("#toolbar_Image").modal('hide');
      $("#rich-edit").val('false');
    }else{
      localStorage.setItem('videoid', id);
     // alert('Video added successfully')
      // location.reload();
      $("#videoid").val(id);
      $(".e-content").empty();
             
      this.videoURL="https://www.youtube.com/embed/"+id;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(id);
      $(".hello").hide();
      $("#abc_frame").show()
      $("#abc_frame").attr('src', this.videoURL);
      $("#videoModel").modal('hide');
    }
  }

}
