<!-- <div class="wrapper"> -->
<!-- <div id="content-page" class="content-page"> -->
<div class="col-lg-12">
  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
    <div
      class="
        iq-card-header
        d-flex
        justify-content-between
        align-items-center
        position-relative
      "
    >
      <div class="iq-header-title">
        <h4 class="card-title mb-0 section-heading"> Newest books in our library </h4>
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <!-- (click)="gotoBookDetails(0)" -->
        <a
          routerLink="/book/{{ bookData?.value?.tab }}/{{ bookData?.value?.data[0]?._id}}/{{bookData?.value?.data[0]?.genre[0]}}"
          class="btn btn-sm view-more global-button"
          style="height: 35px"
        >
          <span style="font-size: 13px"> View More </span>
        </a>
      </div>
    </div>
    <div
      class="wrapper"
      style="
        background-image: linear-gradient(
          45deg,
          rgba(209, 0, 42, 0.6) 0%,
          #0e5dc4 100%
        );
        margin: 20px;
        overflow:hidden
      "
    >
      <!-- <div class="background"></div> -->

      <div class="item-bg" style="left: -10px"></div>

      <div class="news-slider" *ngIf="bookData?.value?.data">
        <div class="news-slider__wrp swiper-wrapper">
          <div
            class="news-slider__item swiper-slide"
            *ngFor="let data of bookData?.value?.data; let i = index"
          >
            <!-- let data of [
              'https://res.cloudinary.com/muhammederdem/image/upload/v1537132205/news-slider/item-1.jpg',
              'https://res.cloudinary.com/muhammederdem/image/upload/v1537132205/news-slider/item-2.jpg',
              'https://res.cloudinary.com/muhammederdem/image/upload/v1537132205/news-slider/item-3.jpg',
              'https://res.cloudinary.com/muhammederdem/image/upload/v1537132205/news-slider/item-5.jpg',
              'https://res.cloudinary.com/muhammederdem/image/upload/v1537132205/news-slider/item-4.jpg'
            ] -->
            <!-- (click)="gotoBookDetails(i)" -->
            <a
              routerLink="/book/{{ bookData.value.tab }}/{{ data._id }}/{{
                data.genre
              }}"
              class="news__item"
            >
              <div class="news-date">
                <span class="news-date__title">{{data.created_at | date: "dd"}}</span>
                <span class="news-date__txt">{{data.created_at | date: "MMM"}}</span>
              </div>
              <div class="news__title">{{ data.title }}</div>

              <p
                class="
                  news__txt
                  font-italic
                  product-title
                  text-underline-on-hover
                "
                routerLink="/authorprofile/{{ data.author_id }}"
              >
                <!-- {{ data.author_name }} -->
                @{{ data.author_username }}
              </p>

              <div
                class="news__img"
                [style.backgroundImage]="
                  'url(' + baseimage + data.cover_image + ')'
                "
                style="background-size: 101%; height: 200px"
              >
                <!-- <img
                  [src]="
                    'https://librums.com/backend/testing/uploads/books/1588453200463_.png'
                  "
                  alt="news"
                /> -->
              </div>
            </a>
          </div>
        </div>

        <div class="news-slider__ctr">
          <div class="news-slider__arrows" style="padding-bottom: 1px">
            <button class="news-slider__arrow news-slider-prev">
              <span class="icon-font">
                <svg class="icon icon-arrow-left">
                  <use xlink:href="#icon-arrow-left"></use>
                </svg>
              </span>
            </button>
            <button class="news-slider__arrow news-slider-next">
              <span class="icon-font">
                <svg class="icon icon-arrow-right">
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </span>
            </button>
          </div>

          <div class="news-slider__pagination"></div>
        </div>
      </div>
    </div>

    <svg hidden="hidden">
      <defs>
        <symbol id="icon-arrow-left" viewBox="0 0 32 32">
          <title>arrow-left</title>
          <path
            d="M0.704 17.696l9.856 9.856c0.896 0.896 2.432 0.896 3.328 0s0.896-2.432 0-3.328l-5.792-5.856h21.568c1.312 0 2.368-1.056 2.368-2.368s-1.056-2.368-2.368-2.368h-21.568l5.824-5.824c0.896-0.896 0.896-2.432 0-3.328-0.48-0.48-1.088-0.704-1.696-0.704s-1.216 0.224-1.696 0.704l-9.824 9.824c-0.448 0.448-0.704 1.056-0.704 1.696s0.224 1.248 0.704 1.696z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-right" viewBox="0 0 32 32">
          <title>arrow-right</title>
          <path
            d="M31.296 14.336l-9.888-9.888c-0.896-0.896-2.432-0.896-3.328 0s-0.896 2.432 0 3.328l5.824 5.856h-21.536c-1.312 0-2.368 1.056-2.368 2.368s1.056 2.368 2.368 2.368h21.568l-5.856 5.824c-0.896 0.896-0.896 2.432 0 3.328 0.48 0.48 1.088 0.704 1.696 0.704s1.216-0.224 1.696-0.704l9.824-9.824c0.448-0.448 0.704-1.056 0.704-1.696s-0.224-1.248-0.704-1.664z"
          ></path>
        </symbol>
      </defs>
    </svg>
  </div>
</div>
<!-- </div> -->
<!-- </div> -->
