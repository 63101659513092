import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class BookDetailService {
  private headers;
  private baseurl;
  constructor(private helper: HelperService, private http: HttpClient) {
    this.headers = this.helper.getHeader();
    this.baseurl = BaseUrl.frontend;
  }

  private slider = new BehaviorSubject<Object>(null);
  public sliderData = this.slider.asObservable();

  private bookReviewId = new BehaviorSubject<string>('');
  public bookReviewIdData = this.bookReviewId.asObservable();

  public setBookReviewId = (book_id: string) => {
    this.bookReviewId.next(book_id);
  };

  getBookById = (book_id: string) => {
    return this.http.post(
      `${this.baseurl}/getBookById`,
      { book_id },
      { headers: this.headers }
    );
  };

  getBookById_V1 = (payload: {book_id: string, also_like?: boolean, reviews?: boolean}) => {
    return this.http.post(
      `${this.baseurl}/v1/getBookById`, payload,
      { headers: this.headers }
    );
  };

  getAuthorStories(data: any) {
    return this.http.post(`${this.baseurl}/getAuthorStories`, data, {
      headers: this.headers,
    });
  }

  setSliderData(data: any) {
    this.slider.next(data);
  }

  getBookDetail(postData) {
    return this.http.post(this.baseurl + `/v1/getBookDetail`, postData, {
      headers: this.headers,
    });
  }

  getAlsoLike() {
    return this.http.post(
      this.baseurl + `/getAlsoLikesSec`,
      {},
      {
        headers: this.headers,
      }
    );
  }

  addToBookShelf(postData) {
    return this.http.post(this.baseurl + '/addToBookshelf', postData, {
      headers: this.headers,
    });
  }

  removeToBookShelf(postData) {
    return this.http.post(this.baseurl + '/removeFromBookshelf', postData, {
      headers: this.headers,
    });
  }

  getGenre = () => {
    return this.http.post(`${BaseUrl.admin}/getGenres`, null, {
      headers: this.headers,
    });
  };

  createSketchBook = (data: any) => {
    const userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );

    const headers = new HttpHeaders({
      'x-access-token': decryptUser.data.token,
    });
    return this.http.post(`${this.baseurl}/createSketchBook`, data, {
      headers,
    });
  };

  createVideoBook = (data: any) => {
    const userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );

    const headers = new HttpHeaders({
      'x-access-token': decryptUser.data.token,
    });
    return this.http.post(`${this.baseurl}/createVideoBook`, data, {
      headers,
    });
  };

  getSketchBooks = (id: string) => {
    return this.http.post(
      `${this.baseurl}/getSketchBooks`,
      { id },
      { headers: this.headers }
    );
  };

  getVideBooks = (id: string) => {
    return this.http.post(
      `${this.baseurl}/getVideoBooks`,
      { id },
      { headers: this.headers }
    );
  };

  getSketchBookByAuthorId = () => {
    return this.http.get(`${this.baseurl}/getSketchBookByAuthorId`, {
      headers: this.headers,
    });
  };
  getVideoBookByAuthorId = () => {
    return this.http.get(`${this.baseurl}/getVideoBookByAuthorId`, {
      headers: this.headers,
    });
  };
}
