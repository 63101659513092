import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private globalUser = new BehaviorSubject<object>(null);
  public globalUserData = this.globalUser.asObservable();

  private globalHome = new BehaviorSubject<object>(null);
  public globalHomeData = this.globalHome.asObservable();
  public globalWriteState = false
  setglobalUserData(data) {
    this.globalUser.next(data);
  }

  setGlobalHomeData(homeData) {
    this.globalHome.next(homeData);
  }
  constructor() {}
}
