import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IProductQuickView } from '../Interface/product-quick-view';
import { IFavoriteReads } from '../Interface/favorite-reads';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBrowseBooks } from '../Interface/browse-books';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class BookDataService {
  constructor(private httpClient: HttpClient) { }

  private url: any = '/assets/data/book-data.json';
  private url_2: any = '/assets/data/book-quick-view.json';
  private favoriteReadsUrl: any = '/assets/data/favorite-reads.json';
  private browseBooksUrl: any = '/assets/data/browse-books.json';

  removeDuplicateTags(element: any) {
    return element;
  }

  getBookData() {
    return this.httpClient.get(this.url);
  }

  getProductQuickViewData(): Observable<IProductQuickView[]> {
    return this.httpClient.get<IProductQuickView[]>(this.url_2);
  }

  getFavoriteBooks(): Observable<IFavoriteReads[]> {
    return this.httpClient.get<IFavoriteReads[]>(this.favoriteReadsUrl);
  }

  getBrowseBooks(): Observable<IBrowseBooks[]> {
    return this.httpClient.get<IBrowseBooks[]>(this.browseBooksUrl);
  }

  likeBooks(body: any) {
    return this.httpClient.post('https://reqres.in/api/users', body);
  }

  loadPage(url: string) {
    return this.httpClient.get(`${url}`);
  }

  getBookRecommendation(book_id: string) {
    return this.httpClient.get(`${environment.baseUrl}/recommendation/getBookReceommendation`, { params: { book_id, limit: '10' }, headers: this.tokenHeader });
  }

  getUserRecommendation(user_id: string) {
    return this.httpClient.get(`${environment.baseUrl}/recommendation/getUserReceommendation`, { params: { user_id, limit: '9' }, headers: this.tokenHeader });
  }

  getNewlyAddedBooks(limit:Number,skip:Number) {
    return this.httpClient.post(`${environment.baseUrl}/v1/getNewlyAddedBooks`,{"limit": limit,"skip": skip}, { headers: this.tokenHeader });
  }

  getBookOverview(book_id: string) {
    return this.httpClient.get(`${environment.baseUrl}/v1/getBookOverview`, {params: {book_id}});
  }

  getBestSellingBooks(limit:Number,skip:Number) {
    return this.httpClient.post(`${environment.baseUrl}/v1/getBestSellingBooks`,{"limit": limit,"skip": skip}, { headers: this.tokenHeader });
  }

  getPopularBooks(limit) {
    return this.httpClient.post(`${environment.baseUrl}/v1/getPopularBooks`, { "limit": limit }, { headers: this.tokenHeader });
  }

  getTopBooksByGenre(genreId: string) {
    return this.httpClient.get(`${environment.baseUrl}/getTopBooksByGenre`, { params: { genreId }, headers: this.tokenHeader });
  }

  getHightlighetedBooks () {
    return this.httpClient.get(`${environment.baseUrl}/getHighlightedBooks`);
  }

  get tokenHeader(): HttpHeaders {
    const bookdata = localStorage.getItem("currentUser")
    let token = JSON.parse(crypto.AES.decrypt(bookdata, environment.secretKey).toString(crypto.enc.Utf8)).data.token
    return new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
  }
}
