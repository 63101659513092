import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from './base-url';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class MyWallService {
  userdata: any;
  _baseURL: any;
  headers;
  constructor(private http: HttpClient, private helper: HelperService) {
    this._baseURL = BaseUrl.frontend;
    this.headers = this.helper.getHeader();
  }
  getMyPost(pageNo) {
    return this.http.post(this._baseURL + `/getMyPost`, pageNo, {
      headers: this.headers,
    });
  }
  removeWallPost(data) {
    return this.http.post(this._baseURL + `/deletePost`, data, {
      headers: this.headers,
    });
  }
}
