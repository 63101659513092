import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { ImageDialogComponent } from 'src/app/image-dialog/image-dialog.component';
import { ImageService } from 'src/app/image-editor-canvas/image.service';
import { PostMessageService } from 'src/app/post-message.service';
import { SeoserviceService } from 'src/app/seoservice.service';
import { AlertService } from 'src/app/services/alert.service';
import Swiper from 'swiper';
import { AuthorProfileService } from '../author-profile/author-profile.service';
import { ImagePostComponent } from './image-post/image-post.component';
@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.sass'],
})
export class WallComponent implements OnInit {
  @Input() authorData: any;

  userData: any;
  uploaded_image: string;
  submitted: boolean = false;
  authordata;
  baseimage;
  showLoader: boolean;
  loading;
  userid;
  postimage;
  showImage: boolean = true;
  content: string = '';
  constructor(
    private dialog: MatDialog,
    private helper: HelperService,
    private postMessage: PostMessageService,
    private toaster: ToastrService,
    private authorService: AuthorProfileService,
    private _seoService: SeoserviceService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private postmessage: PostMessageService,
    private imageService: ImageService
  ) {
    this.baseimage = BaseUrl.image;
  }

  openImage(postImage, uploadedImage) {
    this.dialog.open(ImagePostComponent, {
      data: { postImage, uploadedImage },
      height: 'auto',
      width: 'auto',
      maxHeight: '700px',
      maxWidth: '800px',
    });
  }

  onSelectFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dialogRef = this.dialog.open(ImageDialogComponent, {
        data: { url: reader.result, uploadedImage: true, crop: true, aspectRatio: 4/2 },
        height: 'auto',
        width: 'auto',
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        this.uploaded_image = result.data;
        let formData = new FormData();
        this.showImage = false;

        const blob = this.imageService.b64toBlob(result?.data);
        formData.append('image', blob)

        this.postmessage.post_image(formData).subscribe(
          (res: any) => {
            if (res) {
              if (res.status) {
                this.postimage = res.data;
                this.showImage = true;
              }
            }
          },
          (error) => {
            console.log('ERROR');
          }
        );
      });
    };
    reader.readAsDataURL(file);
  }

  removeImage(){
    this.postimage = '';
    this.uploaded_image = '';
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      form.value.user_id = this.userid;
      form.value.post_image = this.postimage ? this.postimage : '';
      this.postMessage.savePost(form.value).subscribe(
        (res: any) => {
          if (res.status) {
            this.toaster.success('Post updated successfully.');
            this.loading = false;
            this.loadAuthorData();
            this.uploaded_image = '';
            this.postimage = '';
            form.resetForm();
          } else {
            this.toaster.error(res.message);
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
    } else {
      this.submitted = true;
      return;
    }
  }

  loadAuthorData() {
    this.authorService.getAuthor(this.userid).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        if (res.data.cover_image) {
          res.data.coverimage = this.baseimage + '' + res.data.cover_image;
        } else {
          res.data.coverimage = 'assets/img/bg_place_holder.jpg';
        }
        this.authorData = res.data;
        this._seoService.updateTitle(this.authordata?.username || 'Librums');
        this.showLoader = false;
      },
      (error) => {
        console.log('ERROR');
      }
    );
  }

  ngOnInit(): void {
    this.userData = this.helper.getUserData().data;
    this.userid = this.activatedRoute.snapshot.params.id;
  }
}
