<breadcrumb #parent class="breadcrumb-parent">
  <ol class="breadcrumb">
    <ng-template ngFor let-route let-last="last" [ngForOf]="parent.breadcrumbs">
      <li class="breadcrumb-item" [class.active]="last">
        <a [class.breadcrumb-active]="last" [routerLink]="[route.url]">{{
          route.displayName
        }}</a>
      </li>
    </ng-template>
  </ol>
</breadcrumb>
