import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
interface userListInterface {
  name: string;
  value: string;
}

@Component({
  selector: 'app-chapter-preview',
  templateUrl: './chapter-preview.component.html',
  styleUrls: ['./chapter-preview.component.sass'],
})
export class ChapterPreviewComponent implements OnInit {
  chapterData: any;
  safeurl: any;
  is_white = 'color_white';
  constructor(private _sanitizer: DomSanitizer) {}

  userlist: Array<userListInterface>;

  ngOnInit() {
    this.userlist = [
      { name: 'Robo To Bold', value: 'robotobold' },
      { name: 'aileronlight', value: 'aileronlight' },
      { name: 'Inconsolata Medium', value: 'inconsolatamedium' },
      { name: 'Comic Reliefregular', value: 'comic_reliefregular' },
      { name: 'Halo Regular', value: 'haloregular' },
      { name: 'Droid Serifregular', value: 'droid_serifregular' },
      {
        name: 'Noname Nocontrast Serif V1Rg',
        value: 'noname_nocontrast_serif_v1Rg',
      },
      {
        name: 'UCU Charles Script Regular',
        value: 'ucu_charles_scriptregular',
      },
      { name: 'Geometric Sans Serif V1Rg', value: 'geometric_sans_serif_v1Rg' },
      { name: 'Press Start 2Pregular', value: 'press_start_2pregular' },
      { name: 'The God Father Regular', value: 'the_godfatherregular' },
    ];

    let pages = localStorage.getItem('previewPage');
    let pageContent = pages.replace('class="medium-insert-buttons-show"','class="medium-insert-buttons-show" style="display:none"');
    this.chapterData = JSON.parse(pageContent);
    if (this.chapterData.video !== 'about:blank') {
      this.safeurl = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.chapterData.video
      );
    } else {
      this.safeurl = '';
    }
    $('.medium-insert-buttons').remove();
  }

  ngAfterViewInit() {
    $('.change_chapter_color_black p').css('font-family', 'Roboto, "Helvetica Neue", sans-serif');
  }

  changeLayout(item) {
    $('.change_chapter_color_black').removeClass('color_black');
    $('.change_chapter_color_black').removeClass('color_wheat');
    $('.change_chapter_color_black').removeClass('color_white');

    $('.change_chapter_color_black').addClass(item);
  }
  onFontSize(size) {
    $('.change_chapter_color_black p').css(
      'font-size',
      size.target.value + 'px'
    );
  }
  onFontChange(font) {
    $('.change_chapter_color_black p').css('font-family', font.target.value);
  }
}
