import { Component, Input, OnInit } from '@angular/core';
import Swiper from 'swiper';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { GlobalService } from '../global.service';
import { Router } from '@angular/router';
import { BookDetailService } from '../book-detail/book-detail.service';
import { BaseUrl } from '../base-url';
@Component({
  selector: 'app-books-by-writer',
  templateUrl: './books-by-writer.component.html',
  styleUrls: ['./books-by-writer.component.sass']
})
export class BooksByWriterComponent implements OnInit {
  @Input() public myStories
  public baseimage;

  public showView: boolean = false;

  public slickInit(e: any) {
    console.log('slick initialized');
  }
  public breakpoint(e: any) {
    console.log('breakpoint');
  }
  public afterChange(e: any) {
    console.log('afterChange');
  }
  public beforeChange(e: any) {
    console.log('beforeChange');
  }

  public slideConfigbs = {
    dots: false,
    infinite: true,
    variableWidth: true,
    // responsive: [
    //   {
    //     breakpoint: 1400,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     }
    //   },
    //   {
    //     breakpoint: 991,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     }
    //   },
    //   {
    //     breakpoint: 680,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       centermod:true,
    //     }
    //   },
    // ]
  };

  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.baseimage = BaseUrl.image;
  }

  showQuickView() {
    if (this.showView) {
      this.showView = false;
    } else {
      this.showView = true;
    }
  }

  openBookDescription(book: any) {
    console.log("books => ", book);
    localStorage.setItem('descriptionData', JSON.stringify(book));
    this.router.navigate(['book', 'explore', book?.data?._id, book?.relatedBooks?.genre_id]);

  }

  ngAfterViewInit() {
    // nonCoverflowSwiperConfig('.swiper2');
    var swiper = new Swiper('.featured-by-writer-swiper', {
      spaceBetween: 15,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        dynamicBullets: true
      },
      lazy: true,
      initialSlide: 2,
      grabCursor: false,
      slidesPerView: 2,
      mousewheel: false,
      slidesPerGroup: 1,
      centeredSlides: true,
      navigation: {
        nextEl: '.featured-by-writer-swiper-button-next',
        prevEl: '.featured-by-writer-swiper-button-prev',
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 3,
        },
      },
      observer: true,
    });
  }

  ngOnInit(): void {
  }
}
