import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Swiper } from 'swiper';
import * as $ from 'jquery';
import { GlobalService } from '../global.service';
import { BookDetailService } from '../book-detail/book-detail.service';
import { Router } from '@angular/router';
import { BaseUrl } from '../base-url';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.sass'],
})
export class ShareComponent implements OnInit {
  baseimage;
  constructor(
    private globalService: GlobalService,
    private bookDetailService: BookDetailService,
    private router: Router
  ) {
    this.baseimage = BaseUrl.image;
  }
  @Input('bookData') bookData;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookData) {
      const value = changes.bookData.currentValue;
    }
  }
  ngAfterViewInit() {
    var bg = document.querySelector('.item-bg');
    var items = document.querySelectorAll('.news__item');
    var item = document.querySelector('.news__item');

    function cLog(content) {}

    if ($(window).width() > 800) {
      $(document).on('mouseover', '.news__item', function (_event, _element) {
        var newsItem = document.querySelectorAll('.news__item');
        newsItem.forEach(function (element, index) {
          element.addEventListener('mouseover', function () {
            var x = this.getBoundingClientRect().left;
            var y = this.getBoundingClientRect().top;
            var width = this.getBoundingClientRect().width;
            var height = this.getBoundingClientRect().height;

            $('.item-bg').addClass('active');
            $('.news__item').removeClass('active');

            $(bg).css({ width: `${width}px` });
            $(bg).css({ height: `${height}px` });
            var y_: number;
            if ($(window).width() <= 500) {
              y_ = 193;
            } else if ($(window).width() >= 1000) {
              y_ = 135;
            }

            $(bg).css({
              transform: `translateX(${x}px) translateY(${y_}px)`,
            });
          });

          element.addEventListener('mouseleave', function () {
            $('.item-bg').removeClass('active');
            $('.news__item').removeClass('active');
          });
        });
      });
    }

    var swiper = new Swiper('.news-slider', {
      // effect: 'coverflow',
      grabCursor: true,
      loop: false,
      // centeredSlides: true,
      keyboard: true,
      spaceBetween: 0,
      slidesPerView: 5,
      speed: 300,
      // coverflowEffect: {
      //   rotate: 0,
      //   stretch: 0,
      //   depth: 0,
      //   modifier: 3,
      //   slideShadows: false,
      // },
      // breakpoints: {
      //   300: {
      //     slidesPerView: 1,
      //   },
      //   480: {
      //     spaceBetween: 0,
      //     centeredSlides: true,
      //   },
      //   580: {
      //     slidesPerView: 1,
      //   },
      //   1000: {
      //     slidesPerView: 4,
      //   },
      // },
      // simulateTouch: true,
      navigation: {
        nextEl: '.news-slider-next',
        prevEl: '.news-slider-prev',
      },
      pagination: {
        el: '.news-slider__pagination',
        clickable: true,
      },
      on: {
        init: function () {
          var activeItem = document.querySelector(
            '.news-slider__item.swiper-slide-active'
          );
        },
      },
    });

    swiper.on('touchEnd', function () {
      $('.news__item').removeClass('active');
      $('.swiper-slide-active .news__item').addClass('active');
    });

    swiper.on('slideChange', function () {
      $('.news__item').removeClass('active');
    });

    swiper.on('slideChangeTransitionEnd', function () {
      $('.news__item').removeClass('active');
      var activeItem = document.querySelector(
        '.news-slider__item.swiper-slide-active'
      );

      var sliderItem = activeItem.querySelector('.news__item');

      $('.swiper-slide-active .news__item').addClass('active');

      var x = sliderItem.getBoundingClientRect().left;
      var y = sliderItem.getBoundingClientRect().top;
      var width = sliderItem.getBoundingClientRect().width;
      var height = sliderItem.getBoundingClientRect().height;

      $('.item-bg').addClass('active');

      $(bg).css({ width: `${width}px` });
      $(bg).css({ height: `${height}px` });
      var _y: number;
      if ($(window).width() <= 500) {
        _y = 193;
      } else if ($(window).width() >= 1000) {
        _y = 135;
      }

      $(bg).css({
        transform: `translateX(${x}px) translateY(${_y}px)`,
      });
    });
  }

  gotoBookDetails(index: number) {
    this.bookDetailService.setSliderData({
      data: this.bookData.value.data,
      index: index,
    });

    this.router.navigate(['/book']);
  }
  ngOnInit(): void {
  }
}
