import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Swiper, { SwiperOptions } from 'swiper';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { BookDataService } from '../services/book-data.service';
import { BaseUrl } from '../base-url';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-best-seller-slider',
  templateUrl: './best-seller-slider.component.html',
  styleUrls: ['./best-seller-slider.component.sass']
})
export class BestSellerSliderComponent implements OnInit {

  public slides = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];

  public slideConfigbs = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    centerMode: false,
  };

  public addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }
  public removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  public slickInit(e: any) {
    // console.log('slick initialized');
  }
  public breakpoint(e: any) {
    // console.log('breakpoint');
  }
  public afterChange(e: any) {
    // console.log('afterChange');
  }
  public beforeChange(e: any) {
    // console.log('beforeChange');
  }

  public bookData;
  bestSellingBooks: Array<any>;
  public baseimage = BaseUrl.image;
  public bookDetails = [
    {
      BackgroundImage:
        '../../assets/Slider/images/slider/home_slides/arts-photography.png',
      ImageSrc:
        '../../assets/Slider/images/slider/home_slides/arts-photography-book.png',
      Category: 'Arts & Photography',
      NumberOfBooks: 12,
      Author: 'Wendy Walker',
      LastUpdatedOn: 'Nov 1, 2020',
      Views: 2,
      Hearts: 1,
      Shares: 1,
      Bookshelves: 1,
    },
    {
      BackgroundImage:
        '../../assets/Slider/images/slider/home_slides/business-investing.png',
      ImageSrc:
        '../../assets/Slider/images/slider/home_slides/business-investing-book.png',
      Category: 'Business & Investing',
      NumberOfBooks: 98,
      Author: 'Daron K. Roberts',
      LastUpdatedOn: 'Nov 1, 2020',
      Views: 2,
      Hearts: 1,
      Shares: 1,
      Bookshelves: 1,
    },
    {
      BackgroundImage:
        'https://portotheme.com/html/porto_ecommerce/demo_27/assets/images/slider/home_slides/literature-fiction.png',
      ImageSrc:
        '../../assets/Slider/images/slider/home_slides/literature-fiction-book.png',
      Category: 'Literature & Fiction',
      NumberOfBooks: 25,
      Author: 'Mary Beth Keane',
      LastUpdatedOn: 'Nov 1, 2020',
      Views: 2,
      Hearts: 1,
      Shares: 1,
      Bookshelves: 1,
    },
    {
      BackgroundImage:
        '../../assets/Slider/images/slider/home_slides/mystery-suspense.png',
      ImageSrc:
        '../../assets/Slider/images/slider/home_slides/mystery-suspense-book.png',
      Category: 'Mystery Suspense',
      NumberOfBooks: 4,
      Author: 'Dot Hutchison',
      LastUpdatedOn: 'Nov 1, 2020',
      Views: 2,
      Hearts: 1,
      Shares: 1,
      Bookshelves: 1,
    },
    {
      BackgroundImage:
        '../../assets/Slider/images/slider/home_slides/scifi-fantasy.png',
      ImageSrc:
        '../../assets/Slider/images/slider/home_slides/scifi-fantasy-book.png',
      Category: 'Scifi Fantasty',
      NumberOfBooks: 3,
      Author: 'Margaret Rogerson',
      LastUpdatedOn: 'Nov 1, 2020',
      Views: 2,
      Hearts: 1,
      Shares: 1,
      Bookshelves: 1,
    },
  ];

  public showView: boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private bookDataService: BookDataService,
    private toastrService: ToastrService
  ) { }

  showQuickView() {
    if (this.showView) {
      this.showView = false;
    } else {
      this.showView = true;
    }
  }

  openBookDescription(book: any) {
    console.log("books => ", book);
    localStorage.setItem('descriptionData', JSON.stringify(book));
    this.router.navigate(['book', 'explore', book?.data?._id, book?.relatedBooks?.genre_id]);

  }

  ngAfterViewInit() {
    var nely_swiper = new Swiper('.best-seller-swiper', {
      slidesPerView: 1,
      spaceBetween: 20,
      grabCursor: true,
      slidesPerGroup: 1,
      observer: true,
      navigation: {
        nextEl: '.best-seller-swiper-button-next',
        prevEl: '.best-seller-swiper-button-prev',
      },
    });
  }

  ngOnInit(): void {
    this.getBestSellerBooks();
  }

  getBestSellerBooks() {
    this.bookDataService.getBestSellingBooks(10, 0).subscribe((res: {status: boolean, data: any, message: string}) => {
      if(res?.status) {
        this.bestSellingBooks = res?.data;
      } else {
        this.toastrService.error(res?.message || 'Something went wrong');
      }
    }, (err) => {
      console.log(err);
      this.toastrService.error(err?.message || 'Something went wrong');
      this
    });
  }

}
