import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BaseUrl } from 'src/app/base-url';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent implements OnInit {
  @Input('notificationData') notificationData: any;

  baseimage: string;
  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.baseimage = BaseUrl.image;
  }

  public userNotifications = [
    {
      image: '../../assets/Home/images/user/01.jpg',
      name: 'Emma Watson Barry',
      days: 'Just Now',
      data: '95 MB',
    },
    {
      image: '../../assets/Home/images/user/02.jpg',
      name: 'New customer is join',
      days: '5 days ago',
      data: 'Cyst Barry',
    },
    {
      image: '../../assets/Home/images/user/03.jpg',
      name: 'Two customer is left',
      days: '2 days ago',
      data: 'Cyst Barry',
    },
    {
      image: '../../assets/Home/images/user/04.jpg',
      name: 'New Mail from Fenny',
      days: '3 days ago',
      data: 'Cyst Barry',
    },
    {
      image: '../../assets/Home/images/user/04.jpg',
      name: 'New Mail from Fenny',
      days: '3 days ago',
      data: 'Cyst Barry',
    },
  ];
  public userNotification: any;

  newNotifications: boolean = false;
  numberOfNewNotifications: number = 0;

  ngOnChanges(change: SimpleChanges) {
    if (change.notificationData.currentValue) {
      let ND = change.notificationData.currentValue;
      this.hasNewNotification(ND);
      this.userNotification = this.notifications(ND);
      this.userNotification = this.userNotification.splice(0, 4);
    }
  }

  private notifications = (data: any) => {
    let notificationsArray = [];
    data.every((element) => {
      element.data.every((elem) => {
        if (
          elem.type == 'New book' ||
          elem.type == 'follow' ||
          elem.type == 'like' ||
          elem.type == 'chapter'
        ) {
          notificationsArray.push(elem);
          this.numberOfNewNotifications++;
        }
        return true;
      });

      if (notificationsArray.length > 0) {
        this.newNotifications = true;
      }

      return true;
    });

    // this.newNotifications = true;
    return notificationsArray;
  };

  public hasNewNotification = (data) => {
    data.forEach((element: any, index: number) => {
      element.data.forEach((elem) => {
        if (
          elem.type == 'New book' ||
          elem.type == 'follow' ||
          elem.type == 'like' ||
          elem.type == 'chapter'
        ) {
          this.newNotifications = true;
          return;
        }
      });
    });
  };

  goto() {
    this.notificationService.setActiveTab(0);
    this.router.navigate(['notification']);
  }
  ngOnInit(): void {}
}
