<div *ngIf="!showFinal" class="cover-mage-contaiter-block" style="background-color: white; border-radius: 10px;" >
  <div class="h-100 d-flex justify-content-center md-0 editor-content-container">
    <h1 class="page-label">Add cover image</h1>
  </div>
  <div class="d-flex justify-content-center">
    <div
      class="canvas-image-text-cover"
      style="
        width: 270px;
        height: 370px;
        background-size: cover;
        object-fit: cover;
      "
      [style.background-image]="'url(' + canvasUrl + ')'"
      [style.text-decoration]="underline ? 'underline' : 'none'"
      [style.color]="color"
      [style.font-size]="fontSize + 'px'"
      [style.font-family]="selectedValue"
      [style.display]="alignItems ? 'flex' : ''"
      [style.align-items]="alignItems ? 'center' : ''"
      [style.justify-content]="justifyContent"
      #BookTitleElement
    >
      <div appFreeDragging (XYCord)="getXYCord($event)">
        <span>
          {{ bookTitle }}
        </span>
      </div>
    </div>
  </div>


  <div class="w-100 d-flex justify-content-center">
    <div class="d-flex flex-column align-items-center " style="width: auto">
      <div class="label_input mt-3">
        <label class="input-label text-center m-0" for="">Enter text on book cover</label>
        <span class="image-text-hint mb-3">(You can move the text around the image, just click on the text and move)</span>
        <input
          type="text"
          [(ngModel)]="bookTitle"
          placeholder="Type..."
          class="input-cover-text"
        />
      </div>
      <div class="d-flex">
        <div class="d-flex flex-column">
          <span class="input-label">Select colour:</span>
          <mat-form-field class="select-color p-0 border-0">
            <input matInput [(colorPicker)]="color" [style.background]="color" class="color-input" />
          </mat-form-field>
        </div>
        <div class="from_inside custom_height">
          <div class="input-label d-flex flex-column">
            <label class="input-label ml-2">Font Type</label>
            <select [(ngModel)]="selectedValue" (selectionChange)="changeFont()" class="select-font">
              <option value="robotobold">Roboto</option>
              <option value="aileronlight">Aileronlight</option>
              <option value="inconsolatamedium">Inconsolata Medium</option>
              <option value="comic_reliefregular">Comic Reliefregular</option>
              <option value="haloregular">Halo Regular</option>
              <option value="droid_serifregular">Droid Serifregular</option>
              <option value="royalty_free">Royalty Free</option>
              <option value="noname_nocontrast_serif_v1Rg">
                Noname Nocontrast Serif V1Rg
              </option>
              <option value="ucu_charles_scriptregular">
                UCU Charles Script Regular
              </option>
              <option value="geometric_sans_serif_v1Rg">
                Geometric Sans Serif V1Rg
              </option>
              <option value="press_start_2pregular">Press Start 2Pregular</option>
              <option value="the_godfatherregular">The God Father Regular</option>
            </select>
          </div>
        </div>

      </div>
      <div class="input_range" style="width: 100%; margin: 10px 0 10px 0">
        <input
          type="range"
          (change)="changeFontSize($event)"
          min="1"
          max="50"
          class="slider1"
          value="{{ fontSize }}"
        />
        <div class="lines">
          <ul class="d-flex" style="margin-left: 10px">
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <button
        class="write-button-save"
        color="primary"
        (click)="reset('title')"
        style="border-radius: 0 0 0 10px; background-color: #333333;"
      >
        Back
    </button>
    <button
        class="write-button-save"
        color="primary"
        (click)="Next()"
        style="border-radius: 0 0 10px 0;"
      >
        Next
    </button>
  </div>
</div>

<div class="final__result" [style.display]="!showCanvas ? 'none' : 'flex'">
  <div class="d-flex justify-content-center mt-5 mt-md-0" style="padding-top: 40px;">
    <h1 class="page-label">Add cover image</h1>
  </div>
  <canvas #textCanvas style="width: 270px; height: 370px; margin-top: 20px;"></canvas>
  <div *ngIf="showCanvas" class=" final-result">
    <button
      class="write-button-save"
      style="border-radius: 41px !important"
      (click)="reset('finish')"
      style="border-radius: 0 0 0 10px; background-color: #333333;"
    >
      Back
    </button>
    <button
      class="d-none d-sm-block write-button-save"
      style="border-radius: 41px !important"
      (click)="saveAndUpload()"
      style="border-radius: 0 0 10px 0;"
    >
      Save & Upload
    </button>
    <button
      class="d-block d-sm-none write-button-save"
      style="border-radius: 41px !important"
      (click)="saveAndUpload()"
      style="border-radius: 0 0 10px 0;"
    >
      Upload
    </button>
  </div>
</div>
