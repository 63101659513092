<form #profileDetails="ngForm" (ngSubmit)="Save()">
  <div
    class="
      iq-card-header
      d-flex
      justify-content-between
      align-items-center
      mb-0
    "
  >
    <div class="iq-header-title d-flex">
      <h1 class="card-title mb-0">Personal Details</h1>
      <i
        *ngIf="!editMode"
        class="ml-5 mt-3 fas fa-edit mr-2 icon-color"
        style="cursor: pointer"
        (click)="Edit()"
      ></i>
    </div>
  </div>
  <div class="iq-card-body">
    <ul class="list-inline p-0 mb-0">
      <li>
        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-sm-6">
            <h2>Username <span class="required-field-icon">*</span></h2>
          </div>
          <div class="col-sm-6">
            <p class="mb-0" *ngIf="!editMode">@{{ profileDetail.username }}</p>
            <div *ngIf="editMode">
              <mat-form-field>
                <input
                  required
                  name="username"
                  ngModel
                  type="text"
                  [(ngModel)]="profileDetail.username"
                  matInput
                  #username="ngModel"
                />
              </mat-form-field>
              <span
                *ngIf="username.touched && !username.valid"
                class="text-danger"
                >Enter Valid Username</span
              >
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-sm-6">
            <h2>Name <span class="required-field-icon">*</span></h2>
          </div>
          <div class="col-sm-6">
            <p class="mb-0" *ngIf="!editMode">{{ profileDetail.fullname }}</p>
            <div *ngIf="editMode">
              <mat-form-field>
                <input
                  required
                  name="location"
                  ngModel
                  type="text"
                  [(ngModel)]="profileDetail.location"
                  matInput
                  #location="ngModel"
                />
              </mat-form-field>
              <span
                *ngIf="location.touched && !location.valid"
                class="text-danger"
                >Enter Valid Name</span
              >
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-sm-6">
            <h2>Gender <span class="required-field-icon">*</span></h2>
          </div>
          <div class="col-sm-6">
            <p class="mb-0" *ngIf="!editMode">{{ profileDetail.gender }}</p>
            <div *ngIf="editMode">
              <mat-form-field>
                <input
                  required
                  name="gender"
                  ngModel
                  type="text"
                  [(ngModel)]="profileDetail.gender"
                  matInput
                  #gender="ngModel"
                />
              </mat-form-field>
              <span *ngIf="gender.touched && !gender.valid" class="text-danger"
                >Enter Valid gender</span
              >
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-sm-6">
            <h2>Email <span class="required-field-icon">*</span></h2>
          </div>
          <div class="col-sm-6">
            <p
              class="mb-0"
              [innerHtml]="profileDetail.email"
              *ngIf="!editMode"
            ></p>
            <div *ngIf="editMode">
              <mat-form-field>
                <input
                  required
                  name="email"
                  ngModel
                  type="text"
                  [(ngModel)]="profileDetail.email"
                  matInput
                  #email="ngModel"
                />
              </mat-form-field>
              <span *ngIf="email.touched && !email.valid" class="text-danger"
                >Enter Valid email</span
              >
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-sm-6">
            <h2>Date Of Birth <span class="required-field-icon">*</span></h2>
          </div>
          <div class="col-sm-6">
            <p class="mb-0" *ngIf="!editMode">{{ profileDetail.dob }}</p>
            <div *ngIf="editMode">
              <mat-form-field>
                <input
                  required
                  name="dob"
                  ngModel
                  type="text"
                  [(ngModel)]="profileDetail.DOB"
                  matInput
                  #dob="ngModel"
                />
              </mat-form-field>
              <span *ngIf="dob.touched && !dob.valid" class="text-danger"
                >Enter Valid Date Of Birth</span
              >
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-sm-6">
            <h2>Bio <span class="required-field-icon">*</span></h2>
          </div>
          <div class="col-sm-6 profile-author-bio">
            <p class="mb-0" *ngIf="!editMode">{{ profileDetail.bio }}</p>
            <div *ngIf="editMode">
              <mat-form-field>
                <input
                  required
                  name="dob"
                  ngModel
                  type="text"
                  [(ngModel)]="profileDetail.DOB"
                  matInput
                  #dob="ngModel"
                />
              </mat-form-field>
              <span *ngIf="dob.touched && !dob.valid" class="text-danger"
                >Enter proper bio</span
              >
            </div>
          </div>
        </div>
      </li>
      <button
        *ngIf="editMode"
        class="ml-5 mt-3 btn btn-lg global-button"
        color="warn"
        (click)="Save()"
        [disabled]="!profileDetails.valid"
      >
        Save
      </button>
    </ul>
  </div>
</form>
