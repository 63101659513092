<img
  style="width: 270px; height: 360px; object-fit: cover"
  *ngFor="let url of imageUrl; let i = index; let last = last"
  [class.superimpose-image]="last && twoImages"
  [style.opacity]="last && twoImages ? value : 1"
  [style.filter]="
    last && twoImages ? 'brightness(' + brightness + '%)' : 'brightness(100%)'
  "
  [src]="url"
  alt=""
/>

<div class="mt-5" *ngIf="transparency">
  <h3>Brightness</h3>
  <mat-slider
    class="w-100"
    [disabled]="disabled"
    [max]="max_brightness"
    [min]="min_brightness"
    [step]="step_brightness"
    [thumbLabel]="thumbLabel"
    [tickInterval]="tickInterval_brightness"
    [(ngModel)]="brightness"
  >
  </mat-slider>
  <h3>Transparency</h3>
  <mat-slider
    class="w-100"
    [disabled]="disabled"
    [invert]="invert"
    [max]="max"
    [min]="min"
    [step]="step"
    [thumbLabel]="thumbLabel"
    [tickInterval]="getSliderTickInterval()"
    [(ngModel)]="value"
  >
  </mat-slider>

  <button
    class="btn btn-sm global-button next"
    color="primary"
    (click)="writeText()"
  >
    Next
  </button>
</div>
