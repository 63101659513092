<div id="content-page" class="content-page mt-8">
  <div class="container-fluid">
    <app-loader *ngIf="loader"></app-loader>

    <!-- Book detail slider -->

    <app-book-detail-slider [authorBookDetails]="authorBookDetails" *ngIf="!loader"></app-book-detail-slider>
    <!-- Similar Books Section -->
    <div style="margin: auto;">
      <app-review *ngIf="!loader"></app-review>
    </div>
    <div style="position: relative;">
      <app-similar-books class="book-sections" *ngIf="!loader"></app-similar-books>
    </div>

    <!-- Trendy Books Section -->
    <!-- <app-trendy-books class="book-sections" *ngIf="!loader"></app-trendy-books> -->

    <!-- Favorite Reads Section -->
    <!-- <app-favorite-reads
      style="width: 100%"
      *ngIf="!loader"
    ></app-favorite-reads> -->
  </div>
</div>
<app-footer></app-footer>
