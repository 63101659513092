import { Component, OnInit, Pipe, AfterViewChecked, Renderer2, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrl } from "../base-url";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { ShareBookDialogComponent } from '../share-book-dialog/share-book-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavbarService } from '../nav-sidebar/navbar.service';
import { TransactionService } from '../services/transaction.service';
import { AuthenticationService } from '../authentication.service';
import { HelperService } from '../helper.service';
import { StarRatingComponent } from 'ng-starrating';
import { BookDataService } from '../services/book-data.service';
import { AuthGuard } from '../auth.guard';
import { SeoserviceService } from '../seoservice.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { filter, first } from 'rxjs/operators'
import { ReadChapterService } from '../read-chapter/read-chapter.service';
import { EditstoriesService } from '../edit-stories/editstories.service';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
@Component({
  selector: 'app-read-book',
  templateUrl: './read-book.component.html',
  styleUrls: ['./read-book.component.sass']
})
export class ReadBookComponent implements OnInit {
  url: string;
  path: string;
  pdf_url: string;
  public isCollapsedNew = true;
  public isCollapsed = true;
  public briefText;
  public fullText;
  public showFullText = false;
  public textIsBrief = false;
  previosChapter: any;
  currentDate = new Date();
  chapterData: any;
  previousUrl: any;
  commentForm: FormGroup;
  commentData: any;
  submitted = false;
  is_white = 'color_white';
  book_id: string;
  chapter_id: string;
  baseURL: any;
  videoURL: any;
  activeClass: any;
  xchaptermsg: any;
  errormsg = false;
  showSuccess: any;
  showError: any;
  amount: any;
  userdata1: any;
  userdata: any;
  userImage: any;
  title: any;
  created_by: any;
  like_msg: any;
  is_subscribed = false;
  timeZoneOffset: any;
  fontFamily: any;
  fontSize: any;
  bookData: any;
  firstTime = true;
  bookTitle: any;
  chapterTitle: any;
  showLoader = true;

  is_purchased: boolean = false;
  buttonLoading: boolean = false;
  show_purchase_options: boolean = false;
  amountOfChupter: any;
  chapterList: any;
  nextChapter: any;
  a;
  @ViewChild('settings') settings: ElementRef;
  @ViewChild('settingsBlock') settingsBlock: ElementRef;
  @ViewChild('selectFonts') selectFonts: ElementRef;
  @ViewChild('background1') background1: ElementRef;
  @ViewChild('background2') background2: ElementRef;
  @ViewChild('background3') background3: ElementRef;
  @ViewChild('fontSizeText') fontSizeText: ElementRef;
  @ViewChild('labelInput') labelInput: ElementRef;
  @ViewChild('listBackgrounds') listBackgrounds: ElementRef;
  @ViewChild('labelFonts') labelFonts: ElementRef;
  @ViewChild('collapseElementBlock') collapseElementBlock: ElementRef;
  @ViewChild('buttonHandler') buttonHandler: ElementRef;
  @ViewChild('titleHandler') titleHandler: ElementRef;
  @ViewChild('imgHandler') imgHandler: ElementRef;
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent: PdfViewerComponent;

  initLoadCompleted(pdf: PDFDocumentProxy): void {
    this.pdfComponent.pdfViewer.scroll.down = false;
  }
  constructor(
    // private activatedRoute: ActivatedRoute,
    private editStoriesService: EditstoriesService,
    // private _snackbar: MatSnackBar,
    private chapterService: ReadChapterService,
    private renderer: Renderer2,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authoService: AuthenticationService,
    private _sanitizer: DomSanitizer,
    private _snackbar: MatSnackBar,
    private authguard: AuthGuard,
    private toastr: ToastrService,
    private _seoService: SeoserviceService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private bookDataService: BookDataService,
    // private shareDialog: ShareBookDialogComponent,
    private dialog: MatDialog,
    private navbar: NavbarService,
    private router: Router,
    private readChapterService: ReadChapterService,
    private transactionService: TransactionService,
    private helper: HelperService

  ) {
    this.baseURL = BaseUrl.image;
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(this.settings.nativeElement){
        if (e.target !== this.settings.nativeElement && e.target !== this.settingsBlock.nativeElement.children
          && e.target !== this.selectFonts.nativeElement.children && e.target !== this.selectFonts.nativeElement
          && e.target !== this.background1.nativeElement.parent && e.target !== this.background1.nativeElement
          && e.target !== this.background2.nativeElement.parent && e.target !== this.background2.nativeElement
          && e.target !== this.background3.nativeElement.parent && e.target !== this.background3.nativeElement
          && e.target !== this.listBackgrounds.nativeElement.parent && e.target !== this.listBackgrounds.nativeElement
          && e.target !== this.labelInput.nativeElement.children[0] && e.target !== this.labelInput.nativeElement
          && e.target !== this.labelFonts.nativeElement.children[0] && e.target !== this.labelFonts.nativeElement
          && e.target !== this.fontSizeText.nativeElement.children[0] && e.target !== this.fontSizeText.nativeElement) {
            this.isCollapsedNew = true
        }
        if (e.target !== this.collapseElementBlock.nativeElement && e.target !== this.buttonHandler.nativeElement &&
            e.target !== this.buttonHandler.nativeElement.children && e.target !== this.titleHandler.nativeElement &&
            e.target !== this.imgHandler.nativeElement) {
              this.isCollapsed = true
        }
      }
    });
    this.is_subscribed = this.authguard.is_subscribed;
    this.a = localStorage.getItem('currentUser');
    this.a = JSON.parse(
      crypto.AES.decrypt(this.a, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
  }

  hendleShoweMoreText(){
    this.showFullText = true
  }
  hendleShoweLessText(){
    this.showFullText = false
  }

  afterLoadComplete(event){
    const ele = $('.ng2-pdf-viewer-container')
    ele.css('position', 'static')
    ele.css('margin-bottom', '35px')
  }

  ngOnInit(): void {
    // this.book_id = this.activeRoute.snapshot.paramMap.get('id');
    // this.editStoriesService.getEditBook({book_id: this.book_id}).subscribe(
    //   (res: any) => {

    //   },(error) => {
    //     console.log(error)
    //   }
    // )
    this.path = localStorage.getItem('path')
    this.url = this.baseURL + this.path;
    this.pdf_url = this.url
    this.activatedRoute.url.subscribe((url: any) => {
      this.previousUrl = localStorage.getItem('previousUrl');
      this.bookData = localStorage.getItem('mybooks');
      this.bookData = JSON.parse(this.bookData);

      this.userdata = this.a;
      this.userdata1 = this.a;
      // this. = this.userdata.data._id;
      this.userImage = this.userdata1.data.image;


      this.helper.updateUserData.subscribe((data) => {
        this.a =this.helper.getUserData();;
        this.userdata = this.a;
        this.userdata1 = this.a;
        this.userImage = this.userdata1.data.image;
      })

      this.commentForm = this.formBuilder.group({
        comment: ['', Validators.required],
      });
      this.book_id = this.activeRoute.snapshot.paramMap.get('id');
      this.chapter_id = this.activeRoute.snapshot.paramMap.get('chapter_id');
      this.author_id = this.activatedRoute.snapshot.paramMap.get('author_id');
      this.getBookOverview();
      this.addBookToHistory();
      this.getComments();
      setTimeout(function () {
        $('.medium-insert-embeds-overlay').remove();
      }, 3000);
      this.timeZoneOffset = this.timeConvert(
        this.currentDate.getTimezoneOffset() * 2
      );
    });
  }
  author_id: string;
  getBookOverview() {
    if(!this.book_id) {
      this.toastr.error('Invalid Book Reference, please go back and try again');
      return;
    }
    this.bookDataService.getBookOverview(this.book_id).subscribe((res: { status: boolean, data: any, message: string }) => {
      if(res?.status) {
        this.bookData = res.data;
        if(this.bookData.description.length > 150){
          const splitBriefText = this.bookData.description.split('').slice(0,150)
          this.briefText = splitBriefText.join('')
          this.fullText = this.bookData.description
        }else{
          if(this.bookData.description.length < 150){
            this.textIsBrief = true
          }
        }
      } else {
        this.toastr.error('Invalid Book Reference, please go back and try again');
      }
    }, (err) => {
      this.toastr.error(err.message || 'Something went wrong');
    }).add(() => {
      this.showLoader = false;
    })
  }
  getComments() {
    this.chapterService
      .getComments({ book_id: this.book_id, chapter_id: this.chapter_id })
      .subscribe(
        (res: any) => {
          this.commentData = res.data;
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }
  timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var total = -(rhours + rminutes);
    if (n < 0) {
      return '+' + total;
    } else {
      return '-' + total;
    }
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  }
  addBookToHistory = () => {
    this.readChapterService
      .addBookHistory(this.book_id, this.chapter_id, this.author_id)
      .subscribe((res: any) => {
        if (res.status) {
          console.log('Request Success');
        } else {
          console.log('Request failed');
        }
      });
  };
  onError(error: any) {
    this._snackbar.open(error.message, 'Ok', { duration: 2000 });
  }

}
