import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { interval } from 'rxjs';
import Swiper, { SwiperOptions } from 'swiper';
import { IFavoriteReads } from '../Interface/favorite-reads';
import { BookDataHandlerService } from '../services/book-data-handler.service';
import { nonCoverflowSwiperConfig } from '../swiper-config';
import * as $ from 'jquery';
import { FavoriteReadsService } from './favorite-reads.service';
import { HomeService } from '../home/home.service';
import { map } from 'rxjs/operators';
import { GlobalService } from '../global.service';
import { BookDetailService } from '../book-detail/book-detail.service';
import { Router } from '@angular/router';
import { BaseUrl } from '../base-url';
@Component({
  selector: 'app-favorite-reads',
  templateUrl: './favorite-reads.component.html',
  styleUrls: ['./favorite-reads.component.sass'],
})
export class FavoriteReadsComponent implements OnInit {
  @Input() name: boolean;
  bookData: any;
  baseimage;
  noImage;
  noAuthorImg;
  constructor(
    private homeService: HomeService,
    private favoriteReadsService: FavoriteReadsService,
    private globalService: GlobalService,
    private bookDetailService: BookDetailService,
    private router: Router
  ) {
    this.baseimage = BaseUrl.image;
    this.noImage = 'uploads/assets/img/rectangle1.svg'
    this.noAuthorImg = 'https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookData && changes.bookData.currentValue) {
      var value = changes.bookData.currentValue;
    }
  }
  // public bookDetails: IFavoriteReads[] = [];
  public isDataLoaded: boolean = false;

  public progressBarTheme = ['primary', 'accent', 'warn'];

  pcent = 0;
  public bookDetails = [
    {
      image: '',
      description: 'Every Book is a new Wonderful Travel..',
      author: 'Pedro Araez',
      readingCompletion: 78,
      currentPercentage: 0,
    },
    {
      image: '',
      description: 'Casey Christie night book into find...',
      author: 'Michael klock',
      readingCompletion: 32,
      currentPercentage: 0,
    },
    {
      image: '',
      description: 'Every Book is a new Wonderful Travel..',
      author: 'Pedro Araez',
      readingCompletion: 89,
      currentPercentage: 0,
    },
    {
      image: '',
      description: 'The Secret to English Busy People..',
      author: 'Daniel Ace',
      readingCompletion: 50,
      currentPercentage: 0,
    },
    {
      image: '',
      description: 'The adventures of Robins books...',
      author: 'Luka Afton',
      readingCompletion: 12,
      currentPercentage: 0,
    },
  ];

  progressBarValue = 0;
  swiper:any;
  ngAfterViewInit() {
    var swiper = new Swiper('.current-read-swiper', {
      slidesPerView: 4,
      spaceBetween: 20,
      grabCursor: true,
      breakpoints:{
        "1200": {
          slidesPerView: 4,
        },
        "1000": {
          slidesPerView: 3,
        },
        "969": {
          slidesPerView: 3,
        },
        "572":{
          slidesPerView: 2,

        },
        "501":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "450":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "400":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "375":{
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "350":{
          slidesPerView: 1,
          spaceBetween: 10,
        },

      },
      slidesPerGroup: 1,
      observer: true,
      // navigation: {
      //   nextEl: '.newly-added-swiper-button-next',
      //   prevEl: '.newly-added-swiper-button-prev',
      // },
    });
  }
  public slickInit(e: any) {
    console.log('slick initialized');
  }
  public breakpoint(e: any) {
    console.log('breakpoint');
  }
  public afterChange(e: any) {
    console.log('afterChange');
  }
  public beforeChange(e: any) {
    console.log('beforeChange');
  }

  gotoBookDetails(index: number) {
    this.bookDetailService.setSliderData({
      data: this.bookData?.value?.data,
      index: index,
    });

    this.router.navigate(['/book']);
  }

  ngOnInit(): void {

    this.favoriteReadsService
      .getBookHistory(false, 6)
      .subscribe((bookHistory: any) => {
        if (bookHistory.status === 200) {
          this.bookData = bookHistory.history;
          console.log('bookHistory =>', bookHistory)
          console.log('this.bookData =>', this.bookData)
          // setTimeout(() => {
          //   this.swiper.update();
          // }, 0);
        }
      }
    );
  }
}
