import { Component, OnInit } from '@angular/core';
import { AddManageGenreService } from "./add-manage-genre.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-manage-genre',
  templateUrl: './add-manage-genre.component.html',
  styleUrls: ['./add-manage-genre.component.sass']
})
export class AddManageGenreComponent implements OnInit {
  getGenre: any;
  updateForm: any;
  loading = false;
  submitted = false;
  showMsg = false;
  errorMsg: any;
  alertService: any;
  constructor(
    private addgenre: AddManageGenreService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
     this.updateForm = this.formBuilder.group({
      name: ["", Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }
  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
  }
  onSubmit() {
    // console.log(this.updateForm.value);
    this.cleanForm(this.updateForm);
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitted = true;
    this.addgenre.addGenre(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          this.toaster.success("Genre added successfully.")
          this.router.navigate(["/manage-genre"]);
        } else {
          this.toaster.error(res.message)
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

}
