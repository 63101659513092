import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EditUsersService } from '../edit-users/edit-users.service';
import { BaseUrl } from 'src/app/base-url';
import * as moment from "moment";
declare var $: any;
@Component({
  selector: 'app-edit-author',
  templateUrl: './edit-author.component.html',
  styleUrls: ['./edit-author.component.sass']
})
export class EditAuthorComponent implements OnInit {

  userid: any;
  usersUpdate: any;
  usersimage: any;
  loading = false;
  submitted = false;
  showMsg = false;
  updateForm: any;
  showImg = true;
  url: any;
  temp: any;
  errorMsg: any;
  alertService: any;
  selectedFiles: FileList;
  currentFileUpload: File;
  baseimage: any;
  myDate = new Date();
  myDates = new Date();
  modal: any;
  showLoader = false;

  constructor(
    private editUserService: EditUsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.userid = this.activatedRoute.snapshot.paramMap.get("id");
    this.getUserData();

    this.updateForm = this.formBuilder.group({
      user_id: [""],
      fullname: ["", Validators.required],
      username: ["", Validators.required],
      email: ["", Validators.required],
      dob: ["", Validators.required],
      gender: [""],
      bio: [""],
      location: [""],
      image: [""],
      cover_image: [""]
    });
  }

  getUserData() {
    this.editUserService.getUserData(this.userid).subscribe(
      (res: any) => {
        if (res.status) {
          if(res.data.dob){
            res.data.dob = new Date(res.data.dob);
          }
          this.usersUpdate = res.data;
          this.showLoader = false;
        }
      },
      error => {
        console.log("ERROR");
      }
    );
  }
  get f() {
    return this.updateForm.controls;
  }

  onSelectCoverImage(event) {
    this.showLoader = true;
    if (event.target.files.length > 0) {
      let formData = new FormData();
      this.selectedFiles = event.target.files;
      this.currentFileUpload = this.selectedFiles.item(0);
      formData.append("image", this.currentFileUpload);
      this.editUserService.cover_image(formData).subscribe(
        (res: any) => {
          if (res) {
            this.usersimage = res;
            if (res.status) {
              $("#user_cover").attr('src', this.baseimage+'/'+res.data)
              this.updateForm.controls["cover_image"].setValue(res.data);
              this.showLoader = false;
            }
          }
        },
        error => {
          console.log("ERROR");
          this.showLoader = false;
        }
      );
    }
  }

  onSelectFile(event) {
    this.showLoader = true;
    if (event.target.files.length > 0) {
      let formData = new FormData();
      this.selectedFiles = event.target.files;
      this.currentFileUpload = this.selectedFiles.item(0);
      formData.append("image", this.currentFileUpload);
      this.editUserService.user_image(formData).subscribe(
        (res: any) => {
          if (res) {
            this.usersimage = res;
            if (res.status) {
              $("#user_img").attr('src', this.baseimage+'/'+res.data)
              this.updateForm.controls["image"].setValue(res.data);
              this.showLoader = false;
            }
          }
        },
        error => {
          console.log("ERROR");
          this.showLoader = false;
        }
      );
    }
  }

  onSubmit() {
    this.submitted = true;
   
    if(this.updateForm.value.dob != null){
      let Dob = new Date(this.updateForm.value.dob)
      this.updateForm.value.dob = moment(Dob).format('L')
    } else {
        this.updateForm.value.dob = '';
    }
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitted = true;

    this.editUserService.updateUser(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          this.router.navigate(["authors"]);
          // this.showMsg = true;
          this.toaster.success("Author details updated successfully.")
        } else {
          this.toaster.error(res.message)
          // this.errorMsg = res.message;
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

}
