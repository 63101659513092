<div class="main_header main_header_login" *ngIf="!loggedIn">
  <div class="d-flex justify-content-center">
    <div class="flex_header justify-content-center">
      <div class="logo3 px-5 text-center w-100 top-navbar">
        <!-- <a class="align-middle" routerLink="/"><img loading="lazy" src="assets/images/Rectangle 5 (1).png" alt="" /></a> -->
        <!-- <div
          class="
            align-middle
            d-flex
            justify-content-center justify-content-between
            align-items-center
          "
        >
          <a class="text-decoration-none text-muted" routerLink="/contact-us"
            ><span>Contact Us</span></a
          >
          <a class="text-decoration-none text-muted" routerLink="/about-us"
            ><span>About Us</span></a
          >
          <a class="text-decoration-none text-muted" routerLink="/withdrawal"
            ><span>Withdrawal</span></a
          >
          <a class="text-decoration-none text-muted" routerLink="/contact-us"
            ><span>Subscription</span></a
          >
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <button class="mr-4 btn btn-sm view-more global-button">
            Sign Up
          </button>
          <button class="ml-4 btn btn-sm view-more global-button">
            Login
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="wrapper">
  <div id="content-page" class="content-page">
    <img
      src="assets/images/Rectangle 5 (1).png"
      style="width: 1540px; margin-left: 12px; height: 400px"
    />
    <div class="div-container-margin-top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <mat-tab-group>
              <mat-tab label="Terms And Conditions">
                <div class="col-lg-12">
                  <div
                    class="
                      iq-card iq-card-block iq-card-stretch iq-card-height
                      mt-3
                    "
                  >
                    <div
                      class="
                        iq-card-header
                        d-flex
                        justify-content-between
                        align-items-center
                        position-relative
                      "
                    >
                      <div class="container-fluid p-5">
                        <!-- <div class="row"> -->
                        <!-- <div class="col-lg-3"></div> -->
                        <!-- <div class="col-lg-6" [innerHTML]="htmlPage"></div> -->
                        <div class="m-3" [innerHTML]="htmlPage"></div>
                        <!-- <div class="col-lg-3"></div> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>
