import { Component, OnInit } from "@angular/core";
import { ViewManagePageService } from "./../view-manage-page/view-manage-page.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-edit-manage-page",
  templateUrl: "./edit-manage-page.component.html",
  styleUrls: ["./edit-manage-page.component.sass"]
})
export class EditManagePageComponent implements OnInit {
  updateForm: any;
  page_id: string;
  temp: boolean;
  getPage: any;
  submitted: boolean;
  loading: boolean;
  showMsg: boolean;
  errorMsg: any;
  alertService: any;

  constructor(
    private viewpage: ViewManagePageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.page_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadBook();
    this.updateForm = this.formBuilder.group({
      page_id: [""],
      content: ["", Validators.required]
    });
  }
  get f() {
    return this.updateForm.controls;
  }

  loadBook() {
    // this.manageservice.getBookDetail("5def94f7ed54f659fae740fe").subscribe(
    this.viewpage.getPageDetail(this.page_id).subscribe(
      (res: any) => {
        this.getPage = res.data;
        this.temp = true;
      },
      () => {
        console.log("ERROR");
      }
    );
  }

  onSubmit() {
    // console.log(this.updateForm.value);
    this.submitted = true;
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitted = true;
    this.viewpage.updatePageDetail(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          this.router.navigate(["/manage-pages"]);
          // this.showMsg = res.message;
          this.toaster.success('Page updated successfully')
        } else {
          this.toaster.error(res.message)
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
}
