<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedIndex">
        <mat-tab label="Profile">
          <!-- <div
            *ngIf="loader"
            class="loader d-flex justify-content-center align-items-center"
          >
            <mat-spinner></mat-spinner>
          </div> -->

          <!-- <div *ngIf="loader" class="container-fluid user-profile"> -->
          <div class="container-fluid user-profile">
            <div class="row profile-content">
              <div class="col-12 col-md-12 col-lg-4">
                <div class="iq-card">
                  <div class="iq-card-body profile-page">
                    <app-profile-desc></app-profile-desc>
                  </div>
                </div>
                <div class="iq-card">
                  <app-profile-details></app-profile-details>
                </div>
                <div class="iq-card">
                  <app-profile-change-password></app-profile-change-password>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-8">
                <div class="iq-card">
                  <app-profile-book-sales></app-profile-book-sales>
                </div>
                <div class="iq-card">
                  <app-profile-published-stories></app-profile-published-stories>
                </div>
                <div class="iq-card">
                  <app-profile-drafted-stories></app-profile-drafted-stories>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Wall">
          <!-- <div *ngIf="loader" class="user-profile user-wall"> -->
          <div class="user-profile user-wall">
            <div class="container">
              <div class="row">
                <div class="col-md-1 col-sm-1 col-lg-1"></div>
                <div class="col-md-10 col-sm-10 col-lg-10">
                  <div class="iq-card">
                    <app-profile-create-post
                      (postEmit)="updatePost($event)"
                    ></app-profile-create-post>
                  </div>
                  <app-profile-wall [Post]="Posts"></app-profile-wall>
                </div>
                <div class="col-md-1 col-sm-1 col-lg-1"></div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Following">
          <!-- <app-following></app-following> -->
        </mat-tab>
        <mat-tab label="Followers">
          <!-- <app-followers></app-followers> -->
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
