import { BaseUrl } from '../base-url';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  JsonpClientBackend,
} from '@angular/common/http';
import { HelperService } from '../helper.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ChapterAddService {
  addtype: any;
  base: string;
  chapterimage: any;
  baseimage: string;
  headers;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.base = BaseUrl.frontend;
    this.baseimage = BaseUrl.imageApi;
    this.headers = this.helper.getHeader();
  }

  addChapter(data) {
    return this.http.post(this.base + `/v1/addChapter`, data, {
      headers: this.headers,
    });
  }

  updateChapter(data) {
    return this.http.post(this.base + `/v1/updateChapter`, data, {
      headers: this.headers,
    });
  }

  chapter_image(image) {
    const userData = this.helper.getUserData();
    const headers = new HttpHeaders({
      'x-access-token': userData.data.token,
    });
    return this.http.post(
      `${environment.baseUrl}/uploadImage/chapter_image`,
      image,
      { headers: headers }
    );
  }

  deleteChapter(paylaod: {chapter_id: string, book_id: string}) {
    return this.http.post(environment.baseUrl + '/deleteChapter', paylaod);
  }
}
