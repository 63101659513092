<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
    <div class="page-main">
        <!-- sidebar area -->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
                <div class="page-header">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Chapter Payments
                        </li>
                    </ol>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Chapter Payments</div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div id="example_wrapper"
                                        class="dataTables_wrapper dt-bootstrap4 no-footer view_user_table_component">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <table id="chaptertable" datatable class="table row-border hover" *ngIf="temp"
                                                   role="grid" aria-describedby="example_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th class="wd-15p sorting_asc" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-sort="ascending"
                                                                aria-label="First name: activate to sort column descending"
                                                                style="width: 30px;">
                                                                #
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Last name: activate to sort column ascending"
                                                                style="width: 240px;">
                                                                Purchased by
                                                            </th>
                                                            <th class="wd-20p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Position: activate to sort column ascending"
                                                                style="width: 321px;">
                                                                Purchased on
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Start date: activate to sort column ascending"
                                                                style="width: 150px;">
                                                                Amount
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Start date: activate to sort column ascending"
                                                                style="width: 218px;">
                                                                Book name
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Start date: activate to sort column ascending"
                                                                style="width: 178px;">
                                                                chapter
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Start date: activate to sort column ascending"
                                                                style="width: 178px;">
                                                                Author Rec. Amount
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0"
                                                                aria-controls="example" rowspan="1" colspan="1"
                                                                aria-label="Start date: activate to sort column ascending"
                                                                style="width: 178px;">
                                                                librums comm. amount
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" class="odd" *ngFor="
                                                                let message of getpayments
                                                                let i = index
                                                                ">
                                                            <td class="sorting_1">{{ i + 1 }}</td>
                                                            <td>{{ message.user_name }}</td>
                                                            <td>{{ message.updated_at | date }}</td>
                                                            <td>${{ message.amount }}</td>
                                                            <td>{{ message.book_name }}</td>
                                                            <td>{{ message.chapter_name}}</td>
                                                            <td>${{ message.amount - message.amount *10/100}}</td>
                                                            <td>${{ message.amount *10/100}}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- table-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
