import { BaseUrl } from '../base-url';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AddStoriesService {
  getstory: any;
  baseurl: any;
  base: any;
  addstory: any;
  usersimage: any;
  userdata: string;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
    this.base = BaseUrl.imageApi;
  }

  addStory(data) {
   //  const tags = [];
   //  data.tags.forEach(function (item) {
   //    tags.push(item.name);
   //  });

   //  data.tags = tags;
    const headers = this.helper.getHeader();
    return this.http.post(this.baseurl + `/addStory`, data, {
      headers,
    });
  }

  addChapter(data) {
   const headers = this.helper.getHeader();
   return this.http.post(this.base + `/addChapter`, data, {
     headers,
   });
 }

  getContentType() {
    const headers = this.helper.getHeader();
    return this.http.post(this.baseurl + '/getContentType', null, {
      headers,
    });
  }

  book_cover_image(image) {
    this.usersimage = JSON.parse(localStorage.getItem('currentUser'));
    const headers = new HttpHeaders({
      // "Content-Type": "multipart/form-data",
      'x-access-token': this.usersimage.data.token,
    });
    return this.http.post(this.base + `book_cover_image`, image, {
      headers,
    });
  }

    upload_file(event) {
      this.userdata = localStorage.getItem('currentUser');
      const token = JSON.parse(crypto.AES.decrypt(this.userdata, environment.secretKey).toString(crypto.enc.Utf8)).data.token

      let fileList: FileList = event.target.files;
      let file: File = fileList[0];
      let formData:FormData = new FormData();
      formData.append('file', file, file.name);
 
      const headers = new HttpHeaders({
        'x-access-token': token,
      });
      return this.http.post(`${environment.baseUrl}/uploadBook`, formData, {
        headers,
      });
  }
}
