<div *ngIf="!book_title">
  <input
    style="display: none"
    [disabled]="twoImages"
    type="file"
    (change)="onSelectFile($event)"
    #file
  />

  <img
    *ngIf="imageUrl.length === 0"
    style="width: 270px; height: 360px"
    routerLink="/add-book-cover"
    [src]="baseurl + url"
    onerror="this.src = 'assets/images/rectangle1.svg'"
    alt=""
  />

  <div
    style="text-align: right; width: 91%"
    *ngIf="brightnessAndTransparency && !(imageUrl.length == 2)"
  >
    <div class="remove-image" *ngFor="let data of imageUrl; let i = index">
      <div class="d-flex">
        {{ i + 1 }}
        <img
          (click)="removeImage(i)"
          src="assets/Slider/images/icons/Gradient/Close button@3x.svg"
          alt=""
        />
      </div>
    </div>
  </div>

  <img
    class="book_cover_image"
    style="width: 270px; height: 360px; object-fit: cover"
    *ngFor="let url of imageUrl; let i = index; let last = last"
    [class.superimpose-image]="last && twoImages"
    [style.opacity]="last && twoImages ? value : 1"
    [style.filter]="
      last && twoImages ? 'brightness(' + brightness + '%)' : 'brightness(100%)'
    "
    [src]="url"
    alt=""
  />

  <div
    *ngIf="uploadImage && !twoImages"
    class="plus_icon_img text-center mt-4 upload-canvas-image"
    id="first_browse"
  >
    <div class="d-block">
      <label for="cover_image">
        <img
          (click)="file.click()"
          src="../../assets/Slider/images/icons/Plus graident.svg"
        />
      </label>
    </div>

    <button
      *ngIf="imageUrl.length >= 1"
      (click)="
        brightnessAndTransparency = !brightnessAndTransparency;
        transparency = true;
        uploadImage = false;
        twoImages = true
      "
      class="btn btn-sm global-button cancel"
    >
      Next
    </button>

    <button
      (click)="uploadImage = !uploadImage"
      *ngIf="!twoImages"
      class="btn btn-sm global-button cancel"
    >
      Cancel
    </button>
  </div>

  <canvas *ngIf="showCanvas" style="height: 0px" #canvas></canvas>

  <div class="mt-5" *ngIf="transparency">
    <h3>Brightness</h3>
    <div class="input_range" style="width: 100%; margin: 10px 0 10px 0">
      <input
        type="range"
        (change)="changeBrightness($event)"
        min="0"
        max="100"
        class="slider1"
        value="{{ brightness }}"
      />
      <div class="lines">
        <ul class="d-flex">
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
        </ul>
      </div>
    </div>

    <h3>Transparency</h3>
    <div class="input_range" style="width: 100%; margin: 10px 0 10px 0">
      <input
        type="range"
        (change)="chnageTransparency($event)"
        min="0"
        max="1"
        step="0.1"
        class="slider1"
        value="{{ value }}"
      />
      <div class="lines">
        <ul class="d-flex">
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
          <li><span></span></li>
        </ul>
      </div>
    </div>

    <button
      class="btn btn-sm global-button next"
      color="primary"
      (click)="writeText()"
    >
      Next
    </button>
  </div>

  <div class="mt-5" *ngIf="text">
    <h3>Title</h3>
    <mat-form-field>
      <input matInput type="text" class="w-100" [(ngModel)]="bookTitle" />
    </mat-form-field>
    <span>
      {{ bookTitle }}
    </span>

    <button
      class="mt-4"
      mat-raised-button
      color="primary"
      (click)="writeText()"
    >
      Next
    </button>
  </div>
</div>
