<div class="page">
    <div class="page-main">
      <!-- Sidebar menu-->
      <div class="app-content  my-3 my-md-5">
        <div class="page-container">
          <div class="page-header">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/manage-subscription']"> Subscriptions</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add 
            </li>
          </ol></div>
          <div class="">
            <div class="card all_custom_input">
                <div class="card-header">
                    <h2 class="card-title">Add Subscription</h2>
                  </div>
                  <div class="card-body">
              <form
                [formGroup]="updateForm"
                class="issue_report"
                (ngSubmit)="onSubmit()"
              >
                
                <div *ngIf="errorMsg">
                  <p class="alert alert-danger">
                    <strong>{{ errorMsg }}</strong>
                  </p>
                </div>
                <div class="row">
                <div class="col-md-4 form-group input_txt">
                  <label for="title">Title <span class="required-field-icon">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter title"
                    formControlName="title"
                    class="form-control"
                    id="title"
                    required
                    [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                  />
                  <div
                    *ngIf="submitted && f.title.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.title.errors.required">Title is required.</div>
                    <div *ngIf="f.title.errors.whiteSpace">Please remove leading and trailing whitespace.</div>
                                 
                  </div>
                </div>
                <div class="col-md-4 form-group input_txt">
                  <label for="type">Type</label>
                  <select
                    formControlName="type"
                    class="form-control"
                    id="type"
                    type="type"
                    [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
                  >
                    <option value="" selected disabled>Select type</option>
                    <!-- <option value="DAY">Daily</option>
                                  <option value="WEEK">Weekly</option> -->
                    <option value="MONTH">Monthly</option>
                    <option value="YEAR">Yearly</option>
                  </select>
                  <div
                    *ngIf="submitted && f.type.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.type.errors.required">Type is required.</div>
                  </div>
                </div>
                <div class="col-md-4 form-group input_txt">
                  <label for="price">Price($) <span class="required-field-icon">*</span></label>
                  <input
                  min="1"
                    type="number"
                    placeholder="Enter price"
                    formControlName="price"
                    class="form-control"
                    id="price"
                    required
                    [ngClass]="{ 'is-invalid': submitted && f.price.errors }"
                  />
                  <div
                    *ngIf="submitted && f.price.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.price.errors.required">Price is required.</div>
                    <!-- <div *ngIf="f.price.errors.required">Price should be greater than $0.</div> -->
                    <div *ngIf="f.price.errors.min">Price should be greater than $0.</div>
                  </div>
                </div> 
                </div>
                <div class="row">
                
                <div class="col-md-12 form-group input_txt">
                  <label for="price">Description</label>
                  <textarea
                    type="text"
                    placeholder="Enter description"
                    formControlName="description"
                    id="description"
                    required
                    maxlength="1000"
                    [ngClass]="{
                      'is-invalid': submitted && f.description.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="submitted && f.description.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.description.errors.required">
                      Description is required.
                    </div>
                  </div>
                </div>
              </div>
  
                <div class="container">
                  <div class="">
                    <div class="">
                      <div class="save text-center">
                        <button [disabled]="loading" class="site_btn">
                          Add
                        </button>
                      </div>
                    </div>
                    <!-- <div class="col-md-6">
                                      <div class="save text-right">
                                          <a routerLink="/users" class="save_btn">Back</a>
                                      </div>
                                  </div> -->
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  