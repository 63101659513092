import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrl } from "./../../../base-url";
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PaymentDashboardService {
  messagedata: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }
 

  getPayments() {
    this.messagedata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.messagedata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/getSubscriptionPayments`, null, {
      headers
    });
  }

  getChapterPayments() {
    this.messagedata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.messagedata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/getChapterPayments`, null, {
      headers
    });
  }
}
