<div class="wrapper">
  <div id="content-page" class="content-page">
    <div *ngIf="chapterData">
      <section>
        <div class="light_gradient_bg">
          <div class="container-fluid container_fluid2">
            <div class="chapter_layout">
              <div class="chapter_icon">
                <div class="dropdown">
                  <a
                    data-target="#show_chapter"
                    data-toggle="collapse"
                    href="#"
                  >
                    <!-- <img loading="lazy"  alt="" src="assets/img/book.svg">
                                  <span  class="pl-3"><img loading="lazy" alt="" src="assets/img/ar.svg"></span> -->
                  </a>
                  <div class="collapse listing_chapter" id="show_chapter">
                    <div class="about_heading follower all_chapter color_white">
                      <div class="follower_list">
                        <div class="follower_pic">
                          <div class="follower_pic_name"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="setting black_chapter2">
                <div class="toggle2 comment_setting">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <ul id="list_view_setting">
                  <li class="setting_drop_down">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#setting_filter"
                      class="drop_it font-edit collapsed d-flex"
                      ><img
                        src="assets/images/font.svg"
                        alt=""
                      />Setting
                      <img
                        src="assets/images/header_arrow.svg"
                        alt=""
                        class="img_transform icon-config pr-0 pl-2 mt-2"
                      />
                    </a>
                    <div id="setting_filter" class="collapse collapse_setting">
                      <div class="fliter_font fliter_font_hover">
                        <ul>
                          <li class="box_txt">
                            <a (click)="changeLayout('color_white')">A</a>White
                          </li>
                          <li class="box_txt">
                            <a (click)="changeLayout('color_wheat')">A</a
                            >Eggshell
                          </li>
                          <li class="box_txt">
                            <a (click)="changeLayout('color_black')">A</a
                            >Nightowl
                          </li>
                        </ul>
                      </div>
                      <div class="select_font_size">
                        <div class="label_input">
                          <label for="">Select Font Type</label>
                          <select name="" id="" (change)="onFontChange($event)">
                            <option
                              *ngFor="let list of userlist"
                              value="{{ list.value }}"
                            >
                              {{ list.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="range_fonts">
                        <div class="fonts_inrs"><span>Font Size</span></div>
                        <div class="input_range">
                          <input
                            type="range"
                            step="2"
                            min="10"
                            max="40"
                            id="slide"
                            class="slider1"
                            (change)="onFontSize($event)"
                          />
                          <div class="lines lines_hover">
                            <ul>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                              <li><span></span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="">
          <div class="change_chapter_color_black {{ is_white }}">
            <div class="">
              <div class="col-md-9 m-auto">
                <div class="editor_heading">
                  <h3>{{ chapterData.title }}</h3>
                  <a><span>Last Updated On : May 12, 2020</span></a>
                </div>

                <div class="detail_editor">
                  <div class="editor_img" *ngIf="chapterData.image">
                    <img loading="lazy" src="{{ chapterData.image }}" />
                  </div>
                  <div class="text-center mt-3 mb-3" *ngIf="safeurl != ''">
                    <iframe
                      frameborder="0"
                      width="850px"
                      height="400px"
                      id="abc_frame"
                      name="abc_frame"
                      scrolling="no"
                      [src]="safeurl"
                    ></iframe>
                  </div>

                  <div class="sub_details">
                    <!-- <p
                      style="font-family: Roboto, 'Helvetica Neue', sans-serif"
                      id="cc"
                      [innerHTML]="chapterData.html | safeHtml: 'html'"
                    ></p> -->
                  <quill-view [content]="chapterData.content" format="html"></quill-view>

                    <!-- <div class="container"> -->
                      <!-- <div class="add">
                        <img
                          style="margin-left: 23%"
                          src="https://librums-3c2f8.web.app/assets/img/add.png"
                        />
                      </div> -->
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-footer></app-footer>
