<div class="page">
    <div class="page-main">
        <!-- Sidebar menu-->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
              <div class="page-header">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/dashboard']">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/manage-copyright']"> Copyrights</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                     Add 
                    </li>
                   </ol> </div>
                  <div class="">
                    <div class="card all_custom_input">
                      <div class="card-header">
                        <div class="card-title">
                          Add Copyright
                        </div>
                      </div>
                      <div class="card-body">
            <form
              [formGroup]="updateForm"
              class="issue_report"
              (ngSubmit)="onSubmit()"
            >
              <div class="form-group input_txt">
                <label for="name">Title <span class="required-field-icon">*</span></label>
                <input
                
                  type="text"
                  placeholder="Enter Title"
                  formControlName="name"
                  id="name"
                  required
                  [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                />
                <div
                  *ngIf="submitted && f.name.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.name.errors.required">Title is required</div>
                  <div *ngIf="f.name.errors.whiteSpace">Please remove leading and trailing whitespace.</div>
                               
                </div>
              </div>
              <div class="container">
                <div class="">
                  <div class="">
                    <div class="save text-center">
                      <button [disabled]="loading" class="site_btn">Add</button>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="save text-right">
                        <a routerLink="/users" class="save_btn">Back</a>
            </div>
        </div> -->
    </div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
