import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { Book, PageType } from '@labsforge/flipbook';
import { Swiper } from 'swiper';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookDetailService } from '../book-detail/book-detail.service';
import videojs from 'video.js';
import _ from 'lodash'
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-sketch-book',
  templateUrl: './sketch-book.component.html',
  styleUrls: ['./sketch-book.component.sass'],
})
export class SketchBookComponent implements OnInit {
  baseurl: string;
  headers: HttpHeaders;
  baseimage: string;
  public bookImages: any;

  @ViewChild('target', { static: true }) target: ElementRef;

  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };
  player: videojs.Player;

  constructor(
    private http: HttpClient,
    private helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bookService: BookDetailService,
    private elementRef: ElementRef,
    public sanitizer:DomSanitizer
  ) {
    this.baseurl = BaseUrl.frontend;
    this.headers = this.helper.getHeader();
    this.baseimage = BaseUrl.image;
  }

  public bookPages: Array<any> = [];

  public bookData: Book = {
    width: 600,
    height: 400,
    zoom: 1,
    cover: {
      front: {
        imageUrl:
          'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
      },
      back: {
        imageUrl:
          'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
      },
    },
    pages: this.bookPages,
    startPageType: PageType.Double,
    endPageType: PageType.Double,
  };

  getSlider() {
    this.http
      .post(
        `${this.baseurl}/getSketchBooks`,
        { id: '614d9935e9991510dc35b1c5' },
        {
          headers: this.headers,
        }
      )
      .subscribe((result: any) => {
        this.bookImages = result;
        setTimeout(() => {
          const swiper = new Swiper('.sketch_book_container', {
            slidesPerView: 1,
            lazy: true,
            // effect: 'coverflow',
            spaceBetween: 10,
            centeredSlides: true,
            grabCursor: true,
            // autoHeight: true,
            // coverflowEffect: {
            //   depth: 500,
            //   rotate: 0,
            // },
            on: {
              init: function (slide) {
                $('.swiper-slide-active').addClass('active-swiper-slide');
              },
              slideChange: function (slide) {
                // $('.swiper-slide-active').addClass('active-swiper-slide');
                // $('.swiper-slide').removeClass('active-swiper-slide');
              },
            },
          });
        }, 0);
      });
  }

  videoPlayerSlider = () => {
    const swiper = new Swiper('.video__book__container', {
      spaceBetween: 20,
      slidesPerView: 1,
    });
  };

  public videoBooksData: Array<any>;
  public videoBooksData1: Array<any>;
  ngOnInit(): void {
    if (this.data.type == 'sketchBook') {
      this.bookService
        .getSketchBooks(this.data.sketchBookId)
        .subscribe((data: any) => {
          this.bookPages = data.sketch.sketchs;
          this.bookPages = this.bookPages.map((sketch) => {
            return {
              imageUrl: `${this.baseimage}${sketch.destination}/${sketch.filename}`,
              backgroundColor: '#41473A',
            };
          });
          if (this.bookPages.length == 1) {
            this.bookPages.push({
              imageUrl: '',
            });
          }
          this.bookData.pages = this.bookPages;
        });
    } else {
      let k = []
      this.bookService
        .getVideBooks(this.data.sketchBookId)
        .subscribe(async (data: any) => {
          await _.filter(data.video, (item, index) => k[index] = (this.sanitizer.bypassSecurityTrustResourceUrl(item)))
          this.videoBooksData1 = k
          setTimeout(() => {
            this.videoPlayerSlider();
          }, 0);
        });
    }
  }
}
