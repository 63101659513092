import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';
import { HelperService } from "src/app/helper.service";

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  admindata: any;
  base: any;
  usersdata: any;
  admin_id: any;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.base = BaseUrl.admin;
  }

  getUserData(id) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(
      this.base + `/getUserData`,
      { user_id: id },
      {
        headers
      }
    );
  }
  // userdata.data._id
  updateAdmin(data) {
    this.admindata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.admindata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(this.base + `/updateAdmin`, data, {
      headers
    });
  }

  update_user_profile(data: any) {
    return this.http.post(BaseUrl.frontend + '/v1/updateProfile', data);
  }

  upload_image(image) {
    const userData = this.helper.getUserData();
    const headers = new HttpHeaders({
      'x-access-token': userData.data.token,
    });
    return this.http.post(
      `${environment.baseUrl}/uploadImage/user_cover_image`,
      image,
      { headers: headers }
    );
  }
}
