<div class="iq-card shadow-none m-0">
  <div class="iq-card-body p-0 toggle-cart-info text-left">
    <div class="bg-primary-cyan p-3">
      <h5 class="mb-0 notification-header text-white">
        My bookshelf<small class="badge badge-light text-dark float-right pt-1">{{
          bookshelfCount
        }}</small>
      </h5>
    </div>
    <div class="navbar-popover">
      <div *ngFor="let data of bookshelfData; let i = index" class="iq-sub-card">
        <div class="media align-items-center">
          <div class="">
            <img
              class="rounded"
              [src]="baseimage + data?.book_details?.cover_image"
              alt=""
              onerror="this.src = 'assets/images/rectangle1.svg'"
            />
          </div>
          <div class="media-body ml-3">
            <h5 class="mb-0">{{ data?.book_details?.title }}</h5>
            <!-- <p class="mb-0 bookshelf-genre">{{ data?.book_details?.genre }}</p> -->
            <a [routerLink]="['/', 'authorprofile', data?.book_details?.author_id]"><h5>@{{ data?.book_details?.author_username }}</h5></a>
          </div>
          <div class="float-right font-size-24 text-danger">
            <i
              matTooltip="Continue Reading"
              class="fas fa-book-open fa-xs color-cyan"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-primary-cyan p-2"
      style="
        border-radius: 0 0 4px 4px !important;
        height: 39px;
        cursor: pointer;
      "
      routerLink="/bookshelf"
    >
      <h3 class="text-white" style="margin-left: 37%">
        <u> View All </u>

        <small class="badge badge-light float-right pt-1"></small>
      </h3>
    </div>
  </div>
</div>
