<div class="page">
  <div *ngIf="showLoader" class="loader">
    <img loading="lazy" src="/assets/img/loader.svg" />
  </div>
  <div class="page-main">
    <!-- Sidebar menu-->
    <div class="app-content  my-3 my-md-5" *ngIf="usersUpdate">
      <div class="page-container">
        <div class="page-header">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">Dashboard</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/users']"> Users</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Edit Profile
          </li>
        </ol></div>
        <div class="mt_mb">
          <div class="form_edit_book">
            <div class="heading_form">
              <div *ngIf="showMsg">
                <p class="alert alert-success">
                  <strong>User profile updated successfully.</strong>
                </p>
              </div>
              <div *ngIf="errorMsg">
                <p class="alert alert-danger">
                  <strong>{{ errorMsg }}</strong>
                </p>
              </div>
            </div>
            <div class="cover_img2">
              <div class="cover_img_upload2">
                <label for="edit_cover_img_upload" class="cam_edit_dp_bg">
                  <input
                    type="file"
                    id="edit_cover_img_upload"
                    (change)="onSelectCoverImage($event)"
                    class="d-none"
                  />
                  <template *ngIf="usersUpdate.cover_image; else other_content">
                    <img loading="lazy" id="user_cover"
                      src="{{ baseimage }}{{ usersUpdate.cover_image }}"
                      alt=""
                    />
                  </template>
                  <ng-template #other_content
                    ><img loading="lazy" id="user_cover" src="assets/img/bg_place_holder.jpg" alt=""
                  /></ng-template>
                  <div class="cam_edit_dp">
                    <img loading="lazy" _ngcontent-bmu-c7="" alt="" src="assets/img/cam.svg" />
                  </div>
                </label>
              </div>
              <div class="user_pic_edit">
                <label for="upload_edit_pic" class="cam_rel">
                  <template *ngIf="usersUpdate.image; else other">
                    <img loading="lazy" id="user_img" src="{{ baseimage }}{{ usersUpdate.image }}" alt="" />
                  </template>

                  <ng-template #other>
                    <img loading="lazy" id="user_img" src="assets/images/img_user1.svg" alt="" />
                    <div class="cam_edit_dp">
                      <img loading="lazy" src="assets/img/white_pen.svg " alt="" />
                    </div>
                  </ng-template>

                  <input
                    type="file"
                    id="upload_edit_pic"
                    (change)="onSelectFile($event)"
                    class="d-none"
                  />
                  <img
                    class="edit_btn_pic"
                    src="assets/img/white_pen.svg "
                    alt=""
                  />
                </label>
              </div>
            </div>
            <!-- class="d-none" -->
            <form
              [formGroup]="updateForm"
              class="issue_report"
              (ngSubmit)="onSubmit()"
              enctype="multipart/form-data"
            >
              <div class="form-group input_txt ">
                <input
                  type="hidden"
                  [ngModel]="usersUpdate._id"
                  formControlName="user_id"
                  placeholder="User id"
                  class="form-control"
                  id="user_id"
                  required
                />
              </div>
              <input
                type="hidden"
                [ngModel]="usersUpdate.image"
                formControlName="image"
              />
              <input
                type="hidden"
                [ngModel]="usersUpdate.cover_image"
                formControlName="cover_image"
              />
                <div class="row" >
              <div class="col-6 form-group input_txt">
                <label for="fullname">Name<em class="text-danger">*</em></label>
                <input
                  type="text"
                  [ngModel]="usersUpdate.fullname"
                  formControlName="fullname"
                  placeholder="Name"
                  class="form-control"
                  id="fullname"
                  required
                  [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
                />
                <div
                  *ngIf="submitted && f.fullname.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.fullname.errors.required">
                    Name is required.
                  </div>
                </div>
              </div>

                <div class="col-md-6 form-group input_txt ">
                  <label for="username">Username<em class="text-danger">*</em></label>
                  <input
                    type="text"
                    [ngModel]="usersUpdate.username"
                    formControlName="username"
                    maxlength="16"
                    placeholder="Username"
                    class="form-control"
                    id="username"
                    required
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                  />
                  <div
                    *ngIf="submitted && f.username.errors"
                    class="invalid-feedback"
                  >
                  <div *ngIf="f.username.errors.required">
                    Username is required.
                  </div>
                </div>
                </div>
          </div>
          <div class="row" >
            <div class="col-md-6 form-group input_txt">
                <label for="email">Email<em class="text-danger">*</em></label>
                <input
                type="text"
                placeholder="E-mail"
                [ngModel]="usersUpdate.email"
                formControlName="email"
                class="form-control"
                id="email"
                required
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <div
                *ngIf="submitted && f.email.errors"
                class="invalid-feedback"
                >
                <div *ngIf="f.email.errors.required">Email is required.</div>
                </div>
            </div>
            <div class="col-md-5 form-group input_txt">
                <!-- <template> -->
                  <label for="gender">Gender</label>
                  <select
                    formControlName="gender"
                    [ngModel]="usersUpdate.gender"
                    id="gender"
                    name="gender"
                    [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
                  >
                    <option value="" selected disabled>Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <div
                    *ngIf="submitted && f.gender.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.gender.errors.required">
                      Gender is required
                    </div>
                  </div>
                <!-- </template> -->
            </div>
            </div>
            <div class="row">
                <div class="col-6 form-group input_txt">
                <label for="location">Location</label>
                <input
                    type="text"
                    [ngModel]="usersUpdate.location"
                    formControlName="location"
                    placeholder="Location"
                    class="form-control"
                    id="location"
                />
                </div>
              <div class="col-6 form-group input_txt">
                  <div>
                    <label for="dob">Date Of Birth </label>
                    <mat-form-field class="ml-2">
                        <input type="text" [ngModel]="usersUpdate.dob" formControlName="dob"
                            class="form-control" id="dob" [matDatepicker]="picker" matInput
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <!-- <input type="text" [ngModel]="usersUpdate.dob" formControlName="dob" placeholder="Date Of Birth" class="form-control" id="dob" width="100%" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" /> -->
                    <div *ngIf="submitted && f.dob.errors" class="invalid-feedback" style="display: block">
                      <div *ngIf="f.dob.errors.required">
                        Date of birth is required.
                      </div>
                    </div>
                  </div>
              </div>
            
        <!-- </div> -->
      </div>
         
      <div class="form-group input_txt">
        <label for="bio">Bio</label>
        <textarea     
          [ngModel]="usersUpdate.bio"
          formControlName="bio"
          placeholder="Bio"
          class="form-control"
          id="bio"
          rows="8"
          [ngClass]="{ 'is-invalid': submitted && f.bio.errors }"
        >
          {{usersUpdate.bio}}
        </textarea>
            
        <div *ngIf="submitted && f.bio.errors" class="invalid-feedback">
          <div *ngIf="f.bio.errors.required">Bio is required.</div>
        </div>
      </div>
           
      <div class="container">
        <!-- <div class="col-md-6">
          <div class="save text-left">
            <a routerLink="/users" class="save_btn">Back</a>
          </div>
        </div> -->
        <div class="">
          <div class="save text-center">
            <button [disabled]="loading" class="site_btn">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
</div>
</div>
</div>
