import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  baseurl: string;
  headers: HttpHeaders;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
    this.headers = this.helper.getHeader();
  }

  reviewBook = (book_id: string, stars: number, reviewComment: string) => {
    return this.http.post(
      `${this.baseurl}/reviewBook`,
      { book_id, stars, reviewComment },
      { headers: this.headers }
    );
  };
  getReviewStars = (book_id: string) => {
    return this.http.post(
      `${this.baseurl}/getReviewStars`,
      { book_id },
      { headers: this.headers }
    );
  };
  getReviewByBookId = (book_id: string) => {
    return this.http.post(
      `${this.baseurl}/getReviewByBookId`,
      { book_id, full: true },
      { headers: this.headers }
    );
  };

  updateReview = (book_id: string, stars: number, reviewComment: string) => {
    return this.http.post(
      `${this.baseurl}/updateReview`,
      { book_id, stars, reviewComment },
      { headers: this.headers }
    );
  };

  deletReview = (book_id: string, prevStar: number) => {
    return this.http.post(
      `${this.baseurl}/deleteReview`,
      { book_id, prevStar },
      { headers: this.headers }
    );
  };
}
