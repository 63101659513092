<div class="col-lg-12 mt-5 folowers-container">
  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
    <section class="conatiner__" *ngFor="let data of follower">
      <div class="author__following">
        <div class="d-flex flex-column flex-lg-row">
          <a class="d-flex justify-content-center" routerLink="/authorprofile/{{ data.follower.id }}">
            <img
              *ngIf="data.user_image !== ''"
              class="following__image rounded-circle"
              src="{{ baseimageurl }}{{ data.user_image }}"
              alt=""
            />
            <img
              *ngIf="data.user_image === ''"
              style="height: 60px"
              class="img-fluid avatar-60 rounded-circle"
              src="https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png"
              alt=""
            />
          </a>

          <div class="follower_username_container ml-3">
            <h4 class="follower_username font-italic text-center">
              @{{ data.follower.username }}
            </h4>
          </div>
        </div>

        <div *ngIf="data.created_by !== currentUser._id" class="button-container">
          <button
            (click)="manageFollowing(2, data.created_by)"
            *ngIf="data.isFollowed === 1"
            class="following__unfollow font-italic"
          >
            Unfollow
          </button>
          <button
            (click)="manageFollowing(1, data.created_by)"
            *ngIf="data.isFollowed === 0"
            class="following__follow font-italic"
          >
            Follow
          </button>
        </div>
      </div>
    </section>
  </div>
</div>
