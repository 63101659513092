import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';
import * as crypto from "crypto-js"
import { BaseUrl } from '../base-url';
@Injectable({
  providedIn: 'root',
})
export class EditstoriesService {
  baseURL: any;
  user: any;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseURL = BaseUrl.frontend;
  }

  getEditBook(data) {
    const headers = this.helper.getHeader()
    // tslint:disable-next-line: align
    return this.http.post(this.baseURL + '/getMyBookDetail', data, {
      headers,
    });
  }

  updateStory(data, newTags?) {

    const tags = [];
    let isUploadedBook:boolean
    if(data.isUploadedBook){
          newTags.forEach(function (item) {
            tags.push(item);
          });
          isUploadedBook = true
    }else{
      data.tags.forEach(function (item) {
        tags.push(item.value);
      });
    }



    data.tags = tags;
    data.isUploadedBook = isUploadedBook;
    data.not_for_below_eighteen = data.not_for_below_eighteen
    var userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': decryptUser.data.token,
    });
    // tslint:disable-next-line: align
    return this.http.post(this.baseURL + `/updateStory`, data, {
      headers,
    });
  }
}
