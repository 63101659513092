import { Component, OnInit } from '@angular/core';
import { AdminEditSubscriptionService } from "./admin-edit-subscription.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-edit-subscription',
  templateUrl: './admin-edit-subscription.component.html',
  styleUrls: ['./admin-edit-subscription.component.sass']
})
export class AdminEditSubscriptionComponent implements OnInit {
  getGenre: any;
  temp: boolean;
  updateForm: any;
  subscriptionid: any;
  loading = false;
  submitted = false;
  showMsg = false;
  showImg = true;
  errorMsg: any;
  alertService: any;
  constructor(
    private editsub: AdminEditSubscriptionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.subscriptionid = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadGenre();
    this.updateForm = this.formBuilder.group({
      subscription_id: [""],
      title: ["", Validators.required],
      type: ["", Validators.required],
      price: ["", Validators.required],
      description: ["", Validators.required]
    });
  }

  loadGenre() {
    this.editsub.getSubscriptionDetail(this.subscriptionid).subscribe(
      (res: any) => {
        this.getGenre = res.data;
        this.temp = true;
      },
      () => {
        console.log("ERROR");
      }
    );
  }

  get f() {
    return this.updateForm.controls;
  }
  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
  }
  onSubmit() {
    // console.log(this.updateForm.value);
    // this.cleanForm(this.updateForm);
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitted = true;
    this.editsub.updateSubscription(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          this.toaster.success(res.message)
          this.router.navigate(["/manage-subscription"]);
        } else {
          this.toaster.error(res.message)
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

}
