<div class="main_header main_header_login" *ngIf="!loggedIn">
  <div class="d-flex justify-content-center">
    <div class="flex_header justify-content-center">
      <div class="logo3 px-5 text-center w-100 top-navbar">
        <div class="col-lg-12">
          <a class="align-middle" routerLink="/">
            <img loading="lazy" src="assets/images/logo.png" alt="" style="width: 100%;"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="col-lg-12">
      <img
        src="/assets/images/report-an-issue.png"
        style="width: 100%; height: auto"
      />
    </div>
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
        <div class="mt-0" style="margin-top: 143px;">
          <section>
            <div class="container">
              <div class="about_heading follower change_password feedback">
                <h2 class="text-center">Report an Issue</h2>
                <form
                  [formGroup]="reportForm"
                  class="issue_report all_custom_placeholders"
                  (ngSubmit)="onSubmit()"
                >
                  <div class="label_input">
                    <div class="dropdown reports_drops">
                      <button
                        type="button"
                        id="text_change"
                        class="dropdown-toggle custom_drops_down button-select-report-type"
                        data-toggle="dropdown"
                      >
                        {{ value }}
                        <div
                          style="
                            text-align: right;
                          "
                        >
                          <img
                            style="height: 15px; width: 15px"
                            src="../../assets/Slider/images/icons/Gradient/arrowDown.svg"
                            alt=""
                          />
                        </div>
                      </button>
                      <div class="dropdown-menu report-list-dropdown-menu">
                        <ul *ngIf="getreportissue">
                          <li
                            *ngFor="let issue of getreportissue; let i = index"
                            class="dropdown-item"
                            (click)="selectIssue(issue.value.name)"
                          >
                            <div class="custom-control custom-radio">

                              <span>
                                {{ issue.value.name }}
                              </span>
                              <!-- <label
                                class="custom-control-label"

                                for="customRadio{{ i }}"
                                ></label> -->
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      *ngIf="submitted && selectedTypeError"
                      class="invalid-feedback"
                      style="display: block"
                    >
                      <div>
                        Report issue type is required.
                      </div>
                    </div>
                  </div>

                  <div *ngIf="value == 'Content'" class="mb-5">
                    <div
                      class="
                        full-data-container
                      "
                      *ngIf="loadBookData && loadBookData.status"
                    >
                      <div>
                        <img
                          class="rounded"
                          src="{{ baseimage }}{{ loadBookData.data.cover_image }}"
                          alt=""
                        />
                      </div>
                      <div class="d-flex flex-column book-data-container">
                        <div class="author__data">
                          <span>Author Name: </span>
                          <span class="font-italic text-warning">
                            {{ loadBookData.data.author_name }}</span
                          >
                        </div>
                        <div class="author__data">
                          <span>Book Title:</span>
                          <span class="font-italic text-warning">
                            {{ loadBookData.data.title }}</span
                          >
                        </div>
                        <div class="author__data">
                          <span>Chapter Name:</span>
                          <span class="font-italic text-warning">
                            {{
                              loadBookData.data.currentChapter.chapter_name
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="label_input">
                    <textarea
                      class="chapter-title textarya-stile"
                      maxlength="1000"
                      [ngClass]="{
                        'is-invalid': submitted && f.content.errors
                      }"
                      cols="30"
                      rows="5"
                      placeholder="Enter your issue"
                      formControlName="content"
                    ></textarea>
                    <div
                      *ngIf="submitted && f.content.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.content.errors.required">
                        Report content is required.
                      </div>
                    </div>
                  </div>

                  <div class="login_btn">
                    <button [disabled]="loading" class="site_btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
