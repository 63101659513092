import { BaseUrl } from "../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class ManageFeatureSectionService {
  genredata: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getbooks() {
    this.genredata = localStorage.getItem("currentUser");
    let token = JSON.parse( crypto.AES.decrypt(this.genredata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(this.base + `/manageContent`, null, {
      headers
    });
  }

  getFetureBook () {
    this.genredata = localStorage.getItem("currentUser");
    let token = JSON.parse( crypto.AES.decrypt(this.genredata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(this.base + `/getFeatureBook`, null, {
      headers
    });
  }

  addfeaturebook (data: any) {
    return this.http.post(this.base + `/addFeatureBook`, {book: data});
  }

  addAuthors (data) {
    return this.http.post(this.base + `/addAuthors`, {authors: data});
  }
}
