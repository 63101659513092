import { Component, OnInit } from "@angular/core";
import { AdminAddSubscriptionService } from "./admin-add-subscription.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-admin-add-subscription",
  templateUrl: "./admin-add-subscription.component.html",
  styleUrls: ["./admin-add-subscription.component.sass"]
})
export class AdminAddSubscriptionComponent implements OnInit {
  getGenre: any;
  updateForm: any;
  loading = false;
  submitted = false;
  showMsg = false;
  errorMsg: any;
  alertService: any;
  constructor(
    private addsub: AdminAddSubscriptionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.updateForm = this.formBuilder.group({
      title: ["", Validators.required],
      type: ["", Validators.required],
      price: ["", [Validators.required,Validators.min(1)]],
      description: ["", Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }
  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
  }
  onSubmit() {
    // console.log(this.updateForm.value);
    // this.cleanForm(this.updateForm);
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitted = true;
    
    this.addsub.addSubscription(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          // this.showMsg = res.message;
          this.toaster.success(res.message)
          this.router.navigate(["/manage-subscription"]);
        } else {
          this.toaster.error(res.message)
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
}
