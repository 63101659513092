<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="conatiner__">
      <div class="col-lg-12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height" >
          <img
            style="border-radius: 5px 5px 0 0;"
            class="register__banner"
            src="assets/images/payment-banner.png"
            alt=""
          />

          <!-- <mat-accordion class="mt-5"> -->
            <!-- <mat-expansion-panel> -->
              <!-- <mat-expansion-panel-header>
                <mat-panel-title>Card</mat-panel-title>
                <mat-panel-description>Pay with card</mat-panel-description>
              </mat-expansion-panel-header> -->
              <div class="register__container mt-5 d-flex justify-content-center">
                <div class="register__form__container text-center">
                  <h3 class="register__signup">Payment Method</h3>
                  <form #register="ngForm" class="mt-4">
                    <!-- Full Name -->
                    <div class="register__fullname text-left">
                      <label for="fullname">Card Number <span class="required-field-icon">*</span></label>
                      <mat-form-field
                        class="register__matformfield"
                        appearance="outline"
                      >
                        <span matPrefix class="material-icons-outlined">
                          lock
                        </span>
                        <mat-label class="lablja" >Type your card number</mat-label>
                        <input
                          class="chapter-title"
                          matInput
                          name="fullname"
                          type="text"
                          ngModel
                          required
                          #fullname="ngModel"
                        />
                      </mat-form-field>
                      <div
                        class="error-message"
                        *ngIf="fullname.touched && !fullname.valid"
                      >
                        <small class="text-danger"
                          >Enter your card number</small
                        >
                      </div>
                    </div>

                    <!-- Username -->
                    <div class="register__username text-left">
                      <label for="username">Expiration <span class="required-field-icon">*</span></label>
                      <mat-form-field
                        class="register__matformfield"
                        appearance="outline"
                      >
                        <span matPrefix class="material-icons-outlined">
                          lock
                        </span>

                        <mat-label
                          >Type your card number expiraton date 
                        </mat-label
                        >
                        <input
                          class="chapter-title"
                          matInput
                          name="username"
                          type="text"
                          ngModel
                          #username="ngModel"
                          required
                        />
                      </mat-form-field>
                      <div
                        class="error-message"
                        *ngIf="username.touched && !username.valid"
                      >
                        <small class="text-danger"
                          >Enter your card number expiration date</small
                        >
                      </div>
                    </div>

                    <!-- Email -->
                    <div class="register__email text-left">
                      <label for="email">CVV <span class="required-field-icon">*</span></label>
                      <mat-form-field
                        class="register__matformfield"
                        appearance="outline"
                      >
                        <span matPrefix class="material-icons-outlined">
                          lock
                        </span>

                        <mat-label>Type your CVV</mat-label>
                        <input
                          class="chapter-title"
                          matInput
                          name="email"
                          type="text"
                          required
                          #email="ngModel"
                          ngModel
                        />
                      </mat-form-field>
                      <div
                        class="error-message"
                        *ngIf="email.touched && !email.valid"
                      >
                        <small class="text-danger">Enter your CVV</small>
                      </div>
                    </div>

                    <!-- <input
                      class="w-100 font-italic register__submit-button"
                      type="submit"
                      value="Save"
                    /> -->
                    <button class="btn btn-cyan br-8 font-italic w-100 p-3 text-white fw-600 fs-13">Save</button>
                  </form>
                </div>
              </div>
            <!-- </mat-expansion-panel> -->
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Paypal</mat-panel-title>
                <mat-panel-description>Pay with paypal</mat-panel-description>
              </mat-expansion-panel-header>

              <div class="container-fluid" *ngIf="bookData">
                <div class="row">
                  <div class="col-md-2 text-center">
                    <div
                      [style.background-image]="
                        'url(' + baseimage + bookData.cover_image + ')'
                      "
                      style="
                        background-size: cover;
                        background-position: center;
                        border-radius: 8px;
                        height: 200px;
                        width: 150px;
                      "
                    >
                    </div>
                  </div>
                  <div
                    class="
                      col-md-2
                      d-flex
                      text-left
                      align-items-center
                      flex-column
                      justify-content-center
                    "
                  >
                    <div class="d-flex text-left">
                      <label>Author Name: </label>
                      <div class="font-italic">
                        @{{ bookData.author_username }}
                      </div>
                    </div>
                    <div class="d-flex text-left">
                      <label>Chapter Name: </label>
                      <div class="font-italic">
                        {{ bookData.currentChapter.chapter_name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 d-flex text-center">
                    <div class="d-flex align-items-center">${{ amount }}</div>
                  </div>
                </div>
              </div>

              <ngx-paypal class="ml-5" [config]="payPalConfig"></ngx-paypal>
            </mat-expansion-panel> -->
          <!-- </mat-accordion> -->
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
