import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.sass'],
})
export class ConfirmationModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {}

  title: string = 'Librums';
  description: string;
  leftAction: string = 'No';
  rightAction: string = 'Yes';

  ngOnInit(): void {
    if(this.data.cancel) this.description = 'Are you sure you want to cancel?';
    else if(this.data.edit) this.description = 'Are you sure you want to edit this record?'
    else if(this.data.save) this.description = 'Are you sure you want to save?'
    else if(this.data.custom) {
      this.title = this.data?.title;
      this.description = this.data?.description;
    }
  }

  onAction(action: boolean = true) {
    this.dialogRef.close(action);
  }
}
