<div class="">
  <div class="container-fluid">
     <div class="row d-flex justify-content-center read-chapter-container">
      <div class="col-lg-10">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height m-0" style="border-radius:10px">
          <div class="iq-card-body p-0">
            <div class=" p-0">
              <form class="m-0" enctype="multipart/form-data">
                <div class="">
                   <div class="book-wraper mt-3">
                      <div class="row m-0">
                         <div class="col-sm-4 col-xl-3 px-3 d-flex justify-content-center align-items-center">
                               <img *ngIf="bookData?.cover_image"
                                  loading="lazy" style="border-radius: 10px; height: 360px;"
                                  [src]="baseURL + bookData.cover_image" [routerLink]="['/', 'profile', 'mybookdetails', book_id]" alt="" onerror="this.src = 'assets/images/rectangle1.svg'" />

                         </div>
                         <div
                            class="col-sm-8 col-xl-8 p-0 pl-sm-3 pt-3 pt-sm-0 px-4 d-flex flex-column input-root-container justify-content-between h-100">
                            <div
                               class="d-flex flex-column book-data-container justify-content-center justify-content-sm-end">
                               <div
                                  class="bool-name mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                  {{bookData?.title}}
                               </div>
                               <div
                                  class="book-sentence mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                  {{bookData?.tagline}}
                               </div>
                               <div
                                  class="block-author-book mb-3 d-flex justify-content-center justify-content-sm-start">
                                  <img loading="lazy" class="image-author-book" [src]="baseURL + bookData?.author_image"
                                  onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'">
                                  <p class="m-0 pl-3 author-name-text">@{{bookData?.author_name}}</p>
                               </div>
                               <div
                                  *ngIf="!textIsBrief"
                                  class="book-description d-flex d-sm-block justify-content-center book-field-text">
                                  <p *ngIf="!showFullText">{{ briefText }}...<span class="showText" (click)="hendleShoweMoreText()">show more</span></p>
                                  <p *ngIf="showFullText">{{ fullText }}<span class="showText" (click)="hendleShoweLessText()">show less</span></p>
                               </div>
                               <div
                                  *ngIf="textIsBrief"
                                  class="book-description d-flex d-sm-block justify-content-center book-field-text">
                                  {{bookData?.description}}
                               </div>
                               <!-- <star-rating [value]="0" [totalstars]="5" checkedcolor="#11e1c2" uncheckedcolor="#C4C4C4" size="41px" [readonly]="false" (rate)="onRate($event)"></star-rating> -->
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
              </form>
              <div class="page-container">
                <div class="drop_box">
                  <div *ngIf="path === 'undefined'">
                      <div class="card text-center" style="margin-right: 32vh!important">
                          <div class="card-body">
                              <h2>No File Selected</h2>
                          </div>
                        </div>
                  </div>
                  <div *ngIf="path !== 'undefined'">
                      <pdf-viewer
                      [src]="pdf_url"
                      [render-text]="true"
                      [autoresize]="true"
                      style="min-height: 700px"
                      [zoom]="0.9"
                      [fit-to-page]="true"
                      [show-all]="true"
                      (after-load-complete)="afterLoadComplete($event)"
                      (error)="onError($event)"
                      ></pdf-viewer>
                      <!-- <object
                        data="pdf_url"
                        frameBorder="0"
                        scrolling="auto"
                        height="700px"
                        width="100%"
                      ></object> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
