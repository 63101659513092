import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb, BreadcrumbService } from 'angular-crumbs';
// import { Post } from './component/profile-create-post/profile-create-post.component';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.sass'],
})
export class ProfileLayoutComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  breadcrumbs;

  ngOnInit(): void {
    if (this.router.url == '/profile') {
      this.router.navigate(['./pro'], { relativeTo: this.activatedRoute });
    }
  }
}
