<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height p-3">
        <img loading="lazy" src="assets/images/bookmark.png" alt="" />
        <div class="container mt-5">
          <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 text-center">
              <div
                class="
                  crasoule_heading_txt_left
                  p-5
                  d-flex
                  justify-content-center
                "
              >
                <h2 class="m-0">My Bookshelf</h2>

              </div>

              <div class="nav-buttons">
                <div class="swiper-button-prev">
                  <i
                    style="color: black"
                    class="fas fa-chevron-left previous-button-icon"
                  ></i>
                </div>
              </div>
              <div class="swiper-container swiper1 column-swiper w-100">
                <div class="swiper-wrapper pt-4 pr-4">
                  <!-- routerLink="/singlebook/stories.book_details." -->
                  <a
                    class="swiper-slide"
                    *ngFor="let stories of bookshelfdata; let i = index"
                  >
                    <div>
                      <div class="slider-image-hoverable  bookshelf-cover-image-container" *ngIf="stories.book_details.cover_image !== ''">
                        <a (click)="goToBookPage(stories.book_details._id)">
                          <img
                            class="hover-over-image w-100"
                            src="{{ baseurl }}{{
                              stories.book_details.cover_image
                            }}"
                            alt=""
                          />
                        </a>

                        <i class="dots-three fas fa-ellipsis-h"></i>

                        <div *ngIf="stories.book_details.not_for_below_eighteen" class="eighteen-plus-container"><h4 class="eighteen-plus">18+</h4></div>
                        <div class="btn-icons">
                          <button type="button" class="btn-icon btn-add-cart" data-toggle="modal" (click)="removeBookShelf(stories)">
                            <i class="far fa-bookmark bookshelve-book"
                              aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <div class="slider-image-hoverable bookshelf-cover-image-container" *ngIf="stories.book_details.cover_image == ''">
                        <!-- https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg -->
                        <a (click)="goToBookPage(stories.book_details._id)">
                          <img
                            class="hover-over-image w-100"
                            src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                            alt=""
                          />
                        </a>

                        <i class="dots-three fas fa-ellipsis-h"></i>
                        <div *ngIf="stories.book_details.not_for_below_eighteen" class="eighteen-plus-container"><h4 class="eighteen-plus">18+</h4></div>
                        <div class="btn-icons">
                          <button type="button" class="btn-icon btn-add-cart" data-toggle="modal" (click)="removeBookShelf(stories)">
                            <i class="far fa-bookmark bookshelve-book"
                              aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>

                      <h2 class="text-left book-title">
                        {{ stories.book_details.title }}
                      </h2>

                      <div class="percentage_read mt-5 mb-5">
                        <span
                          *ngIf="stories.update_viewed == false"
                          style="height: 25px; width: 25px"
                          class="rounded-circle awesome-circle"
                        ></span>
                        <div
                          class="
                            d-flex
                            justify-content-end
                            align-items-center
                            text-dark
                            font-size-13
                          "
                        >
                        </div>
                        <span
                          *ngIf="stories.update_viewed == false"
                          style="height: 15px; width: 15px"
                          class="rounded-circle awesome-circle"
                        ></span>

                        <span
                          *ngIf="stories.update_viewed == false"
                          class="mr-4 awesome"
                          >New</span
                        >

                        <mat-progress-bar
                          mode="determinate"
                          [value]="(stories?.readingPercentage / stories?.book_details?.no_of_chapter) * 100"
                          color="#0DD6B8"
                        ></mat-progress-bar>
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                            text-dark
                            font-size-13
                          "
                        >
                          <span class="mr-4">{{(stories?.readingPercentage / stories.book_details?.no_of_chapter) * 100 | number: "1.0-0"}}%</span>
                        </div>
                        <ng-container>
                          <div class="d-flex justify-content-between book-data-rating">
                            <div class="">
                              <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
                              <span class="views-hearts-shares-bookshelves">{{ stories?.book_details.views }}</span>
                            </div>
                            <div class="">
                              <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
                              <span class="views-hearts-shares-bookshelves">{{ stories?.book_details.likes }}</span>
                            </div>
                            <div class="">
                              <mat-icon class="small color-cyan-secondary">share</mat-icon>
                              <span class="views-hearts-shares-bookshelves">{{ stories?.book_details.no_of_shares }}</span>
                            </div>
                            <div class="">
                              <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
                              <span class="views-hearts-shares-bookshelves">{{ stories?.book_details.no_of_chapter }}</span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div class="nav-buttons">
                <div class="swiper-button-next">
                  <i
                    style="color: black"
                    class="fas fa-chevron-right next-button-icon"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container ">
          <h4 class="swiper-name">Liked Books</h4>
          <div class="row" style="overflow-x: hidden;">
            <div class="col-sm-12 col-lg-12 col-md-12 text-center pb-3 liked-book-container">
              <div class="swiper swiper2">
                <div class="swiper-wrapper">
                  <div
                    *ngFor="let book of likedBooks; let i = index"
                    class="swiper-slide"
                  >
                    <div *ngIf="book.cover_image !== ''" class="position-relative">
                      <img
                        routerLink="/singlebook/{{ book._id }}"
                        class="liked-books-effect w-100"
                        src="{{ baseurl }}{{ book.cover_image }}"
                        alt=""
                      />
                      <div *ngIf="book.not_for_below_eighteen" class="eighteen-plus-container liked-eighteen-plus"><h4 class="eighteen-plus">18+</h4></div>
                    </div>
                    <div *ngIf="book.cover_image == ''" class="position-relative">
                      <img
                        routerLink="/singlebook/{{ book._id }}"
                        class="liked-books-effect w-100"
                        src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                        alt=""
                      />
                      <div *ngIf="book.not_for_below_eighteen" class="eighteen-plus-container liked-eighteen-plus"><h4 class="eighteen-plus">18+</h4></div>
                    </div>
                    <h3 class="font-italic text-center m-0 book-title">
                      <span>
                        {{ book.title }}
                      </span>
                    </h3>
                  </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination swiper-pagination1"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="container mt-5">
          <h4 class="swiper-name">Last viewed books</h4>
          <div class="row" style="overflow-x: hidden;">
            <div class="col-sm-12 col-lg-12 col-md-12 text-center pb-3 last-seen-book-container">
              <div class="swiper swiper2">
                <div class="swiper-wrapper">
                  <div
                    *ngFor="let lastBook of lastSeenBook; let i = index"
                    class="swiper-slide"
                  >
                    <div *ngIf="lastBook.book.cover_image !== ''">
                      <img
                        routerLink="/singlebook/{{ lastBook.authorDetails._id }}"
                        class="liked-books-effect"
                        src="{{ baseurl }}{{ lastBook.book.cover_image }}"
                        alt=""
                      />
                    </div>
                    <div *ngIf="lastBook.book.cover_image == ''">
                      <img
                        routerLink="/singlebook/{{ lastBook.authorDetails._id }}"
                        class="liked-books-effect"
                        src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle.jpg"
                        alt=""
                      />
                    </div>
                    <h3 class="font-italic text-center m-0 book-title">
                      <span>
                        {{ lastBook.book.title }}
                      </span>
                    </h3>
                  </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination swiper-pagination1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>
