import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AddBookCoverComponent } from './add-book-cover/add-book-cover.component';
import { AuthGuard } from './auth.guard';
import { AuthorProfileComponent } from './profile-author/author-profile/author-profile.component';
import { BookDetailComponent } from './book-detail/book-detail.component';
import { ChapterPreviewComponent } from './chapter-preview/chapter-preview.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { EditChapterComponent } from './edit-chapter/edit-chapter.component';
import { EditStoriesComponent } from './edit-stories/edit-stories.component';
import { HomeComponent } from './home/home.component';
import { BookshelfComponent } from './notification/bookshelf/bookshelf.component';
import { NotificationPageComponent } from './notification/notification-page/notification-page.component';
// import { MybookdetailsComponent } from './user-profile/component/mybookdetails/mybookdetails.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReadChapterComponent } from './read-chapter/read-chapter.component';
import { ReportAnIssueComponent } from './report-an-issue/report-an-issue.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { TempComponent } from './temp/temp.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FeedbackComponent } from './user-profile/component/feedback/feedback.component';
import { ReadingSettingsComponent } from './user-profile/component/reading-settings/reading-settings.component';
import { WithdrawalComponent } from './user-profile/component/withdrawal/withdrawal.component';
import { ProfileLayoutComponent } from './user-profile/profile-layout.component';
import { WriteComponent } from './write/write.component';
import { CreateStoriesComponent } from './create-stories/create-stories.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { WithdrawMoneyComponent } from './user-profile/component/withdrawal/withdraw-money/withdraw-money.component';
// import { SingleBookComponent } from './pages/single-book/single-book.component';
import { WriteMediaContentComponent } from './write-media-content/write-media-content.component';
import { UploadBookComponent } from './upload-book/upload-book.component';
import { ReadBookComponent } from './read-book/read-book.component';
import { EditProfileInfoComponent } from './edit-profile-info/edit-profile-info.component';


// Admin Components

import { DashboardComponent } from "./admin/admin-modules/dashboard/dashboard.component";
import { UsersComponent } from "./admin/admin-modules/users/users.component";
import { ManageContentComponent } from "./admin/admin-modules/manage-content/manage-content.component";
import { ChapterPaymentsComponent } from "./admin/admin-modules/chapter-payments/chapter-payments.component";
import { PaymentsDashboardComponent } from "./admin/admin-modules/payments-dashboard/payments-dashboard.component";
import { MessageBoardComponent } from "./admin/admin-modules/message-board/message-board.component";
import { BackendFeedbackComponent } from "./admin/admin-modules/backend-feedback/backend-feedback.component";
import { ManageNotificationComponent } from "./admin/admin-modules/manage-notification/manage-notification.component";
import { BackendNotificationsComponent } from "./admin/admin-modules/backend-notifications/backend-notifications.component";
import { AdminContactUsComponent } from "./admin/admin-modules/contact-us/contact-us.component";
import { ViewchapterComponent } from "./admin/admin-modules/viewchapter/viewchapter.component";
import { ViewFeedbackComponent } from "./admin/admin-modules/view-feedback/view-feedback.component";
import { EditUsersComponent } from "./admin/admin-modules/edit-users/edit-users.component";
import { AuthorListComponent } from "./admin/admin-modules/author-list/author-list.component";
import { PublishedBooksComponent } from './admin/admin-modules/published-books/published-books.component';
import { EditAuthorComponent } from './admin/admin-modules/edit-author/edit-author.component';
import { PublishedBookViewComponent } from './admin/admin-modules/published-book-view/published-book-view.component';
import { PublishedBookContentComponent } from './admin/admin-modules/published-book-content/published-book-content.component';
import { ManageSliderComponent } from './admin/admin-modules/manage-slider/manage-slider.component';
import { AddSliderComponent } from './admin/admin-modules/add-slider/add-slider.component';
import { ManageContentTypeComponent } from './admin/admin-modules/manage-content-type/manage-content-type.component';
import { AddManageContentTypeComponent } from './admin/admin-modules/add-manage-content-type/add-manage-content-type.component';
import { EditManageContentTypeComponent } from './admin/admin-modules/edit-manage-content-type/edit-manage-content-type.component';
import { ManageCopyrightComponent } from './admin/admin-modules/manage-copyright/manage-copyright.component';
import { AddManageCopyrightComponent } from './admin/admin-modules/add-manage-copyright/add-manage-copyright.component';
import { EditManageCopyrightComponent } from './admin/admin-modules/edit-manage-copyright/edit-manage-copyright.component';
import { ManageGenreComponent } from './admin/admin-modules/manage-genre/manage-genre.component';
import { EditManageGenreComponent } from './admin/admin-modules/edit-manage-genre/edit-manage-genre.component';
import { AddManageGenreComponent } from './admin/admin-modules/add-manage-genre/add-manage-genre.component';
import { ManageFeatureSectionComponent } from './admin/admin-modules/manage-feature-section/manage-feature-section.component';
import { ManagePagesComponent } from './admin/admin-modules/manage-pages/manage-pages.component';
import { ViewManagePageComponent } from './admin/admin-modules/view-manage-page/view-manage-page.component';
import { EditManagePageComponent } from './admin/admin-modules/edit-manage-page/edit-manage-page.component';
import { AdminSubscriptionComponent } from './admin/admin-modules/admin-subscription/admin-subscription.component';
import { AdminAddSubscriptionComponent } from './admin/admin-modules/admin-add-subscription/admin-add-subscription.component';
import { AdminEditSubscriptionComponent } from './admin/admin-modules/admin-edit-subscription/admin-edit-subscription.component';
import { ProfileComponent } from './admin/admin-modules/profile/profile.component';
import { ChangePasswordComponent } from './admin/admin-modules/change-password/change-password.component';
import { BookClubsComponent } from './pages/book-clubs/book-clubs.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  // { path: 'login', component: LoginComponent },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  { path: 'book/:type/:id/:genre_id', component: BookDetailComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  // Type will be web, vr, mobile
  { path: 'privacy-policy', redirectTo: 'privacy-policy/web', pathMatch: 'full' },
  { path: 'privacy-policy/:type', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  {
    path: 'report-an-issue',
    component: ReportAnIssueComponent,
    data: { breadcrumb: 'Report and Issue' },
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'verify-email/:id',
  //   component: VerifyEmailComponent,
  //   data: { title: 'Verify Email' },
  // },
  { path: 'search', component: SearchPageComponent, canActivate: [AuthGuard] },
  { path: 'dummy', component: TempComponent },
  { path: 'write-media-content/:id', component: WriteMediaContentComponent },
  {
    path: 'write',
    component: WriteComponent,
    canActivate: [AuthGuard]
  },
  { path: 'write/:id', component: WriteComponent, canActivate: [AuthGuard] },

  { path: 'read-book/:id', component: ReadBookComponent, canActivate: [AuthGuard]},
  {
    path: 'profile',
    component: ProfileLayoutComponent,
    data: { breadcrumb: 'profile' },
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard],
    component: FeedbackComponent,
    data: { breadcrumb: 'Feedback', url: 'feedback' },
  },
  {
    path: 'withdrawal',
    canActivate: [AuthGuard],
    component: WithdrawalComponent,
    data: { breadcrumb: 'Withdrawal', url: 'withdrawal' },
  },
  {
    path: 'reading-settings',
    canActivate: [AuthGuard],
    component: ReadingSettingsComponent,
    data: { breadcrumb: 'Reading Settings', url: 'reading-settings' },
  },
  {
    path: 'add-book-cover',
    component: AddBookCoverComponent,
  },

  {
    path: 'chapter-preview',
    component: ChapterPreviewComponent,
    canActivate: [AuthGuard],
    data: { title: 'Chapter Preview' },
  },
  {
    path: 'editstories/:id',
    component: EditStoriesComponent,
    canActivate: [AuthGuard],
    data: { title: 'Edit Stories' },
  },
  {
    path: 'readchapter/:book_id/:chapter_id/:author_id',
    component: ReadChapterComponent,
    canActivate: [AuthGuard],
    data: { title: 'Read Chapter' },
  },
  {
    path: 'editchapter/:book_id/:chapter_id',
    component: EditChapterComponent,
    canActivate: [AuthGuard],
    data: { title: 'Edit Chapter' },
  },
  // {
  //   path: 'editchapter/:book_id/:chapter_id/:type',
  //   component: EditChapterComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: 'Edit Chapter' },
  // },
  {
    path: 'notification',
    component: NotificationPageComponent,
    canActivate: [AuthGuard],
    data: { title: 'Notification' },
  },

  // TWO ROUTES
  {
    path: 'authorprofile/:id',
    canActivate: [AuthGuard],
    component: AuthorProfileComponent,
    data: { title: 'Author Profile' },
  },

  {
    path: 'authorprofile/:id/:tab_type',
    component: AuthorProfileComponent,
    canActivate: [AuthGuard],
    data: { title: 'Author Profile' },
  },

  {
    path: 'bookshelf',
    component: BookshelfComponent,
    canActivate: [AuthGuard],
    data: { title: 'My Bookshelf' },
  },
  // {
  //   path: 'Singup',
  //   component: RegisterComponent,
  //   data: { title: 'Singup' },
  // },
  {
    path: 'create-stories',
    component: CreateStoriesComponent,
    data: { title: 'Create Stories' },
  },
  {
    path: 'create-stories/:type',
    component: CreateStoriesComponent,
    data: { title: 'Create Stories' },
  },
  {
    path: 'payment-method',
    component: PaymentMethodComponent,
    data: { title: 'Payment Method' },
  },
  {
    path: 'book-club',
    component: BookClubsComponent
  },
  {
    path: 'withdraw-money',
    component: WithdrawMoneyComponent,
    data: { title: 'Withdraw Money' },
  },

  // Admin Routes

  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {title: 'Dashboard'}
  },
  {
    path: "users",
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {title: 'Users'}
  },
  {
    path: "manage-books",
    component: ManageContentComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Books'}
  },
  {
    path: "chapter-payments",
    component: ChapterPaymentsComponent,
    canActivate: [AuthGuard],
    data: {title: 'Chapter Payments'}
  },
  {
    path: "subscription-payments",
    component: PaymentsDashboardComponent,
    canActivate: [AuthGuard],
    data: {title: 'Subscription Payments'}
  },
  {
    path: "reported-issues",
    component: MessageBoardComponent,
    canActivate: [AuthGuard],
    data: {title: 'Reported Issues'}
  },
  {
    path: "feedbacks-and-suggestions",
    component: BackendFeedbackComponent,
    canActivate: [AuthGuard],
    data: {title: 'Feedbacks And Suggestions'}
  },
  {
    path: "manage-notifications",
    component: ManageNotificationComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Notifications'}
  },
  {
    path: "backend-notifications",
    component: BackendNotificationsComponent,
    canActivate: [AuthGuard],
    data: {title: 'Backend Notifications'}
  },
  {
    path: "manage-contact-us",
    component: AdminContactUsComponent,
    canActivate: [AuthGuard],
    data: {title: 'Contact Us'}
  },
  {
    path: "viewchapter/:id",
    component: ViewchapterComponent,
    canActivate: [AuthGuard],
    data: {title: 'View Chapter'}
  },
  {
    path: "view-feedback/:id",
    component: ViewFeedbackComponent,
    canActivate: [AuthGuard],
    data: {title: 'View Feedback'}
  },
  {
    path: "edit-users/:id",
    component: EditUsersComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit User'}
  },
  {
    path: "authors",
    component: AuthorListComponent,
    canActivate: [AuthGuard],
    data: {title: 'Authors'}
  },
  {
    path: "published-books",
    component: PublishedBooksComponent,
    canActivate: [AuthGuard],
    data: {title: 'Published Books'}
  },
  {
    path: "edit-author/:id",
    component: EditAuthorComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Author'}
  },
  {
    path: "view-published-book/:id",
    component: PublishedBookViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'View Published Book'}
  },
  {
    path: "published-book-content/:id",
    component: PublishedBookContentComponent,
    canActivate: [AuthGuard],
    data: {title: 'Published Book Content'}
  },
  {
    path: "manage-slider",
    component: ManageSliderComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Sliders'}
  },
  {
    path: "add-slider",
    component: AddSliderComponent,
    canActivate: [AuthGuard],
    data: {title: 'Add Slider'}
  },
  {
    path: "manage-content-type",
    component: ManageContentTypeComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Content Types'}
  },
  {
    path: "add-manage-content-type",
    component: AddManageContentTypeComponent,
    canActivate: [AuthGuard],
    data: {title: 'Add Content Type'}
  },
  {
    path: "edit-manage-content-type/:id",
    component: EditManageContentTypeComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Content Type'}
  },
  {
    path: "manage-copyright",
    component: ManageCopyrightComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Copyrights'}
  },
  {
    path: "add-manage-copyright",
    component: AddManageCopyrightComponent,
    canActivate: [AuthGuard],
    data: {title: 'Add Copyright'}
  },
  {
    path: "edit-manage-copyright/:id",
    component: EditManageCopyrightComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Copyright'}
  },
  {
    path: "manage-genre",
    component: ManageGenreComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Genres'}
  },
  {
    path: "add-manage-genre",
    component: AddManageGenreComponent,
    canActivate: [AuthGuard],
    data: {title: 'Add Gerne'}
  },
  {
    path: "edit-manage-genre/:id",
    component: EditManageGenreComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Manage Genre'}
  },
  {
    path: "manage-feature-section",
    component: ManageFeatureSectionComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Feature'}
  },
  {
    path: "manage-pages",
    component: ManagePagesComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Pages'}
  },
  {
    path: "view-manage-page/:id",
    component: ViewManagePageComponent,
    canActivate: [AuthGuard],
    data: {title: 'View Page'}
  },
  {
    path: "edit-manage-page/:id",
    component: EditManagePageComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Page'}
  },
  {
    path: "manage-subscription",
    component: AdminSubscriptionComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manage Subscription'}
  },
  {
    path: "add-subscription",
    component: AdminAddSubscriptionComponent,
    canActivate: [AuthGuard],
    data: {title: 'Add Subscription'}
  },
  {
    path: "edit-subscription/:id",
    component: AdminEditSubscriptionComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Subscription'}
  },
  {
    path: "profile/:id",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {title: 'Profile'}
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {title: 'Change Password'}
  },
  {
    path: "edit-profile-info",
    component: EditProfileInfoComponent,
    canActivate: [AuthGuard],
    data: {title: 'Edit Profile Info'}
  },


  // Lazy loaded
  // {
  //   path: 'singlebook',
  //   canActivate: [AuthGuard],
  //   data: { title: 'Single Book' },
  // },

  { path: 'singlebook',  canActivate: [AuthGuard], loadChildren: () => import('./pages/single-book/single-book.module').then(m => m.SingleBookModule)},

  // Validation
  { path: 'validation', loadChildren: () => import('./pages/validation/validation.module').then(m => m.ValidationModule) },
  // {
  //   path: 'mybookdetails/:id',
  //   component: MybookdetailsComponent,
  //   data: { breadcrumb: 'My Stories' },
  // },
  // { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
