import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from 'src/app/base-url';
import { RoomService } from 'src/app/services/room/room.service';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.sass'],
})
export class UserNotificationComponent implements OnInit {
  @Input() notificationData: any;

  baseimage: string;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private roomService: RoomService,
    private toastService: ToastrService
  ) {
    this.baseimage = BaseUrl.image;
  }

  newNotifications: boolean;
  numberOfNewNotifications: number = 0;
  userNotification: any;
  ngOnChanges(change: SimpleChanges) {
    if (change.notificationData.currentValue) {
      this.notificationData = change.notificationData.currentValue;
      this.userNotification = (this.notificationData?.data || [])?.slice(0,10);
      this.numberOfNewNotifications = this.notificationData?.total_unread_items;
    }
  }

  private messages = (data: any) => {
    let messagesArray = [];
    data.every((element) => {
      element.data.every((elem) => {
        if (
          elem.type == 'other_wall' ||
          elem.type == 'comment' ||
          elem.type == 'my_wall' ||
          elem.type == 'report' ||
          elem.type == 'room_invite' ||
          elem.type == 'like'
        ) {
          messagesArray.push(elem);
          if (!elem.viewed) this.numberOfNewNotifications++;
        }
        return true;
      });

      return true;
    });

    if (messagesArray.length > 0) {
      this.newNotifications = true;
    }

    return messagesArray;
  };

  public hasNewNotification = (data) => {
    data.forEach((element: any, index: number) => {
      element.data.forEach((elem) => {
        if (
          elem.type == 'other_wall' ||
          elem.type == 'comment' ||
          elem.type == 'my_wall' ||
          elem.type == 'report' ||
          elem.type == 'room_invite'
        ) {
          this.newNotifications = true;
          return;
        }
      });
    });
  };

  goto() {
    this.notificationService.setActiveTab(1);
    this.router.navigate(['notification']);
  }


  room_invite_action (notification: any, status: 'active' | 'declined') {
    this.roomService.notification_invite_room_action({ room_id: notification?.room_id, status }).subscribe((res: any) => {
      if(res?.status) {
        this.toastService.success(`Room invitation has been ${status === 'active' ? 'activated' : 'declined'} successfully`);
          this.loadNotification();

      } else {
        this.toastService.warning(res?.message);
      }
    });
  }

  loadNotification() {
    this.notificationService.getNotification().subscribe(
    (res: any) => {
      if (res.status) {
        this.notificationData = res;
        this.userNotification = (this.notificationData?.data || [])?.slice(0,10);
        this.numberOfNewNotifications = this.notificationData?.total_unread_items;
      }
    },
    (error) => {
      console.log('Error', error);
    }
    );
  }

  ngOnInit(): void {
  }
}
