import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.sass'],
})
export class InviteFriendsComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<InviteFriendsComponent>) {}

  close() {
    this.dialogRef.close()
  }

  ngOnInit(): void {}
}
