import { Component, OnInit, AfterViewChecked } from '@angular/core';
import * as $ from 'jquery';
interface userListInterface {
  name: string;
  value: string;
}
@Component({
  selector: 'app-reading-settings',
  templateUrl: './reading-settings.component.html',
  styleUrls: ['./reading-settings.component.sass'],
})
export class ReadingSettingsComponent implements OnInit {
  activeClass: any;
  activeFont: any;
  activeSize: any;
  userlist: Array<userListInterface>;
  constructor() {}

  ngOnInit() {
    this.userlist = [
      { name: 'Robo To Bold', value: 'robotobold' },
      { name: 'aileronlight', value: 'aileronlight' },
      { name: 'Inconsolata Medium', value: 'inconsolatamedium' },
      { name: 'Comic Reliefregular', value: 'comic_reliefregular' },
      { name: 'Halo Regular', value: 'haloregular' },
      { name: 'Droid Serifregular', value: 'droid_serifregular' },
      {
        name: 'Noname Nocontrast Serif V1Rg',
        value: 'noname_nocontrast_serif_v1Rg',
      },
      {
        name: 'UCU Charles Script Regular',
        value: 'ucu_charles_scriptregular',
      },
      { name: 'Geometric Sans Serif V1Rg', value: 'geometric_sans_serif_v1Rg' },
      { name: 'Press Start 2Pregular', value: 'press_start_2pregular' },
      { name: 'The God Father Regular', value: 'the_godfatherregular' },
    ];
    this.activeSize = 25;
    this.activeFont = 'robotobold';
    if ($('li.active').find('span').text() == 'Eggshell') {
      $('.demo-back').css('background-color', '#eeeee3');
      $('.demo-back').css('color', '#222');
    } else if ($('li.active').find('span').text() == 'Nightowl') {
      $('.demo-back').css('background-color', '#212121');
      $('.demo-back').css('color', '#fff');
    } else {
      $('.demo-back').css('background-color', '#fff');
      $('.demo-back').css('color', '#222');
    }

    // $('.demo-back').css('font-family', $('#font-drop').val());
    $('.demo-text').css('font-size', $('.slider1').val() + 'px');
    $('.demo-heading').css('font-size', $('.slider1').val() + 'px');

    let color = localStorage.getItem('color');
    if (color) {
      this.activeClass = color;
      if (color == 'color_wheat') {
        $('.demo-back').css('background-color', '#eeeee3');
        $('.demo-back').css('color', '#222');
        $('.demo-heading').css('color', '#222');
      } else if (color == 'color_black') {
        $('.demo-back').css('background-color', '#212121');
        $('.demo-back').css('color', '#fff');
        $('.demo-heading').css('color', '#fff');
      } else {
        $('.demo-back').css('background-color', '#fff');
        $('.demo-back').css('color', '#222');
        $('.demo-heading').css('color', '#222');
      }
    }

    let font = localStorage.getItem('font');
    if (font) {
      this.activeFont = font;
      $('.demo-back').css('font-family', font);
      $('.demo-heading').css('font-family', font);
    }

    let size = localStorage.getItem('size');
    if (size) {
      this.activeSize = size;
      $('.demo-text').css('font-size', size + 'px');
      $('.demo-heading').css('font-size', size + 'px');
    }
  }

  ngAfterViewChecked() {
    let font = localStorage.getItem('font');
    if (font) {
      $('[name=' + font + '] option').prop('selected', true);
    }
  }

  colorPick(color) {
    localStorage.setItem('color', color);
    $('li.color').find('a').removeClass('.active');
    this.activeClass = color;

    if (color == 'color_wheat') {
      $('.demo-back').css('background-color', '#eeeee3');
      $('.demo-back').css('color', '#222');
    } else if (color == 'color_black') {
      $('.demo-back').css('background-color', '#212121');
      $('.demo-back').css('color', '#fff');
    } else {
      $('.demo-back').css('background-color', '#fff');
      $('.demo-back').css('color', '#222');
    }

    $('.demo-heading').css('color', '#222');
    console.log('color', color);
    if (color == 'color_black') {
      $('.demo-heading').css('color', '#fff');
    }
  }
  onFontChange(font: any) {
    font = font.target.value;
    console.log('font', font);
    localStorage.setItem('font', font);
    $('.demo-back').css('font-family', font);
    $('.demo-heading').css('font-family', font);
    this.activeFont = font;
  }
  onFontSize(size) {
    size = size.target.value;
    localStorage.setItem('size', size);
    this.activeSize = size;
    $('.demo-text').css('font-size', size + 'px');
    $('.demo-heading').css('font-size', size + 'px');
  }
}
