import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { AuthorProfileService } from '../author-profile/author-profile.service';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.sass'],
})
export class FollowingComponent implements OnInit {
  @Input() authorData: any;
  constructor(
    private authorService: AuthorProfileService,
    private activatedRoute: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private router: Router,
    private helper: HelperService,
    private userProfileService: UserProfileService
  ) {
    this.baseimageurl = BaseUrl.image;
  }

  following: any;
  baseimageurl: string;
  currentUser: any;
  ngOnInit(): void {
    const userId = this.helper.getUserData().data._id;
    // console.log('user Id:  dsdssd', userId);
    this.currentUser = this.helper.getUserData().data;
    this.authorService.getFollowing(userId).subscribe((res: any) => {
      this.following = res.data;
    });
  }

  manageFollowing(status: number, user_id: string) {
    this.authorService
      .followAuther({
        status: status,
        user_id: user_id,
      })
      .subscribe(
        (res: any) => {
          if (res.status) {
            this._snackbar.open(res.message, 'ok', {
              duration: 2000,
            });
            const currentUrl = this.router.url;
            const userId = this.helper.getUserData().data._id;
            this.currentUser = this.helper.getUserData().data;
            this.authorService.getFollowing(userId).subscribe((res: any) => {
              this.following = res.data;
            });
          }
        },
        (error) => {
          console.log('Error: ', error);
        }
      );
  }
}
