<mat-drawer-container class="sidebar-drawer-container" autosize>
  <mat-drawer #drawer class="sidebar-drawer" mode="over">

    <div class="d-flex justify-content-end m-2">
      <button mat-icon-button (click)="drawer.close()" class="shadow">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <ul id="iq-sidebar-toggle" class="iq-menu">
          <div class="division">
            <li matRipple>
              <a (click)="drawer.close()" routerLink="/" class="iq-waves-effect" data-toggle="collapse"
                aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Home</span>
              </a>
            </li>
            <li matRipple>
              <a (click)="drawer.close()" routerLink="/create-stories" class="iq-waves-effect" data-toggle="collapse"
                aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Write</span>
              </a>
            </li>
            <li matRipple>
              <a (click)="drawer.close()" routerLink="/search" class="iq-waves-effect" data-toggle="collapse"
                aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Explore</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" [routerLink]="['/authorprofile', userData?._id]" class="iq-waves-effect"
                data-toggle="collapse" aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>My Profile</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" [routerLink]="['/book-club']" class="iq-waves-effect"
                data-toggle="collapse" aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Book Club</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" routerLink="/withdrawal" class="iq-waves-effect" data-toggle="collapse"
                aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Wallet</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" [routerLink]="['/payment-method']" class="iq-waves-effect"
                data-toggle="collapse" aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Payment Method</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" [routerLink]="['/reading-settings']" class="iq-waves-effect"
                data-toggle="collapse" aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Reading Settings</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="logout(); drawer.close();" class="iq-waves-effect" data-toggle="collapse"
                aria-expanded="false">
                <span>Signout</span>
              </a>
            </li>
          </div>
          <hr>
          <div class="division">
            <li matRipple>
              <a (click)="showInviteOptions(); drawer.close();" routerLink="/withdrawal" class="iq-waves-effect"
                data-toggle="collapse" aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Invite Friends</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" [routerLink]="['/feedback']" class="iq-waves-effect" data-toggle="collapse"
                aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Feedback And Suggestions</span>
              </a>
            </li>

            <li matRipple>
              <a (click)="drawer.close()" [routerLink]="['/report-an-issue']" class="iq-waves-effect"
                data-toggle="collapse" aria-expanded="false"><span class="ripple rippleEffect"></span>
                <span>Report</span>
              </a>
            </li>
          </div>

        </ul>
      </nav>
    </div>
  </mat-drawer>
  <div class="example-sidenav-content">
    <div>
      <app-navbar *ngIf="role==='user'"></app-navbar>
      <app-header *ngIf="role==='admin'"></app-header>
    </div>

    <!-- Side Bar -->

    <!-- Breadcrumbs -->
    <div *ngIf="!show" class="col-1 menu" [hidden]="router?.url?.includes('login')">
      <div class="d-flex align-items-center">
         <img loading="lazy" class="mr-1" src="../../assets/images/left-arrow-blue.png" alt="">
         <button (click)="previousUrl()" class="back-button-navigation">back</button>
      </div>
    </div>
    <button *ngIf="role" type="button" class="toggle-button" mat-button (click)="drawer.toggle()">
      <i class="fas fa-bars"></i>
    </button>
    <div class="w-100 d-flex justify-content-center" *ngIf="role">
      <img loading="lazy" class="site-logo responsive" routerLink="/" src="../../assets/Home/images/site-logo.png" alt="" />
    </div>
    <!-- <img loading="lazy" src="../../assets/images/message-text-icon.png" alt="" class="notification-icons site-logo-scrch"> -->
    <!-- <div *ngIf="show" class="site-logo-scrch-div">
      <img loading="lazy" src="../../assets/images/header-scarch-icone.png" alt="" class="header-scarch-icone site-logo-scrch">
    </div> -->
    <div class="{{role === 'user' ? 'wrapper-content-block' : ''}}"><router-outlet></router-outlet></div>
  <!-- <div class="example-sidenav-content">
    <div *ngIf="show">
      <app-navbar *ngIf="role==='user'"></app-navbar>
      <app-header *ngIf="role==='admin'"></app-header>
    </div>

    <div class="{{ show ? 'container-fluid mt-4' : 'container-fluid' }}">
      <div class="row">
        <div *ngIf="show" class="col-1 menu"></div>
        <div *ngIf="!show" class="col-1 menu">
            <div class="d-flex align-items-center">
               <img loading="lazy" class="mr-1" src="../../assets/images/left-arrow-blue.png" alt="">
               <button (click)="previousUrl()" class="back-button-navigation">back</button>
            </div>
        </div>
        <div class="{{ show ? '' : '' }}" style="margin-top: {{ show ? '150' : '50px' }}"><router-outlet></router-outlet></div>
        <div *ngIf="show" class="col-1"></div>
      </div>
      <div>

      </div>
    </div> -->

  </div>
</mat-drawer-container>

