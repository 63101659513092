<div style="width: 88%; margin: auto; overflow: hidden;">
  <app-books-slider [fullSize]="" [title]="title" [id]="bookId" type="similar_books"></app-books-slider>
</div>

<!-- <div class="col-lg-12">
  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
    <div class="
        iq-card-header
        d-flex
        justify-content-between
        align-items-center
        position-relative
      ">
      <div class="iq-header-title">
        <h4 class="card-title mb-0 section-heading">Similar Books</h4>
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <a href="category.html" class="btn btn-sm global-button">View More</a>
      </div>
    </div>

    <div class="p-5">
      <div class="
          swiper-container
          similar-books-swiper-container
          iq-card-body
          single-similar-contens
          w-100
        ">
        <ul class="swiper-wrapper">
          <li class="swiper-slide" *ngFor="let book of bookData" style="width: 35%;">
            <div class="row align-items-center">
              <div class="col-5">
                <div class="position-relative image-overlap-shadow">
                  <a href="javascript:void();"><img loading="lazy" class="img-fluid rounded img-border-radius h-100 w-100"
                      [src]="baseUrl + book?.cover_image" alt=""
                      onerror="this.src = 'assets/images/rectangle1.svg'" /></a>
                  <div class="view-book">
                    <a [routerLink]="['/singlebook', book._id]" class="btn btn-sm global-button">
                      View Book
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-7 pl-0">
                <h6 class="mb-2">{{ book?.title | slice:0:20 }} <span *ngIf="book?.title?.length > 20">...</span> </h6>
                <p class="text-body">Author : {{ book?.author_name }}</p>
                <a href="#" class="text-dark" tabindex="-1">Read Now<i class="ri-arrow-right-s-line"></i></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="nav-buttons">
        <div class="swiper-button-prev">
          <i class="fas fa-chevron-left previous-button-icon" style="z-index: 0 !important"></i>
        </div>
        <div class="swiper-button-next">
          <i class="fas fa-chevron-right next-button-icon" style="z-index: 0 !important"></i>
        </div>
      </div>
    </div>
  </div>
</div> -->
