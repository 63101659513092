import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FeedbackService } from "src/app/services/feedback/feedback.service";

@Component({
  selector: 'app-view-feedback',
  templateUrl: './view-feedback.component.html',
  styleUrls: ['./view-feedback.component.sass']
})
export class ViewFeedbackComponent implements OnInit {
  managePages: any;
  temp: boolean;
  getPages: any;
  // tslint:disable-next-line: variable-name
  feedback_id: string;
  showLoader = false
  constructor(
    private feedbackService: FeedbackService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.showLoader = true;
    this.feedback_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadPages();
  }
  loadPages() {
    this.feedbackService.getFeedbackDetail(this.feedback_id).subscribe(
      (res: any) => {
        this.getPages = res.data;
        this.showLoader = false;
      },
      () => {
        console.log("ERROR");
      }
    );
  }
}
