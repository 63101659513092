import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.sass'],
})
export class ImageDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImageDialogComponent>
  ) {}
  name = 'Angular';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  crop: boolean = true;
  aspectRatio: any = 3/4
  roundCropper: boolean = false;
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  submitCroppedImage() {
    this.dialogRef.close({ data: this.croppedImage });
  }
  ngOnInit(): void {

    // if aspect ration is provided from outer source then use that value otherwise use default 3/4
    if(this.data.aspectRatio) {
      this.aspectRatio = this.data.aspectRatio;
    }

    if(this.data.roundCropper){
      this.roundCropper = this.data.roundCropper;
    }

    this.crop = this.data.crop;
  }
}
