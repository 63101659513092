<!-- TODO : refactor -->
<div class="wrapper">
  <div id="content-page" class="content-page">
    <app-loader *ngIf="showLoader"></app-loader>

    <!-- <img class="w-100" style="border-radius: 10px !important" src="assets/images/notification.png" alt="" /> -->
    
    <div class="conatiner__">
      <div class="club container br-8 d-flex flex-column align-items-center book-club-container"
        style="max-width: 800px">
        <div class="d-flex justify-content-between w-100 mb-3">
          <div class="align-self-center">
            <h1 class="align-self-center mb-0 fw-600 heading">Notifications
              <span *ngIf="total_unread_items">
                ({{ total_unread_items }})
              </span>
            </h1>
          </div>
          <div class="align-self-center">
            <button [disabled]="!total_unread_items" (click)="viewNotification()"
              class="btn btn-cyan p-3 align-self-center"> Mark all as viewed </button>
          </div>
        </div>


        <ng-container *ngIf="!showLoader">
          <div class="w-100"
            *ngFor="let notify of notifications | paginate: { id: 'notification-pagination', itemsPerPage: 10, currentPage: page, totalItems: total_items} ; let i = index">
            <h1 class="font-weight-bold"></h1>
            <div *ngIf="
                    notify.type == 'other_wall' ||
                    notify.type == 'comment' ||
                    notify.type == 'follow' ||
                    notify.type == 'my_wall' ||
                    notify.type == 'report' ||
                    notify.type == 'room_invite' ||
                    notify.type == 'transaction'
                  ">
              <div class="container my-3 card-shadow p-3 notification">
                <div [ngClass]="{ new_notification: !notify.viewed }">
                  <section *ngIf="notify.type == 'follow'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " [routerLink]="['/authorprofile', notify.created_by]">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " src="{{ baseimage }}{{
                                    notify.user_info.image
                                  }}" alt="" />
                          <div>
                            <p class="ml-3 notification__message">
                              <span class="font-italic user_username" (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">
                                @{{ notify.user_info.username }}
                              </span>
                              started following you
                            </p>
                            <div class="d-flex">
                              <img class="ml-5" src="assets/images/follow.png" alt="" />
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section *ngIf="notify.type == 'report'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " [routerLink]="['/authorprofile', notify.created_by]">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " [src]="baseimage + notify.user_info.image"
                            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                            alt="" />
                          <div>
                            <p class="ml-3 notification__message">
                              <span class="font-italic user_username" (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">
                                @{{ notify.user_info.fullname }}
                              </span>
                              reported
                              <span class="text-warning">
                                {{ notify.book_info.bookname }}
                              </span>
                            </p>
                            <div class="d-flex">
                              <img class="ml-5" src="assets/images/follow.png" alt="" />
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section *ngIf="notify.type == 'comment'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " [routerLink]="['/singlebook', notify.book_id]">
                      <div class="d-flex justify-content-center" *ngIf="notify.comment_id">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " [src]="baseimage + notify.user_info.image"
                            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                            alt="" />

                          <div>
                            <p class="ml-3 notification__message">
                              <span (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " class="font-italic user_username" routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">@{{ notify.user_info.username }}</span>
                              commented on chapter:
                              <span (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " routerLink="/readchapter/{{
                                        notify.book_info._id
                                      }}/{{ notify.book_info.chapter_id }}" class="font-italic text-warning">
                                {{ notify.book_info.chapter_title }}
                              </span>
                              of your book:
                              <span class="font-italic text-warning">
                                {{ notify.book_info.title }}
                              </span>
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                message
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <img class="
                                  user_profile_image
                                  cover_images
                                  mb-2
                                  mr-3
                                  rounded
                                  image__about
                                " src="{{ baseimage }}{{
                                  notify.book_info.cover_image
                                }}" alt="" />
                      </div>
                    </div>
                  </section>

                  <section *ngIf="notify.type == 'my_wall'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " routerLink="/authorprofile/{{
                              notify.user_info._id
                            }}">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " [src]="baseimage + notify.user_info.image"
                            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                            alt="" />
                          <div>
                            <p class="ml-3 notification__message">
                              <span (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " class="font-italic user_username" routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">@{{ notify.user_info.username }}</span>
                              posted on their wall:
                              <span class="font-italic text-warning">
                                {{ notify.message }}
                              </span>
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                message
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section *ngIf="notify.type == 'other_wall'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            ">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " [src]="baseimage + notify.user_info.image"
                            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                            alt="" />
                          <div>
                            <p (click)="
                                      !notify.viewed
                                        ? viewNotification(notify._id)
                                        : null
                                    " routerLink="/authorprofile/{{
                                      notify.user_info._id
                                    }}" class="ml-5 notification__message">
                              <span class="font-italic user_username">@{{ notify.user_info.username }}</span>
                              posted on your wall
                              <span class="font-italic text-warning">
                              </span>
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                message
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section *ngIf="notify.type == 'transaction'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            ">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " [src]="baseimage + notify.user_info.image"
                            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                            alt="" />
                          <div>
                            <p (click)="
                                      !notify.viewed
                                        ? viewNotification(notify._id)
                                        : null
                                    " routerLink="/authorprofile/{{
                                      notify.user_info._id
                                    }}" class="ml-5 notification__message">
                                    {{ notify?.message }}
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                message
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section *ngIf="notify.type == 'room_invite'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            ">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " [src]="baseimage + notify.user_info.image"
                            onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                            alt="" />
                          <div>
                            <p (click)="
                                      !notify.viewed
                                        ? viewNotification(notify._id)
                                        : null
                                    " routerLink="/authorprofile/{{
                                      notify.user_info._id
                                    }}" class="ml-5 notification__message">
                              {{notify.message}}
                              <span class="font-italic text-warning">
                              </span>
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                message
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                          <div *ngIf="notify?.action_type === 'room_action'">
                            <div> <button class="btn btn-success my-2"
                                (click)="room_invite_action(notify, 'active')">Accept</button> </div>
                            <div> <button class="btn btn-danger"
                                (click)="room_invite_action(notify, 'declined')">Decline</button> </div>
                          </div>

                          <div *ngIf="notify?.action_type === 'active'">
                            <span class="badge bg-success">Accepted</span>
                          </div>
                          <div *ngIf="notify?.action_type === 'declined'">
                            <span class="badge bg-danger">Declined</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>


            <div *ngIf="
                    notify.type == 'New book' ||
                    notify.type == 'like' ||
                    notify.type == 'chapter'
                  ">
              <div class="container my-3 card-shadow p-3 notification">
                <div [ngClass]="{ new_notification: !notify.viewed }">
                  <section *ngIf="notify.type == 'like'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " [routerLink]="['/singlebook', notify.book_id]">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " src="{{ baseimage }}{{
                                    notify.user_info.image
                                  }}" alt=""
                                  onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'" />

                          <div>
                            <p class="ml-3 notification__message">
                              <span class="font-italic user_username" (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">
                                @{{ notify.user_info.username }}
                              </span>
                              liked your book &lt; &lt;
                              <span class="text-warning">
                                {{ notify.book_info?.title }}
                              </span>
                              &gt; &gt;
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                favorite
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <img class="
                                  user_profile_image
                                  cover_images
                                  mb-2
                                  mr-3
                                  rounded
                                  image__about
                                " src="{{ baseimage }}{{
                                  notify.book_info?.cover_image
                                }}" alt=""
                                onerror="this.src = 'assets/images/rectangle1.svg'" />
                      </div>
                    </div>
                  </section>

                  <section *ngIf="notify.type == 'New book'">
                    <div class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " [routerLink]="['/singlebook', notify.book_info._id]">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " src="{{ baseimage }}{{
                                    notify.user_info.image
                                  }}" alt="" />
                          <div>
                            <p class="ml-3 notification__message">
                              <span (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " class="font-italic user_username" routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">
                                @{{ notify.user_info.username }}
                              </span>
                              added a new book:
                              <span class="text-warning">
                                {{ notify.book_info.title }}
                              </span>
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                menu_book
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <img class="
                                  user_profile_image
                                  cover_images
                                  mb-2
                                  mr-3
                                  rounded
                                  image__about
                                " src="{{ baseimage }}{{
                                  notify.book_info.cover_image
                                }}" alt="" />
                      </div>
                    </div>
                  </section>
                  <section *ngIf="notify.type == 'chapter'">
                    <div *ngIf="notify.book_info.chapter_title" class="notification-div" (click)="
                              !notify.viewed
                                ? viewNotification(notify._id)
                                : null
                            " [routerLink]="['/singlebook', notify.book_info._id]">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex">
                          <img (click)="
                                    !notify.viewed
                                      ? viewNotification(notify._id)
                                      : null
                                  " routerLink="/authorprofile/{{
                                    notify.user_info._id
                                  }}" class="
                                    user_profile_image
                                    cover_images
                                    mb-2
                                    ml-3
                                    rounded-circle
                                  " src="{{ baseimage }}{{
                                    notify.user_info.image
                                  }}" alt=""
                                  onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'" />
                          <div>
                            <p class="ml-3 notification__message">
                              <span (click)="
                                        !notify.viewed
                                          ? viewNotification(notify._id)
                                          : null
                                      " class="font-italic user_username" routerLink="/authorprofile/{{
                                        notify.user_info._id
                                      }}">
                                @{{ notify.user_info.username }}
                              </span>
                              added a new chapter:
                              <span class="text-warning">
                                {{ notify.book_info.chapter_title | slice: 0 : 50 }}
                                <span *ngIf="notify?.book_info.chapter_title?.length > 50">...</span>
                              </span>
                              of book:
                              <span class="text-warning">
                                {{ notify.book_info.title }}
                              </span>
                            </p>
                            <div class="d-flex">
                              <span class="
                                        ml-5
                                        material-icons-round
                                        register__mat-icon
                                      ">
                                menu_book
                              </span>
                              <span class="ml-3 text-capitalize">
                                {{ notify.created_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <img *ngIf="false" class="
                                  user_profile_image
                                  cover_images
                                  mb-2
                                  mr-3
                                  rounded
                                  image__about
                                " src="{{ baseimage }}{{
                                  notify.book_info.cover_image
                                }}" alt="" />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="!showLoader && notifications?.length === 0">
          <div align="center">
            <img src="assets/images/undraw_not_found.svg" alt="" style="height: 240px; opacity: 0.9;">
            <div class="text-secondary pt-5">No Notifications found</div>
          </div>
        </ng-container>

        <div *ngIf="!showLoader && notifications?.length" align="right" class="my-4">
          <pagination-controls id="notification-pagination" (pageChange)="getNotification($event)">
          </pagination-controls>
        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>