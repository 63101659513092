<div class="wrapper">
   <div id="content-page" class="content-page">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height p-2">
         <app-loader *ngIf="showLoader"></app-loader>
         <div *ngIf="!showLoader" class="__container">
            <div class="edit-container">
              <label class="upload-photo-label">
                <img loading="lazy" _ngcontent-mnc-c425="" alt="" src="../../assets/Slider/images/icons/Edit pencil@2x.svg" class="edit-chapter-icon __web-inspector-hide-shortcut__">
                <input
                    type="file"
                    class="upload-photo"
                    (change)="onSelectFile($event, 'cover')"
                    #file
                />
              </label>
            </div>

            <ng-container *ngIf="cover_image">
               <img loading="lazy" class="profile-banner" [src]="cover_image"
               alt="Cover Image" />
            </ng-container>
            <ng-container *ngIf="!cover_image">
               <img loading="lazy" *ngIf="authordata.cover_image" class="profile-banner" src="{{ baseimage }}{{ authordata.cover_image }}"
               alt="" />
               <img loading="lazy" *ngIf="!authordata.cover_image" class="profile-banner"
               src="https://blogs.sas.com/content/sastraining/files/2015/03/black_background.png" alt="" />
            </ng-container>
            <div class="profile-user-info-block">

               <ng-container *ngIf="profile_image">
                  <img loading="lazy" class="user__image rounded-circle" [src]="profile_image" alt="Profile image preview" />
                  <label class="upload-photo-label">
                     <img loading="lazy" _ngcontent-mnc-c425="" alt="" src="../../assets/Slider/images/icons/Edit pencil@2x.svg"
                        class="edit-chapter-icon icon-edit-user-data __web-inspector-hide-shortcut__">
                     <input type="file" class="upload-photo" (change)="onSelectFile($event, 'profile')" #file />
                  </label>
               </ng-container>

               <ng-container *ngIf="!profile_image">
                  <img loading="lazy" *ngIf="authordata.image" class="user__image rounded-circle" src="{{ baseimage }}{{ authordata.image }}"
                     alt="" />
                  <label class="upload-photo-label">
                     <img loading="lazy" _ngcontent-mnc-c425="" alt="" src="../../assets/Slider/images/icons/Edit pencil@2x.svg"
                        class="edit-chapter-icon icon-edit-user-data __web-inspector-hide-shortcut__">
                     <input type="file" class="upload-photo" (change)="onSelectFile($event, 'profile')" #file />
                  </label>
                  <img loading="lazy" *ngIf="!authordata.image" class="user__image rounded-circle"
                     src="https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png" alt="" />
               </ng-container>
            </div>
            <div class="d-flex justify-content-center">
              <div class="nickname-wrapper">
                <span class="user__username">@{{ authordata.username }}</span>
             </div>
            </div>
         </div>
         <div class="user__profile__details">
            <div class="author__profile">
               <form #editProfileInfo="ngForm" (ngSubmit)="submitNewProfileInfo(editProfileInfo)" class="edit-profile-info-form m-0">
                  <div class="form-group fullname edit-info-block-form input_txt">
                     <label class="text-left" for="fullname">Name <span class="required-field-icon">*</span></label>
                     <input type="text" requared placeholder="Enter name" class="name-input" name="fullname" [ngModel]="userdata.data.fullname" ngModel required #name="ngModel">
                  </div>
                  <div class="form-group edit-info-block-form input_txt">
                     <label for="biography">Bio</label>
                     <textarea type="text" placeholder="Biography" style="font-size: inherit" class="form-control text-area-edit-info" [ngModel]="userdata.data.bio" name="bio" ngModel #bio="ngModel"></textarea>
                  </div>
                  <div class="form-group fullname edit-info-block-form input_txt">
                     <label class="text-left" for="fullname">DOB <span class="required-field-icon">*</span></label>
                     <input [matDatepicker]="picker" (click)="picker.open()" type="text" requared placeholder="Enter DOB" class="name-input" name="dob" [ngModel]="userdata.data.dob" ngModel required #name="ngModel">
                     <mat-datepicker #picker></mat-datepicker>
                  </div>
                  <div class="form-group fullname edit-info-block-form input_txt">
                     <label class="text-left" for="fullname">Location <span class="required-field-icon">*</span></label>
                     <input type="text" requared placeholder="Enter location" class="name-input" name="location" [ngModel]="userdata.data.location" ngModel required #location="ngModel">
                  </div>
                  <div class="form-group edit-info-block-form input_txt">
                     <label for="mature">Gender <span class="required-field-icon">*</span></label>
                     <select name="gender" [ngModel]="userdata.data.gender" ngModel required #gender="ngModel" class="select-mature">
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                     </select>
                  </div>
                  <div class="form-group edit-info-block-form input_txt">
                     <label for="mature">Mature Content <span class="required-field-icon">*</span> </label>
                     <select name="enable_mature_content" [ngModel]="userdata.data.enable_mature_content" ngModel required #enable_mature_content="ngModel" class="select-mature">
                        <option [value]="true">Enabled</option>
                        <option [value]="false">Disabled</option>
                     </select>
                  </div>
                  <div class="container">
                     <div class="row mt-5">
                        <div class="col-md-12">
                           <div class="save text-center">
                              <button type="submit" class="write-button-save">
                                 Update
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>

<app-footer></app-footer>
