import { Component, OnInit, } from '@angular/core';
import { EditChapterService } from './edit-chapter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ChapterAddService } from '../chapter-add/chapter-add.service';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoDetails } from '../chapter-add/video-details';
import { NavbarService } from '../nav-sidebar/navbar.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateStoriesService } from '../create-stories/create-stories.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileService } from '../user-profile/user-profile.service';
import { environment } from 'src/environments/environment';
import { HandleEditService } from '../add-book-cover/handle-edit.service';
import { AppComponent } from '../../../src/app/app.component';
import { HelperService } from '../helper.service';
import { BaseUrl } from '../base-url';

import Quill from 'quill';
import ResizeModule from "@botom/quill-resize-module";


Quill.register("modules/resize", ResizeModule);

declare var $: any;
@Component({
  selector: 'app-edit-chapter',
  templateUrl: './edit-chapter.component.html',
  styleUrls: ['./edit-chapter.component.sass'],
})
export class EditChapterComponent implements OnInit {
  getGenre: any;
  updateForm: any;
  baseUrl: any;
  loading = false;
  submitted = false;
  showMsg = false;
  baseURL: any;
  errorMsg: any;
  alertService: any;
  book_id: string;
  chapter_id: string;
  chapterIsPaid: boolean;
  priceOfChapter: string;
  chapterData: any;
  selectedFiles: any;
  currentFileUpload: any;
  chapterimage: any;
  content: any;
  no_of_purchase: any
  videoId: any;
  chapterIsAlredyPurchase: boolean = false
  RichTextImage: any;

  messages: string;
  safeURL: any;
  results: VideoDetails[];
  loadings: boolean;
  message = '';
  childmessage: string;
  videoURL: any;

  sourceValue: any;
  showData = false;
  is_white = 'color_white';
  activeClass: any;

  bookData: any;
  showLoader = false;
  baseimage: any;

  image: any;
  title: any;
  video: any;
  html: any;
  prices: any = [
    '0.1',
    '0.2',
    '0.3',
    '0.4',
    '0.5',
    '0.6',
    '0.7',
    '0.8',
    '0.9',
    '1.0',
    '1.5',
    '2.0',
  ];
  public amount = false;
  public chapterIsAlreadyPayd = 0;
  bookAmount = false;
  sketchBookId: string = environment.sketchBookId;
  videoBookId: string = environment.videoBookId;
  bookType: any;
  chapterUpdate = this.formBuilder.group({
   chapterName: '',
   content: '',
  });

  quillModule: any = {
    resize: {
      locale: {
        // change them depending on your language
        altTip: "Hold down the alt key to zoom",
        floatLeft: "Left",
        floatRight: "Right",
        center: "Center",
        restore: "Restore",
      },
    },
  }

  constructor(
    private addtype: ChapterAddService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private navbarServices: NavbarService,
    private dialog: MatDialog,
    private editChapter: EditChapterService,
    private snackbar: MatSnackBar,
    private userProfileService: UserProfileService,
    private createStoriesService: CreateStoriesService,
    private navbarService: NavbarService,
    private handleEdit: HandleEditService,
    private appComponent: AppComponent,
    private helper: HelperService,
    ) {
    // this.baseURL = 'https://spotcodes.in/backend/testing/';
    this.videoId = $('#videoid').val();
    this.baseURL = BaseUrl.image;
    this.baseUrl = BaseUrl.image;
    this.baseimage = BaseUrl.image;
    this.bookType = ''
    if (this.videoId) {
      this.videoURL = 'https://www.youtube.com/embed/' + this.videoId;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.videoURL
      );
    }
  }
  public url: any = 'assets/img/rectangle1.svg';
  public baseurl;

  ngAfterViewInit() {}
  ngAfterViewChecked() {
    //check if reading setting is set
    let color = localStorage.getItem('color');
    let font = localStorage.getItem('font');
    let size = localStorage.getItem('size');
    if (color) {
      this.is_white = color;
      this.activeClass = color;
      // $('body').on('load', '#cc').find('span').css('background', color);
    }
    if (size) {
      // $('body').on('load', '#cc').find('p').css('font-size', size+'px');
      // $('body').on('load', '#cc').find('span').css('font-size', size+'px');
    }
    if (font) {
      //  $('body').on('load', '#cc').find('p').css('font-family', font);
      //  $('body').on('load', '#cc').find('span').css('font-family', font);
    }
  }

  ngOnDestroy() {
    this.navbarServices.setNavbar(true);
  }

  // KEEP COMMENTED
  // changePriceOfChapter(event) {
  //   this.priceOfChapter = event.target.value
  // }

  updateChapter(event) {
    this.loadings = true
    this.chapterUpdate.value['title'] = this.chapterUpdate.value.chapterName;
    this.chapterUpdate.value['book_id'] = this.book_id;
    this.chapterUpdate.value['is_paid'] = this.chapterIsPaid ? this.chapterIsPaid : 'false';
    this.chapterUpdate.value['amount'] = this.chapterIsPaid ? environment.default_chapter_amount : 0;
    this.chapterUpdate.value['is_published'] = $(event.submitter).attr('id') === 'publish-book' ? 1 : 2;
    this.chapterUpdate.value['is_completed'] = 'true';
    this.chapterUpdate.value['chapter_id'] = this.chapter_id;
    this.chapterUpdate.value['image'] = '';
    this.chapterUpdate.value['video'] = '';

    this.addtype.updateChapter(this.chapterUpdate.value).subscribe(
      (result: any) => {
        if (result.status) {
          this.showMsg = true;
          const currentUrl = this.router.url;
          this.userProfileService
            .loadAuthorData(this.chapterUpdate.value.book_id)
            .subscribe((res: any) => {
              const mapped = Object.entries(res.data).map(
                ([type, value]) => ({
                  type,
                  value,
                })
              );
              this.bookData = res.data;
              localStorage.setItem('mybooks', JSON.stringify(this.bookData));

              this.snackbar.open(
                `${result.message} You can create a new chapter or you can go to the book page by clicking the back button`,
                'Ok',
                { duration: 7000 }
              );
            });
          this.createStoriesService.setTabIndex(this.chapterUpdate.value.is_published - 1);
          this.loadings = false
          this.router.navigate(['/create-stories']);
        } else {
          console.log(result.message)
        }
      }),
      (error) => {
        console.log('error', error.message)
      }
    }


  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('type') === this.sketchBookId) {
      this.bookType = 'sketchBook'
    } else if (this.activatedRoute.snapshot.paramMap.get('type') === this.videoBookId) {
      this.bookType = 'videoBook'
    }
    this.navbarServices.setNavbar(false);

    this.book_id = this.activatedRoute.snapshot.params['book_id'];
    this.chapter_id = this.activatedRoute.snapshot.params['chapter_id'];

    this.updateForm = this.formBuilder.group({
      book_id: this.book_id,
      title: ['', Validators.required],
      content: [''],
      is_paid: [''],
      amount: [''],
      image: [''],
      video: [''],
      is_published: [''],
      is_completed: ['false'],
    });

    this.bookData = localStorage.getItem('mybooks');
    this.bookData = JSON.parse(this.bookData);

    let currentChapter = this.bookData.published_chapters.filter((chapter) => {
      return chapter._id === this.chapter_id;
    })


    if (currentChapter.length == 0) {
      currentChapter = this.bookData.draft_chapters.filter((chapter) => {
         return chapter._id === this.chapter_id;
      })
    }

      this.chapterData = currentChapter[0];
      this.chapterIsPaid = this.chapterData.is_paid
      this.no_of_purchase = this.chapterData.no_of_purchase
      this.priceOfChapter = this.chapterData.amount

      this.chapterUpdate.get('chapterName').setValue(this.chapterData.chapter_name);
      this.chapterUpdate.get('content').setValue(this.chapterData.content);
    if(this.no_of_purchase > 0){
      this.chapterIsAlredyPurchase = true
    }
    $(document).on('click', '.e-icon-btn', function () {
      if ($(this).attr('aria-label') == 'Insert Image') {
        $('#toolbar_Image').modal('show');
        $('#rich-edit').val('true');
      }
    });

    // if (this.bookData.content_type == '614335bf550ec4e995161a10') {
    //   setTimeout(function () {
    //     var editor = new MediumEditor('.container-textarea');
    //     $(function () {
    //       $('.container-textarea').mediumInsert({
    //         editor: editor,
    //         addons: {
    //           images: {
    //             fileUploadOptions: {
    //               type: 'post',
    //               url: `${environment.baseUrl}/uploadImageEditor`,
    //             },
    //             uploadCompleted: function ($el, data) {},
    //           },
    //           embeds: false,
    //         },
    //       });
    //     });
    //     $('.container-textarea').on('keypress', function (event: any) {
    //       event.preventDefault();
    //     });
    //   }, 2000);
    // } else if (this.bookData.content_type == '614335dd550ec4e995161a11') {
    //   setTimeout(function () {
    //     var editor = new MediumEditor('.container-textarea');
    //     $(function () {
    //       $('.container-textarea').mediumInsert({
    //         editor: editor,
    //         addons: {
    //           images: false,
    //         },
    //       });
    //     });
    //     $('.container-textarea').on('keypress', function (event: any) {
    //       event.preventDefault();
    //     });
    //   }, 2000);
    // } else {
    //   let type = this.bookType
    //   setTimeout(function () {
    //     var editor = new MediumEditor('.container-textarea');
    //     $(function () {
    //       if (type === 'videoBook') {
    //         $('.container-textarea').mediumInsert({
    //           editor: editor,
    //           toolbar: {
    //             buttons: [ 'bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote', 'redo', 'undo' ],
    //           },
    //           addons: {
    //             images: false,
    //             embeds: true,
    //           },
    //         });
    //       } else if (type === 'sketchBook') {
    //         $('.container-textarea').mediumInsert({
    //           editor: editor,
    //           toolbar: {
    //             buttons: [ 'bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote', 'redo', 'undo' ],
    //           },
    //           addons: {
    //             images: {
    //               fileUploadOptions: {
    //                 type: 'post',
    //                 url: `${environment.baseUrl}/uploadImageEditor`,
    //               },
    //               uploadCompleted: function ($el, data) {},
    //             },
    //             embeds: false,
    //           },
    //         });
    //       } else {
    //         $('.container-textarea').mediumInsert({
    //           editor: editor,
    //           toolbar: {
    //             buttons: [ 'bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote', 'redo', 'undo' ],
    //           },
    //           addons: {
    //             images: {
    //               fileUploadOptions: {
    //                 type: 'post',
    //                 url: `${environment.baseUrl}/uploadImageEditor`,
    //               },
    //               uploadCompleted: function ($el, data) {},
    //             },
    //             embeds: true,
    //           },
    //         });
    //       }
    //     });
    //   }, 2000);
    // }
    this.navbarService.setNavbar(true);
    this.handleEdit.bookImage.subscribe((res: any) => {
      var { cover_image, editable } = this.helper.appendBaseUrlToImage(res);
      if (this.appComponent.getPreviousUrl() === '/create-stories') {
        this.url = 'assets/img/rectangle1.svg'
      } else {
        this.url = cover_image;
      }
    });
    this.baseurl = BaseUrl.image;
  }

  get f() {
    return this.updateForm.controls;
  }
  handleChapterPay() {
    this.chapterIsPaid = !this.chapterIsPaid
  }

  quillEditorRef: any;
  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = this.quillEditorRef.getModule('toolbar');
    toolbar.addHandler('image', this.uploadImageHandler);
  }

  uploadImageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files?.length ? input.files[0] : null;
      if(!file) {
        // TODO implement nice popup
        alert('File not found, please try again');
        return;
      }
      const range = this.quillEditorRef.getSelection();

      const formData = new FormData();
      formData.append('image', file);

      this.addtype.chapter_image(formData).subscribe((res : any) => {
        console.log(res);
        if(res?.status){
          const image_url = `${environment.imageBaseUrl}${res?.data}`;
          this.quillEditorRef.insertEmbed(range.index, 'image',image_url);
        }
      });
    }
  }
}
