import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swiper from 'swiper';
import { BaseUrl } from '../base-url';
import { BookDetailService } from '../book-detail/book-detail.service';
import { HelperService } from '../helper.service';
import { ReviewHandlerService } from '../product-quick-view/product-quick-view-handler.service';
import { SeoserviceService } from '../seoservice.service';
import { BookDataService } from '../services/book-data.service';
import { HomeService } from './home.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavbarService } from '../navbar/navbar.service';

@Component({
  selector: 'home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  public show: boolean = true;
  public active_featured_duration: 'this_week' | 'last_week' |  'last_month' = 'this_week';
  public slides = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];

  top_genre_list = {
    top_fantasy: { name: 'Top Fantasy', id: "5eade11a37fe886eae35c7d7" },
    top_mystery:  { name: 'Top Mystery / Thriller', id: "5eade15b37fe886eae35c7dc" },
    top_romance: { name: 'Top Romance', id: "5eade1f237fe886eae35c7e1" },
    top_literature: { name: 'Literature and Fiction', id:  "5eade23d37fe886eae35c7e6"} // teen fiction id
  }

  public slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    centerMode: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  public slideConfigbs = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    centerMode: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  public slideConfigFBW = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    centerMode: false,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };


  public featuredBookandWriterData = []

  public addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }
  public removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  public slickInit(e: any) {
    console.log('slick initialized');
  }
  public breakpoint(e: any) {
    console.log('breakpoint');
  }
  public afterChange(e: any) {
    console.log('afterChange');
  }
  public beforeChange(e: any) {
    console.log('beforeChange');
  }

  topPicksForUser: Array<any>
  public bookDetails = [];
  constructor(
    private homeService: HomeService,
    private router: Router,
    private seoService: SeoserviceService,
    private bookService: BookDetailService,
    private bookData: BookDataService,
    private helper: HelperService,
    private reviewServiceHandler: ReviewHandlerService,
    private httpClient: HttpClient,
    private bookDetailService: BookDetailService,
    private navbarService: NavbarService,
    private _snackBar: MatSnackBar,
    ) {
    this.baseurl = BaseUrl.image;
  }

  public homeData;

  role: any;
  gethomedata: any;
  slider: boolean = true;
  firstTime: Boolean;
  showLoader = false;
  showWelcom = true;
  baseurl;
  baseURl = BaseUrl.image
  noImage = 'uploads/assets/img/rectangle1.svg';
  top_three_genre = ["5eade11a37fe886eae35c7d7", "5eade15b37fe886eae35c7dc", "5eade1f237fe886eae35c7e1"];

  userData: any;
  VRCardData: any;
  ngOnInit(): void {
    // this.getHighlightedBooks();
    this.seoService.updateTitle('Librums | Home');
    // this.loadAuthorInfo();
    // this.getVideoBookByAuthorId();
    // this.getSketchBookByAuthorId();
    this.fetchFeaturedSection('this_week');
    this.getOurPicksForYou()
    this.getVRCardData()
    this.userData = this.helper.getUserData().data;
  }

  getVRCardData(){
    this.httpClient.get(`${environment.baseAdminUrl}/config`).subscribe((result:any) => {
      this.VRCardData = result.data[0]
    });
  }

  ngAfterViewInit(){
    setTimeout(() => {
      var swiper = new Swiper('.video__books__container', {
        slidesPerView: 'auto',
      });
    }, 1000);
    var swiper = new Swiper('.featured-writer-swiper', {
      slidesPerView: 1,
      spaceBetween: 20,
      grabCursor: true,
      slidesPerGroup: 1,
      observer: true,
      navigation: {
        nextEl: '.featured-writer-swiper-button-next',
        prevEl: '.featured-writer-swiper-button-prev',
      },
    });
  }
  public imageBaseUrl = environment.imageBaseUrl
  getOurPicksForYou = () => {
    const token = localStorage.getItem('currentUser')
    const decryptedUser = crypto.AES.decrypt(
      token,
      environment.secretKey
    ).toString(crypto.enc.Utf8)
    const user = JSON.parse(decryptedUser)?.data?._id;
    this.bookData.getUserRecommendation(user).subscribe((result: any) => {
      this.topPicksForUser = result.data.slice(0, 9);
      this.bookDetails = result.data
    });
  }

  public RedirectToExplor = (value: string) => {
    this.router.navigate(['/search'], {
      queryParams: {
        search: value
      },
      queryParamsHandling: 'merge',
    });
  }
  authorSketchBooks: any;
  authorVideoBooks: any;
  newFeatureBook: any;
  oldFeatureBook: any;
  featureBook: any;
  featureWriter: any;
  rating: any;
  firstChapterID: any;

  getFeatureBookByType(type) {
    this.homeService.getFeatureBook(type).subscribe((result: any) => {
      this.featureBook = result?.book[0].book
      this.firstChapterID = this.featureBook?.chapters[0]?._id
      let review = this.reviewServiceHandler.getReviewByBookId(this.featureBook._id)
      review.subscribe((resoponse: any) => {
        this.rating = parseInt(resoponse?.bookReview?.averageRating) | 0
      })
    });
  }

  async getFeatureWriterByType(type) {
    this.homeService.getFeatureWriter(type).subscribe((result: any) => {
      this.featureWriter = result?.writerArr
      console.log('featureWriter', this.featureWriter)
    });
  }

  public getSketchBookByAuthorId = () => {
    this.bookService.getSketchBookByAuthorId().subscribe((result: any) => {
      this.authorSketchBooks = result.sketch;
    });
  };

  public getVideoBookByAuthorId = () => {
    this.bookService.getVideoBookByAuthorId().subscribe((result: any) => {
      this.authorVideoBooks = result.video;
    });
  };

  public fetchFeaturedSection(tabName: 'this_week' | 'last_week' | 'last_month') {
    this.active_featured_duration = tabName
    // if(tabName === "This Week") {
    //   this.thisWeekBook();
    //   this.thisWeekWriter();
    // }
    // if(tabName === "Last Month") {
    //   this.lastMonthBook()
    //   this.lastMonthWriter();
    // }
    // if(tabName === "Last Week") {
    //   this.lastWeekBook();
    //   this.lastWeekWriter();
    // }
    this.getFeatureBookByType(tabName);
    this.getFeatureWriterByType(tabName);
    this.homeService.getFeatureWriter(tabName).subscribe((result: any) => {
      this.featureWriter = result?.writerArr
    });
    console.log('featureWriter', this.featureWriter)
  }
  addToBookShelf(book: any) {
    this.bookDetailService.addToBookShelf({ book_id: book?._id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = true;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          if (res.message === 'Book already in bookshelf.') {
            this.removeBookShelf(book)
          } else {
            this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          }
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  removeBookShelf(book: any) {
    this.bookDetailService.removeToBookShelf({ book_id: book?._id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = false;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }
}
