<div class="">
   <div class="container-fluid">
      <app-loader *ngIf="showLoader"></app-loader>
      <div class="row d-flex justify-content-center read-chapter-container" *ngIf="bookData && !showLoader">
         <div class="col-lg-10">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height m-0" style="border-radius:10px">
               <div class="iq-card-body p-0">
                  <div class=" p-0">
                     <form class="m-0" enctype="multipart/form-data">
                        <div class="">
                           <div class="book-wraper mt-3">
                              <div class="row m-0">
                                 <div class="col-sm-4 col-xl-3 px-3 d-flex justify-content-center align-items-center">
                                       <img *ngIf="bookData.cover_image"
                                          loading="lazy" style="border-radius: 10px; height: 360px;"
                                          [src]="baseURL + bookData.cover_image" [routerLink]="['/', 'profile', 'mybookdetails', book_id]" alt="" onerror="this.src = 'assets/images/rectangle1.svg'" />

                                 </div>
                                 <div
                                    class="col-sm-8 col-xl-8 p-0 pl-sm-3 pt-3 pt-sm-0 px-4 d-flex flex-column input-root-container justify-content-between h-100">
                                    <div class="d-flex justify-content-md-end reiting-container" *ngIf="chapterData">
                                       <!-- <a (click)="scroll(target)" routerLink="/readchapter/{{ book_id }}/{{chapter_id}}#comment-form"
                                          class="book-additional-ability">
                                          <img loading="lazy" class="book-details-icons" src="../../../../assets/images/comments.svg"
                                             alt="" />
                                       </a> -->
                                       <a (click)="scroll(commentConntainer)"
                                          class="book-additional-ability">
                                          <img loading="lazy" class="book-details-icons" src="../../../../assets/images/comments.svg"
                                             alt="" />
                                       </a>
                                       <div *ngIf="!chapterData?.isLiked"
                                          (click)="like({ book_id: chapterData?._id, chapter_id: chapter_id, status: 1 })"
                                          class="book-additional-ability">
                                          <img loading="lazy" class="book-details-icons" src="../../../../assets/images/like.svg"
                                             alt="" />
                                       </div>
                                       <div *ngIf="chapterData?.isLiked"
                                          (click)="like({ book_id: chapterData?._id, status: 2 })"
                                          class="book-additional-ability">
                                          <img loading="lazy" class="book-details-icons is-liked-icon"
                                             src="../../../../assets/images/like.svg" alt="" />
                                       </div>
                                       <div (click)="shareBookDialog()" class="book-additional-ability">
                                          <img loading="lazy" class="book-details-icons" src="../../../../assets/images/Share.svg"
                                             alt="" />
                                       </div>
                                       <div class="book-additional-ability"
                                       (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsedNew" aria-controls="collapseExample">
                                          <img loading="lazy" #settings class="book-details-icons"
                                             src="../../../../assets/images/chapterSettings.svg" alt="" />
                                       </div>
                                       <div #settingsBlock #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedNew" id="setting_filter_text" class="collapse collapse_setting p-3 no-transition">
                                          <div class="fliter_font fliter_font_hover">
                                             <ul #listBackgrounds class="p-0 mb-4">
                                                <li class="box_txt">
                                                   <a #background1 (click)="changeLayout('color_white')" [ngClass]="{active: activeClass === 'color_white'}">A</a>White
                                                </li>
                                                <li class="box_txt">
                                                   <a #background2  (click)="changeLayout('color_wheat')" [ngClass]="{active: activeClass === 'color_wheat'}">A</a>Eggshell
                                                </li>
                                                <li class="box_txt">
                                                   <a #background3 (click)="changeLayout('color_black')" [ngClass]="{active: activeClass === 'color_black'}">A</a>Nightowl
                                                </li>
                                             </ul>
                                          </div>
                                          <div class="select_font_size">
                                             <div #labelInput class="label_input">
                                                <label style="font-weight: 700;" for="">Select Font Type</label>
                                                <select #selectFonts name="" id="" (change)="onFontChange($event)">
                                                    <option value="robotobold" style="font-family: robotobold">
                                                        Robo To Bold
                                                    </option>
                                                    <option value="aileronlight">
                                                      <p style="font-family: aileronlight">
                                                        Aileronlight
                                                      </p>
                                                    </option>
                                                    <option value="inconsolatamedium">
                                                      Inconsolata Medium
                                                    </option>
                                                    <option value="comic_reliefregular">
                                                      Comic Reliefregular
                                                    </option>
                                                    <option value="haloregular">
                                                      Halo Regular
                                                    </option>
                                                    <option value="droid_serifregular">
                                                      Droid Serifregular
                                                    </option>
                                                    <option value="noname_nocontrast_serif_v1Rg">
                                                      Noname Nocontrast Serif V1Rg
                                                    </option>
                                                    <option value="ucu_charles_scriptregular">
                                                      UCU Charles Script Regular
                                                    </option>
                                                    <option value="geometric_sans_serif_v1Rg">
                                                      Geometric Sans Serif V1Rg
                                                    </option>
                                                    <option value="press_start_2pregular">
                                                      Press Start 2Pregular
                                                    </option>
                                                    <option value="the_godfatherregular">
                                                      The God Father Regular
                                                    </option>
                                                    <option value="Roboto">
                                                      Roboto
                                                    </option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class="range_fonts">
                                             <div #labelFonts class="fonts_inrs"><span style="font-weight: 700;">Font Size</span>
                                             </div>
                                             <div #fontSizeText style="width: 90%;" class="input_range mb-4">
                                                <input type="range" step="2" min="10" max="40" id="slide"
                                                   class="slider1" (change)="onFontSize($event)" />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       class="d-flex flex-column book-data-container justify-content-center justify-content-sm-end">
                                       <div
                                          class="bool-name mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                          {{bookData.title}}
                                       </div>
                                       <div
                                          class="book-sentence mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                          {{bookData.tagline}}
                                       </div>
                                       <div
                                          class="block-author-book mb-3 d-flex justify-content-center justify-content-sm-start">
                                          <img loading="lazy" class="image-author-book" [src]="baseURL + bookData.author_image"
                                          onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'">
                                          <a  class="author_name" href="/authorprofile/{{bookData.author_id}}">
                                            <p class="m-0 ml-2 author-name-text">@{{bookData.author_name}}</p>
                                          </a>
                                       </div>
                                       <div
                                          *ngIf="!textIsBrief"
                                          class="book-description d-flex d-sm-block justify-content-center book-field-text">
                                          <p *ngIf="!showFullText">{{ briefText }}...<span class="showText" (click)="hendleShoweMoreText()">show more</span></p>
                                          <p *ngIf="showFullText">{{ fullText }}<span class="showText" (click)="hendleShoweLessText()">show less</span></p>
                                       </div>
                                       <div
                                          *ngIf="textIsBrief"
                                          class="book-description d-flex d-sm-block justify-content-center book-field-text">
                                          {{bookData.description}}
                                       </div>
                                       <!-- <star-rating [value]="0" [totalstars]="5" checkedcolor="#11e1c2" uncheckedcolor="#C4C4C4" size="41px" [readonly]="false" (rate)="onRate($event)"></star-rating> -->
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                     <form style="margin-bottom: 30px;" class="issue_report">
                        <div>
                           <div class="p-3">
                              <div #buttonHandler class="d-flex flex-column align-items-center chapter-label">
                                <div class="w-100 d-flex justify-content-center">
                                  <div class="d-flex" *ngIf="!isLoading">
                                    <button
                                      *ngIf="this.chapterData?.currentChapter._id != this.chapterData?.chapters[0]._id"
                                      routerLink="/readchapter/{{ chapterData?._id }}/{{previosChapter}}/{{ author_id }}"
                                      style="background: none; color: #11e1c2;"
                                    >
                                      <mat-icon style="font-size: 40px; margin: 0 15px 15px 0;">chevron_left</mat-icon>
                                    </button>
                                    <button type="button" class="btn btn-outline-primary" (click)="changeCollapse()" [attr.aria-expanded]="!isCollapsed"
                                      aria-controls="collapseTry" class="d-flex" style="border-bottom: 1px solid #adadad; margin-bottom: 5px; background-color: #fff;">
                                      <div class="d-flex justify-content-center " *ngFor="
                                        let chapter of chapterData?.chapters;
                                        let i = index
                                      " style="cursor: pointer;">
                                        <h1 #titleHandler [matTooltip]="chapterData?.currentChapter.chapter_name"
                                          matTooltipPosition="above" *ngIf="chapter_id === chapter._id"
                                          style="margin: 5px;">
                                          <ng-container *ngIf="chapterData?.currentChapter.is_paid">
                                            <mat-icon *ngIf="is_purchased">lock_open</mat-icon>
                                            <mat-icon *ngIf="!is_purchased">lock</mat-icon>
                                          </ng-container>
                                          Chapter {{ i + 1 }}:
                                          {{ chapterData?.currentChapter.chapter_name }}
                                        </h1>
                                      </div>
                                      <a class="drop_it drop_it__a collapsed d-flex">
                                        <img loading="lazy" #imgHandler class="img_transform"
                                          style="width: 16px; height: 10px; margin-top: 15px; margin-left: 10px;"
                                          src="assets/images/openChapterList.svg" alt="" />
                                      </a>
                                    </button>
                                    <button
                                      *ngIf="chapterData?.chapters?.length > 1 && this.chapterData?.currentChapter._id != this.chapterData?.chapters[chapterData?.chapters.length - 1]._id"
                                      routerLink="/readchapter/{{ chapterData?._id }}/{{nextChapter}}/{{ author_id }}"
                                      style="background: none; color: #11e1c2;"
                                    >
                                      <mat-icon style="font-size: 40px; margin-bottom: 15px;">chevron_right</mat-icon>
                                    </button>
                                  </div>
                                </div>
                                 <!-- list of chapters !-->
                                <div #collapseElementBlock #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="show_chapter" class="collapse listing_chapter">
                                  <div class="about_heading follower all_chapter {{ is_white }}" style="overflow: scroll;overflow-x: hidden;height: 320px;">
                                      <h2 class="d-flex justify-content-between w-100">
                                        <h2>Chapters</h2>
                                        <span matTooltip="Report Book" matTooltipPosition="above"
                                            [routerLink]="['/report-an-issue']" [queryParams]="{
                                            report: 'Content',
                                            bookId: book_id,
                                            chapterId: chapter_id
                                          }" class="
                                        filter-report-icon
                                        material-icons-two-tone
                                        mr-5
                                        report-flag
                                        cursor-pointer
                                      ">
                                            flag
                                        </span>
                                      </h2>

                                      <div *ngFor="
                                      let chapter of chapterData?.chapters;
                                      let i = index
                                    " class="follower_list font-italic {{
                                      chapter_id == chapter._id ? 'active' : ''
                                    }}">
                                        <a class="follower_pic" routerLink="/readchapter/{{ chapterData?._id }}/{{
                                        chapter._id
                                      }}/{{ author_id }}">
                                            <div class="follower_pic_name">
                                              <a>
                                                  <h2 class="chapter-heading">
                                                    Chapter {{ i + 1 }} :
                                                    <span class="chapter__name {{
                                                chapter_id == chapter._id
                                                  ? 'font-italic font-weight-bold'
                                                  : ''
                                              }}">{{ chapter.chapter_name }}</span>
                                                  </h2>
                                              </a>
                                              <img loading="lazy" *ngIf="chapter.is_paid" src="assets/images/lock1.svg" alt=""
                                                  style="width: 14px; margin-right: 30px" />
                                            </div>
                                        </a>
                                      </div>
                                  </div>
                                </div>
                                 <!--  -->
                                <div *ngIf="isLoading" class="p-4" >Chapter is loading...</div>
                                <div class="w-100" *ngIf="!isLoading">
                                    <div>
                                       <div class="detail_editor">
                                          <div *ngIf="chapterData?.currentChapter.image !== ''" class="d-flex justify-content-center">
                                             <img loading="lazy" src="{{ baseURL }}{{chapterData?.currentChapter.image}}" style="max-height: 340px;" alt=""/>
                                          </div>
                                          <div *ngIf="chapterData?.currentChapter.video !== ''" class="w-100 mb-3 py-3 iframe-control">
                                            <div  class="iframe-container" >
                                              <iframe style="width: 100% !important;" height="315" id="add_frame" [src]="videoURL" frameborder="0" allowfullscreen></iframe>
                                            </div>
                                          </div>
                                          <div class="alert alert-danger mt-4" *ngIf="showError">
                                             {{ showError }}
                                          </div>
                                          <div class="alert alert-success mt-4" *ngIf="showSuccess">
                                             {{ showSuccess }}
                                          </div>
                                          <div *ngIf="chapterData?.currentChapter.is_paid && is_purchased" id="party-element-chapter-purchase">
                                             <div class="sub_details" *ngIf="chapterData?.currentChapter.content">
                                                <!-- <p id="cc" [innerHTML]="
                                                chapterData?.currentChapter.content | safeHtml: 'html'
                                              "></p> -->
                                              <quill-view [content]="chapterData?.currentChapter.content" format="html" theme="snow"></quill-view>
                                                <div class="container">
                                                </div>
                                             </div>
                                          </div>
                                          <div *ngIf="
                                          chapterData?.currentChapter.is_paid &&
                                          userdata.data._id !=
                                            chapterData?.currentChapter.created_by
                                            && !is_purchased
                                        ">
                                             <div *ngIf="!chapterData?.currentChapter.paid_for">
                                                <div class="payment-details">

                                                  <div align="center">
                                                    <img loading="lazy" src="assets/images/unlock chapter.svg" style="width: 250px" />
                                                  </div>

                                                   <p class="mt-3 text-center fw-600">
                                                      This chapter is paid for continuous reading please unlock the
                                                      chapter
                                                   </p>

                                                   <div class="d-flex justify-content-center">
                                                      <div id="party-element-wallet-recharge">
                                                          <div align="center">
                                                            <div class="badge badge-light text-dark" [ngClass]="{
                                                              'text-danger': chapterData?.currentChapter?.amount > userdata.data.libcoins,
                                                              'text-dark': !(chapterData?.currentChapter?.amount > userdata.data.libcoins)
                                                            }" style="font-size: 22px">Wallet Amount: {{ userdata.data.libcoins }} Libcoins</div>
                                                          </div>

                                                        <div class="mt-3">
                                                          <div class="d-flex justify-content-center align-items-center purchase-section">
                                                                <div class="m-2">
                                                                    <button class="w-100 paid-chapter-buttons btn p-3 br-8"
                                                                      [disabled]="chapterData?.currentChapter?.amount > userdata.data.libcoins"
                                                                      [disabled]="buttonLoading" (click)="onPurchase()">
                                                                      <span> Unlock Chapter - {{ amount }} coins </span>
                                                                      <span class="mx-2" *ngIf="buttonLoading">
                                                                          <app-loader show="small"></app-loader>
                                                                      </span>
                                                                    </button>
                                                                </div>
                                                              <div>|</div>
                                                                <div class="m-2">
                                                                <button class="w-100 btn paid-chapter-buttons p-3 br-8"(click)="show_purchase_options = true">
                                                                    <span>Add Coins</span>
                                                                </button>
                                                              </div>
                                                          </div>
                                                        </div>

                                                        <div align="center" *ngIf="show_purchase_options">
                                                          <div style="max-width: 400px" class="mt-3">
                                                              <app-purchase-options [chapterId]="chapter_id"
                                                                [userLibcoins]="userdata.data.libcoins"
                                                                (onPaymentComplete)="onPaymentComplete()"
                                                                (closePurchaseOption)="show_purchase_options = false">
                                                              </app-purchase-options>
                                                          </div>
                                                        </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="editor_heading" *ngIf="
                                          !chapterData?.currentChapter.is_paid ||
                                          chapterData?.currentChapter.paid_for ||
                                          userdata.data._id ==
                                            chapterData?.currentChapter.created_by
                                          ">
                                            <div class="sub_details" *ngIf="chapterData?.currentChapter.content && !isLoading">
                                              <!-- <div id="cc" class="demo-back" [innerHTML]="chapterData?.currentChapter.content | safeHtml: 'html'"></div> -->
                                              <quill-view [content]="chapterData?.currentChapter.content" format="html"></quill-view>
                                              <div class="container">
                                              </div>
                                            </div>
                                          </div>

                                          <ng-container *ngIf="chapterData?.currentChapter?.audio">
                                            <hr>
                                            <div>
                                              <app-audio-player [audioSource]="baseURL + chapterData?.currentChapter?.audio"></app-audio-player>
                                            </div>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div #commentConntainer
                              class=" d-flex {{this.chapterData?.currentChapter._id != this.chapterData?.chapters[0]._id ? 'justify-content-between' : 'justify-content-end' }}  upload-chapter">
                              <button class="write-button-save drafted-chapter-button" id="drafted-book" type="submit"
                                color="warn" style="background-color: #333333;"
                                *ngIf="this.chapterData?.currentChapter._id != this.chapterData?.chapters[0]._id && this.chapterData?.currentChapter._id !== this.chapterData?.chapters[this.chapterData?.chapters.length - 1]._id"
                                routerLink="/readchapter/{{ chapterData?._id }}/{{previosChapter}}/{{ author_id }}"
                              >
                                Previous Chapter
                              </button>
                              <button class="write-button-save drafted-chapter-button first-chapter-button" id="drafted-book" type="submit"
                                color="warn" style="background-color: #333333;"
                                *ngIf="this.chapterData?.currentChapter._id != this.chapterData?.chapters[0]._id && this.chapterData?.currentChapter._id == this.chapterData?.chapters[this.chapterData?.chapters.length - 1]._id"
                                routerLink="/readchapter/{{ chapterData?._id }}/{{previosChapter}}/{{ author_id }}"
                              >
                                Previous Chapter
                              </button>
                              <button class="write-button-save upload-chapter-button" id="publish-book" type="submit"
                                color="warn"
                                *ngIf="chapterData?.chapters?.length > 1 && this.chapterData?.currentChapter._id != this.chapterData?.chapters[chapterData?.chapters.length - 1]._id && this.chapterData?.currentChapter._id !== this.chapterData?.chapters[0]._id"
                                routerLink="/readchapter/{{ chapterData?._id }}/{{nextChapter}}/{{ author_id }}"
                              >
                                Next
                              </button>
                              <button class="write-button-save upload-chapter-button first-chapter-button" id="publish-book" type="submit"
                                color="warn"
                                *ngIf="chapterData?.chapters?.length > 1 && this.chapterData?.currentChapter._id != this.chapterData?.chapters[chapterData?.chapters.length - 1]._id && this.chapterData?.currentChapter._id == this.chapterData?.chapters[0]._id"
                                routerLink="/readchapter/{{ chapterData?._id }}/{{nextChapter}}/{{ author_id }}"
                              >
                                Next
                              </button>
                              <!-- <button class="write-button-save upload-chapter-button next first-chapter-button" id="publish-book" type="submit"
                                color="warn" *ngIf="chapterData?.chapters?.length > 1 && chapterData?.currentChapter.next_chapter_id?.length > 1 && this.chapterData?.currentChapter._id == this.chapterData?.chapters[0]._id" routerLink="/readchapter/{{ chapterData?._id }}/{{
                                chapterData?.currentChapter.next_chapter_id
                              }}/{{ author_id }}">
                                Next
                              </button> -->
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
        <div class="col-lg-10 ">
          <section class="comment-container">
            <div class="d-flex align-items-center flex-column mt-4 mb-4 pb-4">
              <div class="light_gradient_bg2 w-75 d-flex justify-content-start">
                <h3 class="comment-title">
                  Comments<span
                    ><img src="assets/images/comment2.svg" alt=""
                  /></span>
                </h3>
              </div>

              <div class="alert alert-success mt-4" *ngIf="xchaptermsg">
                {{ xchaptermsg }}
              </div>

              <div class="leave_comment d-flex align-items-center w-75 m-0">
                <div class="comment_pic mr-3">
                  <img
                    *ngIf="userImage !== ''"
                    src="{{ baseURL }}{{ userImage }}"
                    alt=""
                  />
                  <img
                    *ngIf="userImage == ''"
                    [src]="baseURL + userImage"
                    onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                    alt=""
                  />
                </div>

                <form [formGroup]="commentForm" class="commt_post_btn d-flex w-100 justify-content-between m-0">
                  <input
                    type="text"
                    placeholder="Leave a comment"
                    type="text"
                    formControlName="comment"
                    [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                    id="comment-form"
                    maxlength="1000"
                    class="comment-input"
                  />

                  <div
                    *ngIf="submitted && f.comment.errors.required"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.comment.errors.required">
                      Comment is required.
                    </div>
                  </div>
                  <button
                    type="button"
                    (click)="postComment()"
                    class="
                      site_btn
                      btn btn-sm
                      view-more
                      global-button
                      post__comment
                      d-flex
                    "
                    style="min-width: 80px;"
                  >
                    Post
                  </button>
                </form>
              </div>
              <div
                *ngFor="let comment of commentData; let i = index"
                class="see_comment"
              >
                <div class="read_chapter_comment_pic cursor-pointer">
                  <img
                    routerLink="/authorprofile/{{ comment.created_by }}"
                    *ngIf="comment.author_image !== ''"
                    src="{{ baseURL }}{{ comment.author_image }}"
                    alt=""
                  />
                  <img
                    routerLink="/authorprofile/{{ comment.created_by }}"
                    *ngIf="comment.author_image == ''"
                    src="https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png"
                    alt=""
                  />
                </div>
                <div class="see_comment_txt">
                  <h2 class="cursor-pointer comment-data">
                    <div routerLink="/authorprofile/{{ comment.created_by }}">
                      @{{ comment.author_username }}
                    </div>
                    <span class="text-capitalize">
                      {{comment.created_at}}
                  </span>
                  </h2>
                  <p class="author__coment" style="white-space: break-spaces">
                    {{ comment.comment }}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
   </div>
</div>
<app-footer></app-footer>
