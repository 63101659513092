<div class="product-wrapper video__books__container swiper-container w-100" style="width:500px; height: 260px;">
  <div class="featured-by-writer-swiper" >
    <div class="swiper-wrapper">
      <div class="swiper-slide browse-book-tile w-auto p-4"
        *ngFor="let book of myStories; let i = index">
          <div class="home-product product-default inner-quickview img-border-radius scroll-item-width"
              style="background-color: inherit!important; margin-top: 0rem;">
            <figure class="img-border-radius image-border-radius slider-image">
                <a [routerLink]="['/book', 'explore', book.id]">
                    <img loading="lazy" *ngIf="book.cover_image" class="img-border-radius image-border-radius"
                        [src]="baseimage + book.cover_image" onerror="this.src = 'assets/images/rectangle1.svg'"
                        style="min-height: 177px;" />
                </a>
            </figure>
          </div>
          <div class="product-details">
            <h2 class="product-title" routerLink="/book/most_liked/{{ book._id }}/{{book.genre}}">
                <a class="text-underline-on-hover">{{ book.title }}</a>
            </h2>
            <h2 class="product-title" routerLink="/authorprofile/{{ book.author_id }}">
                <a class="font-italic text-underline-on-hover">@{{ book.author_username }}</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="myStories && myStories.length !== 0" class="featured-by-writer-swiper-button-prev"><mat-icon>arrow_back_ios</mat-icon></button>
    <button *ngIf="myStories && myStories.length !== 0" class="featured-by-writer-swiper-button-next"><mat-icon>arrow_forward_ios</mat-icon></button>
</div>
