import { Component, OnInit } from '@angular/core';
import * as crypto from 'crypto-js';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  fullname: any;
  admin_id: any;
  showComponent: boolean;
  constructor() { }

  ngOnInit() {
    const userdata = localStorage.getItem('currentUser');
    let data = JSON.parse( crypto.AES.decrypt(userdata, environment.secretKey).toString( crypto.enc.Utf8 ) )
    this.fullname = data.data.fullname;
    this.admin_id = data.data._id;
  }

  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("role");
    window.location.href = "/";
  }
}
