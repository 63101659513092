import { Injectable } from '@angular/core';
import { BaseUrl } from "./../../../base-url";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  feedbackdata: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }
  
  getReports(){
    return this.http.post(this.base + `/v1/getDashboard`, null);
  }
}
