<div class="wrapper mt-5">
  <div id="content-page" class="content-page">
    <div class="container-fluid">
      <div class="row row-container">
        <!-- <div class="col md-1 col-lg-1 col-sm-1"></div> -->
        <div class="col md-12 col-lg-12 col-sm-12">
          <form
            #searchForm="ngForm"
            (ngSubmit)="onSubmit(searchForm)"
            style="width: 100%"
            class="mb-0"
          >
            <mat-form-field appearance="outline" style="width: 100%; padding: 0 15px;">
              <mat-label>Search for stories/poems/articles/tags</mat-label>
              <input matInput [(ngModel)]="searchByInput" name="searchInput" />
              <!-- <mat-icon
                (click)="onSubmit(searchForm)"
                matSuffix
                style="color: #3256f4; cursor: pointer"
                >search</mat-icon
              > -->

              <span
                class="material-icons-outlined"
                (click)="onSubmit(searchForm)"
                matSuffix
                style="color: #48E8E8; cursor: pointer"
              >
                search
              </span>
              <!-- <i class="fas fa-search" style="margin-left: 99%;"></i> -->
            </mat-form-field>
            <h1 style="font-weight: 600; margin-bottom: 25px; padding: 0 15px;">
              Results for:
              <span class="font-italic" style="font-weight: 300">{{seachText}}</span>
            </h1>
          </form>

          <!-- <div class="row">
            <div class="col-md-10 col-sm-10 col-lg-10"></div>
            <div
              class="
                col-md-1 col-sm-1 col-lg-1
                d-flex
                flex-row-reverse
                ml-5
                text-right
              "
            >
              <div
                class="layout-modes"
                style="
                  margin-top: -55px !important;
                  position: absolute;
                  right: -96px;
                "
              >
                <div
                  style="width: 100px; height: 50px; letter-spacing: normal"
                  (click)="setColumn('2', '30em')"
                  [class.active-layout]="InitialColumn"
                  [ngClass]="{
                    'btn btn-sm view-more layout-btn btn-grid grid-and-list-layout d-flex border rounded justify-content-center align-items-center font-family-roboto': true,
                    active: InitialColumn
                  }"
                  title="Grid"
                >
                  <span>Grid</span>
                  <i class="porto-icon-mode-grid ml-2"></i>
                </div>
                <div
                  style="width: 100px; height: 50px; letter-spacing: normal"
                  [class.active-layout]="!InitialColumn"
                  (click)="setColumn('1', '30em')"
                  [ngClass]="{
                    'btn btn-sm view-more layout-btn btn-grid grid-and-list-layout d-flex border rounded justify-content-center align-items-center font-family-roboto': true,
                    active: !InitialColumn
                  }"
                  title="List"
                >
                  <span>List</span>
                  <i class="porto-icon-mode-list ml-2"></i>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="nav-buttons" style="margin-top: -9px">
            <div class="swiper-button-prev">
              <i
                class="fas fa-chevron-left previous-button-icon"
                style="color: blue"
              ></i>
            </div>
          </div> -->
          <div class="col-lg-12">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div
                class="
                  iq-card-header
                  d-flex
                  justify-content-between
                  align-items-center
                  position-relative
                "
              >
                <div class="iq-header-title">
                  <h1 class="card-title mb-0 section-heading mt-0">
                    Explore by genre
                  </h1>
                </div>
              </div>

              <div class="pt-4">
                <div
                  class="swiper-container genre-slider-container mb-5"
                  style="width: 97.1%"
                >
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div
                        (click)="searchBookByGenre('all', '')"
                        class="
                          border
                          text-center
                          d-flex
                          align-items-center
                          genre-containers
                          all-ganres
                          {{ selectedItemGanre === 'all'? 'all-ganres-active': ''}}
                        "
                      >
                        All
                      </div>
                    </div>
                    <div class="swiper-slide" *ngFor="let data of genreDetails">
                      <div
                        (click)="searchBookByGenre(data.name, data._id)"
                        class="
                          border
                          text-center
                          d-flex
                          align-items-center
                          genre-containers
                          {{ selectedItemGanre === data.name? 'all-ganres-active': ''}}
                        "
                        [style.backgroundImage]="'url(' + baseimage + data.image + ')'"
                      >
                        {{ data.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12" *ngIf="tag__">
            <!-- <div class="col-lg-12"> -->
            <div
              *ngIf="tempTagList?.length > 0"
              class="iq-card iq-card-block iq-card-stretch iq-card-height tag-swiper"
            >
              <div
                class="
                  iq-card-header
                  d-flex
                  justify-content-between
                  align-items-center
                  position-relative
                "
              >
                <div class="iq-header-title">
                  <h1 class="card-title mb-0 section-heading">#Tags</h1>
                </div>
              </div>

              <div
                class="swiper-container tag-list-container mt-5 mb-5"
                style="width: 100%; padding: 0 21px"
              >
                <div class="swiper-wrapper tag-swiper">
                  <div
                    (click)="filterByTags('all', 0)"
                    class="swiper-slide swiper-slide-tags tag-size swiper-tag-item"
                    routerLinkActive="active-pill"
                    [class.active-pill]="tempTagList.isActive"
                  >
                    <span class="{{ selectedAll? 'select-all-tag': ''}} book-tag text-center m-0">#All</span>
                  </div>
                  <!-- (click)="tagHandler(i)" -->
                  <div
                    class="swiper-slide swiper-slide-tags tag-size swiper-tag-item"
                    (click)="filterByTags(tags.name, i)"
                    *ngFor="let tags of tempTagList; let i = index"
                    [class.active-pill]="tags.isActive"
                    routerLinkActive="active-pill"
                  >
                    <span class="book-tag text-center m-0">#{{ tags.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="nav-buttons">
            <div class="swiper-button-next" style="margin: 0 0 0 10px">
              <i
                class="fas fa-chevron-right next-button-icon"
                style="color: blue"
              ></i>
            </div>
          </div> -->

          <app-loader *ngIf="!loaded"></app-loader>

          <div *ngIf="loaded" style="margin-top: 100px">
            <div class="col-lg-12">
              <div
                class="
                  iq-card iq-card-block iq-card-stretch iq-card-height
                  p-5
                  text-center
                "
                *ngIf="returnData.length == 0"
              >
                <h1>No Data Found</h1>
              </div>
              <div
                *ngIf="returnData.length > 0"
                class="iq-card iq-card-block iq-card-stretch iq-card-height p-5"
              >
                <div
                  *ngFor="let data of returnData; let i = index"
                  style="margin-top: 30px"
                >
                  <!-- <div *ngIf="NewStories.type == 'data'" class="tab-content">
                    <div *ngFor="let data of NewStories.value; let i = index">
                      <div
                        style="width: 96%"
                        *ngFor="let data of data.data; let i = index"
                      > -->
                  <div
                    style="border-radius: 10px"
                    class="myStories__write p-5"
                    [style.background]="backgroundColor[i % 2]"
                  >
                    <div class="d-flex justify-content-center">
                      <!-- '../profile/mybookdetails', -->
                      <a
                        *ngIf="data.cover_image !== ''"
                        [routerLink]="['/singlebook', data._id]"
                      >
                        <img
                          *ngIf="data.cover_image !== ''"
                          class="myStories__add_image"
                          src="{{ baseimage }}{{ data.cover_image }}"
                          alt=""
                        />
                      </a>
                      <!-- '../profile/mybookdetails', -->
                      <a
                        *ngIf="data.cover_image === ''"
                        [routerLink]="['/singlebook', data._id]"
                      >
                        <div
                          *ngIf="data.cover_image == ''"
                          style="width: 240px"
                          class="image-tile patahani-cover myStories__add_image"
                        >
                          <h2 class="patahani-h2">{{ data.title }}</h2>
                        </div></a
                      >
                    </div>
                    <div class="mt-5 mb-5 ml-5">
                      <h1 class="font-italic myStories__published-drafted">
                        {{ data.title }}
                      </h1>

                      <span
                        routerLink="/authorprofile/{{ data.author_id }}"
                        class="
                          d-flex
                          font-italic
                          myStories__published-drafted
                          align-items-center
                          justify-content-start
                        "
                      >
                        <img
                          class="myStories__author_image rounded-circle"
                          src="{{ baseimage }}{{ data.author_image }}"
                          onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                        />
                        << {{ data.author_name }} >></span
                      >
                      <div class="myStories__published-drafted">
                        <!-- <h5 class="d-flex">
                              <img
                                routerLink="/profile/pro"
                                class="myStories__author_image rounded-circle"
                                src="{{ baseimage }}{{ userData.cover_image }}"
                                alt=""
                              />
                              <h5 class="ml-3 mt-1 font-italic">
                                @{{ userData.username }}
                              </h5>
                            </h5> -->
                      </div>
                      <p
                        class="
                          font-italic
                          myStories__description myStories__published-drafted
                        "
                      >
                        {{ data.description }}
                      </p>
                      <div class="ratings-container d-flex mt-4">
                        <div class="book-interation mr-4">
                          <!-- <img
                            class="book-details-icons mr-3"
                            src="../../assets/Slider/images/icons/visibility.svg"
                            alt=""
                          /> -->
                          <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
                          <span
                            class="
                              myStories__published-drafted
                              views-hearts-shares-bookshelves
                              font-italic
                            "
                            >{{ data.views }}</span
                          >
                        </div>
                        <div class="book-interation mr-4">
                          <!-- <img
                            class="book-details-icons mr-3"
                            src="../../assets/Slider/images/icons/heart_gradient.svg"
                            alt=""
                          /> -->
                          <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
                          <span
                            class="
                              myStories__published-drafted
                              views-hearts-shares-bookshelves
                              font-italic
                            "
                            >{{ data.likes }}</span
                          >
                        </div>
                        <div class="book-interation mr-4">
                          <!-- <img
                            class="book-details-icons mr-3"
                            src="../../assets/Slider/images/icons/share.svg"
                            alt=""
                          /> -->
                          <mat-icon class="small color-cyan-secondary">share</mat-icon>
                          <span
                            class="
                              myStories__published-drafted
                              views-hearts-shares-bookshelves
                              font-italic
                            "
                            >{{ data.no_of_shares }}</span
                          >
                        </div>
                        <div class="book-interation mr-4">
                          <!-- <img
                            class="book-details-icons mr-3"
                            src="../../assets/Slider/images/icons/open-book.svg"
                            alt=""
                          /> -->
                          <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
                          <span
                            class="
                              myStories__published-drafted
                              views-hearts-shares-bookshelves
                              font-italic
                            "
                            >{{ data.no_of_chapter }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- {{ data.title }} -->
                  <!-- </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col md-1 col-lg-1 col-sm-1"></div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5">
  <app-footer></app-footer>
</div>

<script>
  $(".add-cart").on("mouseover", () => {
    $(".add-cart").css({ "background-color": "#0dd6b8", border: "none" });
  });
  $(".add-cart").on("mouseout", () => {
    $(".add-cart").css({
      "background-color": "white",
      border: "1px solid lightgray",
    });
  });
</script>
