import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-image-brightness-transparency',
  templateUrl: './image-brightness-transparency.component.html',
  styleUrls: ['./image-brightness-transparency.component.sass'],
})
export class ImageBrightnessTransparencyComponent implements OnInit {
  constructor() {}

  public imageUrl: string[] = [];
  public twoImages: boolean = false;
  public transparency: boolean = false;

  autoTicks = false;
  disabled = false;
  invert = false;

  max = 1;
  min = 0;
  value = 0.5;
  tickInterval = 0.1;

  showTicks = false;
  step = 0.1;
  thumbLabel = true;

  max_brightness = 100;
  min_brightness = 1;
  brightness = 100;
  tickInterval_brightness = 1;
  step_brightness = 1;

  vertical = false;

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

  public showCanvas = true;
  @ViewChild('canvas') canvas: ElementRef;

  @Output() public imageCanvas: EventEmitter<any>;

  createCanvas(nativeElement: any) {
    var canvas: HTMLCanvasElement = nativeElement;
    var context: CanvasRenderingContext2D = <CanvasRenderingContext2D>(
      canvas.getContext('2d')
    );

    let img1 = new Image();
    img1.src = this.imageUrl[0];
    // img1.width = 270;
    // img1.height = 360;

    let img2 = new Image();
    img2.src = this.imageUrl[1];
    // img2.width = 270;
    // img2.height = 360;

    img1.onload = () => {
      canvas.width = 270;
      canvas.height = 360;
    };

    img2.onload = () => {
      context.globalAlpha = 1.0;
      context.drawImage(img1, 0, 0, 270, 370);
      context.globalAlpha = this.value; //Remove if pngs have alpha
      context.filter = `brightness(${this.brightness}%)`;

      context.drawImage(img2, 0, 0, 270, 370);

      this.imageCanvas.emit(canvas.toDataURL());
    };
  }

  public text = false;

  writeText() {
    this.showCanvas = true;
    this.createCanvas(this.canvas.nativeElement);
    this.text = true;
    this.transparency = false;
  }

  ngOnInit(): void {}
}
