import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IClientAuthorizeCallbackData, ICreateOrderRequest, IPayPalConfig, OrderStatus } from 'ngx-paypal';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';

interface LibcoinCatalogItem {
    id: string,
    libcoins: number;
    amount: string | number;
    currency: string | 'USD';
}

@Component({
  selector: 'app-purchase-options',
  templateUrl: './purchase-options.component.html',
  styleUrls: ['./purchase-options.component.sass']
})
export class PurchaseOptionsComponent implements OnInit {

  constructor(private transactionService: TransactionService, private _snackbar: MatSnackBar) { }

  payPalConfig: IPayPalConfig;

  @Output() closePurchaseOption = new EventEmitter<void>();
  @Output() onPaymentComplete = new EventEmitter();
  @Input() userLibcoins: number | string;
  @Input() chapterId: string;
  @Input() showCloseButton: boolean = true;

  libcoins_catalog : Array<LibcoinCatalogItem> = [
    { id: '1', libcoins: 3, amount: '0.99', currency: 'USD'},
    { id: '2', libcoins: 15, amount: '4.25', currency: 'USD'},
    { id: '3', libcoins: 100, amount: '25.00', currency: 'USD'}
  ]
  selected_libcoins_price: LibcoinCatalogItem;

  ngOnInit(): void {
  }

  initConfig = (selected_option: LibcoinCatalogItem) => {
    this.selected_libcoins_price = selected_option;
    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.PAYPAL_CLIENT_ID,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: selected_option.amount,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: selected_option.amount,
                  },
                },
              },
              // payee: { email_address: 'sb-umqxq7711335@personal.example.com' },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: selected_option.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: (data: IClientAuthorizeCallbackData) => {

        if(data.status === "COMPLETED") {
          this.creditLibcoins(data.id);
          this._snackbar.open(`Payment completed successfully` , 'Ok', { duration: 2000 });
        } else {
          this._snackbar.open(`Something went wrong, paypal transaction was ${data.status}` , 'Ok', { duration: 2000 });
        }

        // this.
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: (err) => {
        console.log(err);
        // this.anyError = true;
        // this.errorMessage = err;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);

        // this.thisDialogBox.close({ error: data });
      },
      onInit: () => {
        console.log("ON INIt");
      }
    };
  };

  creditLibcoins(paymentId?: string) {
    const data = {
      libcoins: this.selected_libcoins_price.libcoins,
      SKUId: paymentId || '',
      chapterId: this.chapterId,
      currency: this.selected_libcoins_price.currency.toLowerCase(),
      amount: this.selected_libcoins_price.amount
    }
    this.transactionService.creditLibcoin(data).subscribe((res: any) => {
      this._snackbar.open(res?.message, 'Ok', { duration: 2000 });
      if(res?.status){
        this.onPaymentComplete.emit();
        this.closePurchaseOption.emit();
      }
    })
  }

}
