<div class="swiper-container best-seller-swiper" style="width: 90%; position: relative;">
  <div class="swiper-wrapper">
    <div class="swiper-slide d-flex flex-column align-items-center" *ngFor="let book of bestSellingBooks; let i = index">
      <div class="home-slide text-center d-flex flex-column align-items-center">
        <img loading="lazy" src="../../assets/Slider/images/lazy.png" data-src="" alt="slider image" />
        <div class="
          home-product-2
          product-default
          inner-quickview
          img-border-radius
          image-border-radius
        ">
          <figure class="img-border-radius image-border-radius slider-image slider-image-hoverable">
              <div  style="position: relative; cursor: pointer;">
                <a [routerLink]="['/singlebook',book?._id]">
                  <img loading="lazy" *ngIf="book.cover_image != ''" class="img-border-radius image-border-radius book-cover-image" [src]="baseimage + book.cover_image" onerror="this.src = 'assets/images/rectangle1.svg'" />
                  <img loading="lazy" *ngIf="book.cover_image == ''" class="img-border-radius image-border-radius book-cover-image" src="assets/images/rectangle1.svg" />
                  <div class="eighteen-plus-container" *ngIf="book.not_for_below_eighteen">
                    <h4 class="eighteen-plus">18+</h4>
                  </div>
                </a>
              </div>
          </figure>
          <div class="product-details" style="color: black; width: 211px;">
            <h2
              class="product-title book-title m-0 w-100 text-start"
              routerLink="/book/most_liked/{{ book._id }}/{{
                book.genre
              }}"
            >
              <a style="color: black !important; text-align: start; font-style: italic;" class="text-underline-on-hover book-title-text">{{ book.title }}</a>
            </h2>
            <h2 style="text-align: start;" class="product-title book-title w-100" routerLink="/authorprofile/{{ book.author_id }}">
              <a style="color: black !important;" class="font-italic text-underline-on-hover book-title-text">@{{ book.author_username }}</a>
            </h2>
          </div>
          <div class="ratings-container">
            <div class="book-interation mr-4">
              <mat-icon class="small color-cyan-secondary">visibility</mat-icon>
              <span class="views-hearts-shares-bookshelves">{{book?.views}}</span>
            </div>
            <div class="book-interation ml-2">
              <mat-icon class="small color-cyan-secondary">favorite</mat-icon>
              <span class="views-hearts-shares-bookshelves">{{ book?.likes }}</span>
            </div>
            <div class="book-interation ml-2">
              <mat-icon class="small color-cyan-secondary">share</mat-icon>
              <span class="views-hearts-shares-bookshelves">{{ book?.no_of_shares }}</span>
            </div>
            <div class="book-interation ml-2">
              <mat-icon class="small color-cyan-secondary">menu_book</mat-icon>
              <span class="views-hearts-shares-bookshelves">{{ book?.no_of_chapter }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- first arrow -->
  <button class="best-seller-swiper-button-prev"><mat-icon>arrow_back_ios</mat-icon></button>
  <button class="best-seller-swiper-button-next"><mat-icon>arrow_forward_ios</mat-icon></button>
</div>
