import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-bookshelf',
  templateUrl: './navbar-bookshelf.component.html',
  styleUrls: ['./navbar-bookshelf.component.sass'],
})
export class NavbarBookshelfComponent implements OnInit {
  @Input() bookShelf: any;

  bookshelfData: Array<any> = [];
  bookshelfCount: number;
  baseimage: string = environment.imageBaseUrl;

  constructor() {
  }

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges) {
    if (change.bookShelf.currentValue) {
      this.bookShelf = change.bookShelf.currentValue;
      this.bookshelfData = this.bookShelf?.data;
      this.bookshelfCount = this.bookShelf?.total_unread_items;
    }
  }
}
