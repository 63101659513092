import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-image-final-canvas',
  templateUrl: './image-final-canvas.component.html',
  styleUrls: ['./image-final-canvas.component.sass'],
})
export class ImageFinalCanvasComponent implements OnInit {
  @Input() public finalCanvasUrl: any;

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
  }

  ngOnInit(): void {}
}
