import { Component, OnInit } from "@angular/core";
import { ManageCopyrightService } from "./manage-copyright.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-manage-copyright",
  templateUrl: "./manage-copyright.component.html",
  styleUrls: ["./manage-copyright.component.sass"]
})
export class ManageCopyrightComponent implements OnInit {
  temp: boolean;
  getType: any;
  showLoader = false;
  constructor(private genreService: ManageCopyrightService, private toaster: ToastrService) {}

  ngOnInit() {
    this.showLoader = true;
    this.loadType();
  }

  loadType() {
    this.genreService.getCopyrights().subscribe(
      (res: any) => {
        this.getType = res.data;
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
  onClick(event) {
    this.genreService.updateStatus(event).subscribe(
      (res: any) => {
        if (event) {
          if (event.status == 'true') {
            this.toaster.success('Copyright enabled successfully.')
          } else {
            this.toaster.success('Copyright disabled successfully.')
          }
          this.loadType();
        } else {
          this.toaster.success('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
}
