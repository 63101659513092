import { Component, OnInit } from '@angular/core';
import { BookshelfService } from './bookshelf.service';
import { first } from 'rxjs/operators';
// import { AlertService } from 'src/app/_services/alert.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from 'src/app/base-url';
import { AlertService } from 'src/app/services/alert.service';
import Swiper from 'swiper';
import { HelperService } from 'src/app/helper.service';
// import { AuthorProfileService } from 'src/app/author-profile/author-profile.service';
import { AuthorProfileService } from 'src/app/profile-author/author-profile/author-profile.service';
import { BookDetailService } from 'src/app/book-detail/book-detail.service';
import { NavbarService } from '../../navbar/navbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;
@Component({
  selector: 'app-bookshelf',
  templateUrl: './bookshelf.component.html',
  styleUrls: ['./bookshelf.component.sass'],
})
export class BookshelfComponent implements OnInit {
  bookshelfdata: any;
  baseurl: any;
  pagenumer = 0;
  pageNumber = 10;
  SlideOptions = {
    stagePadding: 200,
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    items: 6,
    slidesPerView: 6,
    lazyLoad: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
        stagePadding: 60,
      },
      600: {
        items: 1,
        nav: true,
        stagePadding: 100,
      },
      1000: {
        items: 1,
        nav: true,
        stagePadding: 200,
      },
      1200: {
        items: 6,
        nav: true,
        stagePadding: 250,
      },
      1400: {
        items: 6,
        nav: true,
        stagePadding: 300,
      },
      1600: {
        items: 6,
        nav: true,
        stagePadding: 350,
      },
      1800: {
        items: 6,
        nav: true,
        stagePadding: 300,
      },
    },
  };
  CarouselOptions = { items: 1, dots: false, nav: true };
  userid;
  baseimage;
  authordata;
  likedBooks;
  lastSeenBook: any


  viewedBookObservable;
  constructor(
    private toaster: ToastrService,
    private bookshelf: BookshelfService,
    private router: Router,
    private alertService: AlertService,
    private helper: HelperService,
    private authorService: AuthorProfileService,
    private bookDetailService: BookDetailService,
    private navbarService: NavbarService,
    private _snackBar: MatSnackBar,
  ) {
    this.baseurl = BaseUrl.image;
    this.baseimage = BaseUrl.image;
  }

  ngAfterViewInit() {
    var swiper = new Swiper('.swiper1', {
      slidesPerView: 6,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
      spaceBetween: 20,
      grabCursor: true,
      breakpoints:{
        "969": {
          slidesPerView: 6,
          slidesPerColumn: 2,
        },
        "572":{
          slidesPerView: 4,
          slidesPerGroup: 2

        },
        "375":{
          slidesPerView: 3,
          slidesPerColumn: 1,
        },

      },
      slidesPerGroup: 3,
      observer: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });


    // setTimeout(() => {
    //   var swiper_liked = new Swiper('.liked-books-slider', {
    //     slidesPerView: 5,
    //     slidesPerGroup: 5,
    //     spaceBetween: 20,
    //     grabCursor: true,
    //     observer: true,
    //   });
    // }, 2000);
  }

  randomNumbers: Array<number>;
  ngOnInit() {
    console.log(this.baseurl)
    this.loadBookShelfData();
    this.loadAuthorData();
    this.userHistory()
    this.randomNumbers = Array.from({ length: 40 }, () =>
      Math.floor(Math.random() * 40)
    );
    var liked_swiper = new Swiper('.swiper2', {
      slidesPerView: 6,
      spaceBetween: 20,
      grabCursor: true,
      breakpoints:{
        "969": {
          slidesPerView: 6
        },
        "572":{
          slidesPerView: 4,
          slidesPerGroup: 2

        },
        "375":{
          slidesPerView: 3,
          slidesPerColumn: 1,
        },

      },
      slidesPerGroup: 3,
      observer: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  userHistory(){
    this.bookshelf
      .getBookHistory(false, 10)
      .subscribe((bookHistory: any) => {
        if (bookHistory.status === 200) {
          this.lastSeenBook = bookHistory.history;
          console.log('lastSeenBook =>', this.lastSeenBook)
          var swiper = new Swiper('.favorite-swiper-container', {
            // loop: true,
            spaceBetween: 10,
            grabCursor: true,
            slidesPerView: 3,
            slidesPerGroup: 1,
            speed: 1000,
            autoplay: true,
            breakpoints: {
              300: {
                slidesPerView: 1,
              },
              800: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 3,
              },
            },
          });
          // setTimeout(() => {
          //   this.swiper.update();
          // }, 0);
        }
      }
    );
  }

  loadAuthorData() {
    this.userid = this.helper.getUserData();
    this.userid = this.userid.data._id;

    this.authorService.getAuthor(this.userid).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        if (res.data.cover_image) {
          res.data.coverimage = this.baseimage + '' + res.data.cover_image;
        } else {
          res.data.coverimage = 'assets/img/bg_place_holder.jpg';
        }
        this.authordata = res.data;
        this.authordata.myLikedStories.every((elem) => {
          // var { cover_image } = this.helper.appendBaseUrlToImage(
          //   elem['author_image']
          // );
          // elem['author_image'] = cover_image;

          var { cover_image } = this.helper.appendBaseUrlToImage(
            elem['cover_image']
          );
          elem['cover_image'] = cover_image;

          setTimeout(() => {
            var swiper_liked = new Swiper('.liked-books-slider', {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 20,
              grabCursor: true,
              observer: true,
            });
          }, 0);
          return true;
        });
        // this._seoService.updateTitle(this.authordata?.username || 'Librums');
        // this.showLoader = false;

        this.likedBooks = this.authordata.myLikedStories;
        console.log('this.likedBooks =>', this.likedBooks)
        this.likedBooks.every((elem) => {
          var { cover_image } = this.helper.appendBaseUrlToImage(
            elem['cover_image']
          );
          elem['cover_image'] = cover_image;
        });
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  loadBookShelfData() {
    this.bookshelf.getBookShelf().subscribe(
      (res: any) => {
        if (res.status) {
          this.bookshelfdata = res.data;
          console.log('loadBookShelfData',this.bookshelfdata)
          var temp = [];
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  removeFromBookshelf(book_id) {
    this.bookshelf
      .removeFromBookshelf({ book_id: book_id })
      .pipe(first())
      .subscribe(
        (res: any) => {
          // this.alertService.success("User follow successfully", true);
          this.toaster.success(res.message);
          this.loadBookShelfData();
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }
  reportBook(id) {
    localStorage.setItem('book_id', id);
    this.router.navigate(['/report-an-issue']);
  }
  searchTag(text) {
    localStorage.setItem('search', text);
    this.router.navigate(['/search']);
  }

  onScrollDown() {
    this.pagenumer = this.pagenumer + 1;
    let id = $('.nav-link.active').data('sectionvalue');
    let tabnamse = $('.nav-link.active').data('tabename');
    this.bookshelf.loadMoreBooks({ page_no: this.pagenumer }).subscribe(
      (res: any) => {
        if (res.status) {
          this.pageNumber = this.pageNumber + 10;
          let resultArr = [];
          resultArr = this.bookshelfdata.concat(res.data);
          this.bookshelfdata = resultArr;
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  sharePop(book_id) {
    $('.share-social').each(function (key, index) {
      $(index).find('i').attr('data-id', book_id);
      // $(index).data('id', book_id)
      if ($(index).data('type') == 'facebook') {
        $(index).attr('data-id', book_id);
        $(index).attr(
          'href',
          'https://www.facebook.com/sharer/sharer.php?u=https://librums.com/shareBook.html?book_id=' +
            book_id +
            '&quote=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book.'
        );
      }
      if ($(index).data('type') == 'twitter') {
        $(index).data('id', book_id);
        $(index).attr(
          'href',
          'https://twitter.com/share?url=https://librums.com/shareBook.html?book_id=' +
            book_id +
            '&quote=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book.'
        );
      }
      if ($(index).data('type') == 'linkedin') {
        $(index).data('id', book_id);
        $(index).attr(
          'href',
          'https://www.linkedin.com/shareArticle?mini=true&url=https://librums.com/shareBook.html?book_id=' +
            book_id +
            '&quote=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book.'
        );
      }
      if ($(index).data('type') == 'pinterest') {
        $(index).data('id', book_id);
        $(index).attr(
          'href',
          'http://pinterest.com/pin/create/button/?url=https://librums.com/shareBook.html?book_id=' +
            book_id +
            '&description=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book.'
        );
      }
      if ($(index).data('type') == 'envelope') {
        $(index).data('id', book_id);
        $(index).attr(
          'href',
          'mailto:support@librums.com?Subject=Share Book With your Family&Body=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book. https://librums.com/shareBook.html?book_id=' +
            book_id
        );
      }
      if ($(index).data('type') == 'whatsapp') {
        $(index).data('id', book_id);
        $(index).attr(
          'href',
          'https://web.whatsapp.com/send?text=If you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book. https://librums.com/shareBook.html?book_id=' +
            book_id
        );
      }
    });
  }

  shareBook(e) {
    this.bookshelf
      .shareBook({ book_id: e.target.dataset.id })
      .pipe(first())
      .subscribe(
        (res: any) => {
          $('#forgot').modal('hide');
          this.loadBookShelfData();
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  public goToBookPage = (book_id: string) => {
    this.bookshelf.viewedBookInBookshelf(book_id).subscribe((res: any) => {
      if (res.status === 200) {
        this.router.navigate([`/singlebook/${book_id}`]);
      } else {
      }
    });
    (error: any) => {
      console.log('Error: ', error);
    };
  };

  removeBookShelf(book: any) {
    this.bookDetailService.removeToBookShelf({ book_id: book?.book_id }).subscribe(
      (res: any) => {
        console.log(res)
        if (res.status) {
          book.is_in_bookshelves = false;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
          this.loadBookShelfData();
        } else {
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  // ngOnDestroy() {
  //   this.viewedBookObservable.unsubscribe();
  // }
}
