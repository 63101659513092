<div
  class="iq-card-header d-flex justify-content-between align-items-center mb-0"
>
  <div class="iq-header-title">
    <h1 class="card-title mb-0">Change Password</h1>
  </div>
</div>
<div class="iq-card-body">
  <!-- <mat-form-field class="w-100">
    <mat-label>Current Password</mat-label>
    <input matInput type="text" />
  </mat-form-field> -->
  <ul class="list-inline p-0 mb-0">
    <li>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div style="width: 100% !important" class="d-inline-block mt-1 w-100">
          <mat-form-field class="w-100">
            <mat-label>Current Password</mat-label>
            <input matInput type="text" />
          </mat-form-field>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div style="width: 100% !important" class="d-inline-block mt-1 w-100">
          <mat-form-field class="w-100">
            <mat-label>New Password</mat-label>
            <input matInput type="text" />
          </mat-form-field>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div style="width: 100% !important" class="d-inline-block mt-1 w-100">
          <mat-form-field class="w-100">
            <mat-label>Confirm Password</mat-label>
            <input matInput type="text" />
          </mat-form-field>
        </div>
      </div>
    </li>
  </ul>

  <button class="btn btn-sm view-more global-button" style="height: 35px;">Submit</button>
</div>
