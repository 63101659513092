import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  Injectable,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { WriteBookService } from '../services/write-book.service';
import * as $ from 'jquery';
import { drawImage } from 'canvas-object-fit';
import { Router } from '@angular/router';
import { ImageService } from './image.service';
import { BaseUrl } from '../base-url';
import {GlobalService} from '../global.service'

@Component({
  selector: 'app-image-editor-canvas',
  templateUrl: './image-editor-canvas.component.html',
  styleUrls: ['./image-editor-canvas.component.sass'],
})
@Injectable({
  providedIn: 'root'
})

export class ImageEditorCanvasComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  @Output() public imageCanvas: EventEmitter<any> = new EventEmitter();

  @Input('url') public url =
    'assets/img/rectangle1.svg';

  constructor(
    private writeBookService: WriteBookService,
    private dialog: MatDialog,
    private router: Router,
    private imageService: ImageService,
    private globalService: GlobalService
    ) {
    this.baseurl = BaseUrl.image
  }

  public baseurl: string;

  /**
   * START -  Add Multiple Images
   */
  public imageUrl: string[] = [];
  public twoImages: boolean = false;
  public transparency: boolean = false;
  public brightnessAndTransparency = true;
  public book_title = false;
  public getWrite = this.globalService.globalWriteState

  imageEditorStatus: boolean = false;
  ngOnChanges(change: SimpleChanges) {}
  changeWriteStatus(){
    this.imageEditorStatus = true
  }
  onSelectFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dialogRef = this.dialog.open(ImageDialogComponent, {
        data: { url: reader.result },
        height: '700px',
        width: '700px',
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        // this.url = result.data;
        this.imageUrl.push(result.data);
        this.imageService.setImageUrl(result.data);

        this.twoImages = this.imageUrl.length === 2 ? true : false;
        this.transparency = this.twoImages;
      });
    };
    reader.readAsDataURL(file);
  }
  /**
   * END -  Add Multiple Images
   */

  /**
   * START -  Multiple Image editor
   */
  autoTicks = false;
  disabled = false;
  invert = false;

  max = 1;
  min = 0;
  value = 0.5;
  tickInterval = 0.1;

  showTicks = false;
  step = 0.1;
  thumbLabel = true;

  max_brightness = 100;
  min_brightness = 1;
  brightness = 100;
  tickInterval_brightness = 1;
  step_brightness = 1;

  vertical = false;

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }
  /**
   * END - Mutliple Image Editor
   */

  /**
   * Create Canvas
   */

  public showCanvas = true;
  createCanvas(nativeElement: any) {
    var canvas: HTMLCanvasElement = nativeElement;
    var context: CanvasRenderingContext2D = <CanvasRenderingContext2D>(
      canvas.getContext('2d')
    );

    let img1 = new Image();
    img1.src = this.imageUrl[0];
    img1.style.objectFit = 'cover !important';
    img1.style.backgroundSize = 'cover !important';

    let img2 = new Image();
    img2.src = this.imageUrl[1];
    img2.style.objectFit = 'cover !important';
    img2.style.backgroundSize = 'cover !important';

    img1.onload = () => {
      canvas.width = 270;
      canvas.height = 360;
      context.drawImage(img1, 0, 0, 270, 370);
    };

    img2.onload = () => {
      context.globalAlpha = 1.0;
      drawImage(context, img1, 0, 0, 270, 370, { objectFit: 'cover' });
      context.globalAlpha = this.value; //Remove if pngs have alpha
      context.filter = `brightness(${this.brightness}%)`;

      drawImage(context, img2, 0, 0, 270, 370, { objectFit: 'cover' });

      this.imageCanvas.emit(canvas.toDataURL());
    };
  }
  /**
   * END - Create Canvas
   */

  /**
   * START - Write Text
   */
  public text = false;
  public bookTitle: string = '';
  writeText() {
    this.showCanvas = true;
    this.createCanvas(this.canvas.nativeElement);
    this.text = true;
    this.transparency = false;
    // this.book_title = true;
  }
  /**
   * END - Write Text
   */

  /**
   * Create cover image
   */
  public uploadImage = false;

  createCoverImage() {
    this.uploadImage = !this.uploadImage;
    this.twoImages = false;
  }

  removeImage(index: number) {
    this.imageUrl.splice(index, 1);
  }

  // brightnessValue = 50;
  // opacity = 0.5;

  changeBrightness(size) {
    size = size.target.value;
    this.brightness = size;
  }

  chnageTransparency(val) {
    val = val.target.value;
    this.value = val;
  }

  editBookCover() {
    this.router.navigate(['/add-book-cover'], { queryParams: { edit: true } });
  }

  @Input('editBook') editBook = false;
  ngOnInit(): void {}
}
