import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public _baseURL;
  constructor(private http: HttpClient, private helper: HelperService) {
    this._baseURL = BaseUrl.frontend;
  }

  searchBook(data) {
    // this.searchdata = JSON.parse(localStorage.getItem("currentUser"));
    // const headers = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   "x-access-token": this.searchdata.data.token
    // });
    const headers = this.helper.getHeader();
    return this.http.post(this._baseURL + '/searchBook', data, {
      headers,
    });
  }
  getSearchList(data) {
    // this.searchdata = JSON.parse(localStorage.getItem("currentUser"));
    // const headers = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   "x-access-token": this.searchdata.data.token
    // });
    const headers = this.helper.getHeader();
    return this.http.post(this._baseURL + '/getSearchList', data, {
      headers,
    });
  }

  loadMoreBooks(data) {
    // this.searchdata = JSON.parse(localStorage.getItem("currentUser"));
    // const headers = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   "x-access-token": this.searchdata.data.token
    // });
    const headers = this.helper.getHeader();
    return this.http.post(this._baseURL + '/searchBook', data, {
      headers,
    });
  }

  searchMyBoooks (payload: {search: string, limit?: number, skip?: number, excludeDraft?: boolean, userSpecific?: boolean}) {
    return this.http.post(environment.baseUrl + '/searchMyBooks', payload);
  }

  searchUsers (payload: {search: string, limit?: number, skip?: number}) {
    return this.http.post(environment.baseUrl + '/searchUsers', payload);
  }
}
