import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EditProfileInfoService } from './edit-profile-info.service';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { SeoserviceService } from '../seoservice.service';
import { NgForm } from '@angular/forms';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageService } from '../image-editor-canvas/image.service';
import { PostMessageService } from '../post-message.service';
import { ProfileService } from '../admin/admin-modules/profile/profile.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-profile-info',
  templateUrl: './edit-profile-info.component.html',
  styleUrls: ['./edit-profile-info.component.sass']
})
export class EditProfileInfoComponent implements OnInit {
  _baseURL: any;
  showLoader = false;
  userid: any;
  authordata: any;
  userdata: any;
  loggedIn: any;
  baseimage: any;
  admin_id: any;
  usersUpdate: any;
  userName: string;
  postimage: any;
  cover_image: any;
  profile_image: any;
  public uploadedImage: any

  cover_form_data: FormData = new FormData();
  profile_form_data: FormData = new FormData();

  constructor(
    private editProfileService: EditProfileInfoService,
    private activatedRoute: ActivatedRoute,
    private helper: HelperService,
    private dialog: MatDialog,
    private _seoService: SeoserviceService,
    private imageService: ImageService,
    private postmessage: PostMessageService,
    private profileService: ProfileService,
    private changeadmin: ProfileService,
    private router: Router,
    private toaster: ToastrService,
    ) {
    this._baseURL = BaseUrl.image;
    this.baseimage = BaseUrl.image;
  }

  async submitNewProfileInfo(editProfileInfo: NgForm): Promise<any> {
    try {
      const data: any = { ...editProfileInfo?.value };
      if (this.cover_image) {
        const cover_image_response: any = await this.profileService.upload_image(this.cover_form_data).pipe(first()).toPromise();
        if(cover_image_response?.status && cover_image_response?.data){
          data.cover_image = cover_image_response?.data;
        }
      }
      if(this.profile_image) {
        const profile_image_response: any = await this.profileService.upload_image(this.profile_form_data).pipe(first()).toPromise();
        if(profile_image_response?.status && profile_image_response?.data){
          data.image = profile_image_response?.data;
        }
      }

      this.profileService.update_user_profile(data).subscribe((res: { status: boolean | any, data: any, message: string }) => {
        // console.log("res => ", res);
        this._seoService.updateTitle(res?.data?.fullname);
        this.helper.refetchUserData();
        this.router.navigate(['/', 'authorprofile', this.userdata?.data?._id]);
      })
    } catch (err) {
      this.toaster.error('Something went wrong');
    }

  }
  getUserData() {
    this.changeadmin.getUserData(this.admin_id).subscribe(
      (res: any) => {
        if (res.status) {
          this.usersUpdate = res.data;
          if(!this.usersUpdate.hasOwnProperty('enable_mature_content')) {
            this.usersUpdate.enable_mature_content = true;
          }
          this.showLoader = false;
        }
      },
      error => {
        console.log("ERROR");
      }
    );
  }
  ngOnInit(): void {
    this.activatedRoute.url.subscribe((url) => {
      this.showLoader = true;
      this.userdata = this.helper.getUserData();
      this.userid = this.userdata.data._id;
      this.loggedIn = this.userdata.data._id;
      this.userName = this.userdata.data.fullname
      if(this.userdata.data?.dob) {
        this.userdata.data.dob = new Date(this.userdata.data.dob);
      }
      this.loadAuthorData();
    });

    this.helper.updateUserData.subscribe(() => {
      this.userdata = this.helper.getUserData();
    })
  }

  loadAuthorData() {
      this.editProfileService.getAuthor(this.userid).subscribe(
        (res: any) => {
          const mapped = Object.entries(res.data).map(([type, value]) => ({
            type,
            value,
          }));

          if (res.data.cover_image) {
            res.data.coverimage = this.baseimage + '' + res.data.cover_image;
          } else {
            res.data.coverimage = 'assets/img/bg_place_holder.jpg';
          }
          this.authordata = res.data;
          var { cover_image } = this.helper.appendBaseUrlToImage(
            this.authordata['coverimage']
          );
          this.authordata['coverimage'] = cover_image;

          var { cover_image } = this.helper.appendBaseUrlToImage(
            this.authordata['image']
          );
          this.authordata['image'] = cover_image;
          this.authordata.myLikedStories.every((elem) => {

            var { cover_image } = this.helper.appendBaseUrlToImage(
                elem['cover_image']
            );
            elem['cover_image'] = cover_image;
            return true;
          });
          this._seoService.updateTitle(this.authordata?.username || 'Librums');
          this.showLoader = false;
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  ImageDialogClosed(dialogRef: MatDialogRef<ImageDialogComponent>, type: 'cover' | 'profile' = 'cover') {
    dialogRef.afterClosed().subscribe((result: any) => {
      if(!result) return;
        this.imageService.f(result.data);
          if(result != null){
          const blob = this.imageService.b64toBlob(result?.data);

          if(type === 'cover') {
            this.cover_image = result?.data;
            this.cover_form_data.append('image', blob);
          } else if(type === 'profile') {
            this.profile_image = result?.data;
            this.profile_form_data.append('image', blob);
          }
        }
    });
  }
  onSelectFile(event: any, type: 'cover' | 'profile' = 'cover') {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {

      const data: any = { url: reader.result, uploadedImage: true, crop: true, aspectRatio: 4 / 1 };
      if (type == 'profile') {
        data.aspectRatio = 1/1;
        data.roundCropper = true;
      }

      const dialogRef = this.dialog.open(ImageDialogComponent, {
        data: data,
        height: '370px',
        maxHeight: '370px',
        width: '260px',
      });
      this.ImageDialogClosed(dialogRef, type);
    };
    reader.readAsDataURL(file);
  }
}

