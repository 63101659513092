import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as crypto from 'crypto-js';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ViewManageContentService {
  base: any;
  baseapi: any;
  tags: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
    this.baseapi = BaseUrl.imageApi;
  }
  usersimage: any;
  bookdata: any;
  contentdata: any;
  post<T>(SERVER_URL: any, formData: FormData) {
    throw new Error("Method not implemented.");
  }
  getBookDetail(id) {
    this.bookdata = localStorage.getItem("currentUser")
    let token = JSON.parse( crypto.AES.decrypt(this.bookdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getBookDetail`,
      { book_id: id },
      {
        headers
      }
    );
  }

  getContentType() {
    this.contentdata = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data",
      "x-access-token": this.contentdata.data.token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `getContentType`, null, {
      headers
    });
  }

  book_cover_image(image) {
    this.usersimage = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders({
      // "Content-Type": "multipart/form-data",
      "x-access-token": this.usersimage.data.token
    });
    return this.http.post(this.baseapi + `book_cover_image`, image, {
      headers
    });
  }

  updateBook(data) {
    const tags = [];
    data.tags.forEach(function(item) {
      tags.push(item.value);
    });

    data.tags = tags;
    this.contentdata = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": this.contentdata.data.token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `updateBook`, data, { headers });
  }
}
