<div
  class="
    iq-card-header
    d-flex
    justify-content-between
    align-items-center
    position-relative
    section-header
  "
>
  <div class="iq-header-title">
    <h4 class="card-title mb-0 section-heading">Most Liked</h4>
  </div>
  <div class="iq-card-header-toolbar d-flex align-items-center">
    <!-- (click)="gotoBookDetails(0)" -->
    <a
      routerLink="/book/{{ bookData?.value?.tab }}/{{
        bookData?.value?.data[0]._id
      }}/{{bookData?.value?.data[0]?.genre[0]}}"
      class="btn btn-sm view-more global-button"
      ><span style="font-size: 13px"> View More </span>
    </a>
  </div>
</div>

<div class="product-wrapper video__books__container swiper-container w-100">
  <div
    class="swiper-wrapper w-100 home-products-intro category-grid"
    style="padding-left: 24px"
  >
    <!-- <div
      class="col-sm-4 col-xl-2"
      id="{{ book.id }}"
      *ngFor="let book of browseBookDetails"
    > -->
    <div
      class="col-sm-4 col-xl-2 browse-book-tile swiper-slide w-100"
      *ngFor="let book of bookData?.value?.data || []; let i = index"
    >
      <div
        class="home-product product-default inner-quickview img-border-radius"
      >
        <figure class="browse-books-image-tile img-border-radius">
          <!-- (click)="gotoBookDetails(i)" -->
          <a
            routerLink="/book/{{ bookData?.value?.tab }}/{{ book?._id }}/{{
              book.genre
            }}"
          >
            <img
              class="browse-books-image-tile img-border-radius"
              src="{{ baseimage }}{{ book.cover_image }}"
              alt=""
              onerror="this.src = 'assets/images/rectangle1.svg'"
            />
          </a>
          <!-- <div class="label-group" *ngIf="book.awards"> -->
          <div class="label-group">
            <!-- <span class="product-label label-cut">{{ book.awards }}</span> -->
            <!-- <span class="product-label label-cut">Best in Horror</span> -->
          </div>
          <div class="btn-icons">
            <button
            class="btn-icon btn-add-cart"
            [class.bookshelve-add]="book.is_in_bookshelves === true"
            data-toggle="modal"
            (click)="addToBookShelf(book);"
          >
              <i class="far fa-bookmark bookshelve-book"></i>
            </button>
          </div>
        </figure>

        <div class="product-details">
          <!-- <div class="category-wrap">
            <div style="display: contents">
              <h2
                class="product-category"
                *ngFor="let tag of book.tags | slice: 0:2"
              >
                {{ tag }}
              </h2>
            </div>
            <a
              (click)="likeBook({ book_id: book._id, status: 1 }, i)"
              *ngIf="book.isLiked === 0"
            >
              <i
                class="fa fa-heart fa-heart-o"
                [ngClass]="{
                  'fa fa-heart like-button': true
                }"
                id="heart"
              ></i>
            </a>
            <a
              (click)="likeBook({ book_id: book._id, status: 2 }, i)"
              *ngIf="book.isLiked === 1"
            >
              <i
                class="fa fa-heart fa-heart-o"
                id="heart"
                [ngClass]="{
                  'fa fa-heart like-button': true,
                  'fa-heart-o': !book.isLiked,
                  'icon-color': book.isLiked
                }"
              ></i>
            </a>
          </div> -->
          <h2
            class="product-title"
            routerLink="/book/{{ bookData.value.tab }}/{{ book._id }}/{{
              book.genre
            }}"
          >
            <a class="text-underline-on-hover">{{ book.title }}</a>
          </h2>
          <h2
            class="product-title"
            routerLink="/authorprofile/{{ book.author_id }}"
          >
            <a class="font-italic text-underline-on-hover"
              >@{{ book.author_username }}</a
            >
          </h2>
          <div class="ratings-container">
            <div class="book-interation">
              <img
                class="book-details-icons mr-3"
                src="../../assets/Slider/images/icons/visibility.svg"
                alt=""
              />
              <span class="views-hearts-shares-bookshelves">{{
                book.views
              }}</span>
            </div>
            <div class="book-interation">
              <img
                class="book-details-icons mr-3"
                src="../../assets/Slider/images/icons/heart_gradient.svg"
                alt=""
              />
              <span class="views-hearts-shares-bookshelves">{{
                book.likes
              }}</span>
            </div>
            <div class="book-interation">
              <img
                class="book-details-icons mr-3"
                src="../../assets/Slider/images/icons/share.svg"
                alt=""
              />
              <span class="views-hearts-shares-bookshelves">{{
                book.no_of_shares
              }}</span>
            </div>
            <div class="book-interation">
              <img
                class="book-details-icons mr-3"
                src="../../assets/Slider/images/icons/open-book.svg"
                alt=""
              />
              <span class="views-hearts-shares-bookshelves">
                {{ book.no_of_chapter }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
