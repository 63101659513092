import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendNotificationsService } from './backend-notifications.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-backend-notifications',
  templateUrl: './backend-notifications.component.html',
  styleUrls: ['./backend-notifications.component.sass']
})
export class BackendNotificationsComponent implements OnInit {
  updateForm:FormGroup;
  loading = false;
  submitted = false;
  showMsg : any;
  showImg = true;
  errorMsg = '';
  constructor( private router: Router,private formBuilder : FormBuilder, private backend : BackendNotificationsService, private toaster: ToastrService) { 

  }

  ngOnInit() {
  this.updateForm = this.formBuilder.group({
    message : ["",Validators.required]
  })
  }

  get f() { return this.updateForm.controls;}
  onSubmit(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.backend.adminNotification(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          // alert("Edit Manage Content Successfully");
          // this.showMsg = res.message;
          $("#message").text('');
          $("#message").val('');
          this.toaster.success(res.message)
          setTimeout(() => {
            this.router.navigate(['/manage-notifications']);
            
         }, 2000); 
        } else {
          this.toaster.error(res.message)
        }
      },
      error => {
        //this.alertService.error(error);
        this.loading = false;
      }
    );
  }
  
}
