import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ImageWrapper',
  templateUrl: './image-wrapper.component.html',
  styleUrls: ['./image-wrapper.component.sass']
})
export class ImageWrapperComponent implements OnInit {

  constructor() { }
  @Input() imageUrl: string;
  @Input() imageLoadingUrl: string;
  @Input() imageErrorUrl: string;
  @Input() imageAlt: string = 'image';
  @Input() imageWidth: number;
  @Input() imageHeight: number;
  @Input() skeletonBorderRadius: string;
  @Input() class: string;
  @Input() skeletonType: 'normal' | 'strong' = 'normal';
  isLoaded: boolean = false

  ngOnInit(): void {
  }

  onLoad() {
    this.isLoaded = true;
  }

  onError() {
    this.onLoad();
    this.imageUrl = this.imageErrorUrl;
  }

}
