import { Component, OnInit } from '@angular/core';
import { AddSliderService } from './add-slider.service';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from "./../../../base-url";
@Component({
  selector: 'app-add-slider',
  templateUrl: './add-slider.component.html',
  styleUrls: ['./add-slider.component.sass']
})
export class AddSliderComponent implements OnInit {
  getGenre: any;
  updateForm: any;
  loading = false;
  submitted = false;
  showMsg = false;
  showLoader = false;
  usersimage: any;
  errorMsg: any;
  alertService: any;
  selectedFiles: FileList;
  currentFileUpload: File;
  baseimage: any;
  coverImg: any;
  
  constructor(
    private addSlider: AddSliderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) { 
    this.baseimage = BaseUrl.image;
    this.coverImg = 'uploads/sliders/1643615106843_.jpeg'
  }
  ngOnInit() {
    this.updateForm = this.formBuilder.group({
     image: ["", Validators.required]
   });
 }
  onSelectFile(event) {
    this.showLoader = true;
    if (event.target.files.length > 0) {
      let formData = new FormData();
      this.selectedFiles = event.target.files;
      this.currentFileUpload = this.selectedFiles.item(0);
      formData.append("image", this.currentFileUpload);
      this.addSlider.user_image(formData).subscribe(
        (res: any) => {
          if (res) {
            this.usersimage = res;
            if (res.status) {
              $("#user_cover").attr('src', this.baseimage+''+res.data)
              this.updateForm.controls["image"].setValue(res.data);
              this.showLoader = false;
            }
          }
        },
        error => {
          console.log("ERROR");
          this.showLoader = false;
        }
      );
    }
  }
  onSubmit() {
    // console.log(this.updateForm.value);
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    this.submitted = true;
    this.addSlider.addSlider(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          this.toaster.success("Slider added successfully.")
          this.router.navigate(["/manage-slider"]);
        } else {
          this.toaster.error(res.message)
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
  get f() {
    return this.updateForm.controls;
  }
}
