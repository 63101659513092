import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface PaymentDailogData {
  libcoins: number | string 
}
@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.sass'],
})
export class PaymentDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentDailogData,
  ) {}

  ngOnInit(): void {
    
  }

  onPaymentComplete() {
    this.dialogRef.close({status: 'completed'});
  }
}
