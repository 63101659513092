import { Component, OnInit } from '@angular/core';
import { PaymentDashboardService } from './payment-dashboard.service';
declare var $:any;

@Component({
  selector: 'app-payments-dashboard',
  templateUrl: './payments-dashboard.component.html',
  styleUrls: ['./payments-dashboard.component.sass']
})
export class PaymentsDashboardComponent implements OnInit {
  endDate :any;
  public getpayments: any;
  public temp: Object = false;
  showLoader = false
  constructor(private payment : PaymentDashboardService) { }

  ngOnInit() {
    this.showLoader = true
    this.loadReports();
  }

  loadReports() {
    this.payment.getPayments().subscribe(
      (res: any) => {
        res.data.map(function(val, index){ 
        let myDate = new Date(val.updated_at);
        val.updated_at  =  new Date(myDate.setMonth(myDate.getMonth() + 1));}); 

        this.getpayments = res.data;
        $(document).ready(function() {
          $('#paymenttable').DataTable();
        })
        this.temp = true;
        this.showLoader = false
      },
      error => {
        console.log("ERROR");
      }
    );
  }

}
