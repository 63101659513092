<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <form
          [formGroup]="formGroup"
          (ngSubmit)="onSubmit()"
          class="d-flex flex-column w-100"
        >
          <!-- Enter Title -->
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput type="text" id="title" formControlName="title" />
          </mat-form-field>
          <div
            *ngIf="
              formGroup.get('title').invalid &&
              (formGroup.get('title').dirty || formGroup.get('title').touched)
            "
            class="text-small text-danger"
          >
            <div *ngIf="formGroup.get('title').errors?.required">
              Title is required.
            </div>
          </div>

          <!-- Select Genre -->
          <mat-form-field appearance="fill">
            <mat-label>Select Book Type</mat-label>
            <mat-select
              formControlName="bookType"
              [(ngModel)]="selectedBookValue"
              (onChange)="onBooktypeChange($event)"
            >
              <mat-option value="sketchBook">Sketch Book</mat-option>
              <mat-option value="videoBook">Video Book</mat-option>
              <!-- <mat-option *ngFor="let genre of genreResult" [value]="genre._id">
                {{ genre.name }}
              </mat-option> -->
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" *ngIf="genreResult">
            <mat-label>Select Genre</mat-label>
            <mat-select formControlName="genre">
              <mat-option *ngFor="let genre of genreResult" [value]="genre._id">
                {{ genre.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="
              formGroup.get('genre').invalid &&
              (formGroup.get('genre').dirty || formGroup.get('genre').touched)
            "
            class="text-small text-danger"
          >
            <div *ngIf="formGroup.get('genre').errors?.required">
              Genre is required.
            </div>
          </div>
          <input
            style="display: none"
            type="file"
            id="file-input"
            #sketchInput
            multiple
            name="sketchBook"
            (change)="onFileChange($event)"
            [accept]="
              selectedBookValue == 'sketchBook'
                ? 'image/png, image/jpg, image/jpeg'
                : 'video/*'
            "
          />
          <div class="d-flex">
            <div *ngFor="let image of selectedFileArray; let i = index">
              <img
                class="rounded mr-5"
                style="height: 100px; width: 100px"
                src="{{ image }}"
                alt=""
              />
              <span
                (click)="removeImage(i)"
                style="position: absolute; top: 467px"
                class="material-icons-outlined cursor-pointer"
              >
                close
              </span>
            </div>
          </div>
          <label for="file-input">
            <i
              class="fas fa-file-image fa-3x"
              style="height: 30px; width: 30px; margin-left: 10px"
            ></i>
          </label>
          <input type="submit" value="upload" />
        </form>
      </div>
    </div>
  </div>

  <button (click)="openDialog()">Read</button>
</div>

<!-- <div class="wrapper" *ngIf="bookPages.length > 0">
  <h1>Aditya</h1>
  {{ bookPages | json }}
  <div id="content-page" class="content-page">
    <flipbook [model]="bookData"></flipbook>
  </div>
</div> -->
