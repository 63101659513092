import { Component, OnInit } from '@angular/core';
import { BaseUrl } from 'src/app/base-url';
import Swiper from 'swiper';
import { UserProfileHanlderService } from '../../user-profile-hanlder.service';
import { UserProfileService } from '../../user-profile.service';
@Component({
  selector: 'app-profile-published-stories',
  templateUrl: './profile-published-stories.component.html',
  styleUrls: ['./profile-published-stories.component.sass'],
})
export class ProfilePublishedStoriesComponent implements OnInit {
  constructor(
    private userProfileHandlerService: UserProfileHanlderService,
    private userProfileService: UserProfileService
  ) {}

  public baseurl: string = BaseUrl.image;
  public publishedStories;
  ngAfterViewInit() {
    // var swiper = new Swiper('.published-books-slider', {
    //   effect: 'coverflow',
    //   grabCursor: true,
    //   centeredSlides: true,
    //   slidesPerView: 'auto',
    //   initialSlide: 2,
    //   coverflowEffect: {
    //     rotate: 20,
    //     stretch: 0,
    //     depth: 500,
    //     modifier: 1,
    //     slideShadows: false,
    //   },
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    //   observer: true,
    // });
  }

  handleSlider() {
    var swiper = new Swiper('.published-books-slider', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      initialSlide: 2,
      coverflowEffect: {
        rotate: 20,
        stretch: 0,
        depth: 500,
        modifier: 1,
        slideShadows: false,
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      observer: true,
    });
  }
  ngOnInit(): void {
    this.userProfileHandlerService.getPublishedStories().subscribe((result) => {
      this.publishedStories = result;
      this.publishedStories.every((element) => {
        this.userProfileService
          .getGenreDetails(element['genre'])
          .subscribe((genre) => {
            element['genre'] = genre['data'].name;
          });
        return true;
      });

      setTimeout(() => {
        this.handleSlider();
      }, 100);
    });
  }
}
