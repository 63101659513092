import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private http: HttpClient) { }

  BASE_URL = `${environment.baseUrl}/room/`;

  getRooms (query: {limit: string, skip: string, type?: 'active' | 'invited' | 'self' | ''}): Observable<any> {
    return this.http.get(this.BASE_URL + 'getRoom', {params: query});
  }

  createRoom (data: {room_name: string, host_id: string, isUploadedBook: boolean, book_id: string, invited_user_emails: Array<{email: string, isFollower: boolean, status: string}>}): Observable<any> {
    return this.http.post(this.BASE_URL + 'createRoom', data);
  }

  updateRoom (data: {room_name: string, room_id: string, isUploadedBook: boolean, book_id: string, invited_user_emails: Array<{email: string, isFollower: boolean, status: string}>}): Observable<any> {
    return this.http.post(this.BASE_URL + 'updateRoom', data);
  }

  inviteUser (data: { room_id: string, email: string, isFollower?: boolean}): Observable<any> {
    return this.http.post(this.BASE_URL + 'invite-user', data);
  }

  

  removeRoom(room_id: string) {
    return this.http.post(this.BASE_URL + 'removeRoom', {room_id});
  }

  leaveRoom(payload: {room_id: string, email: string}) {
    return this.http.post(this.BASE_URL + 'remove-user', payload);
  }

  invite_room_action(data: {token: string, status: 'active' | 'declined'}) {
    return this.http.post(this.BASE_URL + 'email-invite-action', data);
  }

  notification_invite_room_action(data: {room_id: string, status: 'active' | 'declined'}) {
    return this.http.post(this.BASE_URL + 'notification-invite-action', data);
  }
}
