<div class="swiper current-read-swiper">
  <div>
    <h4 class="card-title mb-0 section-heading"> Current Reads </h4>
  </div>
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let book of bookData; let i = index">
      <div class="d-flex cr-card card-shadow">
        <div class="mr-3">
          <div class="position-relative image-overlap-shadow">
            <div  style="position: relative; cursor: pointer;">
              <a>
                <div *ngIf="book?.book?.cover_image !== ''; else elseBlock">
                  <img loading="lazy" class=""
                    src="{{ baseimage }}{{ book.book.cover_image }}" style="max-height: 183px; max-width: 137px;" alt="" />
                </div>
                <div class="eighteen-plus-container" *ngIf="book.not_for_below_eighteen">
                  <h4 class="eighteen-plus">18+</h4>
                </div>
                <ng-template #elseBlock>
                  <img loading="lazy" style="max-height: 183px; max-width: 137px;" class="img-fluid rounded h-100 w-100 img-border-radius" src="{{ baseimage }}{{ noImage }}"
                    alt="" />
                </ng-template>
              </a>
            </div>
          </div>
        </div>
        <div class="w-100">
          <div class="mb-2">
            <h6 class="mb-1" style="font-size: 16px">{{ book.book.title }}</h6>
            <div class=" d-flex
              justify-content-start
              align-items-center
              cursor-pointer"
              routerLink="/authorprofile/{{ book.authorDetails._id }}"
            >
              <img loading="lazy" *ngIf="book?.authorDetails?.image !== ''" class="rounded-circle"
                style="height: 30px; width: 30px !important"
                src="{{ baseimage }}{{ book.authorDetails.image }}" />
              <img loading="lazy" *ngIf="book?.authorDetails?.image === ''" class="rounded-circle"
                style="height: 30px; width: 30px !important" src="{{ noAuthorImg }}" />
              <p class="font-size-13 line-height mb-1 ml-3">
                <span class="font-italic text-underline-on-hover">
                  @{{ book.authorDetails.username }}
                </span>
              </p>
            </div>
          </div>
          <div class="price d-flex align-items-center">
          </div>
          <div class="
            d-flex
            justify-content-between
            align-items-center
            text-dark
            font-size-13
          ">
            <span>Reading</span>
            <span class="mr-4">{{
              (book.readingPercentage / book.book.no_of_chapter) * 100
              | number: "1.0-0"
              }}%</span>
          </div>
          <mat-progress-bar class="progress-bar" mode="determinate"
            [value]="(book.readingPercentage / book.book.no_of_chapter) * 100" color="#0DD6B8">
          </mat-progress-bar>
          <a
            routerLink="/readchapter/{{ book.book._id }}/{{book.lastRead._id}}/{{ book.authorDetails._id }}"
            class="text-dark"
          >
            Continue Reading<i class="ri-arrow-right-s-line"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
