import { Component, OnInit } from '@angular/core';
import { PaymentDashboardService } from '../payments-dashboard/payment-dashboard.service';
declare var $:any;

@Component({
  selector: 'app-chapter-payments',
  templateUrl: './chapter-payments.component.html',
  styleUrls: ['./chapter-payments.component.sass']
})

export class ChapterPaymentsComponent implements OnInit {
  public getpayments: any;
  public temp: Object = false;
  showLoader = false;
  constructor(private payment : PaymentDashboardService) { }

  ngOnInit() {
    this.showLoader = true;
    this.loadReports();
  }

  loadReports() {
    this.payment.getChapterPayments().subscribe(
      (res: any) => {
        this.getpayments = res.data;
        $(document).ready(function() {
          $('#chaptertable').DataTable();
        })
        this.temp = true;
        this.showLoader = false
      },
      error => {
        console.log("ERROR");
      }
    );
  }

}

