<app-loader *ngIf="showLoader"></app-loader>
<mat-card *ngIf="!showLoader" class="example-card">
  <mat-card-header class="d-flex">
    <img
      mat-card-avatar
      src="{{ baseurl }}{{ userData.image }}"
      style="height: 5%; width: 7%"
      onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
    />
    <mat-card-subtitle class="mt-4">
      <div>
        <h2>{{ userData.fullname }}</h2>
      </div>
      <h5 class="ml-5 mt-2">@{{ userData.username }}</h5>
    </mat-card-subtitle>
  </mat-card-header>

  <div *ngIf="createPost">
    <form #createPost="ngForm" (ngSubmit)="onSubmit(createPost)">
      <mat-card-content class="mt-4">
        <textarea
          class="border"
          matInput
          id=""
          cols="135"
          rows="10"
          name="content"
          placeholder="Create A Post"
          [(ngModel)]="postArray.postContent"
        ></textarea>
      </mat-card-content>

      <mat-card-actions>
        <label for="file-input">
          <i
            class="fas fa-file-image fa-3x"
            style="height: 30px; width: 30px; margin-left: 10px"
          ></i>
        </label>
        <input
          style="display: none"
          type="file"
          id="file-input"
          name="post_image"
          (change)="onSelectFile($event)"
        />
        <!-- (click)="Post()" -->
        <button
          type="submit"
          class="btn btn-sm global-button"
          style="margin-left: 89%"
        >
          Post
        </button>
        <img
          (click)="openImageDialog(url)"
          *ngIf="url"
          class="mt-4 border post-image rounded"
          [src]="url"
          alt=""
        />
      </mat-card-actions>
    </form>
  </div>
</mat-card>
