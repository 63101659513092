import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class EditChapterService {
  userdata: any;
  baseURL: any;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseURL = BaseUrl.frontend;
  }

  getChapterDetails(data) {
    const headers = this.helper.getHeader();
    return this.http.post(this.baseURL + `/getChapterDetail`, data, {
      headers,
    });
  }
}
