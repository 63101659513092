import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.sass'],
})
export class ProfileDetailsComponent implements OnInit {
  baseurl
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend
  }

  // profileDetail = {
  //   username: 'Barry_Tech',
  //   location: 'USA',
  //   gender: 'Female',
  //   email: 'Barry@example.com',
  //   DOB: '15/11/1982',
  // };

  profileDetail;
  public editMode = false;
  toggleEdit() {
    this.editMode = !this.editMode;
  }
  Edit() {
    this.toggleEdit();
  }

  Save() {
    this.toggleEdit();
  }
  ngOnInit(): void {
    const headers = this.helper.getHeader();
    this.http
      .post(this.baseurl + `/getUserProfile`, null, { headers })
      .subscribe((result: any) => {
        this.profileDetail = result.data;
      });
  }
}
