import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  userdata: any;
  _baseURL: any;
  constructor(private http: HttpClient, private helper: HelperService) {
    this._baseURL = BaseUrl.frontend;
  }


  // getUserInfo() {}
  // getBookInfo() {}
  // getCommentInfo() {}

  private tabIndex = new BehaviorSubject<number>(0);
  public tabIndexData = this.tabIndex.asObservable();

  getNotification(data: { limit: number, skip: number } = {limit: 10, skip: 0}): Observable<any> {
    return this.http.post(this._baseURL + `/v1/getNotifications`, {groupByDate: false, ...data}).pipe(map((res: any) => {
      if(res?.status) {
        res.data = res?.data?.map((item: any) => {
          item.created_at = moment(item.created_at).fromNow();
          return item;
        })
      }
      return res;
    }))
  }

  setActiveTab(tabIndex: number) {
    this.tabIndex.next(tabIndex);
  }

  public viewedNotification = (notification_id?: string) => {
    const data: {notification_id?: string | number} = {};
    if(notification_id) data.notification_id = notification_id;
    return this.http.post(`${this._baseURL}/viewedNotification`, data );
  };

  getNewNotificationCount = (data: any) => {
    let messagesArray = [];
    let numberOfNewNotifications = 0;
    data.every((element) => {
      element.data.every((elem) => {
        if (
          elem.type == 'other_wall' ||
          elem.type == 'comment' ||
          elem.type == 'my_wall' ||
          elem.type == 'report' ||
          elem.type == 'room_invite'
        ) {
          messagesArray.push(elem);
          if (!elem.viewed) numberOfNewNotifications++;
        }
        return true;
      });

      return true;
    });

    return numberOfNewNotifications
  }

  getAdminNotification(data: { limit: number, skip: number } = {limit: 10, skip: 0}): Observable<any> {
    return this.http.post(environment.baseAdminUrl + `/v1/getNotifications`, data).pipe(map((res: any) => {
      if(res?.status) {
        res.data = res?.data?.map((item: any) => {
          item.created_at_from_now = moment(item.created_at).fromNow();
          return item;
        })
      }
      return res;
    }))
  }
}
