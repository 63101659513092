import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AuthGuard } from '../auth.guard';
import { BaseUrl } from '../base-url';
import { NavbarService } from '../nav-sidebar/navbar.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.sass'],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(private http: HttpClient, private authGaurd: AuthGuard, private navbarService: NavbarService) {
    this.baseurl = BaseUrl.frontend;
    this.loggedIn = this.authGaurd.userLoggedin()
  }
  private baseurl: string;

  public loggedIn: boolean

  htmlPage;

  ngOnInit(): void {
    this.http
      .get(`${this.baseurl}/termandcondition`)
      .subscribe((result: any) => {
        this.htmlPage = result.data.content;
        // this.htmlPage = '<h1>Terms and Conditions</h1><br/ >' + this.htmlPage;
      });
  }
}
