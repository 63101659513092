import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';
import { BookDetailService } from '../book-detail/book-detail.service';
import { GlobalService } from '../global.service';
import { NavbarService } from '../navbar/navbar.service';
import { ReadChapterService } from '../read-chapter/read-chapter.service';
import { BookDataService } from '../services/book-data.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-books-slider',
  templateUrl: './books-slider.component.html',
  styleUrls: ['./books-slider.component.sass']
})
export class BooksSliderComponent implements OnInit, AfterViewInit {

  @Input() type: 'book_receommendation' | 'user_recommendation' | 'genre' | 'similar_books';
  @Input() id: string;
  @Input() fullSize: any = '';
  @Input() listTipe: any = 'defaulte';
  @Input() title: string = 'Books';
  @Input() passBookData: boolean = false;
  @Input() bookData: Array<any> | any = [];
  newBookData: any = [];
  swiperRef: Swiper;

  public slides = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];


  public addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }
  public removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  public slickInit(e: any) {
    console.log('slick initialized');
  }
  public breakpoint(e: any) {
    console.log('breakpoint');
  }
  public afterChange(e: any) {
    console.log('afterChange');
  }
  public beforeChange(e: any) {
    console.log('beforeChange');
  }


  public browseBookDetails: any[] = [];
  private likedBooks: Object[] = [];
  public baseimage: string;;
  public fullSizeTrue: string;
  constructor(
    private dialog: MatDialog,
    private bookDataService: BookDataService,
    private globalService: GlobalService,
    private readChapter: ReadChapterService,
    private _snackbar: MatSnackBar,
    private bookDetailService: BookDetailService,
    private _snackBar: MatSnackBar,
    private navbarService: NavbarService
  ) {
    this.baseimage = BaseUrl.image;
  }


  ngOnInit(): void {
    // user_id
    //  if(this.user_id) {
    //   this.bookDataService.getUserRecommendation(this.user_id).subscribe((res: any) => {
    //     console.log("user recommendation => ", res);
    //     if(res?.data){
    //       this.bookData = res?.data?.recommendation[0]?.recommended_book_id;
    //     }
    //   });
    //  }
    if (!this.passBookData) this.fetchBookData();

  }
  ngAfterViewInit() {
    console.log('listTipe', this.listTipe)
    if (this.fullSize !== 'fullSize') {
      this.swiperRef = new Swiper(`.-${this.listTipe}-book-slider-added-swiper`, {
        slidesPerView: 5,
        spaceBetween: 20,
        grabCursor: true,
        breakpoints: {
          "1800": {
            slidesPerView: 5,
          },
          "969": {
            slidesPerView: 4,
          },
          "725": {
            slidesPerView: 3,
          },
          "601": {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          "541": {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          "450": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "400": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "375": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "350": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "270": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        },
        slidesPerGroup: 1,
        observer: true,
      });
    } else {
      this.swiperRef = new Swiper(`.${this.fullSize + '-'}${this.listTipe}-book-slider-added-swiper`, {
        slidesPerView: 6.5,
        spaceBetween: 20,
        grabCursor: true,
        breakpoints: {
          "1600": {
            slidesPerView: 6.5,
          },
          "1400": {
            slidesPerView: 6,
          },
          "1200": {
            slidesPerView: 5.5,
          },
          "1000": {
            slidesPerView: 5,
          },
          "969": {
            slidesPerView: 4,
          },
          "725": {
            slidesPerView: 3,
          },
          "601": {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          "450": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "350": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          "270": {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        },
        slidesPerGroup: 1,
        observer: true,
      });
    }
  }

  private async fetchBookData() {

    if (this.type === 'book_receommendation') {
      const data: any = await this.bookDataService.getBookRecommendation(this.id).pipe(first()).toPromise();
      this.bookData = data?.data.slice(10);
      for(var i = 0; i<=this.bookData.length; i++ ){
        if(i<=15){
          this.newBookData = this.newBookData.concat(this.bookData[i])
        }
      }
    } else if (this.type === 'user_recommendation') {
      const data: any = await this.bookDataService.getUserRecommendation(this.id).pipe(first()).toPromise();
      this.bookData = data?.data.slice(10);
      for(var i = 0; i<=this.bookData.length; i++ ){
        if(i<=15){
          this.newBookData = this.newBookData.concat(this.bookData[i])
        }
      }
    }
    else if (this.type === 'genre') {
      this.bookData = await this.bookDataService.getTopBooksByGenre(this.id).pipe(map((result: any) => result?.data)).toPromise();
      for(var i = 0; i<=this.bookData.length; i++ ){
        if(i<=15){
          this.newBookData = this.newBookData.concat(this.bookData[i])
        }
      }
    }
    else if (this.type === 'similar_books') {
      this.bookData = await  this.bookDataService.getBookRecommendation(this.id).pipe(map((result: any) => result?.data)).toPromise()
      for(var i = 0; i<this.bookData.length; i++ ){
        if(i<=15){
          this.newBookData = this.newBookData.concat(this.bookData[i])
        }
      }
    }
  }

  addToBookShelf(book: any) {
    console.log(book)
    this.bookDetailService.addToBookShelf({ book_id: book?._id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = true;
          this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          if (res.message === 'Book already in bookshelf.') {
            this.removeBookShelf(book)
          } else {
            this._snackBar.open(res.message, 'Ok', { duration: 2000 });
          }
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  removeBookShelf(book: any) {
    this.bookDetailService.removeToBookShelf({ book_id: book?._id }).subscribe(
      (res: any) => {
        if (res.status) {
          book.is_in_bookshelves = false;
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
          this.navbarService.updateBookShelf.next();
        } else {
          this._snackbar.open(res.message, 'Ok', { duration: 2000 });
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  heartBook(id: String) {
    this.browseBookDetails.every((element: any) => {
      if (element['id'] == id) {
        if (element['liked']) {
          element['likes']--;
          element['liked'] = false;
          this.bookDataService
            .likeBooks(element)
            .subscribe((result: any) => { });

          // this.likedBooks.push(element);
          return false;
        } else {
          element['likes']++;
          element['liked'] = true;
          this.bookDataService
            .likeBooks(element)
            .subscribe((result: any) => { });
          this.likedBooks.push(element);
          return false;
        }
      }
      return true;
    });
  }

  likeBook(body: any, index: number) {
    this.readChapter.likeBook(body).subscribe(
      (res: any) => {
        if (res.status) {
          if (body.status === 1) {
            // this.bookData.value.data[index].isLiked = 1;
            // this.bookData.value.data[index].likes++;
          } else {
            // this.bookData.value.data[index].isLiked = 0;
            // this.bookData.value.data[index].likes--;
          }

          this._snackbar.open(res.message, 'ok', {
            duration: 2000,
          });
        } else {
        }
      },
      (error) => { }
    );
  }

  gotoBookDetails(index: number) {
    // this.bookDetailService.setSliderData({
    //   data: this.bookData.value.data,
    //   index: index,
    // });

    // this.router.navigate(['/book']);
  }

}
