import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass'],
})
export class LayoutComponent implements OnInit {
  @Input() InitialColumn: boolean = true;
  @Input() columnsToSet: String = '4';
  @Input() setColumnTo: any;

  @Output() columns = new EventEmitter<String>();
  @Output() rows = new EventEmitter<String>();

  setColumn(column: String, rowHeight: String) {
    this.columns.emit(column);
    this.rows.emit(rowHeight);
  }
  

  constructor() {}

  ngOnInit(): void {}
}
