<div class="page">
    <div class="page-main">
      <!-- Sidebar menu-->
      <div class="app-content  my-3 my-md-5" *ngIf="getGenre">
        <div class="page-container">
          <div class="page-header ">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/manage-subscription']"> Subscriptions</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Update Subscription
            </li>
          </ol>
          </div>
          <div class="">
            <div class="card all_custom_input">
                <div class="card-header">
                    <h2 class="card-title">Edit Subscription</h2>
                  </div>
              <div class="card-body">
              <form
                [formGroup]="updateForm"
                class="issue_report"
                (ngSubmit)="onSubmit()"
              >
                <div class="form-group input_txt">
                  <input
                    type="hidden"
                    [ngModel]="getGenre._id"
                    formControlName="subscription_id"
                    class="form-control"
                    id="subscription_id"
                  />
                  <input
                    type="hidden"
                    [ngModel]="getGenre.type"
                    formControlName="type"
                    class="form-control"
                    id=""
                  />
                  <input
                    type="hidden"
                    [ngModel]="getGenre.price"
                    formControlName="price"
                    class="form-control"
                    id=""
                  />
                  <input
                  type="hidden"
                  [ngModel]="getGenre.description"
                  formControlName="description"
                  class="form-control"
                  id=""
                />
                </div>
                <div class="form-group input_txt">
                  <label for="title">Title <span class="required-field-icon">*</span></label>
                  <input
                    type="text"
                    [ngModel]="getGenre.title"
                    placeholder="Enter Title"
                    formControlName="title"
                    id="title"
                    required
                    [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                  />
                  <div
                    *ngIf="submitted && f.title.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.title.errors.required">Title is required.</div>
                  </div>
                </div>
  
                <!-- <div class="form-group input_txt">
                                      <label for="title">TitleDescription</label>
                                      <input type="text" [ngModel]="getGenre.description" placeholder="Enter Description" formControlName="description" class="form-control" id="description" required [ngClass]="{ 'is-invalid': submitted && f.description.errors }"/>
                                      <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                          <div *ngIf="f.description.errors.required">Description is required.</div>
                                      </div>
                              </div> -->
                <div class="container">
                  <div class="">
                    <div class="">
                      <div class="save text-center">
                        <button [disabled]="loading" class="site_btn">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  