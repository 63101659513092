import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from 'src/app/loader/loader.component';
import { MaterialModules } from './material.module';
import { FooterComponent } from '../footer/footer.component';
import { ReviewComponent } from '../review/review.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { VimeModule } from '@vime/angular';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';


const componentList: Array<any> = [
  LoaderComponent,
  FooterComponent,
  ReviewComponent,
  AudioPlayerComponent
]

const moduleList: Array<any> = [
  MaterialModules, 
  RouterModule, 
  FormsModule, 
  ReactiveFormsModule, 
  CommonModule,
  NgxSkeletonLoaderModule,
  VimeModule
];
 
@NgModule({
  declarations: componentList,
  imports: moduleList,
  exports: [...componentList, ...moduleList]
})
export class SharedModule { }
