import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { HelperService } from 'src/app/helper.service';
import { SeoserviceService } from 'src/app/seoservice.service';
import { environment } from 'src/environments/environment';
import * as _ from 'underscore';
import { Room } from '../../Interface/room.interface';
import { RoomService } from '../../services/room/room.service';
import { CreateBookClubDialogComponent, RoomDialogData } from './create-book-club-dialog/create-book-club-dialog.component';

@Component({
  selector: 'app-book-clubs',
  templateUrl: './book-clubs.component.html',
  styleUrls: ['./book-clubs.component.sass']
})
export class BookClubsComponent implements OnInit {

  constructor (
    private roomService: RoomService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private helper: HelperService,
    private seoService: SeoserviceService
  ) { }

  rooms: Array<Room> = [];
  isLoading: boolean = false;
  page: number = 1;
  itemsPerPage:number = 10;
  total_items: number = 0;
  selectedIndex: number = 0;
  userData: any;
  imageBaseUrl = environment.imageBaseUrl;

  ngOnInit(): void {
    this.seoService.updateTitle('Librums | Book Clubs')
    this.userData = this.helper.getUserData().data;
    this.getRooms();
  }


  getRooms (page: number = 1, selectedIndex = this.selectedIndex) {

    const index_map: Array<'self' | 'active' | 'invited'> = ['self', 'active', 'invited'];
    this.userData = this.helper.getUserData().data;
    this.page = page;
    this.isLoading = true;
    this.rooms = [];
    const skip = (this.page - 1 ) * this.itemsPerPage;
    this.roomService.getRooms({limit: this.itemsPerPage.toString(), skip: skip.toString(), type: index_map[selectedIndex] }).subscribe((res: {status: boolean, message: string, error?: string, data: Array<Room>, total_items: number}) => {
      if(res.status) {
        this.rooms = res.data;
        console.log('this.userData._id', this.userData._id)
        console.log('rooms', this.rooms)
        this.total_items = res.total_items;
      }
    }, (err) => {
      this.toastrService.error(err.message, 'Something went wrong');
    }).add(() => {
      this.isLoading = false;
    })
  }

  open_create_room_dialog(room?: Room) {
    const data: RoomDialogData = {
      mode: 'create',
      userData: this.userData
    }

    if(room){
      data.mode = 'edit';
      data.room = JSON.parse(JSON.stringify(room));
    }

    const dialogRef = this.dialog.open(CreateBookClubDialogComponent, {
      width: '1000px',
      maxWidth: '95%',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getRooms();
      }
    });
  }

  removeBookClub(room: Room) {
    this.dialog.open(ConfirmationModalComponent, {
      data: {custom: true, title: 'Librums | Book Clubs', description: `Are you sure you want to remove ${room.room_name} ?`}
    }).afterClosed().subscribe((flag) => {
      if(flag){
        this.roomService.removeRoom(room._id).subscribe((res: {status: boolean, message: string, data: any}) => {
          if(res.status) {
            this.getRooms();
            this.toastrService.success(`${room.room_name} has been removed successfully`);
          }
        })
      }
    })
  }

  leaveBookClub(room: Room, email: string = this.userData?.email) {
    const description = email === this.userData?.email ? `Are you sure you want to leave ${room.room_name} ?` : `Are you sure you want to remove ${email} from ${room.room_name} ?`
    this.dialog.open(ConfirmationModalComponent, {
      data: {custom: true, title: 'Librums | Book Clubs', description}
    }).afterClosed().subscribe((flag) => {
      if(flag){
        this.roomService.leaveRoom({ room_id: room._id, email }).subscribe((res: {status: boolean, message: string, data: any}) => {
          if(res.status) {
            this.getRooms();
            if(email === this.userData?.email) {
              this.toastrService.success(`You left the club ${room.room_name} successfully`);
            } else {
              this.toastrService.success(`You successfully removed ${email} from the club ${room.room_name} successfully`);
            }
          }
        })
      }
    })
  }

  onMatTabChanged(index: number) {
    console.log(index);
    this.getRooms(1, this.selectedIndex)
  }

  room_invite_action (room_id: any, status: 'active' | 'declined') {
    this.roomService.notification_invite_room_action({ room_id, status }).subscribe((res: any) => {
      if(res?.status) {
        this.toastrService.success(`Room invitation has been ${status === 'active' ? 'activated' : 'declined'} successfully`);
          this.getRooms(this.page, this.selectedIndex);

      } else {
        this.toastrService.warning(res?.message);
      }
    });
  }

}
