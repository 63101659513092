{{ Post.postContent }}
<app-loader *ngIf="showLoader"></app-loader>
<div *ngIf="!showLoader">
  <div class="iq-card" [id]="post._id" *ngFor="let post of postArray">
    <div
      class="
        iq-card-header
        d-flex
        justify-content-between
        align-items-center
        mb-0
      "
    >
      <div class="iq-header-title">
        <h1 class="card-title mb-0"></h1>
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <div class="dropdown">
          <span
            class="dropdown-toggle text-primary"
            id="dropdownMenuButton05"
            data-toggle="dropdown"
          >
            <i class="ri-more-fill"></i>
          </span>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton05"
          >
            <!-- (click)="deletePost(post._id)" -->
            <a class="dropdown-item"
              ><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a
            >
          </div>
        </div>
      </div>
    </div>
    <mat-card class="example-card">
      <mat-card-header class="d-flex">
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <img
          mat-card-avatar
          src="{{ baseurl }}{{ post.user_image }}"
          style="height: 7%; width: 7%"
        />
        <mat-card-subtitle class="mt-4 d-flex">
          <div>
            <h2>{{ post.fullname }}</h2>
            <h5>24 January, 2021</h5>
          </div>
          <h5 class="ml-5 mt-2">@{{ post.user_name }}</h5>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          {{ post.content }}
        </p>
      </mat-card-content>
      <div class="container" *ngIf="post.image">
        <div class="row">
          <img
            (click)="imageDialog(baseurl + post.image)"
            class="mt-4 border post-image rounded wall-image"
            [src]="baseurl + post.image"
          />
        </div>
      </div>
    </mat-card>
  </div>
</div>
