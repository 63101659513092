import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import { ImageDialogComponent } from 'src/app/image-dialog/image-dialog.component';
import { PostMessageService } from 'src/app/post-message.service';
import { AuthorProfileService } from 'src/app/profile-author/author-profile/author-profile.service';
import { SeoserviceService } from 'src/app/seoservice.service';

export interface Post {
  id?: number;
  postImage?: File;
  postContent?: string;
}

@Component({
  selector: 'app-profile-create-post',
  templateUrl: './profile-create-post.component.html',
  styleUrls: ['./profile-create-post.component.sass'],
})
export class ProfileCreatePostComponent implements OnInit {
  public postArray: Post = {
    postContent: '',
  };
  @Output() postEmit = new EventEmitter();

  baseurl;
  constructor(
    private dialog: MatDialog,
    private helper: HelperService,
    private authorService: AuthorProfileService,
    private _seoService: SeoserviceService,
    private postMessage: PostMessageService,
    private snackbar: MatSnackBar
  ) {
    this.baseurl = BaseUrl.image;
    this.baseimage = BaseUrl.image;
  }

  public url: any;
  public post: boolean = false;
  public isPostImage: boolean = false;
  public createPost: boolean = true;
  public showLoader: boolean = true;

  togglePost() {
    this.createPost = !this.createPost;
  }
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (e: any) => {
        const dialofRef = this.dialog.open(ImageDialogComponent, {
          data: { url: e.target.result, uploadedImage: true, crop: true },
          height: 'auto',
          width: 'auto',
        });

        dialofRef.afterClosed().subscribe((result: any) => {
          this.url = result.data;
          let formData = new FormData();
          let selectedFiles = event.target.files;
          let currentFileUpload = selectedFiles.item(0);
          formData.append('image', currentFileUpload);
          this.postMessage.post_image(formData).subscribe(
            (res: any) => {
              if (res) {
                if (res.status) {
                  this.postimage = res.data;
                  // this.showImage = true;
                }
              }
            },
            (error) => {
              console.log('ERROR');
            }
          );
        });
      };
    }
  }

  Post() {
    const id = Math.floor(Math.random() * 1000000000);
    this.postEmit.emit({
      id: id,
      content: this.postArray.postContent,
      image: this.postArray.postImage,
    });
  }

  userData;
  userid;
  baseimage: string;
  ngOnInit(): void {
    this.userData = this.helper.getUserData().data;
    this.userid = this.helper.getUserData().data._id;
    this.loadAuthorData();
    this.showLoader = false;
  }

  myPosts: any;

  onSubmit(form: NgForm) {
    this.showLoader = true;
    if (form.valid) {
      form.value.user_id = this.userid;
      form.value.post_image = this.postimage ? this.postimage : '';

      this.postMessage.savePost(form.value).subscribe(
        (res: any) => {
          if (res.status) {
            this.snackbar.open(res.message, 'Ok', { duration: 2000 });
            this.loadAuthorData();
            form.resetForm();
            this.url = '';
            this.showLoader = false;
          } else {
            this.snackbar.open(res.message, 'Ok', { duration: 2000 });
          }
        },
        (error) => {
          console.log('Error: ', error);
        }
      );
    } else {
      return;
    }
  }

  loadAuthorData() {
    this.authorService.getAuthor(this.userid).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));

        this.myPosts = mapped[1].value;
        this.postEmit.emit(this.myPosts);
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }

  postimage: string;

  openImageDialog(imageUrl: string) {
    let imageDialog = this.dialog.open(ImageDialogComponent, {
      data: { url: imageUrl, uploadedImage: true, crop: false },
      height: 'auto',
      width: 'auto',
    });
  }
}
