<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
    <div class="page-main">
        <!-- Sidebar menu-->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
                <div class="page-header">
                    <!-- <h4 class="page-title">View Payments Deshboard</h4> -->
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Change Password
                        </li>
                    </ol>
                </div>
                <div class="mt_mb">
                    <div class="all_custom_input card">
                        <div class="card-header">
                            <div  class="card-title">
                                Change Password
                            </div>
                        </div>
                        <div class="card-body row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <form [formGroup]="changepasswrodForm" class="issue_report" (ngSubmit)="onSubmit()">
                                    <div class="form-group input_txt">
                                        <label for="">Old Password</label>
                                        <input id="old_password" type="password" placeholder="Enter old password" class="form-control" formControlName="old_password" [ngClass]="{'is-invalid': submitted && f.old_password.errors}" />
                                        <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                                            <div *ngIf="f.old_password.errors.required">
                                                Old password is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group input_txt">
                                        <label for="">New Password</label>
                                        <input id="new_password" type="password" placeholder="Enter new password" class="form-control" formControlName="new_password"[ngClass]="{'is-invalid': submitted && f.new_password.errors}" />
                                        <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                                            <div *ngIf="f.new_password.errors.required">
                                                New password is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group input_txt">
                                        <label for="">Confirm Password</label>
                                        <input id="con_password" type="password" placeholder="Enter confirm password" class="form-control" formControlName="confirm_password" [ngClass]="{'is-invalid': submitted && f.confirm_password.errors}"/>
                                        <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirm_password.errors.required">
                                                Confirm password is required.
                                            </div>
                                            <div *ngIf="f.confirm_password.errors.mustMatch">Password and Confirm Password must match.</div>
                                            
                                        </div>
                                    </div>
            
                                    <div class="container">
                                        <div class="row mt-5">
                                            <div class="col-md-12">
                                                <!-- <div class="save text-center">
                                                    <a routerLink="/dashboard" class="save_btn">Back</a>
                                                </div> -->
                                                <div class="save text-center">
                                                    <button  class="site_btn">
                                                    Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
