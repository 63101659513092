<div id="global-loader"></div>
<div class="dash_header header-container" >
  <div class="mobile_header">
    <div class="app-header header py-1 d-flex">
      <div class="container-fluid custom_fluid">
        <!-- <div class="mobile_show_logo">
          <a class="header-brand custom_header_brand" routerLink="/dashboard">
            <img loading="lazy" src="../../../../assets/images/logo.png" class="header-brand-img d-none d-sm-block" alt="" />
          </a>
        </div> -->
        <div class="d-flex w-100 my-4 align-items-center admin-navbar">
          <!-- Logo -->
          <div class="nav-item"> <h2 class="mb-0">Librums</h2> </div>
          <div class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/dashboard">Dashboard</a>
          </div>
          <div class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/users">Users</a>
          </div>
          <div class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/manage-books">Books</a>
          </div>
          <div class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                Payments
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/chapter-payments">Chapter</a></li>
                <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/subscription-payments">Subscription</a></li>
              </ul>
          </div>
          <div class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
              Messages
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/reported-issues">Reported Issues</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/feedbacks-and-suggestions">Feedback and Suggestions</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-notifications">Manage Notifications</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-contact-us">Contact Us</a></li>
            </ul>
          </div>
          <div class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
              Manage
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-slider">Homepage Sliders</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-content-type">Content Types</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-copyright">Copyrights</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-genre">Genres</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-feature-section">Featured section</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-pages">Pages</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/manage-subscription">Subscriptions</a></li>
            </ul>
          </div>
          <div class="flex-fill"></div>
          <div class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
              <!-- {{ fullname }} -->
              Admin
              <mat-icon>person</mat-icon>
            </a>
            <ul class="dropdown-menu">
              <li class="text-center">
                <h3 class="mb-0">Welcome {{ fullname }}</h3>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" [routerLink]="['/profile', admin_id]">Profile</a></li>
              <li><a class="dropdown-item" routerLinkActive="active-link" routerLink="/change-password">Change Password</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item text-danger" (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dash_header_mobile_menu">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="header-brand custom_header_brand" routerLink="/dashboard">
      <img loading="lazy" style="width: 200px;" src="../../../../assets/images/logo.png" class="" alt="" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" style="z-index: 1;">
      <ul class="navbar-nav mr-auto">
        <li class="">
          <a class="" routerLink="/dashboard" routerLinkActive="active"><span class="">Dashboard</span></a>
        </li>
        <li class="">
          <a class="" routerLink="/users"><span class="">Users</span></a>
        </li>
        <li class="">
          <a class="" routerLink="/manage-books"><span class="">Books</span></a>
        </li>
        <li class="">
          <!-- <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
              Payments <span class="caret"></span>
            </button>
            <ul class="dropdown-menu mt-2">
              <li>
                <a routerLink="/chapter-payments">Chapters</a>
              </li>
              <li>
                <a routerLink="/subscription-payments">Subscriptions</a>
              </li>
            </ul>
          </div> -->
        </li>

        <li class="">
          <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle dropdown-css" type="button" data-toggle="dropdown" >
              Messages<span class="caret"></span>
            </button>
            <ul class="dropdown-menu mt-2">
             
              <li>
                <a class="" routerLink="/reported-issues" routerLinkActive="active">Reported
                    Issues</a>
              </li>

              <li class="">
                <a class="" routerLink="/book-report" routerLinkActive="active">Book
                    Reports</a>
              </li>
              <li class="">
                <a class="" routerLink="/manage-notifications" routerLinkActive="active">Manage
                    Notifications</a>
              </li>

              <li>
                <a routerLink="/announcement" routerLinkActive="active"><span>Announcements</span></a>
              </li>
              <li>
                <a class="" routerLink="/manage-contact-us" routerLinkActive="active">
                    Contact Us</a>
              </li>
            </ul>
          </div>
        </li>
        <li class="">
          <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle dropdown-css" type="button" data-toggle="dropdown">
              Manage<span class="caret"></span>
            </button>
            <ul class="dropdown-menu mt-2">
              <li>
                <a routerLink="/manage-content-type">Content Type</a>
              </li>
              <li>
                <a routerLink="/manage-copyright">Copyrights</a>
              </li>
              <li><a routerLink="/manage-genre">Genre</a></li>
              <li><a routerLink="/manage-pages">Pages</a></li>
              <li>
                <a routerLink="/manage-subscription">Subscription</a>
              </li>
              <li>
                <a routerLink="/manage-slider">HomePage Sliders</a>
              </li>
            </ul>
          </div>
        </li>
        <li class="">
          <div class="dropdown">
            <button class="btn btn-primary dropdown-toggle dropdown-css" type="button" data-toggle="dropdown">
              Profile <span class="caret"></span>
            </button>
            <ul class="dropdown-menu mt-2">
              <li>
                <a class="dropdown-item" [routerLink]="['/profile', admin_id]"><i
                    class="dropdown-icon mdi mdi-account-outline "></i>Edit</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/change-password"><i
                    class=" dropdown-icon mdi mdi-account-outline "></i>Change
                  Password</a>
              </li>
              <div class="dropdown-divider "></div>
              <li>
                <a class="dropdown-item " routerLink="/logout">
                  <i class="dropdown-icon mdi mdi-logout-variant "></i>Sign
                  out</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>