<div class="d-flex justify-content-between">
  <h2 mat-dialog-title class="mb-0 align-self-center">
    Payment Option
  </h2>
  <div class="align-self-center">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mt-2">
  <div class="mt-3">
    <app-purchase-options [showCloseButton]="false" [userLibcoins]="data.libcoins"
      (onPaymentComplete)="onPaymentComplete()">
    </app-purchase-options>
  </div>
</mat-dialog-content>