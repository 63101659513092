import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../../user-profile.service';
// import { Post } from './component/profile-create-post/profile-create-post.component';
import { Post } from '../profile-create-post/profile-create-post.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass'],
})
export class ProfileComponent implements OnInit {
  constructor(private userProfileService: UserProfileService) {}

  loader = true;
  public Posts: Post = {
    postContent: '',
  };
  // public Posts: any;
  updatePost(event: any) {
    this.Posts = event;
  }

  setLoader(dataLoaded) {
    this.loader = false;
  }

  selectedIndex: number;

  ngOnInit(): void {
    this.userProfileService.tabIndexData.subscribe((res) => {
      this.selectedIndex = res;
    });
  }
}
