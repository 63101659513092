<div *ngIf="!isLoading" style="min-height: 62.5vh">
   <div class="container-fluid">
      <div class="row d-flex justify-content-center">
         <div class="col-lg-10 p-0">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height m-0" style="border-radius:10px">
               <div class="iq-card-body p-0">
                  <div class="p-0" style="min-height: 52vh;">
                    <ng-template #content let-modal>
                      <div class="modal-header" style="height:40px;">
                        <h4 class="modal-title m-0">Upload Book</h4>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body">
                        <app-upload-book></app-upload-book>
                      </div>
                    </ng-template>
                    <ng-template #videContent let-modal>
                      <div class="modal-header" style="height:40px;">
                        <h4 class="modal-title video-modal-title m-0">Chapter Video</h4>
                        <button type="button" class="btn-close" aria-label="Close"
                          (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body d-flex justify-content-between">
                        <input class="video-input" placeholder="Paste YouTube URL" matInput type="text" [(ngModel)]="youtubeInput" [ngModelOptions]="{standalone: true}">
                        <button class="video-button-save" type="button" (click)="youtoubeSearch()" (click)="modal.dismiss('Cross click')">Save</button>

                        <!-- <p>Value: {{ playerName }}</p> -->
                        <!-- <app-search-box
                            (results)="updateResults($event)"
                          >
                        </app-search-box> -->
                      </div>
                      <!-- <div class="tittle_chapter">
                        <div class="label_input video_search">
                          <label for=""> Youtube Search</label>
                          <app-search-box
                            (results)="updateResults($event)"
                          >
                          </app-search-box>
                        </div>
                      </div>
                      <div class="container video_model_overflow">
                        <p class="top_search">{{ message }}</p>
                        <div class="row">
                          <app-search-result
                            class="col-md-6 col-lg-4"
                            *ngFor="let result of results"
                            [result]="result"
                          ></app-search-result>
                        </div>
                      </div> -->
                    </ng-template>
                     <form #bookSubmit="ngForm" (ngSubmit)="submitBook(bookSubmit)" class="m-0">
                        <div class="">
                           <div class="book-wraper mt-3" style="height: 100% ;padding-bottom: 20px;">
                              <div class="row m-0 {{!book_id ? 'withoutBook' : ''}}">
                                 <div *ngIf="!book_id" class="col-sm-4 col-xl-3 px-3 add-cover-image-container">
                                    <div class="d-flex justify-content-center block-cover-image"
                                       style="border-radius: 10px">
                                       <app-image-editor-canvas *ngIf="canvas" (imageCanvas)="getImageCanvas($event)"
                                          [url]="url" [editBook]="editBook"></app-image-editor-canvas>
                                       <app-image-final-canvas [finalCanvasUrl]="finalCanvas" *ngIf="finalCanvas">
                                       </app-image-final-canvas>
                                       <img loading="lazy" routerLink="/add-book-cover" class="plus-for-add-cover" src="../../assets/images//plusPhoto.svg" alt="">
                                    </div>
                                    <div class="d-flex justify-content-center mt-2">
                                      <button type="button" class="add-cover-image-button" style="height: 59px; margin-top: 25px" routerLink="/add-book-cover" alt="">
                                        Add Book Cover Image
                                      </button>
                                    </div>
                                  </div>
                                 <div *ngIf="book_id" class="col-sm-4 col-xl-3 px-3 d-flex justify-content-center">
                                    <img loading="lazy" style="border-radius: 10px" [src]="baseurl + bookData.cover_image"
                                       alt="" />
                                 </div>
                                 <div *ngIf="!book_id"
                                    class="col-sm-7 col-xl-8 p-0 d-flex flex-column input-root-container {{!book_id ? '' : 'justify-content-between'}}  h-100">
                                    <div
                                       class="d-flex flex-column input-container align-items-center align-items-sm-start w-100">
                                       <input [(ngModel)]="writeTitle" name="title" ngModel required #title="ngModel" (ngModelChange)="handleTitleChange($event)" class="write-input book-sentence" type="text"
                                          placeholder="Book Name">
                                       <div *ngIf="(title.touched && !title.valid) || (submitted && !title.value)">
                                          <small class="text-danger pl-3">Title is required</small>
                                       </div>
                                       <textarea  [(ngModel)]="writeSentence" name="tagline" ngModel required #tagline="ngModel" (ngModelChange)="handleSentenceChange($event)" class="write-input book-sentence mt-2" type="text"
                                          placeholder="One sentence from book" style="min-height: 40px; margin-top: 30px !important; overflow: hidden;" [maxLength]="150" cdkTextareaAutosize></textarea>
                                          <div class="d-flex justify-content-end mr-4 count-of-charactrs" style="font-style: italic;"> {{ tagline?.value?.length || 0 }}/{{150}} </div>
                                       <div *ngIf="(tagline.touched && !tagline.valid) || (submitted && !tagline.value)">
                                          <small class="text-danger pl-3">Sentence is required</small>
                                       </div>
                                       <textarea [(ngModel)]="writeDescription" name="description" ngModel required #description="ngModel" (ngModelChange)="handleDescriptionChange($event)" class="write-input-description mt-2" ngModel
                                          type="text" style="min-height: 40px; margin-top: 10px; overflow: hidden;" placeholder="Description of book" [maxLength]="descriptionMaxCharacter" cdkTextareaAutosize> </textarea>
                                          <div class="d-flex justify-content-end mr-4" style="width: 95% !important; font-style: italic;"> {{ description?.value?.length || 0 }}/{{500}} </div>
                                       <div *ngIf="(description.touched && !description.valid) || (submitted && !description.value)">
                                          <small class="text-danger pl-3">Description is required</small>
                                       </div>
                                       <div class="d-sm-flex justify-content-between" style="width: 95%; margin: 0">
                                          <div class="d-flex flex-column w-100">
                                              <mat-form-field class="mr-md-5 mb-0 select-book-type" appearance="fill">
                                                <mat-label>Select content type</mat-label>
                                                <mat-select class="select-options" [(ngModel)]="writeContentType" (ngModelChange)="handleContentTypeChange($event)" name="content_type" ngModel required #content_type="ngModel">
                                                  <mat-option class="select-options" value="5ea29e78fa235a61a420b9f4">Book</mat-option>
                                                  <mat-option class="select-options" value="{{ sketchBookId }}">Story</mat-option>
                                                  <mat-option class="select-options" value="{{ videoBookId }}">Poem</mat-option>
                                                  <mat-option class="select-options" value="{{ videoBookId }}">Article</mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                          </div>
                                          <div class="d-flex flex-column w-100">
                                             <mat-form-field class="select-book-type" appearance="fill">
                                                <mat-label>Select Copyright</mat-label>
                                                <mat-select [(ngModel)]="writeCopyright" (ngModelChange)="handleCopyright($event)" name="copyright" ngModel required #copyright="ngModel">
                                                  <mat-option class="select-options" value="5ea1388219e995193b5e6b1c">Not Specified2</mat-option>
                                                  <mat-option class="select-options" value="5eade2a737fe886eae35c7e8">All Rights Reserved</mat-option>
                                                  <mat-option class="select-options" value="5eade2b037fe886eae35c7e9">Public Domain</mat-option>
                                                  <mat-option class="select-options" value="5eade2bb37fe886eae35c7ea">(CC) Attrib. NonCommercial</mat-option>
                                                  <mat-option class="select-options" value="5eade2c437fe886eae35c7eb">Creative Commons (CC) Attribution</mat-option>
                                                  <mat-option class="select-options" value="5eade2d037fe886eae35c7ec">(CC) Attrib. NonComm. NoDerivs</mat-option>
                                                  <mat-option class="select-options" value="5eade2ef37fe886eae35c7ed">(CC) Attrib. NonComm. ShareAlike</mat-option>
                                                  <mat-option class="select-options" value="5eade30437fe886eae35c7ee">(CC) Attribution-ShareAlike</mat-option>
                                                  <mat-option class="select-options" value="5eade30c37fe886eae35c7ef">(CC) (CC) Attribution-NoDerivs</mat-option>
                                                </mat-select>
                                             </mat-form-field>
                                          </div>
                                       </div>
                                       <div class="mb-3 select-ganre-block select-book-type">
                                          <div class="multiple-selection w-100">
                                             <ng-multiselect-dropdown
                                                name="genre" (ngModelChange)="handleGenre($event)" ngModel required #genre="ngModel" [placeholder]="'Select Genre'"
                                                [settings]="dropdownSettings" [data]="genreList" [(ngModel)]="genreVal"
                                                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                                (onDeSelect)="onItemDeSelect($event)">
                                             </ng-multiselect-dropdown>
                                             <div *ngIf="genreError">
                                                <small style="font-style: normal; font-size: 13px;" class="text-danger pl-3">Genre is required</small>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="w-100 mb-3 d-flex flex-column align-items-center align-items-sm-start">
                                          <mat-form-field class="example-chip-list">
                                             <mat-chip-list #chipList aria-label="tag selection">
                                                <mat-chip class="mat-chip-tag" *ngFor="let tag of tags"
                                                   [selectable]="selectable" [removable]="removable"
                                                   (removed)="remove(tag)">
                                                   {{ tag.name }}
                                                   <span matSuffix *ngIf="removable" matChipRemove
                                                      style="color: white; margin-top: -7px"
                                                      class="material-icons-outlined">
                                                      cancel
                                                   </span>
                                                </mat-chip>
                                                <input class="input-tags" placeholder="Hit enter to create new tags"
                                                   [matChipInputFor]="chipList"
                                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                   [matChipInputAddOnBlur]="addOnBlur"
                                                   (matChipInputTokenEnd)="add($event)"
                                                   name="bookTags" ngModel #bookTags="ngModel"
                                                />
                                             </mat-chip-list>
                                          </mat-form-field>
                                          <div *ngIf="bookTags.touched && !bookTags.valid">
                                             <small class="text-danger pl-3">Book tags is required</small>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="d-flex">
                                      <button class="write-button-save mb-5 mb-sm-0" type="submit"
                                        style="border-radius: 10px;">
                                        Save Changes
                                      </button>
                                    </div>
                                  </div>
                                 <div *ngIf="book_id"
                                    class="col-sm-7 col-xl-8 p-0 pl-sm-3 pt-3 mt-5 pt-sm-0 d-flex flex-column input-root-container justify-content-between h-100">
                                    <div class="d-flex flex-column book-data-container justify-content-center justify-content-sm-end">
                                      <div>
                                        <div
                                            class="bool-name mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                            {{bookData.title}}
                                        </div>
                                        <div
                                            class="book-sentence mb-3 d-flex d-sm-block justify-content-center book-field-text">
                                            {{bookData.tagline}}
                                        </div>
                                        <div
                                            class="block-author-book mb-3 d-flex justify-content-center justify-content-sm-start">
                                            <img loading="lazy" class="image-author-book" src="{{ baseurl }}{{ bookData.author_image }}"
                                              alt="">
                                            <a  class="author_name" href="/authorprofile/{{bookData.author_id}}">
                                              <p class="m-0 ml-2 author-name-text">@{{bookData.author_name}}</p>
                                            </a>
                                        </div>
                                        <div
                                            class="book-description d-flex d-sm-block justify-content-center book-field-text">
                                            {{bookData.description}}
                                        </div>
                                      </div>
                                      <div *ngIf="noChapters" class="d-md-flex button-container">
                                        <button
                                          class="write-button-save mt-5 mb-sm-0"
                                          style="border-radius: 10px;"
                                          type="button"
                                          (click)="openVerticallyCentered(content)"
                                        >
                                          Upload Book
                                        </button>
                                        <button
                                            class="write-button-save set-chupter-button mt-5 ml-4 mb-sm-0"
                                            style="border-radius: 10px;"
                                            type="button"
                                            (click)="writeChupter()"
                                          >
                                            Write Chapter
                                        </button>
                                      </div>

                                    </div>
                                 </div>
                                 <div *ngIf="!book_id" class="col-sm-1 p-0 d-flex justify-content-center" mat-raised-button #tooltip="matTooltip"
                                       matTooltip="Click the button to restrict access if the content of this book is 18+"
                                       style="height: 50px;">
                                    <input type="text" [value]="mature" class="d-none">
                                    <button class="p-0 button-mature-access" type="button" (click)="setMature()">
                                       <img loading="lazy" src="../../assets/images/18years.svg"
                                          [class]="mature ? 'years-age-18' : '' " alt="">
                                    </button>
                                 </div>
                                 <div *ngIf="book_id" class="col-sm-1 p-0 d-flex flex-column  align-items-center"
                                    style="height: 50px; margin-bottom: 80px;">
                                    <div class="is-free-block" mat-raised-button #tooltip="matTooltip"
                                       matTooltip="Click the button if you want this chapter to be paid">
                                       <img loading="lazy" class="image-for-pay-chapter-disabled" (click)="handleChapterPay()"
                                          *ngIf="!chapterIsPaid" src="../../assets/images/cash-coin.png" alt="">
                                       <img loading="lazy" class="image-for-pay-chapter-active" (click)="handleChapterPay()"
                                          *ngIf="chapterIsPaid" src="../../assets/images/cash-coin.png" alt="">
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                     <form [formGroup]="chapterSubmit" (ngSubmit)="submitChapter($event)" style="margin-bottom: 30px;">
                        <div *ngIf="book_id && chuseWriteCapter">
                           <div class="p-3">
                            <div class="d-flex flex-column align-items-center">
                              <div class="d-flex add-chapter-items">
                                <div class="d-flex justify-content-center flex-column">
                                  <textarea [ngStyle]="{'width.px': width} " stple="padding: 0;" #elasticInput matInput (input)="resize()" style="text-align: center;" class="write-input bool-name text-center" type="text"
                                     placeholder="Chapter Name" formControlName="chapterName" cdkTextareaAutosize>
                                  </textarea>
                                  <span [ngStyle]="{'width.px': width} "  #hiddenText class="write-chapter-name-input bool-name w-100" style="visibility: hidden; white-space: pre; height: 0px;">{{elasticInput.value}}</span>
                                </div>
                                <div class="d-flex justify-content-center chapter-items">
                                  <label type="button" class="d-flex justify-content-center align-items-center m-0 pb-3" matTooltip="Add chapter image" mat-raised-button #tooltip="matTooltip">
                                    <img
                                      src="../../assets/Slider/images/icons/photo.svg"
                                      alt=""
                                      class="pr-3 img-video"
                                      style="filter: invert(48%) sepia(6%) saturate(0%) hue-rotate(162deg) brightness(98%) contrast(83%);"
                                    />
                                    <input
                                      formControlName="chapterImage"
                                      style="display: none;"
                                      #file
                                      type="file"
                                      id="image"
                                      (change)="onSelectCoverImage($event)"
                                    />
                                  </label>
                                  <label *ngIf="!rightURL" type="button" class="d-flex justify-content-center align-items-center m-0 pb-3" matTooltip="Add chapter image" mat-raised-button #tooltip="matTooltip">
                                    <img
                                      src="assets/images/video.svg"
                                      class="img-video"
                                      (click)="openVerticallyCenteredUploadVideo(videContent)"
                                      alt=""
                                      style="filter: invert(48%) sepia(6%) saturate(0%) hue-rotate(162deg) brightness(98%) contrast(83%);"
                                    />
                                  </label>
                                  <div *ngIf="rightURL" ngbDropdown #myDrop="ngbDropdown">
                                    <button id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()" type="button" class="d-flex justify-content-center align-items-center m-0 pb-3 upload-video-dropdown pl-0" matTooltip="Add chapter image" mat-raised-button #tooltip="matTooltip">
                                      <img
                                        src="assets/images/video.svg"
                                        class="img-video"
                                        alt=""
                                        style="filter: invert(48%) sepia(6%) saturate(0%) hue-rotate(162deg) brightness(98%) contrast(83%);"
                                      />
                                    </button>
                                    <div  ngbDropdownMenu aria-labelledby="dropdownManual">
                                      <button ngbDropdownItem type="button" (click)="openVerticallyCenteredUploadVideo(videContent)" class="dropdown-item" >Replace Video</button>
                                      <button ngbDropdownItem type="button" (click)="removeUploadVideo()" class="dropdown-item" >Remove Video</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="hello d-flex chapterimage-container text-center mt-3 mb-3"  *ngIf="chapterimage">
                                <div style="position: relative;">
                                  <img
                                    class="chapterimage"
                                    src="{{ baseurl }}{{ chapterimage }}"
                                    style="max-height: 340px;"
                                  />
                                  <button type="button" class="remove-image-button" (click)="removeChapterImage()" style="position: absolute; top: 1%; right: 1%;">
                                    <mat-icon class="text-color-black-200">close</mat-icon>
                                  </button>
                                </div>
                              </div>

                              <!-- <iframe id="ytplayer" type="text/html" width="640" height="360"
                                src="{{youtubeInput}}"
                                frameborder="0"></iframe> -->
                                <!-- <div class="container">
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div *ngIf="rightURL" class="iframe-container" >
                                        <iframe width="560" height="315" [src]="rightURL | safeHtml: 'resourceUrl'" style="max-height: 340px; max-width: 590px;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen ></iframe>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                              <div *ngIf="rightURL" class="w-100 mb-3 py-3 iframe-control">
                                <div  class="iframe-container" >
                                  <iframe style="width: 100% !important;" height="315" [src]="rightURL | safeHtml: 'resourceUrl'" frameborder="0" allowfullscreen ></iframe>
                                </div>
                              </div>
                              <quill-editor formControlName="content" class="content-editor w-100" [styles]="editorStyle"
                                (onEditorCreated)="getEditorInstance($event)"
                                [placeholder]="'Type something...'" [modules]="quillModule"> </quill-editor>
                               <!-- <textarea class="write-chapter" formControlName="content"
                                  placeholder="Type something...">
                              </textarea> -->
                            </div>
                           </div>
                           <div class=" d-flex justify-content-between upload-chapter">
                              <button class="write-button-save drafted-chapter-button" id="drafted-book" type="submit"
                                 color="warn">
                                 Save to Drafts
                              </button>
                              <button class="write-button-save upload-chapter-button" id="publish-book" type="submit"
                                 color="warn">
                                 Publish
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-loader *ngIf="isLoading || isSubmitLoading"></app-loader>
<app-footer></app-footer>
