import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent implements OnInit {

  constructor() { }
  @Input() show: 'small' | 'normal' = 'normal';
  @Input() type: 'placeholder' | 'spinner' = 'spinner';
  @Input() placeholderType: 'list' | 'grid' = 'list';
  @Input() placeholderLength: any = 1;
  @Input() height: string = '70px';
  @Input() width: string = '100%';
  @Input() borderRadius: string = '8px';
  @Input() skeletonType: 'normal' | 'strong' = 'strong';
  @Input() animationType: 'progress' | 'pulse' = 'progress';
  @Input() backgroundColor: string = '#f0f0f0';

  ngOnInit(): void {
  }

}
