<div id="global-loader"></div>

<div class="page">
  <div class="page-main">
    <!-- sidebar area -->
    <div class="app-content  my-3 my-md-5">
      <div class="page-container">
        <div class="page-header">
          <!-- <h4 class="page-title">View Message Board</h4> -->
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/manage-notifications']"> Notifications</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Send Notifications
            </li>
          </ol>
        </div>
        <!-- <div class="page-header">
                    <h4 class="page-title">Announcements/Notifications</h4>
                </div> -->
        <div class="all_custom_input card">
          <div class="card-header justify-content-between">
              <div class="heading_form">
                  <div class="card-title">Notifications</div>
                </div>
          </div>
          <div class="card-body">
          <form
            [formGroup]="updateForm"
            class="issue_report"
            (ngSubmit)="onSubmit()"
          >
           
            <div class="aler alert-sucees" *ngIf="showMsg">{{ showMsg }}</div>
            <div class="form-group input_txt">
              <label for="message">Message <span class="required-field-icon">*</span></label>

              <textarea
                placeholder="Enter message"
                formControlName="message"
                class="form-control"
                id="message"
                maxlength="300"
                required
                [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
              ></textarea>
              <div
                *ngIf="submitted && f.message.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.message.errors.required">
                  Message is required.
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row mt-5">
                <div class="col-md-12">
                  <div class="save ">
                    <input
                      type="submit"
                      value="Send"
                      name="Submit"
                      class="site_btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
