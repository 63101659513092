import { Component, Input, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.sass'],
})
export class BreadcrumbsComponent implements OnInit {
  // @Input('breadcrums') breadcrumb;
  breadcrumbs;
  breadcrumbArray = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activatedRoute.url.subscribe(() => {
          this.breadcrumbArray.push(
            this.activatedRoute.snapshot.firstChild.data
          );
        });
      }
    });
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: [] = []
  ) {
    let label =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.breadcrumb
        : '';
    let path =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
  }

  detectRouteChange() {}

  ngOnInit(): void {
    // this.breadcrumbs.push(this.breadcrumb);
  }
}
