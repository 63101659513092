import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationStart, Event as NavigationEvent, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';
import { InviteFriendsComponent } from '../invite-friends/invite-friends.component';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.sass'],
})
export class NavSidebarComponent implements OnInit {
  @ViewChild('sideNav') sideNav: MatSidenav;
  event$
  showFiller = false;
  fullScreen = false;
  public book_id: any;
  public show: boolean;
  public showOnLogin: boolean;
  public role: string;
  public path: string;
  public showStatus: any
  constructor(
    private navbarService: NavbarService,
    private helper: HelperService,
    private dialog: MatDialog,
    public router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {
   this.event$ = location.onUrlChange((val) => {
      if (val.includes('/write')) {
         this.fullScreen = true
         this.show = false
         this.path = '/write'
      } else if (val.includes('/editstories')) {
         this.fullScreen = true
         this.show = false
         this.book_id = val.split('/')
         this.path = '/editstories'
      }else if (val.includes('/add-book-cover')) {
        this.fullScreen = true
        this.show = false
        this.book_id = val.split('/')
        this.path = '/add-book-cover'
      }else if (val.includes('/editchapter')) {
        this.fullScreen = true
        this.show = false
        this.book_id = val.split('/')
        this.path = '/editchapter'}
       else {
         this.fullScreen = false
      }
    })
  }

  close() {
    this.sideNav.close();
  }

  ngOnDestroy() {
   this.event$.unsubscribe()
  }

  previousUrl() {
   switch(this.path) {
      case '/write': {
         localStorage.setItem('writeTitle', '');
         localStorage.setItem('writeSentence', '');
         localStorage.setItem('writeDescription', '');
         localStorage.setItem('writeContentType', '');
         localStorage.setItem('writeCopyright', '');
         localStorage.setItem('writeGenre', '');
         localStorage.setItem('writeTags', '');
         this.show = true
         this.fullScreen = false
         this.router.navigate(["/create-stories"]);
         break;
      }
      case '/editstories': {
         this.show = true
         this.fullScreen = false
         this.router.navigate(["/profile/mybookdetails/" + this.book_id[2]]);
         break;
      }
      case '/add-book-cover': {
        this.show = true
        this.fullScreen = false
        // this.router.navigate(["/profile/mybookdetails/" + this.book_id[2]]);
        this.location.back()
        break;
      }
      case '/editchapter': {
        this.show = true
        this.fullScreen = false
        // this.router.navigate(["/profile/mybookdetails/" + this.book_id[2]]);
        this.location.back()
        break;
     }
      default:
         this.show = true
         this.fullScreen = false
         break;
   }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.navbarService.currentShowNavbar.subscribe((res) => {
        if (this.fullScreen) {
           this.show = false;
        } else {
         this.show = res
        }
        this.role = localStorage.getItem('role');
      });
    }, 0);
  }


  userData: any;
  ngOnInit(): void {
    this.userData = this.helper.getUserData().data;
    this.navbarService.currentShowNavbar.subscribe((res) => {
      if (this.fullScreen) {
         this.show = false;
      } else {
       this.show = res
      }
      this.role = localStorage.getItem('role');
    });
    if(window.location.href.includes("/login")){
      this.showOnLogin = false;
    }else{
      this.showOnLogin = true;
    }
  }

  showInviteOptions() {
    this.dialog.open(InviteFriendsComponent, {
      height: '400px',
      width: '700px',
      panelClass: environment.dialogBoxPanelClass,
    });
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
