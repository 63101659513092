import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { UserProfileHanlderService } from '../user-profile/user-profile-hanlder.service';
import { UserProfileService } from '../user-profile/user-profile.service';
import { CreateStoriesService } from './create-stories.service';

@Component({
  selector: 'app-create-stories',
  templateUrl: './create-stories.component.html',
  styleUrls: ['./create-stories.component.sass'],
})
export class CreateStoriesComponent implements OnInit {
  constructor(
    private userProfileHandlerService: UserProfileHanlderService,
    private userProfileService: UserProfileService,
    private helper: HelperService,
    private createStoriesService: CreateStoriesService,
    private router: Router,
    private activatedRoute : ActivatedRoute
  ) {
    this.baseimage = BaseUrl.image;
  }

  backgroundColor = ['rgba(216, 118, 121, 0.2)', 'rgba(99, 121, 196, 0.2)'];

  publishedStories: any;
  draftedStories: any;
  userData: any;
  baseimage: string;
  uplodedBooksList: Array<any>;
  fileName: string;
  loadBtn: boolean;

  showLoader: boolean = true;
  selectedIndex: number = 0;
  page_no: number = 1;
  total_drafted: number = 0;
  total_published: number = 0;
  is_book_loading: boolean = true;

  ngOnInit(): void {
    this.userProfileHandlerService.getDraftedStories().subscribe((result) => {
      this.draftedStories = result;
      this.draftedStories.every((element) => {
        this.userProfileService
          .getGenreDetails(element['genre'])
          .subscribe((genre) => {
            element['genre'] = genre['data'].name;
          });
        return true;
      });
      this.showLoader = false;
    });

    // this.userProfileHandlerService.getPublishedStories().subscribe((result) => {
    //   this.publishedStories = result;
    //   console.log("published stories => ", this.publishedStories);
    //   this.publishedStories.every((element) => {
    //     this.userProfileService
    //       .getGenreDetails(element['genre'])
    //       .subscribe((genre) => {
    //         element['genre'] = genre['data'].name;
    //       });
    //     return true;
    //   });
    //   this.showLoader = false;
    // });

    // fetch published stories
    this.getMyStories(1, this.page_no);

    this.userData = this.helper.getUserData().data;
    console.log('this.userData', this.userData)
    console.log('this.userData', this.baseimage)
    this.createStoriesService.tabIndexValue.subscribe((res: any) => {
      if (this.activatedRoute.snapshot.paramMap.get('type') === '3') {
        this.selectedIndex = 3
      } else {
        this.selectedIndex = res;
      }
    });
    this.getUploaedBooks()
  }
  onTabChange(index: any) {
    this.page_no = 1;
    this.getMyStories(index + 1, this.page_no);
  }

  onPageChange(type: number, page_no: number) {
    this.page_no = page_no;
    this.getMyStories(type, page_no);
  }

  getUploaedBooks () {
    this.createStoriesService.getUploadedBooks().subscribe((result: any) => {
      this.uplodedBooksList = result.data;
      this.loadBtn = false;
    })
  }

  gotToBookDetails(storyType: string, book_id: string) {
    this.createStoriesService.setStoryType(storyType);
    this.router.navigate([`/profile/mybookdetails/${book_id}`]);
  }

  readBook(id) {
    let path = _.filter(this.uplodedBooksList, (res) => { if (res._id === id) { return res}})[0].uploadedBook
    localStorage.setItem('path', path);
    this.router.navigate([`/read-book/${id}`]);
  }

  async getMyStories(type: number, page_no: number = 1) {
    this.is_book_loading = true;
    try {
      const { data, draftCount, publishCount, status } = await this.userProfileHandlerService.getPaginatedData(type, page_no - 1).toPromise();
      if (!status) throw new Error('Error while fetching paginated stories');

      this.total_published = publishCount;
      console.log(this.total_published)
      this.total_drafted = draftCount;
      data.every((element) => {
        this.userProfileService
          .getGenreDetails(element['genre'])
          .subscribe((genre) => {
            element['genre'] = genre['data'].name;
          });
        return true;
      });
      if (type === 1) this.publishedStories = data;
      else if (type === 2) this.draftedStories = data;
      console.log(this.publishedStories)

    } catch (err) {
      console.log("error while paginated data => ", err);
    } finally {
      this.is_book_loading = false;
      this.showLoader = false;
    }
  }
}
