<div class="page">
    <div id="global-loader">
        <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
    </div>
    <div class="page-main">
        <!-- Sidebar menu-->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
                <div class="page-header">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" >Dashboard</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/manage-slider']" > Sliders</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add 
                        </li>
                    </ol>
                </div>
                <div class="">
                    <div class="card all_custom_input">
                        <div class="card-header">
                            <div class="card-title">Add Slider</div>
                        </div>
                        <div class="card-body" style="text-align: center;">
                            <form [formGroup]="updateForm" class="issue_report" (ngSubmit)="onSubmit()">
                                <div class="input_txt">
                                <!-- <label for="name">Image</label> -->
                                <label for="edit_cover_img_upload" class="cam_edit_dp_bg">
                                <input
                                  type="file"
                                  id="edit_cover_img_upload"
                                  (change)="onSelectFile($event)"
                                  class="d-none"
                                />
                                <img loading="lazy" id="user_cover" src="{{ baseimage }}{{ coverImg }}" alt=""
                                />
                                <div class="cam_edit_dp">
                                  <img loading="lazy" _ngcontent-bmu-c7="" alt="" src="assets/img/cam.svg" />
                                </div>
                              </label>
                            <!-- <input  type="file" placeholder="Enter Title"  (change)="onSelectFile($event)"
                            class="form-control" /> -->
                            <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
                               <div *ngIf="f.image.errors.required">Image is required.</div>
                               <div *ngIf="f.image.errors.whiteSpace">Please remove leading and trailing whitespace.</div>
                               <input
                               type="hidden"
                               formControlName="image"
                             />
                                    </div>
                                </div>
                                <div class="container">
                                    <div class="">
                                        <div class="">
                                            <div class="save text-center">
                                                <button [disabled]="loading" class="site_btn">
                                                Add
                                                </button>
                                            </div>
                                        </div>
                                
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>