<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
<div class="page-main">
    <div class="app-content my-3 my-md-5 ">
        <div class="page-container">
            <div class="page-header ">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                         Featured
                    </li>
                </ol>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 ">
                    <div class="card">
                        <div class="card-header justify-content-between">
                            <div class="card-title "> Books</div>
                        </div>
                        <div>
                            <div class="card-body">
                                <div class="form-group input_txt">
                                    <ol class="breadcrumb w-30">
                                        <li class="breadcrumb-item">Current Book - 
                                            <span class="gray-badge" *ngIf="currentFeatureBook"> {{ currentFeatureBook?.title }}</span>
                                            <span *ngIf="!currentFeatureBook"> N/A </span>
                                    </ol>
                                    <br>
                                    <ng-select
                                        [items]="books$ | async"
                                        bindLabel="title"
                                        [hideSelected]="true"
                                        [trackByFn]="trackByFn"
                                        [minTermLength]="1"
                                        [loading]="booksLoading"
                                        [typeahead]="bookInput$"
                                        [(ngModel)]="selectedBook"
                                        [appendTo]="'body'"
                                        [placeholder]="'Search books by title'"
                                        >
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                            <!-- <div> {{ item.title }} </div> -->
                                            <div class="d-flex">
                                                <div>
                                                    <img [src]="image_base_url + item?.cover_image" alt="Book Cover"
                                                    onerror="this.src = 'assets/images/rectangle1.svg'" height="60" width="40">
                                                </div>
                                                <div class="m-2">
                                                    <div class="fw-600">
                                                        {{ item.title }}
                                                    </div>
                                                    <div class="mt-2">
                                                        {{ item?.description | slice: 0 : 120 }}
                                                        <span *ngIf="item?.description?.length > 120">...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="container">
                                    <div class="">
                                        <div class="">
                                            <div class="save text-center">
                                                <button (click)="addFeatureBook()" class="btn btn-cyan">
                                                    Select Book
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 ">
                    <div class="card ">
                        <div class="card-header justify-content-between">
                            <div class="card-title "> Authros </div>
                        </div>
                        <div class="card-body">
                            <div class="form-group input_txt">
                                    <ng-select
                                        [items]="authors$ | async"
                                        bindLabel="email"
                                        [hideSelected]="true"
                                        [multiple]="true"
                                        [maxSelectedItems]="6"
                                        [trackByFn]="trackByFn"
                                        [minTermLength]="1"
                                        [loading]="authorLoading"
                                        [typeahead]="authorInput$"
                                        [(ngModel)]="selectedAuthors"
                                        [placeholder]="'Search authors by email'"
                                        >
                                    </ng-select>
                            </div>
                            <div class="container">
                                <div class="">
                                    <div class="">
                                        <div class="save text-center">
                                            <button (click)="selectAuthors()" class="btn btn-cyan">
                                                Select Author
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</div>
</div>