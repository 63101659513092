import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  baseurl: string;
  headers: HttpHeaders;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
    this.headers = this.helper.getHeader();
  }

  getReviewByBookId = (book_id: string) => {
    return this.http.post(
      `${this.baseurl}/getReviewByBookId`,
      { book_id, full: true },
      { headers: this.headers }
    ); 
  };
}
