<div class="iq-card shadow-none m-0">
  <div class="iq-card-body p-0">
    <div class="bg-primary-cyan p-3">
      <h5 class="mb-0 notification-header text-white">
        <!-- Messages -->
        Notifications
        <small class="float-right ml-2"> New </small>
        <small class="badge badge-light text-dark float-right pt-1">{{
          numberOfNewNotifications
        }}</small>
      </h5>
    </div>
    <!-- class="iq-sub-card" -->
    <div *ngIf="numberOfNewNotifications" class="navbar-popover p-3">
      <a
        (click)="goto()"
        *ngFor="let notify of userNotification; let i = index"
      >
      <!-- [ngClass]="{ 'mb-3': notify.viewed }" -->
      <div class="mb-3">
      <div class="d-flex">
        <img
          class="avatar-40 rounded"
          [src]="baseimage + notify.user_info.image"
          onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
          alt=""
        />
        <div class="media-body ml-3">
          <h6 class="mb-0">{{ notify.message }}</h6>
          <small class="float-left font-size-12">{{
            notify.created_at
          }}</small>
        </div>
      </div>
    </div>
        <!-- <div
          *ngIf="
            (notify.type == 'other_wall' ||
              notify.type == 'comment' ||
              notify.type == 'my_wall' ||
              notify.type == 'report' ||
              notify.type == 'like' ||
              notify.type == 'follow' ||
              notify.type == 'room_invite') &&
            i <= 5
          "
          class="media align-items-center mt-2 ml-3 mr-3"
          [ngClass]="{ new__notification: !notify.viewed }"
        >
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'other_wall'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                [src]="baseimage + notify.user_info.image"
                onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'comment'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                [src]="baseimage + notify.user_info.image"
                onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'report'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                [src]="baseimage + notify.user_info.image"
                onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">
                  {{ notify.user_info.username }} reported
                  {{ notify.book_info.bookname }}
                </h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'my_wall'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                [src]="baseimage + notify.user_info.image"
                onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                alt=""
              />
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ notify.message }}</h6>
                <small class="float-left font-size-12">{{
                  notify.created_at
                }}</small>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{ 'mb-3': notify.viewed }"
            *ngIf="notify.type == 'room_invite'"
          >
            <div class="d-flex">
              <img
                class="avatar-40 rounded"
                [src]="baseimage + notify.user_info.image"
                onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                alt=""
              />
              <div class="media-body ml-3">
                <div>
                  <h6 class="mb-0">{{ notify.message }}</h6>
                  <small class="float-left font-size-12">{{
                    notify.created_at
                  }}</small>
                </div>
              </div>
              <div *ngIf="notify?.action_type === 'active'" class="align-self-center ml-2">
                <span class="badge bg-success">Accepted</span>
              </div>
              <div *ngIf="notify?.action_type === 'declined'" class="align-self-center ml-2">
                <span class="badge bg-danger">Declined</span>
              </div>
            </div>
            <div class="d-flex mt-3 justify-content-around ml-4" *ngIf="notify?.action_type === 'room_action'">
              <div> <button class="btn btn-secondary" (click)="room_invite_action(notify, 'active')">Accept</button> </div>
              <div> <button class="btn text-danger" (click)="room_invite_action(notify, 'declined')">Decline</button> </div>
            </div>
          </div>
        </div> -->
        <!-- </div> -->
      </a>
    </div>
    <div *ngIf="!numberOfNewNotifications" class="soft-card">No New Notification</div>
    <div
      class="bg-primary-cyan p-2"
      style="border-radius: 0 0 4px 4px !important; height: 39px"
      (click)="goto()"
    >
      <h3 class="text-white" style="margin-left: 37%; cursor: pointer">
        <u> View All </u>
        <br />
        <small class="badge badge-light float-right pt-1"></small>
      </h3>
    </div>
  </div>
</div>
