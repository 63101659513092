import { BaseUrl } from "./../../../base-url";
import { Component, OnInit } from "@angular/core";
import { MessageBoardService } from "./message-board.service";
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.sass']
})
export class MessageBoardComponent implements OnInit {
  public getreports: any;
  public temp: Object = false;
  data: any;
  baseimage: any;
  delete: any;
  delId: any;
  showLoader = false;
  constructor(private message: MessageBoardService, private toaster: ToastrService) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.loadReports();
  }

  loadReports() {
    this.message.getReports().subscribe(
      (res: any) => {
        this.getreports = res.data.reverse();
        $(document).ready(function() {
          $('#msgboardtable').DataTable();
        })
        this.temp = true;
        this.showLoader = false
      },
      error => {
        console.log("ERROR");
      }
    );
  }

  onClick11(event) {
    this.delId = event;
  }
  onClick(event) {
    this.message.deleteReport(event).subscribe(
      (res: any) => {
        this.toaster.success('Issue deleted successfully.')
        
        this.loadReports();
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
}
