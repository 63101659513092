import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthGuard } from '../auth.guard';
import { NavbarService } from '../nav-sidebar/navbar.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.sass'],
})
export class ContactUsComponent implements OnInit {
  reportForm: FormGroup;
  loading = false;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router, // private help: HelpService,
    private http: HttpClient,
    private _snackbar: MatSnackBar,
    private authGaurd: AuthGuard,
    private navbarService: NavbarService
  ) {
    this.loggedIn = this.authGaurd.userLoggedin();
  }

  public loggedIn: boolean;

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.reportForm.controls;
  }

  // submit issue form
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.reportForm.invalid) {
      return undefined;
    }
    this.loading = true;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(
        'https://librums.com:2001/api' + `/contactUs`,
        this.reportForm.value,
        {
          headers: headers,
        }
      )
      .pipe(first())
      .subscribe((result) => {
        this._snackbar.open(result['message'], 'ok', { duration: 2000 });
      });
  }

  // reset form data
  private reset() {
    this.reportForm = this.formBuilder.group({
      name: [''],
      email: [''],
      message: [''],
      subject: [''],
    });
  }
}
