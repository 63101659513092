import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserProfileService } from './user-profile.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserProfileHanlderService {
  constructor(
    private userProfileService: UserProfileService,
    private datePipe: DatePipe
  ) {}

  setBaseUrlToIMage(data) {
    var myStories = data.data;
    for (var keys in myStories) {
      myStories[keys].every((e) => {
        if (e['cover_image']) {
          if (e['cover_image'].split('/')[0] == 'uploads') {
            e[
              'cover_image'
            ] = `https://librums.com/backend/testing/${e['cover_image']}`;
          }
        } else {
          e['cover_image'] =
            '';
        }
        return true;
      });
    }

    data.data = myStories;
    return data;
  }

  setTextLimit(bookData) {
    bookData.every((element) => {
      if (element.title.split(' ').length > 4) {
        element.title = element.title.split(' ').slice(0, 4).join(' ');

        element.title += '...';
        element.tooLong = true;
      }
      return true;
    });
    return bookData;
  }

  // Get Drafted Stories
  filterDraftedStories(data) {
    return data.data.drafted;
  }
  getDraftedStories() {
    return this.userProfileService
      .getMyStories()
      // .pipe(map(this.setBaseUrlToIMage))
      .pipe(map(this.filterDraftedStories))
      .pipe(map(this.setTextLimit));
    // .pipe(map(this.formatDate));
  }

  // Get Published Stories
  filterPublishedStories(data) {
    return data.data.published;
  }
  getPublishedStories() {
    return this.userProfileService
      .getMyStories()
      // .pipe(map(this.setBaseUrlToIMage))
      .pipe(map(this.filterPublishedStories))
      .pipe(map(this.setTextLimit));

    // .pipe(map(this.formatDate));
  }

  // type is 1 for published, 2 for drafted, and 3 for uploaded book
  public getPaginatedData(type: number, page_no: number = 1) {
    return this.userProfileService.getPaginatedStories(type, page_no)
      .pipe(map((data: any) => {
        data.data = this.setTextLimit(data?.data);
        return data;
      }));

  }
}
