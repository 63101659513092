import { Component, OnInit } from "@angular/core";
import { ManageFeatureSectionService } from "./manage-feature-section.service";
import { ToastrService } from 'ngx-toastr';
import { UsersService } from "../users/users.service";
import _ from 'lodash'
import { concat, Observable, of, Subject } from "rxjs";
import { catchError, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { SearchService } from "src/app/search-page/search.service";
import { environment } from "src/environments/environment";
import { UtilityService } from "src/app/services/utility/utility.service";

@Component({
  selector: "app-manage-feature-section",
  templateUrl: "./manage-feature-section.component.html",
  styleUrls: ["./manage-feature-section.component.sass"]
})
export class ManageFeatureSectionComponent implements OnInit {
  // public manageContent: any;
  showLoader = false;
  currentFeatureBook: any;

  // Ng Select

  // authors
  authors$: Observable<any>;
  authorLoading: boolean = false;
  authorInput$: Subject<string> = new Subject<string>();
  selectedAuthors: Array<any> = [];

  // books
  books$: Observable<any>;
  booksLoading: boolean = false;
  bookInput$: Subject<string> = new Subject<string>();
  selectedBook: any = null;
  image_base_url: string = environment.imageBaseUrl;
  

  constructor(
    private manageService: ManageFeatureSectionService,
    private usersService: UsersService,
    private searchService: SearchService,
    private utils: UtilityService
    ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.fetch_users();
    this.fetch_books();

    this.get_current_feature_book();
  }

  get_current_feature_book() {
    this.manageService.getFetureBook().subscribe(
      (res: { status: boolean, data: any, message: string }) => {
        if(res?.status) {
          this.currentFeatureBook = res?.data?.book;
          console.log("current feature book => ", this.currentFeatureBook);
        }
      }
    )
  }

  fetch_users() {
    const getUser$ = (search = '') => this.usersService.getUsers({ limit: 10, skip: 0, search }).pipe(
      catchError(() => of([])), // empty list on error
      map((users: any) => users?.data),
      tap(() => this.authorLoading = false));


    this.authors$ = concat(
      getUser$(),
      this.authorInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.authorLoading = true),
        switchMap(searchText => getUser$(searchText))
      )
    );
    this.showLoader = false;
  }

  fetch_books () {
    const getBooks$ = (search = '') => this.searchService.searchMyBoooks({ limit: 10, skip: 0, search, userSpecific: false }).pipe(
      catchError(() => of([])), // empty list on error
      map((books: any) => books?.data),
      tap(() => this.booksLoading = false));


    this.books$ = concat(
      getBooks$(),
      this.bookInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.booksLoading = true),
        switchMap(searchText => getBooks$(searchText))
      )
    );
    this.showLoader = false;
  }

  trackByFn(item: any) {
    return item._id;
  }

  // onItemSelect(item: any) { this.authors.push(item._id) }
  
  // onItemDeSelect = (item: any) => { _.remove(this.authors, function(n) { return n === item._id}) }

  addFeatureBook () {
    if (this.selectedBook) {
      this.manageService.addfeaturebook(this.selectedBook?._id).subscribe(
        (res: any) => { 
          if (res.status) {
            // this.toaster.success("Feature book added successfully.")
            this.utils.openSanckbar({title: 'Feature book added successfully.'});
            this.get_current_feature_book()
            this.selectedBook = null;
          } else {
            // this.toaster.error(res.message)
            this.utils.openSanckbar({title: res?.message || 'Something went wrong'});
          }
        },
        error => {
          // this.toaster.error(error?.message || 'Something went wrong');
          this.utils.openSanckbar({title: error?.message || 'Something went wrong'});
          // this.loading = false;
        }
      );
    } else {
      // this.toaster.error("Selection of book is required.")
      this.utils.openSanckbar({title: 'Selection of book is required.'});
    }
  }

  selectAuthors () {
    if (this.selectedAuthors.length !== 0) {
      this.manageService.addAuthors(this.selectedAuthors.map(author => author._id)).subscribe(
        (res: any) => { 
          if (res.status) {
            this.utils.openSanckbar({title: 'Authors added successfully.'});
            this.selectedAuthors = [];
          } else {
            // this.toaster.error(res.message)
            this.utils.openSanckbar({title: res?.message || 'Something went wrong'});
          }
        },
        error => {
          // this.toaster.error(error?.message || 'Something went wrong');
          this.utils.openSanckbar({title: error?.message || 'Something went wrong'});
          // this.loading = false;
        }
      );
    } else {
      this.utils.openSanckbar({title: 'Selection of author is required.'});
      // this.toaster.error("Selection of author is required.")
    }
  }

}
