import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';


@Injectable({
  providedIn: 'root',
})
export class ChapterAddService {
  addtype: any;
  base: string;
  chapterimage: any;
  baseimage: string;
  constructor(private http: HttpClient, private helper: HelperService) {
    // this.base = 'https://librums.com:2001/api';
    // this.baseimage = 'https://librums.com:2001/api/uploadImage/';
    this.base = BaseUrl.frontend
    this.baseimage = BaseUrl.imageApi
  }

  addChapter(data) {
    // this.addtype = JSON.parse(localStorage.getItem('currentUser'));
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'x-access-token': this.addtype.data.token,
    // });
    const headers = this.helper.getHeader();
    return this.http.post(this.base + `/v1/addChapter`, data, {
      headers,
    });
  }

  updateChapter(data) {
    // this.addtype = JSON.parse(localStorage.getItem('currentUser'));
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'x-access-token': this.addtype.data.token,
    // });
    const headers = this.helper.getHeader();
    return this.http.post(this.base + `/v1/updateChapter`, data, {
      headers,
    });
  }

  chapter_image(image) {
    // this.chapterimage = JSON.parse(localStorage.getItem('currentUser'));
    // const headers = new HttpHeaders({
    //   // "Content-Type": "application/x-www-form-urlencoded",
    //   'x-access-token': this.chapterimage.data.token,
    // });
    // const headers = this.helper.getHeader();
    const userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'x-access-token': decryptUser.data.token,
    });
    return this.http.post(this.baseimage + `chapter_image`, image, {
      headers,
    });
  }
}
