import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SliderComponent } from './slider/slider.component';
import { HomeComponent } from './home/home.component';
import { BrowseBooksComponent } from './browse-books/browse-books.component';
import { BookDetailComponent } from './book-detail/book-detail.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SimilarBooksComponent } from './similar-books/similar-books.component';
import { FavoriteReadsComponent } from './favorite-reads/favorite-reads.component';
import { LayoutComponent } from './layout/layout.component';


import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ImageEditorCanvasComponent } from './image-editor-canvas/image-editor-canvas.component';
import { ImageEditorBookTitleComponent } from './image-editor-book-title/image-editor-book-title.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SearchPageComponent } from './search-page/search-page.component';
import { BookDetailSliderComponent } from './book-detail-slider/book-detail-slider.component';
import { NotificationComponent } from './notification/notification/notification.component';
import { UserNotificationComponent } from './notification/user-notification/user-notification.component';
import { WriteComponent } from './write/write.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';

import { MatCarouselModule } from '@ngmodule/material-carousel';

import { ImageCropperModule } from 'ngx-image-cropper';

import { ColorPickerModule } from 'ngx-color-picker';
import { UserProfileModule } from './user-profile/user-profile.module';
import { FreeDraggingDirective } from './image/free-dragging.directive';
import { ImageFinalCanvasComponent } from './image-final-canvas/image-final-canvas.component';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';

import { ToastrModule } from 'ngx-toastr';
import { SearchBoxComponent } from './chapter-add/search-box/search-box.component';
import { SearchResultComponent } from './chapter-add/search-result/search-result.component';
import { TempComponent } from './temp/temp.component';

import { ShareComponent } from './share/share.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ReportAnIssueComponent } from './report-an-issue/report-an-issue.component';
import { AuthGuard } from './auth.guard';

import { DatePipe } from '@angular/common';

import { BreadcrumbModule } from 'angular-crumbs';
import { InviteFriendsComponent } from './invite-friends/invite-friends.component';
import { ImageAddComponent } from './image-add/image-add.component';
import { ImageBrightnessTransparencyComponent } from './image-brightness-transparency/image-brightness-transparency.component';
import { AddBookCoverComponent } from './add-book-cover/add-book-cover.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ChapterPreviewComponent } from './chapter-preview/chapter-preview.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EditStoriesComponent } from './edit-stories/edit-stories.component';

// import { NgToggleModule } from 'ng-toggle-button';
import { NgToggleModule } from 'ngx-toggle-button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReadChapterComponent } from './read-chapter/read-chapter.component';

import { NgxPayPalModule } from 'ngx-paypal';
import { EditChapterComponent } from './edit-chapter/edit-chapter.component';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { NgxSpinnerModule } from "ngx-spinner";

import { GoogleLoginProvider } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { NotificationPageComponent } from './notification/notification-page/notification-page.component';
import { AuthorProfileComponent } from './profile-author/author-profile/author-profile.component';
import { BookshelfComponent } from './notification/bookshelf/bookshelf.component';
import { AboutComponent } from './profile-author/about/about.component';
import { WallComponent } from './profile-author/wall/wall.component';
import { FollowingComponent } from './profile-author/following/following.component';
import { FollowersComponent } from './profile-author/followers/followers.component';

import { CreateStoriesComponent } from './create-stories/create-stories.component';
import { ImagePostComponent } from './profile-author/wall/image-post/image-post.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';

import { ShareBookDialogComponent } from './share-book-dialog/share-book-dialog.component';


import { NavbarBookshelfComponent } from './notification/navbar-bookshelf/navbar-bookshelf.component';


import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';

import { SketchBookComponent } from './sketch-book/sketch-book.component';

import { FlipBookModule } from '@labsforge/flipbook';
import { WriteMediaContentComponent } from './write-media-content/write-media-content.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// Admin

import { DashboardComponent } from './admin/admin-modules/dashboard/dashboard.component';
import { HeaderComponent } from './admin/admin-modules/header/header.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UsersComponent } from './admin/admin-modules/users/users.component';
import { ManageContentComponent } from './admin/admin-modules/manage-content/manage-content.component';
import { ChapterPaymentsComponent } from './admin/admin-modules/chapter-payments/chapter-payments.component';
import { PaymentsDashboardComponent } from './admin/admin-modules/payments-dashboard/payments-dashboard.component';
import { UploadBookComponent } from './upload-book/upload-book.component'; // <-- import the module
import { MessageBoardComponent } from './admin/admin-modules/message-board/message-board.component';
import { BackendFeedbackComponent } from './admin/admin-modules/backend-feedback/backend-feedback.component';
import { ManageNotificationComponent } from './admin/admin-modules/manage-notification/manage-notification.component';
import { BackendNotificationsComponent } from './admin/admin-modules/backend-notifications/backend-notifications.component';
import { AdminContactUsComponent } from './admin/admin-modules/contact-us/contact-us.component';
import { ReadBookComponent } from './read-book/read-book.component'; // <-- import the module
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ViewchapterComponent } from './admin/admin-modules/viewchapter/viewchapter.component';
import { ViewFeedbackComponent } from './admin/admin-modules/view-feedback/view-feedback.component';
import { EditUsersComponent } from './admin/admin-modules/edit-users/edit-users.component';
import { AuthorListComponent } from './admin/admin-modules/author-list/author-list.component';
import { PublishedBooksComponent } from './admin/admin-modules/published-books/published-books.component';
import { EditAuthorComponent } from './admin/admin-modules/edit-author/edit-author.component';
import { PublishedBookContentComponent } from './admin/admin-modules/published-book-content/published-book-content.component';
import { PublishedBookViewComponent } from './admin/admin-modules/published-book-view/published-book-view.component';
import { ViewManageContentComponent } from './admin/admin-modules/view-manage-content/view-manage-content.component';
import { ViewChapterContentComponent } from './admin/admin-modules/view-chapter-content/view-chapter-content.component';
import { ManageSliderComponent } from './admin/admin-modules/manage-slider/manage-slider.component';
import { AddSliderComponent } from './admin/admin-modules/add-slider/add-slider.component';
import { ManageContentTypeComponent } from './admin/admin-modules/manage-content-type/manage-content-type.component';
import { AddManageContentTypeComponent } from './admin/admin-modules/add-manage-content-type/add-manage-content-type.component';
import { EditManageContentTypeComponent } from './admin/admin-modules/edit-manage-content-type/edit-manage-content-type.component';
import { ManageCopyrightComponent } from './admin/admin-modules/manage-copyright/manage-copyright.component';
import { AddManageCopyrightComponent } from './admin/admin-modules/add-manage-copyright/add-manage-copyright.component';
import { EditManageCopyrightComponent } from './admin/admin-modules/edit-manage-copyright/edit-manage-copyright.component';
import { ManageGenreComponent } from './admin/admin-modules/manage-genre/manage-genre.component';
import { AddManageGenreComponent } from './admin/admin-modules/add-manage-genre/add-manage-genre.component';
import { EditManageGenreComponent } from './admin/admin-modules/edit-manage-genre/edit-manage-genre.component';
import { ManageFeatureSectionComponent } from './admin/admin-modules/manage-feature-section/manage-feature-section.component';
import { ManagePagesComponent } from './admin/admin-modules/manage-pages/manage-pages.component';
import { ViewManagePageComponent } from './admin/admin-modules/view-manage-page/view-manage-page.component';
import { EditManagePageComponent } from './admin/admin-modules/edit-manage-page/edit-manage-page.component';
import { AdminSubscriptionComponent } from './admin/admin-modules/admin-subscription/admin-subscription.component';
import { AdminAddSubscriptionComponent } from './admin/admin-modules/admin-add-subscription/admin-add-subscription.component';
import { AdminEditSubscriptionComponent } from './admin/admin-modules/admin-edit-subscription/admin-edit-subscription.component';
import { ProfileComponent } from './admin/admin-modules/profile/profile.component';
import { ChangePasswordComponent } from './admin/admin-modules/change-password/change-password.component';
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { BooksSliderComponent } from './books-slider/books-slider.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpRequestInterceptor } from './services/http-request.interceptor';
import { PurchaseOptionsComponent } from './shared/components/purchase-options/purchase-options.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuillModule } from 'ngx-quill'
import { PopularReadsSliderComponent } from './popular-reads-slider/popular-reads-slider.component';
import { BooksByWriterComponent } from './books-by-writer/books-by-writer.component';
import { BestSellerSliderComponent } from './best-seller-slider/best-seller-slider.component';
import { NewlyAddedSliderComponent } from './newly-added-slider/newly-added-slider.component';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { BookClubsComponent } from './pages/book-clubs/book-clubs.component';
import { EditProfileInfoComponent } from './edit-profile-info/edit-profile-info.component';
import { CreateBookClubDialogComponent } from './pages/book-clubs/create-book-club-dialog/create-book-club-dialog.component';

import { ImageWrapperComponent } from './image-wrapper/image-wrapper.component';
import { NgSelectModule } from '@ng-select/ng-select';


import { RatingModule } from 'ng-starrating';
import { SharedModule } from './shared/shared.module';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VimeModule } from '@vime/angular';
@NgModule({
  declarations: [
    AppComponent,
    SliderComponent,
    ImageWrapperComponent,
    HomeComponent,
    BrowseBooksComponent,
    // ProductQuickViewComponent, // Quick view component
    BookDetailComponent,
    NavbarComponent,
    // BreadcrumbsComponent,
    SimilarBooksComponent,
    FavoriteReadsComponent,
    TermsAndConditionsComponent,
    SearchPageComponent,
    LayoutComponent,
    BookDetailSliderComponent,
    NotificationComponent,
    UserNotificationComponent,
    WriteComponent,
    ImageDialogComponent,
    ImageEditorCanvasComponent,
    ImageEditorBookTitleComponent,
    FreeDraggingDirective,
    ImageFinalCanvasComponent,
    NavSidebarComponent,
    SearchBoxComponent,
    SearchResultComponent,
    TempComponent,
    ShareComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    ContactUsComponent,
    ReportAnIssueComponent,
    InviteFriendsComponent,
    ImageAddComponent,
    ImageBrightnessTransparencyComponent,
    AddBookCoverComponent,
    ConfirmationModalComponent,
    ChapterPreviewComponent,
    SafeHtmlPipe,
    EditStoriesComponent,
    ReadChapterComponent,
    EditChapterComponent,
    NotificationPageComponent,
    AuthorProfileComponent,
    BookshelfComponent,
    AboutComponent,
    WallComponent,
    FollowingComponent,
    FollowersComponent,
    CreateStoriesComponent,
    ImagePostComponent,
    PaymentMethodComponent,
    ShareBookDialogComponent,
    NavbarBookshelfComponent,
    PaymentDialogComponent,
    SketchBookComponent,
    WriteMediaContentComponent,
    DashboardComponent,
    HeaderComponent,
    UsersComponent,
    ManageContentComponent,
    ChapterPaymentsComponent,
    PaymentsDashboardComponent,
    UploadBookComponent,
    MessageBoardComponent,
    BackendFeedbackComponent,
    ManageNotificationComponent,
    BackendNotificationsComponent,
    AdminContactUsComponent,
    ReadBookComponent,
    ViewchapterComponent,
    ViewFeedbackComponent,
    EditUsersComponent,
    AuthorListComponent,
    PublishedBooksComponent,
    EditAuthorComponent,
    PublishedBookContentComponent,
    PublishedBookViewComponent,
    ViewManageContentComponent,
    ViewChapterContentComponent,
    ManageSliderComponent,
    AddSliderComponent,
    ManageContentTypeComponent,
    AddManageContentTypeComponent,
    EditManageContentTypeComponent,
    ManageCopyrightComponent,
    AddManageCopyrightComponent,
    EditManageCopyrightComponent,
    ManageGenreComponent,
    AddManageGenreComponent,
    EditManageGenreComponent,
    ManageFeatureSectionComponent,
    ManagePagesComponent,
    ViewManagePageComponent,
    EditManagePageComponent,
    AdminSubscriptionComponent,
    AdminAddSubscriptionComponent,
    AdminEditSubscriptionComponent,
    ProfileComponent,
    ChangePasswordComponent,
    BooksSliderComponent,
    PurchaseOptionsComponent,
    PopularReadsSliderComponent,
    BooksByWriterComponent,
    BestSellerSliderComponent,
    NewlyAddedSliderComponent,
    BookClubsComponent,
    EditProfileInfoComponent,
    CreateBookClubDialogComponent,
    // MybookdetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCarouselModule.forRoot(),
    SlickCarouselModule,
    CarouselModule,
    NgxUsefulSwiperModule,
    SwiperModule,
    HttpClientModule,
    ImageCropperModule,
    ColorPickerModule,
    UserProfileModule,
    SharedModule,
    NgSelectModule,

    ToastrModule.forRoot(),

    BreadcrumbModule,

    NgToggleModule,
    NgbModule,
    NgxPayPalModule,
    // ScrollToModule.forRoot(),

    SocialLoginModule,
    FlipBookModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    PdfViewerModule,
    RichTextEditorAllModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    RatingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    QuillModule.forRoot(),
    // UiSwitchModule
    // SlickCarouselModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      // deps: [EnvironmentService],
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleTestingAClientId
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    AuthGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
