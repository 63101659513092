import { Component, OnInit } from '@angular/core';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';

@Component({
  selector: 'app-profile-desc',
  templateUrl: './profile-desc.component.html',
  styleUrls: ['./profile-desc.component.sass'],
})
export class ProfileDescComponent implements OnInit {
  constructor(private helper: HelperService) {
    this.baseurl = BaseUrl.image;
  }

  userData;
  baseurl;
  ngOnInit(): void {
    this.userData = this.helper.getUserData().data;
  }
}
