<div class="upload-book">
    <div class="text-center" *ngIf="bookData">
        <h1>{{ bookData.title }}</h1>
    </div>
    <div class="drop_box">
        <header>
            <h4>Select File Here</h4>
        </header>
        <label>{{fileName}}</label>
        <p>Files Supported: PDF</p>
        <input type="file" (change)="readURL($event)" hidden accept=".pdf" #file>
        <button *ngIf="!loadBtn" class="btn btn-lg upload-book-button rounded-pill" (click)="file.click()"> <i class="fa fa-cloud-upload mr-2"></i>Upload file</button>
        <button *ngIf="loadBtn" class="btn btn-primary rounded-pill" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </button>
    </div>
</div>
