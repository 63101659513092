import { Component, OnInit } from '@angular/core';
import { ContactUsService } from './contact-us.service';
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.sass']
})
export class AdminContactUsComponent implements OnInit {

  temp: boolean;
  getGener: any;
  showLoader = false;
  constructor(private contactService: ContactUsService, private toaster: ToastrService) {}

  ngOnInit() {
    this.showLoader = true;
    this.loadGenre();
  }
  loadGenre() {
    this.contactService.manageContent().subscribe(
      (res: any) => {
        this.getGener = res.data;
        $(document).ready(function() {
          $('#contactustable').DataTable();
        })
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }

}
