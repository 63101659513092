<div class="wrapper">
  <div id="content-page" class="content-page">
    <app-breadcrumbs></app-breadcrumbs>

    <div class="col-lg-12 mt-5">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
        <!-- <section class="hero_section_craousel_mr pt_row"> -->
        <section class="">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="reading_setting">
                  <div class="reading_txt_heading">
                    <h2 style="font-size: 30px; margin-top: 15px">
                      Reading Settings
                    </h2>
                  </div>
                  <div class="setting">
                    <div id="setting_filter" class="">
                      <div class="fliter_font">
                        <h3 style="font-size: 20px">Select Reading Colour</h3>
                        <ul>
                          <li
                            class="color active"
                            [ngClass]="{
                              active: activeClass === 'color_white'
                            }"
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="colorPick('color_white')"
                              class="active"
                              [ngClass]="{
                                active: activeClass === 'color_white'
                              }"
                              >A</a
                            ><span>White</span>
                          </li>
                          <li
                            class="color"
                            [ngClass]="{
                              active: activeClass === 'color_wheat'
                            }"
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="colorPick('color_wheat')"
                              [ngClass]="{
                                active: activeClass === 'color_wheat'
                              }"
                              >A</a
                            ><span>Eggshell</span>
                          </li>
                          <li
                            class="color"
                            [ngClass]="{
                              active: activeClass === 'color_black'
                            }"
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="colorPick('color_black')"
                              [ngClass]="{
                                active: activeClass === 'color_black'
                              }"
                              >A</a
                            ><span>Nightowl</span>
                          </li>
                        </ul>
                      </div>
                      <div class="from_inside">
                        <div class="label_input">
                          <label for="">Select Font Type</label>
                          <select
                            name=""
                            id="font-drop"
                            (change)="onFontChange($event)"
                          >
                            <option
                              *ngFor="let list of userlist"
                              value="{{ list.value }}"
                              selected="{{
                                list.value == activeFont ? 'selected' : ''
                              }}"
                            >
                              {{ list.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="range_fonts">
                        <div class="fonts_inrs"><span>Font Size</span></div>
                        <div class="input_range">
                          <input
                            type="range"
                            (change)="onFontSize($event)"
                            min="10"
                            max="40"
                            class="slider1"
                            value="{{ activeSize }}"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!-- <h2 class="demo-heading">Example</h2> -->
                <div class="setting demo-back" style="margin-top: 151px">
                  <h2 class="demo-heading">What is librums.</h2>
                  <p class="demo-text">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
