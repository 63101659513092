import { BaseUrl } from "./../../../base-url";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ManageContentService } from '../manage-content/manage-content.service';
declare var $: any;

@Component({
  selector: 'app-published-books',
  templateUrl: './published-books.component.html',
  styleUrls: ['./published-books.component.sass']
})
export class PublishedBooksComponent implements OnInit {

  manageContent =  [];
  public temp: Object = false;
  data: any;
  baseimage: any;
  blockTest: any;
  bookid: string;
  bookId: any;
  showLoader = false;
  constructor(
    private manage: ManageContentService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService
  ) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.bookid = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadManage();
  }

  openPopup(event) {
    $("#block_book").modal('show');
    this.bookId = event;
    if (event.status == "true") {
      this.blockTest = "block";
    } else {
      this.blockTest = "unblock";
    }
  }

  onClick(event) {
    this.manage.updateBookStatus(event).subscribe(
      (res: any) => {
        if (event) {
          if (event.status == 'true') {
            this.toaster.success('Book blocked successfully.')
          } else {
            this.toaster.success('Book unblocked successfully.')
          }
          this.loadManage();
        } else {
          this.toaster.success('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }


  loadManage() {
    this.manage.manageContent().subscribe(
      (res: any) => {
        if (res.status) {
          for(let i=0; i<res.data.length; i++){
            let check = res.data[i].is_published;
            if(check == '1'){
              this.manageContent.push(res.data[i]);
            }
          }
          this.manageContent = this.manageContent.reverse();
          $(document).ready(function() {
            $('#bookstable').DataTable();
          })
          this.showLoader = false;
          this.temp = true;
        }
      },
      error => {
        console.log("ERROR");
      }
      );
  }


  onClose(){
    this.loadManage();
  }
}
