import { Component, OnInit } from "@angular/core";
import { ManageContentTypeService } from "./manage-content-type.service";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: "app-manage-content-type",
  templateUrl: "./manage-content-type.component.html",
  styleUrls: ["./manage-content-type.component.sass"]
})
export class ManageContentTypeComponent implements OnInit {
  temp: boolean;
  getContent: any;
  showLoader = false;
  constructor(private contentService: ManageContentTypeService, private toaster: ToastrService) {}

  ngOnInit() {
    this.showLoader = true;
    this.loadGenre();
  }

  loadGenre() {
    this.contentService.getContentTypes().subscribe(
      (res: any) => {
        this.getContent = res.data;
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
  onClick(event) {
    this.contentService.updateStatus(event).subscribe(
      (res: any) => {
        if (event) {
          if (event.status == 'true') {
            this.toaster.success('Content type enabled successfully.')
          } else {
            this.toaster.success('Content type disabled successfully.')
          }
          this.loadGenre();
        } else {
          this.toaster.success('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
}
