<div id="global-loader">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
</div>
<div class="page">
    <div class="page-main">
        <!-- sidebar area -->
        <div class="app-content  my-3 my-md-5">
            <div class="page-container">
                <div class="page-header">
                    <!-- <h4 class="page-title">View Feedback and Suggestions</h4> -->
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']">Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Feedback and Suggestions
                        </li>
                    </ol>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">
                                    Feedback and Suggestions
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="">
                                    <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <table id="feedbacktable" datatable class="table row-border hover" *ngIf="temp"  role="grid" aria-describedby="example_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th class="wd-15p sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="First name: activate to sort column descending" style="width: 30px;">
                                                                #
                                                            </th>
                                                            <th class="wd-15p sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Last name: activate to sort column ascending" style="width: 172px;">
                                                                 User
                                                            </th>
                                                            <!-- <th class="wd-20p unsort" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" style="width: 121px;">
                                                                Subject
                                                            </th> -->
                                                            <th class="wd-20p unsort" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" style="width: 421px;">
                                                                Message
                                                            </th>
                                                            <th class="wd-20p unsort" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending" style="width: 50px;">
                                                                
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" class="odd" *ngFor="let feedback of getfeedback;let i = index">
                                                            <td class="sorting_1">{{ i + 1 }}</td>
                                                            <td>{{ feedback.value.user_name }}</td>
                                                            <td> 
                                                                 <a [routerLink]="['/view-feedback',feedback.value._id]">
                                                                    <strong style="text-transform: capitalize;">{{ ( feedback.value.type == 'look_and_feel')?'Look and Feel': feedback.value.type }}</strong><br>
                                                                    {{  feedback.value.content | slice: 0:100 }}{{  feedback.value.content.length > 100 ? "..." : "" }}</a>
                                                            </td>
                                                           <td>
                                                            <a data-toggle="modal" data-target="#delete" (click)="onClick12({feedback_id: feedback.value._id})">
                                                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                                            </a>                                                           
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- table-wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="container block_user_popup">
    <div class="modal fade" id="delete">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
                </div>

                <div class="modal-body">
                    <div class="block_user">
                        <p>Are you sure want to Delete this Message Board?</p>
                    </div>
                </div>

                 <div class="modal-footer">
                    <a (click)="onClick(delId)" class="back_btn add_content" data-dismiss="modal">
            Yes
          </a>
                    <a class="back_btn add_content" data-dismiss="modal">
            No
          </a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="container block_user_popup">
    <!-- The Modal -->
    <div class="modal fade" id="delete" data-backdrop="false">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <!-- <button type="button" class="close" data-dismiss="modal">
            &times;
          </button> -->
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="block_user">
                        <h2 style="text-align: center;">Are you sure you want to delete this record?</h2>
                        <p>After you delete this record you will not able to get this.</p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <a (click)="onClick(delId)" class="btn btn-primary pl-6 pr-6" data-dismiss="modal">
            Yes
          </a>
                    <a class="btn btn-light pl-6 pr-6" data-dismiss="modal">
            No
          </a>
                </div>
            </div>
        </div>
    </div>
</div>