<div class="row d-flex justify-content-center">
   <div class="col-lg-10 p-0">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
         <app-loader *ngIf="loader"></app-loader>
         <div class="product-wrapper">
            <div class="my-5">
               <div class="container-fluid container_fluid2">
                  <div class="prev_back_arrow_btns"></div>
               </div>
               <section class="publish_draft" *ngIf="bookData">
                  <div class="container-fluid pt-2">
                     <div class="row d-flex flex-column">
                        <div class="bokok-data-container flex-column flex-md-row">
                           <div class="px-5 d-flex justify-content-center align-items-center" style="margin-left: 1%;">
                              <div *ngIf="bookData.cover_image !== ''"
                                 style="width: 240px; height: 320px; border-radius: 8px">
                                 <!-- style="margin-left: 30px" -->
                                 <img loading="lazy" routerLink="/editstories/{{ bookData._id }}"
                                    src="{{ baseurl }}{{ bookData.cover_image }}" style="border-radius: 8px" alt="" />
                              </div>

                           </div>
                           <div class="
                              d-flex
                              flex-column
                              w-100
                            ">
                              <div class="d-flex flex-column justify-content-end h-100">
                                 <div class="about-book mb-3">
                                    <h2 class="book-name text-center text-md-start mt-3 mt-md-0">{{ bookData.title }}
                                    </h2>
                                    <div class="book-short-description text-center text-md-start mb-3">
                                       << {{bookData.tagline}}>>
                                    </div>
                                    <div
                                       class="block-author-book mb-4 d-flex justify-content-center justify-content-md-start">
                                       <img loading="lazy" class="image-author-book"
                                          src="{{ baseurl }}{{ bookData.author_image }}" alt="">
                                          <a  class="author_name" href="/authorprofile/{{bookData.author_id}}">
                                            <p class="m-0 ml-2 author-name-text">@{{bookData.author_name}}</p>
                                          </a>
                                    </div>
                                    <div class="book-description text-center text-md-start mb-3"
                                       style="line-height: 25px;">{{ bookData.description }}</div>
                                 </div>
                                 <div class="d-flex justify-content-center d-md-block">
                                    <button routerLink="../../../write/{{ bookData._id }}"
                                       class="p-3 menege-chupter-button">Add Chapter
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <!-- <div></div> -->
                        <div class="published__drafted">
                           <div class="">
                              <ul class="nav nav-tabs d-flex justify-content-center justify-content-md-start border-0"
                                 role="tablist">
                                 <li class="nav-item ml-md-5">
                                    <a class="published-drafted nav-link active" [class.active]="active_tab === 'published'" data-toggle="tab"
                                       href="#home">Published</a>
                                 <li class="nav-item">
                                    <a class="published-drafted nav-link" data-toggle="tab" href="#menu111">Drafts</a>
                                 </li>
                              </ul>
                              <div class="tab-content tab_published mt-2" ng-init="variable">
                                 <div id="home" class="container-fluid tab-pane active">
                                    <br />
                                    <div class="see_comment pb-0 border-bottom-0 w-100 m-auto"
                                       *ngIf="bookData?.published_chapters?.length > 0">
                                       <div class="see_comment_txt" *ngFor="
                                          let publish of bookData.published_chapters;
                                          index as i
                                       ">
                                          <h2 class="chapter-name"
                                          [routerLink]="['/', 'readchapter', bookData._id, publish._id, userdata._id]">
                                             << {{ publish.chapter_name }}>>
                                          </h2>
                                          <span class="d-flex">
                                             <mat-icon class="mx-2" *ngIf="!(publish?.no_of_purchase && publish?.paid)" (click)="removeChapter(publish)">delete</mat-icon>
                                             <mat-icon class="mx-2"
                                             [routerLink]="['/editchapter', bookData._id, publish._id]">edit</mat-icon>
                                          </span>
                                       </div>
                                    </div>
                                    <div class="see_comment" *ngIf="
                                          bookData.published_chapters &&
                                          !(bookData.published_chapters.length > 0)
                                          ">
                                       <div class="no_content">
                                          <h2>No content available!</h2>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="menu111" class="container-fluid tab-pane fade">
                                    <br />
                                    <div class="see_comment pb-0 border-bottom-0 w-100 m-auto"
                                       *ngIf="bookData.draft_chapters.length > 0">
                                       <div class="see_comment_txt" *ngFor="
                                             let drafts of bookData.draft_chapters;
                                             index as i
                                          " >
                                          <h2 class="chapter-name"
                                          [routerLink]="['/', 'readchapter', bookData._id, drafts._id, userdata._id]">
                                             << {{ drafts.chapter_name }}>>
                                          </h2>
                                          <span class="d-flex">
                                             <mat-icon class="mx-2" *ngIf="!(drafts?.no_of_purchase && drafts?.paid)" (click)="removeChapter(drafts)">delete</mat-icon>
                                             <mat-icon class="mx-2"
                                             [routerLink]="['/editchapter', bookData._id, drafts._id]">edit</mat-icon>
                                             <!-- <a routerLink="/editchapter/{{ bookData._id }}/{{ drafts._id }}"><img
                                                   alt="" class="edit-chapter-icon"
                                                   src="../../assets/Slider/images/icons/Edit pencil@2x.svg" /></a> -->
                                          </span>
                                       </div>
                                    </div>
                                    <div class="see_comment" *ngIf="
                          bookData.draft_chapters &&
                          !(bookData.draft_chapters.length > 0)
                        ">
                                       <div class="no_content">
                                          <h2>No content available!</h2>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- <div
                    *ngIf="bookData.sketchBook"
                    class="tab-content tab_publish mt-2"
                  >
                    <div id="home" class="container tab-pane active">
                      <br />
                      <div
                        class="see_comment pb-0 border-bottom-0 w-100 m-auto"
                      >
                        <img
                          class="rounded mt-5"
                          style="height: 50px; width: 50px"
                          src="{{ baseurl }}{{ data.destination }}/{{
                            data.filename
                          }}"
                          *ngFor="let data of bookData.published_chapters"
                          alt=""
                        />
                      </div>
                    </div>
                  </div> -->
                              <!-- <div
                    *ngIf="bookData.videoBook"
                    class="tab-content tab_publish mt-2"
                  >
                    <div id="home" class="container tab-pane active">
                      <br />
                      <div
                        class="see_comment pb-0 border-bottom-0 w-100 m-auto"
                      >
                        <video
                          width="320"
                          height="240"
                          autoplay
                          controls
                          *ngFor="let video of bookData.videos"
                        >
                          <source
                            src="{{ baseurl }}{{ video.destination }}/{{
                              video.filename
                            }}"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div> -->
                              <!-- </div> -->
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
      <!-- </div>
  </div> -->
   </div>
</div>

<!-- <app-footer></app-footer> -->
