// import { BaseUrl } from "./../../../base-url";
import {
  Component,
  OnInit,
  Pipe,
  AfterViewChecked,
  Inject,
} from '@angular/core';
// import { ReadChapterService } from './read-chapter.service';
import { ReadChapterService } from '../read-chapter/read-chapter.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
// import { SEOServiceService } from 'src/app/seoservice.service';
import { AuthGuard } from '../auth.guard';
import { SeoserviceService } from '../seoservice.service';
import { BaseUrl } from '../base-url';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
// import { ShareBookDialogComponent } from '../share-book-dialog/share-book-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-share-book-dialog',
  templateUrl: './share-book-dialog.component.html',
  styleUrls: ['./share-book-dialog.component.sass'],
})
export class ShareBookDialogComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;

  currentDate = new Date();
  chapterData: any;
  previousUrl: any;
  commentForm: FormGroup;
  commentData: any;
  submitted = false;
  is_white = 'color_white';
  book_id: String;
  chapter_id: String;
  baseURL: any;
  videoURL: any;
  activeClass: any;
  xchaptermsg: any;
  errormsg = false;
  showSuccess: any;
  showError: any;
  amount: any;
  userdata1: any;
  userdata: any;
  userImage: any;
  title: any;
  created_by: any;
  like_msg: any;
  is_subscribed = false;
  timeZoneOffset: any;
  fontFamily: any;
  fontSize: any;
  bookData: any;
  firstTime = true;
  bookTitle: any;
  chapterTitle: any;
  showLoader = false;
  a;
  private activeRoute;
  constructor(
    private chapterService: ReadChapterService,
    // private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private authguard: AuthGuard,
    private toastr: ToastrService,
    private _seoService: SeoserviceService,
    private meta: Meta,
    // private shareDialog: ShareBookDialogComponent,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.activeRoute = this.data.route;
    this.baseURL = BaseUrl.image;
    this.is_subscribed = this.authguard.is_subscribed;
    this.a = localStorage.getItem('currentUser');
    this.a = JSON.parse(
      crypto.AES.decrypt(this.a, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
  }

  ngOnInit() {
    this.previousUrl = localStorage.getItem('previousUrl');
    this.bookData = localStorage.getItem('mybooks');
    this.bookData = JSON.parse(this.bookData);

    this.userdata = this.a;
    this.userdata1 = this.a;
    this.userdata = this.userdata.data._id;
    this.userImage = this.userdata1.data.image;
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });
    this.book_id = this.activeRoute.snapshot.paramMap.get('book_id');
    this.chapter_id = this.activeRoute.snapshot.paramMap.get('chapter_id');

    this.getChapter();
    this.getComments();
    setTimeout(function () {
      $('.medium-insert-embeds-overlay').remove();
    }, 3000);
    this.timeZoneOffset = this.timeConvert(
      this.currentDate.getTimezoneOffset() * 2
    );
  }
  timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var total = -(rhours + rminutes);
    if (n < 0) {
      return '+' + total;
    } else {
      return '-' + total;
    }
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  }

  ngAfterViewChecked() {
    //check if reading setting is set
    let color = localStorage.getItem('color');
    let font = localStorage.getItem('font');
    let size = localStorage.getItem('size');
    if (color) {
      this.is_white = color;
      this.activeClass = color;
      //  $('body').on('load', '#cc').find('span').css('background', color);
    }
    if (size) {
      this.onFontSize(size);
      //  $('body').on('load', '#cc').find('p').css('font-size', size+'px');
      //  $('body').on('load', '#cc').find('span').css('font-size', size+'px');
    }
    if (font) {
      this.onFontChange(font);
      // $('body').on('load', '#cc').find('p').css('font-family', font);
      // $('body').on('load', '#cc').find('span').css('font-family', font);
    }
  }
  getChapter() {
    this.userdata = this.a;
    this.chapterService
      .getChapter({ book_id: this.book_id, chapter_id: this.chapter_id })
      .subscribe(
        (res: any) => {
          this.chapterData = res.data;
          if (this.chapterData.currentChapter.video) {
            this.videoURL =
              'https://www.youtube.com/embed/' +
              this.chapterData.currentChapter.video;
            this.videoURL = this._sanitizer.bypassSecurityTrustResourceUrl(
              this.videoURL
            );
          }

          if (this.chapterData.currentChapter.amount) {
            this.amount = this.chapterData.currentChapter.amount;
            this.title =
              'Hi ' +
              this.userdata.data.fullname +
              ' has bought ' +
              this.chapterData.currentChapter.chapter_name +
              ' from your book ' +
              this.chapterData.title;
            this.created_by = this.chapterData.currentChapter.created_by;
            this.initConfig();
          }

          this.bookTitle = this.chapterData.title;
          this.chapterTitle = this.chapterData.currentChapter.chapter_name;
          this._seoService.updateTitle(
            this.bookTitle + ': ' + this.chapterTitle
          );
          this.meta.addTag({
            name: 'keywords',
            content: 'read,write,share to hearts content',
          });
          this.meta.addTag({
            name: 'description',
            content:
              'AIf you have a family of avid readers, good news: librums makes it pretty easy to share books with every member of your family, So here it is please check my latest book.',
          });
          this.meta.addTag({ name: 'author', content: 'Librums' });
          this.meta.addTag({
            name: 'title',
            content: this.bookTitle + ': ' + this.chapterTitle,
          });
          this.meta.addTag({
            name: 'url',
            content:
              'https://testing.librums.com/shareBook.html?book_id=' +
              this.chapterData.book_id +
              '&genre_id=' +
              this.chapterData.genre,
          });
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }
  getComments() {
    this.chapterService.getComments({ book_id: this.book_id }).subscribe(
      (res: any) => {
        this.commentData = res.data;
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
  }
  like(event) {
    this.chapterService.likeBook(event).subscribe(
      (res: any) => {
        if (res.status) {
          // this.like_msg = res.message;
          // alert(res.message);
          this.getChapter();
        } else {
        }
      },
      (error) => {}
    );
  }

  get f() {
    return this.commentForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    this.showLoader = true;
    if (this.f.comment.value == '') {
      this.errormsg = true;
      this.showLoader = false;
    }
    this.chapterService
      .addComment(this.f.comment.value, this.book_id, this.chapter_id)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.commentForm.reset();
          this.errormsg = false;
          // this.toastr.success(data.message);
          this.getComments();
          this.showLoader = false;
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }
  changeLayout(val) {
    this.firstTime = false;
    this.is_white = val;
    this.activeClass = val;
    localStorage.setItem('color', val);
    $('#cc').find('span').css('background', val);
  }

  onFontSize(size) {
    this.firstTime = false;
    if (typeof size === 'string') {
      size = size;
    } else {
      size = size.target.value;
    }
    $('#cc')
      .find('p')
      .css('font-size', size + 'px');
    $('#cc')
      .find('p')
      .css('line-height', parseInt(size) + parseInt('10') + 'px');
    $('#cc')
      .find('span')
      .css('font-size', size + 'px');
    $('#cc')
      .find('span')
      .css('line-height', parseInt(size) + parseInt('10') + 'px');
    localStorage.setItem('size', size);
  }

  onFontChange(font) {
    this.firstTime = false;
    if (typeof font === 'string') {
      font = font;
    } else {
      font = font.target.value;
    }
    $('#cc').find('p').css('font-family', font);
    $('#cc').find('span').css('font-family', font);
    localStorage.setItem('font', font);
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId:
        'Af-FG-qPrC_Zau9sWip8h8OUJ68XMctAXbiljFZasxYhOhum8UIyqmcmoZIhiAvq1-pk8FlUS7Iy2bHU',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.amount,
                  },
                },
              },
              items: [
                {
                  name: 'Buy Chapter',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        actions.order.get().then((details) => {});
      },
      onClientAuthorization: (data) => {
        this.showError = '';
        this.updtaePayment({
          book_id: this.book_id,
          chapter_id: this.chapter_id,
          transaction_id: data.id,
          type: '',
          amount: this.amount,
          plan_id: '',
          purpose: this.title,
          status: 'COMPLETED',
          user_id: this.created_by,
        });
        this.toastr.success('Payment done successfully.');
      },
      onCancel: (data, actions) => {
        this.toastr.error('Something went wrong.');
      },
      onError: (err) => {
        this.toastr.error('Something went wrong.');
      },
      onClick: (data, actions) => {
        // this.showError = 'Somethin went wrong';
      },
    };
  }

  updtaePayment(postdata) {
    this.chapterService
      .savePayment(postdata)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.getChapter();
            this.toastr.success('Payment done successfully.');
            location.reload();
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  shareBook(id) {
    this.chapterService
      .shareBook({ book_id: id })
      .pipe(first())
      .subscribe(
        (res: any) => {
          // $("#forgot").modal('hide')
          this.getChapter();
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  shareBookDialog() {
    this.dialog.open(ShareBookDialogComponent, {});
  }
}
