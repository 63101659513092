<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
<div class="page-main">
    <!-- Sidebar menu-->
    <div class="app-content  my-3 my-md-5" *ngIf="getPages">
        <div class="page-container">
            <div class="page-header">
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" >Dashboard</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/feedbacks-and-suggestions']" >Feedback and Suggestions</a></li>
                <li class="breadcrumb-item active" aria-current="page">Message </li>
            </ol></div>
            <div class="form_edit_book promocode_txt_label pl-5 pr-5 mb-4">
                <div class="row">
                    <div class="col-md-12">
                            <div class="promocode_txt pull-left">
                                    <p><strong>Reported By:</strong> <a [routerLink]="[
                                        '/edit-users',
                                        getPages.created_by
                                    ]"> {{getPages.user_name}}</a></p>
                            </div>
                            <div class="promocode_txt pull-right">
                                    <p><strong>{{ getPages.created_at | date: "MMM d, y"}}</strong></p>
                            </div>
                        </div>
                    <!-- </form> -->
                </div>
            </div>
            <div class="form_edit_book promocode_txt_label pl-5 pr-5">
                <div class="row">
               
                    <div class="col-md-12">
                            <h2 style="text-transform: capitalize;">{{ (getPages.type == 'look_and_feel')?'Look and Feel':getPages.type }}</h2>
                        <div class="promocode_txt">
                                <!-- <p>Message</p> -->
                                <span>{{getPages.content}}.</span>
                        </div>
                    </div>
                </div>
            </div>
       
        </div>
    </div>
</div>
</div>