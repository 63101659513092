<div class="page">
    <div *ngIf="showLoader" class="loader">
      <img loading="lazy" src="/assets/img/loader.svg" />
    </div>
    <div class="page-main">
      <!-- Sidebar menu-->
      <div class="app-content  my-3 my-md-5" *ngIf="getPages">
        <div class="page-container">
          <div class="page-header">
            <ol class="breadcrumb mb-4">
              <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">Dashboard</a>
              </li>
              <li class="breadcrumb-item">
                <a [routerLink]="['/manage-pages']"> Pages</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ getPages.title }}
              </li>
            </ol>
          </div>
          <div class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="card-header"> <div class="card-title">{{ getPages.title }}</div></div>
                <div class="card-body">

                  <p
                    id="tester_class"
                    [innerHTML]="getPages.content | safeHtml: 'html'"
                  ></p>
                </div>
              </div>

              <!-- </form> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
