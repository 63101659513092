import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { BaseUrl } from 'src/app/base-url';
import { HelperService } from 'src/app/helper.service';
import Swiper from 'swiper';
import { UserProfileHanlderService } from '../../user-profile/user-profile-hanlder.service';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { environment } from '../../../environments/environment';
import { BookDataService } from '../../services/book-data.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass'],
})
export class AboutComponent implements OnInit {
  @Input() authorData: any;
  @HostListener('window:resize', ['$event'])

  public baseimage;
  public briefText;
  public fullText;
  public showFullText = false;
  public textIsBrief = false;
  public innerWidth: any;
  public bookDetails = [];
  // ngOnInit() {
  //     this.innerWidth = window.innerWidth;
  // }
  constructor(
    private helper: HelperService,
    private userProfileHandlerService: UserProfileHanlderService,
    private userProfileService: UserProfileService,
    private bookDataService: BookDataService,
    ) {
    this.baseimage = BaseUrl.image;
  }
  ngAfterViewInit() {
    var swiper = new Swiper('.test____', {
      slidesPerView: 4,
      spaceBetween: 20,
      grabCursor: true,
      breakpoints:{
        "969": {
          slidesPerView: 4,
        },
        "572":{
          slidesPerView: 4,

        },
        "501":{
          slidesPerView: 3,
          spaceBetween: 30,
        },
        "450":{
          slidesPerView: 2,
          spaceBetween: 30,
        },
        "375":{
          slidesPerView: 1,
          spaceBetween: 30,
        },
        "350":{
          slidesPerView: 1,
          spaceBetween: 30,
        },

      },
      slidesPerGroup: 1,
      observer: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
  showLoader: boolean = true;
  draftedStories: any;
  storiesCount: any;
  draftedCount: any;
  myLikedStoriesCount:any;
  userProfile: any;
  limit: any = 10;
  popularRead: any
  hendleShoweMoreText(){
    this.showFullText = true
  }
  hendleShoweLessText(){
    this.showFullText = false
  }

  getNewlyAddedBooks = () => {
    let token = localStorage.getItem('currentUser')
    const decryptedUser = crypto.AES.decrypt(
      token,
      environment.secretKey
    ).toString(crypto.enc.Utf8)
    let user = JSON.parse(decryptedUser)?.data?._id
    this.userProfile = JSON.parse(decryptedUser)?.data
    this.bookDataService.getNewlyAddedBooks(10,0).subscribe((result: any) => {
      this.bookDetails = result.data
      // this.authorSketchBooks = result.sketch;
      // console.log('result: ', this.authorSketchBooks);
    });
  }

  getPopularBooks = () => {
    this.bookDataService.getPopularBooks(this.limit).subscribe((result: any) => {
      this.popularRead = result.data
    });
  }

  ngOnInit(): void {
    this.userProfileHandlerService.getDraftedStories().subscribe((result) => {
      this.draftedStories = result;
      this.draftedStories.every((element) => {
        this.userProfileService
          .getGenreDetails(element['genre'])
          .subscribe((genre) => {
            element['genre'] = genre['data'].name;
          });
        return true;
      });
      this.showLoader = false;
      this.storiesCount = this.authorData.myStories.length
      this.draftedCount = this.draftedStories.length
      this.myLikedStoriesCount = this.authorData.myLikedStories.length
    });
    this.getNewlyAddedBooks()
    this.getPopularBooks()
    let token = localStorage.getItem('currentUser')
    const decryptedUser = crypto.AES.decrypt(
      token,
      environment.secretKey
    ).toString(crypto.enc.Utf8)
    let user = JSON.parse(decryptedUser)?.data?._id
    this.userProfile = JSON.parse(decryptedUser)?.data
    console.log('this.userProfile', this.userProfile)
    if (this.authorData.bio.length > 150) {
      this.textIsBrief = false
      const splitBriefText = this.authorData.bio.split('').slice(0,150)
      this.briefText = splitBriefText.join('')
      this.fullText = this.authorData.bio
    } else if (this.authorData.bio.length <= 150) {
      if(this.authorData._id == this.userProfile._id ){
        if (this.authorData.bio.length == 0) {
          this.textIsBrief = true
          this.fullText = 'You have no bio yet'
          console.log(this.textIsBrief)
        }else{
          this.textIsBrief = true
          this.fullText = this.authorData.bio
        }
      }
      if(this.authorData._id !== this.userProfile._id ){
        if (this.authorData.bio.length == 0) {
          this.textIsBrief = true
          this.fullText = 'This author have no bio yet'
          console.log(this.textIsBrief)
        }else{
          this.textIsBrief = true
          this.fullText = this.authorData.bio
        }
      }


      // if(this.userProfile?._id == this.authorData?._id){
      //   if(this.authorData.bio.length < 150){
      //     this.textIsBrief = true
      //     this.fullText = this.authorData.bio
      //   }
      // }
    }

  }

}
