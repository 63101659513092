import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from '../helper.service';
import { MatDialog } from '@angular/material/dialog';
import { SketchBookComponent } from '../sketch-book/sketch-book.component';
import { Book, PageType } from '@labsforge/flipbook';
import { BookDetailService } from '../book-detail/book-detail.service';
import { result } from 'underscore';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseUrl } from '../base-url';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-write-media-content',
  templateUrl: './write-media-content.component.html',
  styleUrls: ['./write-media-content.component.sass'],
})
export class WriteMediaContentComponent implements OnInit {
  baseimage: string;
  constructor(
    private fb: FormBuilder,
    private bookService: BookDetailService,
    private helper: HelperService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.baseimage = BaseUrl.image;
  }

  @ViewChild('sketchInput', { static: false }) public sketchInput: ElementRef;

  public formGroup = this.fb.group({
    // sketch: [null],
    bookType: ['sketchBook', Validators.required],
  });

  openDialog() {
    this.dialog.open(SketchBookComponent, {
      width: '800px',
      height: '600px',
      disableClose: true,
      data: { sketchBookId: this.sketchBookId, type: this.selectedBookValue },
    });
  }

  public amount: number = 10;
  public book_id: string;
  public booktype: string;
  ngOnInit(): void {
    this.book_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.selectedBookValue =
      this.activatedRoute.snapshot.queryParamMap.get('bookType');
    this.getGenre();

    this.bookService.getBookById(this.book_id).subscribe((book: any) => {
      this.selectedBookValue = book.data.kind;
    });
    this.getVideoBookByAuthorId();
  }

  public bookPages: Array<any> = [];

  public bookData: Book = {
    width: 1190,
    height: 800,
    zoom: 1,
    cover: {
      front: {
        imageUrl:
          'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
      },
      back: {
        imageUrl:
          'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
      },
    },
    pages: this.bookPages,
    startPageType: PageType.Double,
    endPageType: PageType.Double,
  };

  private sketchBookId: string;
  public selectedBookValue: string;

  public uploadData: FormData | null = new FormData();

  public onBooktypeChange = (event: any) => {
    this.uploadData = null;
  };

  public onSubmit = () => {
    if (this.formGroup.valid && this.selectedFile) {
      const userId = this.helper.getUserData().data._id;

      for (let index = 0; index < this.selectedFile.length; index++) {
        this.uploadData.append(
          'sketch',
          this.selectedFile[index],
          this.selectedFile[index].name
        );
      }
      this.uploadData.append('book_id', this.book_id);

      const sketchbookType = this.formGroup.get('bookType').value;
      if (this.selectedBookValue == 'sketchBook') {
        this.bookService
          .createSketchBook(this.uploadData)
          .subscribe((result: any) => {
            if (result.status == 200) {
              this.snackbar.open(result.message, 'Ok', { duration: 2000 });
              this.sketchBookId = result.result._id;
              this.formGroup.reset();
              this.uploadData.delete('sketch');
              this.uploadData.delete('book_id');
              this.selectedFile = null;
            }
          });
      } else {
        this.bookService
          .createVideoBook(this.uploadData)
          .subscribe((result: any) => {
            if (result.status == 200) {
              this.snackbar.open(result.message, 'Ok', { duration: 2000 });
              this.sketchBookId = result.video._id;
              this.formGroup.reset();
              this.uploadData.delete('sketch');
              this.uploadData.delete('book_id');
              this.selectedFile = null;
            }
          });
      }
    } else {
      this.snackbar.open('Please select a file to upload', 'Ok', {
        duration: 2000,
      });
    }
  };
  selectedFile: FileList;

  selectedFileArray = new Array<any>();
  public onFileChange(event) {
    this.selectedFile = event.target.files;
    const file = event.target.files;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedFileArray.push(reader.result);
    };
    reader.readAsDataURL(file);
  }

  removeImage = (imageId: number) => {
    if (imageId > -1) {
      this.selectedFileArray.splice(imageId, 1);
    }
  };

  genreResult: any;
  getGenre = () => {
    this.bookService.getGenre().subscribe((genre: any) => {
      this.genreResult = genre.data;
    });
  };

  authorSketchBooks: any;
  authorVideoBooks: any;

  public getSketchBookByAuthorId = () => {
    this.bookService.getSketchBookByAuthorId().subscribe((result: any) => {
      this.authorSketchBooks = result.sketch;
    });
  };

  public getVideoBookByAuthorId = () => {
    this.bookService.getVideoBookByAuthorId().subscribe((result: any) => {
      this.authorVideoBooks = result.video;
    });
  };
}
