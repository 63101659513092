<div
  *ngIf="imageUrl.length === 0"
  [style.background-image]="'url(' + url + ')'"
  style="width: 270px; height: 360px"
></div>

<input
  [disabled]="twoImages"
  type="file"
  (change)="onSelectFile($event)"
  #file
/>

<div style="text-align: right; width: 91%">
  <div class="remove-image" *ngFor="let data of imageUrl; let i = index">
    <div class="d-flex">
      {{ i + 1 }}
      <!-- <i (click)="removeImage(i)" class="fas fa-times"></i> -->
      <img
        (click)="removeImage(i)"
        src="assets/Slider/images/icons/Gradient/Close button@3x.svg"
        alt=""
      />
    </div>
  </div>
</div>

<div
  *ngIf="uploadImage && !twoImages"
  class="plus_icon_img text-center mt-4 upload-canvas-image"
  id="first_browse"
>
  <div class="d-block">
    <label for="cover_image">
      <img
        (click)="file.click()"
        src="../../assets/Slider/images/icons/Plus graident.svg"
      />
    </label>
  </div>
  <button
    (click)="twoImages = true; transparency = true"
    *ngIf="imageUrl.length >= 1"
    class="btn btn-sm global-button cancel"
  >
    Next
  </button>
  <button
    (click)="uploadImage = !uploadImage"
    *ngIf="!twoImages"
    class="btn btn-sm global-button cancel"
  >
    Cancel
  </button>
</div>
