import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReviewService } from './product-quick-view.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewHandlerService {
  constructor(private reviewService: ReviewService) {}

  getReviewByBookId = (book_id: string): Observable<any> => {
    return this.reviewService
      .getReviewByBookId(book_id)
  };
}
