import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private imageBehaviour: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public image = this.imageBehaviour.asObservable();

  f(a) {
    const cv = this.imageBehaviour.value;
    const uv = [...cv, a];
    this.imageBehaviour.next(uv);
    console.log('image behaviour', this.imageBehaviour.value);
  }

  rf(index) {
    this.imageBehaviour.value.splice(index, 1);
    this.imageBehaviour.next([...this.imageBehaviour.value]);
    // return this.imageBehaviour;
  }

  reset() {
    this.imageBehaviour.next([]);
  }

  public imageUrl = [];
  setImageUrl(image: any) {
    this.imageUrl.push(image);
  }

  removeImage(index) {
    this.imageUrl.splice(index, 1);
    return this.imageUrl;
  }

  getImage() {
    return this.imageUrl;
  }


  b64toBlob(bs64_string: string) {

    const block = bs64_string?.split(';');
    let contentType = block[0].split(':')[1];
    const b64Data = block[1].split(',')[1];
    let sliceSize = 0;

    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  constructor() {}
}
