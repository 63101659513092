import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ReadChapterService {
  _baseURL: string;
  userdata: any;
  headers: HttpHeaders;
  constructor(private http: HttpClient, private helper: HelperService) {
    this._baseURL = BaseUrl.frontend;
    this.headers = this.helper.getHeader();
  }
  getChapter(postData) {
    const headers = this.helper.getHeader();
    return this.http.post(this._baseURL + `/getChapter`, postData, {
      headers,
    });
  }

  getChapter_V1(postData) {
    const headers = this.helper.getHeader();
    return this.http.post(this._baseURL + `/v1/getChapter`, postData, {
      headers,
    });
  }
  getComments(postData) {
    return this.http.post(this._baseURL + `/getComment`, postData, {
      headers: this.headers,
    });
  }
  likeBook(data) {
    return this.http.post(this._baseURL + `/likeBook`, data, {
      headers: this.headers,
    });
  }
  addComment(comment: string, book_id: any, chapter_id: any) {
    return this.http.post(
      this._baseURL + `/addComment`,
      { comment, book_id, chapter_id },
      { headers: this.headers }
    );
  }

  savePayment(data) {
    return this.http.post(this._baseURL + `/saveTransaction`, data, {
      headers: this.headers,
    });
  }

  shareBook(data) {
    return this.http.post(this._baseURL + `/shareBook`, data, {
      headers: this.headers,
    });
  }

  addBookHistory = (bookId: string, chapterId: string, authorId: string) => {
    return this.http.post(
      `${this._baseURL}/addBookHistory`,
      { bookId, chapterId, authorId },
      { headers: this.headers }
    );
  };
}
