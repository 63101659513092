import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AdminEditSubscriptionService {
  editsub: any;
  updatesub: any;
  base: any;
  constructor(private http: HttpClient) {
    this.base = BaseUrl.admin;
  }

  getSubscriptionDetail(id) {
    this.editsub = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.editsub, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
  
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(
      this.base + `/getSubscriptionDetail`,
      { subscription_id: id },
      {
        headers
      }
    );
  }

  updateSubscription(data) {
    this.updatesub = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.updatesub, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
  
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/updateSubscription`, data, {
      headers
    });
  }
}
