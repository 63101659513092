import { Component, OnInit } from '@angular/core';
import { AddManageContentTypeService } from "./add-manage-content-type.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-manage-content-type',
  templateUrl: './add-manage-content-type.component.html',
  styleUrls: ['./add-manage-content-type.component.sass']
})
export class AddManageContentTypeComponent implements OnInit {
  getGenre: any;
  updateForm: any;
  loading = false;
  submitted = false;
  showMsg = false;
  errorMsg: any;
  alertService: any;
  constructor(
    private addtype: AddManageContentTypeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
     this.updateForm = this.formBuilder.group({
      name: ["", Validators.required]
    });
  }

  get f() {
    return this.updateForm.controls;
  }
  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
  }
  onSubmit() {
    // console.log(this.updateForm.value);
    this.cleanForm(this.updateForm);
    // this.updateForm.value.name = this.updateForm.value.name.trim();
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      return;
    }
    this.loading = true;
    // this.submitted = true;
    this.addtype.addContentType(this.updateForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          console.log(res.status);
          this.showMsg = res.message;
          this.toaster.success("Content type added successfully.")
          // alert("Add Content Type successfully");
          this.router.navigate(["/manage-content-type"]);
        } else {
          this.errorMsg = res.message;
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

}
