import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HandleEditService {
  private mode = new BehaviorSubject<string>('write');
  public modeVal = this.mode.asObservable();

  private image = new BehaviorSubject<string>(
    'uploads/assets/img/rectangle1.svg'
    // 'https://testing.librums.com/assets/img/rectangle1.svg'
  );
  public bookImage = this.image.asObservable();

  setMode(m: string) {
    this.mode.next(m);
  }

  setImage(img: string) {
    this.image.next(img);
  }
  constructor() {}
}
