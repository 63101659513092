import { Component, OnInit } from "@angular/core";
import { ManageGenreService } from "./manage-genre.service";
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: "app-manage-genre",
  templateUrl: "./manage-genre.component.html",
  styleUrls: ["./manage-genre.component.sass"]
})
export class ManageGenreComponent implements OnInit {
  temp: boolean;
  getGener: any;
  showLoader = false;
  constructor(private genreService: ManageGenreService, private toaster: ToastrService) {}

  ngOnInit() {
    this.showLoader = true;
    this.loadGenre();
  }

  loadGenre() {
    this.genreService.getGenres().subscribe(
      (res: any) => {
        this.getGener = res.data;
        $(document).ready(function() {
          $('#genretable').DataTable();
        })
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
  onClick(event) {
    this.genreService.updateStatus(event).subscribe(
      (res: any) => {
        if (event) {
          if (event.status == 'true') {
            this.toaster.success('Genre enabled successfully.')
          } else {
            this.toaster.success('Genre disabled successfully.')
          }
          this.loadGenre();
        } else {
          this.toaster.success('Something went wrong.')
        }
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
}
