import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { WriteBookService } from '../services/write-book.service';

@Component({
  selector: 'app-image-add',
  templateUrl: './image-add.component.html',
  styleUrls: ['./image-add.component.sass'],
})
export class ImageAddComponent implements OnInit {
  constructor(
    private writeBookService: WriteBookService,
    private dialog: MatDialog
  ) {}

  public url = 'https://testing.librums.com/assets/img/rectangle1.svg';

  public imageUrl: string[] = [];
  public twoImages: boolean = false;
  public transparency: boolean = false;
  public uploadImage = false;

  onSelectFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dialogRef = this.dialog.open(ImageDialogComponent, {
        data: { url: reader.result },
        height: '700px',
        width: '700px',
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        // this.url = result.data;
        this.imageUrl.push(result.data);
        this.twoImages = this.imageUrl.length === 2 ? true : false;
        this.transparency = this.twoImages;
      });
    };
    reader.readAsDataURL(file);
  }

  removeImage(index: number) {
    this.imageUrl.splice(index, 1);
  }

  ngOnInit(): void {}
}
