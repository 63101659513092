import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HelperService } from 'src/app/helper.service';
import { AlertService } from 'src/app/services/alert.service';
import { ReportService } from 'src/app/services/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.sass'],
})
export class FeedbackComponent implements OnInit {
  getreportissue: any;
  userdata: any;
  loading = false;
  submitted = false;
  showMsg = false;
  reportSubmit: FormGroup
  writeTitle = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private helper: HelperService,
    // private alertService: AlertService,
    private reportService: ReportService, // private alertService: AlertService, // private toaster: ToastrService
    private _snackbar: MatSnackBar
  ) {}

  ngOnInit() {;

    this.loadReport();
  }
  // convenience getter for easy access to form fields

  // submit issue form
  feedbackSubmit(reportSubmit) {
    this.submitted = true;
    // if(reportSubmit.value['title'].replaceAll(' ','').length == 0){
    //   reportSubmit.controls['title'].setErrors({'incorrect': true});
    // }
    if(reportSubmit.value['title'].replaceAll(' ','').length == 0){
      reportSubmit.controls['title'].setErrors({'incorrect': true});
    }
    if(reportSubmit.value['message'].replaceAll(' ','').length == 0){
      reportSubmit.controls['message'].setErrors({'incorrect': true});
    }
    if(reportSubmit.value['message'].replaceAll(' ','').length > 0 && reportSubmit.value['title'].replaceAll(' ','').length > 0){
      this.loading = true;
      reportSubmit.value['type'] = 'content';
      reportSubmit.value['content'] = reportSubmit.value['message'];
      const headers = this.helper.getHeader();
      this.reportService
        .feedback(reportSubmit.value)
        .pipe(first())
        .subscribe(
          (data: any) => {
            // this.router.navigate(['/feedback']);
            // this.toaster.success('Feedback submitted successfully.');
            this._snackbar.open(data.message, 'Ok', { duration: 2000 });
            // setTimeout(() => {
            //   // this.router.navigate(['/']);
            // });
            this.reset();
          },
          (error) => {
            // this.toaster.error('Something went wrong.');
            this.loading = false;
          }
        );
    }

  }
  onChangeTextAria(){
    $('textarea').each(function () {
      this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
    }).on('input', function () {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight) + 'px';
    });
  }
  // reset form data
  private reset() {
    this.reportSubmit = this.formBuilder.group({
      type: [''],
      content: [''],
    });
  }

  //get report issue listing
  private loadReport() {
    this.reportService.getreportissue().subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value,
        }));
        this.getreportissue = mapped;
      },
      (error) => {
        console.log('ERROR');
      }
    );
  }
}
