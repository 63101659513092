<footer class="iq-footer p-0">
  <div class="container-fluid p-0" style="overflow: hidden;">
    <div class="row main-footer justify-content-left text-left pl-3 pr-3">
      <!-- <div class="col-lg-2">
      </div> -->

      <div class="col-xl-4 col-lg-6">
        <!-- <div class="company-info">
          <h1>LOGO</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
          </p>
        </div> -->
        <div class="company-social-media">
          <h2 class="mt-4">Follow Us</h2>
          <div class="social-media-list d-flex justify-content-start flex-wrap p-3">
            <img loading="lazy" src="../../assets/images/social01.png" class="sidebar-bg-1" />
            <!-- <img loading="lazy" src="../../assets/images/social02.png" class="sidebar-bg-1" /> -->
            <img loading="lazy" src="../../assets/images/social03.png" class="sidebar-bg-1" />
            <!-- <img loading="lazy" src="../../assets/images/social04.png" class="sidebar-bg-1" /> -->
            <img loading="lazy" src="../../assets/images/social05.png" class="sidebar-bg-1" />
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-3">
        <div class="categories-list mt-3">
          <h5 class="">Books Categories</h5>
          <div class="row">
            <div class="col-6 col-lg-4">
              <h4><a routerLink="#">Action</a></h4>
              <h4><a routerLink="#">Advanture</a></h4>
              <h4><a routerLink="#">Comedy</a></h4>
              <h4><a routerLink="#">Crime</a></h4>
              <h4><a routerLink="#">Drama</a></h4>
              <h4><a routerLink="#">Fantasy</a></h4>
              <h4><a routerLink="#">Horror</a></h4>
            </div>
            <div class="col-6 col-lg-4">
              <h4><a routerLink="#">Law</a></h4>
              <h4><a routerLink="#">Mystery</a></h4>
              <h4><a routerLink="#">Professional</a></h4>
              <h4><a routerLink="#">Romance</a></h4>
              <h4><a routerLink="#">TV Series</a></h4>
              <h4 class="view-more-link"><a class="justify-content-start justify-content-lg-start" routerLink="#">
                  View more
                  <img loading="lazy" class="" src="../../assets/images/right-arrow-head-blue.png" alt="" width="8" height="8" /></a>
              </h4>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-lg-6">
        <div class="quick-links">
          <h5 class="mt-4">Quick Links</h5>
          <div class="d-flex flex-wrap gap-5">
            <h4><a routerLink="/about-us">About Us</a></h4>

            <h4><a routerLink="/contact-us">Contact Us</a></h4>

            <h4><a routerLink="/terms-and-conditions">Terms and Conditions</a></h4>
            <h4><a routerLink="/privacy-policy">Privacy Policy</a></h4>

            <!-- <h4><a routerLink="/report-an-issue">Report</a></h4> -->
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-6 text-right">
        Copyright 2020 <a href="#">Booksto</a> All Rights Reserved.
      </div> -->
    </div>
    <!-- <hr /> -->
    <!-- <div class="copyright-block d-flex flex-wrap align-items-center footer-bottom-text">
      <p class="mb-0 me-sm-5 p-3">
        Copyright © 2020 Librums™. All Rights Reserved.
      </p>
      <p class="mb-0 ms-5 p-3">
        Terms and Conditions | Privacy Policy
      </p>
    </div> -->
  </div>
</footer>