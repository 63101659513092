import { environment } from "src/environments/environment";

export class BaseUrl {
  public static get frontend(): string {
    return environment.baseUrl;


    // return 'http://54.198.12.98:8002/api'; // Testing
    return 'http://localhost:2001/api'; // Testing
    // return 'https://spotcodes.in/api'; // Testing

    // return "https://librums.com:2200/api"; // Staging
    // return "https://librums.com:2001/api"; // Testing
    // return "http://18.209.143.118:8001/api"
    // return "http://18.191.113.121:8002/api"; // Testing
    // return "/api"; // Testing
    // return "http://18.209.143.118:8002/api"; // Testing
    // return "http://18.209.143.118:8003/api"; // Dev
  }

  public static get admin(): string {
    return environment.baseAdminUrl;
    // return 'http://54.198.12.98:8002/admin'; // Testing
    return 'http://localhost:2001/admin'; // Testing
    // return 'https://spotcodes.in/admin'; // Testing

    // return "https://librums.com:2200/admin/"; // Staging
    // return "https://librums.com:2001/admin/"; // Testing
    // return "http://18.209.143.118:8001/admin"
    // return "http://18.191.113.121:8002/admin"; // Testing
    // return "/admin"; // Testing
    // return "http://18.209.143.118:8002/admin/"; // Testing
    // return "http://18.209.143.118:8003/admin/"; // Dev
  }

  public static get imageApi(): string {
    return environment.uploadImageBaseUrl;
    // return 'http://54.198.12.98:8002/api/uploadImage/'; // Testing
    return 'http://localhost:2001/api/uploadImage/'; // Testing
    // return 'https://spotcodes.in/api/uploadImage/'; // Testing

    // return "https://librums.com:2200/api/uploadImage/";  // Staging
    // return "https://librums.com:2001/api/uploadImage/";  // Testing
    // return "http://18.209.143.118:8001/api/uploadImage/";  // Testing
    // return "http://18.191.113.121:8002/api/uploadImage/";  // Testing
    // return "/api/uploadImage/";  // Testing
    // return "http://18.209.143.118:8002/api/uploadImage/"; // Testing
    // return "http://18.209.143.118:8003/api/uploadImage/"; // Dev
  }

  public static get image(): string {
    return environment.imageBaseUrl;
    // return "https://librums.com/backend/"; // Staging
    // return "https://librums.com/backend/testing/"; // Testing
    // return 'https://librums.com/backend/testing/'; // Testing
    return 'http://localhost:2001/';
    // return 'https://spotcodes.in/'; // Testing
    // return "http://18.209.143.118/backend/testing/"; // Testing
    // return "http://18.209.143.118/backend/dev/"; // Dev
  }
}

/*
Staging Url :- https://librums.com:2200/
Staging Image Url :- https://librums.com/backend/

Testing Url :- https://librums.com:2001/api
Testing Image Url :- http://18.209.143.118/backend/testing/

Dev Url :- http://18.209.143.118:8003/
Dev Image Url :- http://18.209.143.118/backend/dev/
*/
