<div class="app-content  my-3 my-md-5">
    <!-- <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div> -->
    <div class="page-container">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
        </div>
        <div class="row row-cards" *ngIf="isDashboardLoading">
            <div class="col-sm-12 col-lg-6 col-md-6 col-xl-3" *ngFor="let item of [1,2,3,4]">
                <app-loader type="placeholder" backgroundColor="#dbdbdb" height="112px" borderRadius="4px"></app-loader>
            </div>
        </div>
        <div class="row row-cards" *ngIf="!isDashboardLoading">
            <div class="col-sm-12 col-lg-6 col-md-6 col-xl-3 ">
                <div class="card card-img-holder">
                    <a [routerLink]="['/users']">
                        <div class="card-body custom_card_body card_bg_img fourth">
                            <div class="custom_img_card">
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </div>
                            <div class="user_card">
                                <p>{{ getreports?.users }}</p>
                                <h2> Users</h2>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 col-xl-3 ">
                <div class="card card-img-holder">
                    <a [routerLink]="['/manage-books']">
                        <div class="card-body custom_card_body card_bg_img first">
                            <div class="custom_img_card">
                                <i class="fa fa-book" aria-hidden="true"></i>
                            </div>
                            <div class="user_card">
                                <p>{{ getreports?.books }}</p>
                                <h2> Books</h2>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 col-xl-3 ">
                <div class="card card-img-holder">
                    <a [routerLink]="['/authors']">
                        <div class="card-body custom_card_body card_bg_img second">
                            <div class="custom_img_card">
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </div>
                            <div class="user_card">
                                <p>{{ getreports?.payments }}</p>
                                <h2> Authors</h2>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 col-xl-3 ">
                <div class="card card-img-holder">
                    <a [routerLink]="['/published-books']">
                        <div class="card-body custom_card_body card_bg_img third">
                            <div class="custom_img_card">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                            </div>
                            <div class="user_card">
                                <p>{{ getreports?.published }}</p>
                                <h2> Published</h2>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">Notifications</div>
                    </div>
                    <div id="example_wrapper" style="padding:20px;"
                        class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view h-100">
                        <div class="row">
                            <div class="col-sm-12">
                                <table id="notification" datatable class="table row-border hover" role="grid"
                                    aria-describedby="example_info">
                                    <thead>

                                        <tr role="row">
                                            <th style="width:30px;">#</th>
                                            <th class="wd-20p nosort" tabindex="0" aria-controls="example" rowspan="1"
                                                colspan="1" aria-label="Position: activate to sort column ascending"
                                                style="width: 321px;">
                                                Messages
                                            </th>
                                            <th>DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!isNotificationLoading">
                                        <tr
                                            *ngFor="
                                        let notification of notifications | paginate: { id: 'dashboard-notifications', itemsPerPage: notificationItemsPerPage, currentPage: notificationPage, totalItems: notificationTotalItem} ; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>
                                                <a *ngIf="notification.type == 'chapter'"
                                                    [routerLink]="['/viewchapter',notification.chapter_id]"> {{
                                                    notification.message | slice:0:100 }}</a>
                                                <a *ngIf="notification.type == 'book'"
                                                    [routerLink]="['/view-manage-content',notification.book_id]"> {{
                                                    notification.message | slice:0:100 }}</a>
                                            </td>
                                            <td>{{notification.created_at | date: "MMM d, y"}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="isNotificationLoading">
                                        <tr>
                                            <td colspan="4">
                                                <app-loader type="placeholder" height="16px"
                                                    [placeholderLength]="notificationItemsPerPage"
                                                    borderRadius="4px"></app-loader>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-center">
                                <pagination-controls class="pagintion-data" id="dashboard-notifications"
                                    (pageChange)="getNotificationData($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-12 col-xl-6">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">Feedback and Suggestions</div>
                    </div>
                    <div id="example_wrapper" style="padding:20px;"
                        class="dataTables_wrapper dt-bootstrap4 no-footer no-width user_tabel_view h-100">
                        <div class="row">
                            <div class="col-sm-12">
                                <table id="example" class="table row-border hover" role="grid"
                                    aria-describedby="example_info">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col"> USER</th>
                                            <th scope="col">MESSAGE</th>
                                            <th scope="col">DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!isFeedbackLoading">
                                        <tr
                                            *ngFor="let feedback of feedbacks | paginate: { id: 'dashboard-feedbacks', itemsPerPage: feedbackItemsPerPage, currentPage: feedbackPage, totalItems: feedbackTotalItem} ; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ feedback?.created_by?.username }}</td>
                                            <td><a [routerLink]="['/view-feedback',feedback._id]">{{ feedback.content |
                                                    slice:0:25 }}</a></td>
                                            <td>{{feedback.created_at | date: "MMM d, y"}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="isFeedbackLoading">
                                        <tr>
                                            <td colspan="4">
                                                <app-loader type="placeholder" height="16px"
                                                    [placeholderLength]="feedbackItemsPerPage"
                                                    borderRadius="4px"></app-loader>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-center">
                                <pagination-controls class="pagintion-data" id="dashboard-feedbacks"
                                    (pageChange)="getFeedbacks($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>