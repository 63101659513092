import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EditstoriesService } from './editstories.service';
import { SeoserviceService } from '../seoservice.service';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import { HandleEditService } from '../add-book-cover/handle-edit.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { environment } from 'src/environments/environment';

import _ from "lodash";

declare var $: any;

export interface tag {
  display: string;
  value: string;
}

@Component({
  selector: 'app-write',
  templateUrl: './edit-stories.component.html',
  styleUrls: ['./edit-stories.component.sass'],
})
export class EditStoriesComponent implements OnInit {
  myForm:FormGroup;
  bookData: any;
  mature: boolean;
  public _settings: IDropdownSettings;
  defaultSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 25,
    allowSearchFilter: true
  }
  public baseUrl;
  constructor(
    private editStoriesService: EditstoriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _seoService: SeoserviceService,
    private helper: HelperService,
    private toaster: ToastrService,
    private handleEdit: HandleEditService,
    private fb: FormBuilder
  ) {
    this.baseUrl = BaseUrl.image;
  }
  sketchBookId: string = environment.sketchBookId;
  videoBookId: string = environment.videoBookId;
  book_id: string;
  /**
   * START - Edit Book Cover variables
   */
  public canvas = true;
  public imageCanvasUrl: any;
  public bookTitle = !this.canvas;
  // public bookTitle = !this.canvas;
  public finalCanvas = !this.canvas;
  /**
   * END - Edit Book Cover variables
   */

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  matureContent = false;
  public loader: boolean = true;

  public url: any = 'https://testing.librums.com/assets/img/rectangle1.svg';
  selectedFile = null;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: tag[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push({ display: value.trim(), value: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  setMature() {
   this.mature = !this.mature;
  }

  remove(tag: tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  fruits: string[] = ['Lemon'];

  submitted = false;
  onSubmit(form: NgForm) {
    if (form.valid || !this.tags.length) {
      form.value.book_id = this.book_id;
      form.value['cover_image'] =
        this.url == 'https://testing.librums.com/assets/img/rectangle1.svg'
          ? ''
          : this.url;
      form.value['is_published'] = 1;
      form.value['tags'] = this.tags;
      form.value['tagline'] = form.value.tagline;
      form.value['not_for_below_eighteen'] = String(
        this.mature
      );
      let selctedGenre = []
      _.filter(this.genreVal, (item) => { selctedGenre.push(item.value)})
      form.value['genre'] = selctedGenre
      this.editStoriesService.updateStory(form.value).subscribe(
        (res: any) => {
          if (res.status) {
            this.toaster.success(res.message);
            if (this.isBlocked) {
              setTimeout(() => {
                this.router.navigate(['/contact-us']);
              }, 1000); //5s
            } else {
              setTimeout(() => {
                this.router.navigate([
                  `/profile/mybookdetails/${this.book_id}`,
                ]);
              }, 1000); //5s
            }
          } else {
            this.toaster.error(res.message);
          }
        },
        (error) => {
          console.log('error', error);
        }
      );
    } else {
      this.submitted = true;
    }
  }

  /**
   * START - Get Image Canvas
   */

  getImageCanvas(event: any) {
    this.imageCanvasUrl = event;
    this.canvas = false;
    this.bookTitle = !this.canvas;
  }

  getFinalImageCanvas(event: any) {
    this.imageCanvasUrl = event;
    this.canvas = false;
    this.bookTitle = false;
    this.finalCanvas = !this.bookTitle;
  }

  editBook;

  /**
   * END - Get Image Canvas
   */
  updateForm: any;
  isBlocked: any;
  loadEditBook: any;
  isTagValid: boolean;
  genreVal = [];
  genreError: boolean;
  dropdownSettings = {}
  genreList = []

  onItemSelect(item: any) { this.genreVal.push(item.value) }

  onDeSelectAll () { this.genreVal = [] }

  onSelectAll(items: any) { _.filter(items, (itm) => { this.genreVal.push(itm.value) }) }

  onItemDeSelect = (item: any) => { _.remove(this.genreVal, function(n) { return n === item.value}) }

  ngOnInit(): void {
    this.genreError = false
    this.dropdownSettings = this.defaultSettings
    this.genreList = [
      { value: "5ea1389319e995193b5e6b1d", name: 'Action' },
      { value: "5eade0f737fe886eae35c7d4", name: 'Adventure' },
      { value: "5eade10437fe886eae35c7d5", name: 'ChickLit' },
      { value: "5eade10c37fe886eae35c7d6", name: 'Fanfiction' },
      { value: "5eade11a37fe886eae35c7d7", name: 'Fantasy' },
      { value: "5eade12137fe886eae35c7d8", name: 'General Fiction' },
      { value: "5eade13d37fe886eae35c7d9", name: 'Historical Fiction' },
      { value: "5eade14737fe886eae35c7da", name: 'Horror' },
      { value: "5eade15237fe886eae35c7db", name: 'Humor' },
      { value: "5eade15b37fe886eae35c7dc", name: 'Mystery / Thriller' },
      { value: "5eade16537fe886eae35c7dd", name: 'Non-Fiction' },
      { value: "5eade17237fe886eae35c7de", name: 'Paranormal' },
      { value: "5eade1e237fe886eae35c7df", name: 'Poetry' },
      { value: "5eade1ee37fe886eae35c7e0", name: 'Random' },
      { value: "5eade1f237fe886eae35c7e1", name: 'Romance' },
      { value: "5eade1fc37fe886eae35c7e2", name: 'Science Fiction' },
      { value: "5eade20437fe886eae35c7e3", name: 'Short Story' },
      { value: "5eade20e37fe886eae35c7e4", name: 'Spiritual' },
      { value: "5eade21837fe886eae35c7e5", name: 'Vampire' },
      { value: "5eade23d37fe886eae35c7e6", name: 'Teen Fiction' },
      { value: "5eade25937fe886eae35c7e7", name: 'Werewolf' },
    ];
    this.updateForm = {
      title: ['', Validators.required],
      description: ['', Validators.required],
      content_type: ['', Validators.required],
      // genre: ['', Validators.required],
      copyright: [''],
      tags: ['', Validators.required],
      not_for_below_eighteen: ['false'],
      is_published: [1],
      cover_image: [''],
      book_id: [''],
    };

    this.book_id = this.activatedRoute.snapshot.paramMap.get('id');

    this.editStoriesService.getEditBook({ book_id: this.book_id }).subscribe(
      (res: any) => {
        if (res.status) {
          this.bookData = res.data
          this.mature = this.bookData.not_for_below_eighteen

          let temp = []
          for (let id of res.data.genre) {
            _.filter(this.genreList, (item) => {
              if (item.value === id) {
                temp.push(item)
              }
            })
          }
          this.genreVal = temp
          const tags = [];
          res.data.tags.forEach(function (item) {
            tags.push({ display: item, value: item });
          });
          this.myForm = this.fb.group({
              city: [temp]
          });
          res.data.tags = tags;
          let str = res.data.description;
          let removestr = 'So, ' + res.data.title + ':';
          let desc = str.replace(removestr, '');
          res.data.description = desc;
          this.isBlocked = res.data.is_blocked;
          this.loadEditBook = res.data;
          this.tags = this.loadEditBook.tags;
          this.url = res.data.cover_image;
          this.editBook = true;

          this.handleEdit.bookImage.subscribe((result) => {
            if (result.split('/').includes('uploads')) {
              var { cover_image, editable } =
                this.helper.appendBaseUrlToImage(result);
              this.url = cover_image === 'uploads/assets/img/rectangle1.svg' ? this.bookData.cover_image : cover_image ;
              this.editBook = editable;
            } else {
              this.url = this.bookData.cover_image
            }
          });

          this._seoService.updateTitle(this.loadEditBook.title);
          this.loader = false;
        }
      },
      (error) => {}
    );
    this.handleEdit.setMode(`editstories/${this.book_id}`);
  }
}
