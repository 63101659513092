import { Component, OnInit } from '@angular/core';
import { ManageSliderService } from './manage-slider.service';
import { ToastrService } from 'ngx-toastr';
import { BaseUrl } from "./../../../base-url";
@Component({
  selector: 'app-manage-slider',
  templateUrl: './manage-slider.component.html',
  styleUrls: ['./manage-slider.component.sass']
})
export class ManageSliderComponent implements OnInit {
  temp: boolean;
  getContent: any;
  showLoader = false;
  baseimage: any;
  delete: any;
  delId: any;
  constructor(private contentService: ManageSliderService, private toaster: ToastrService) {

    this.baseimage = BaseUrl.image;
  }

  ngOnInit() {
    this.showLoader = true;
    this.loadSlider();
  }

  loadSlider() {
    // alert('dssf');
      this.contentService.getSliders().subscribe(
      (res: any) => {
        // alert('if');
        this.getContent = res.data;
        this.temp = true;
        this.showLoader = false;
      },
      error => {
        alert('else');
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
  onClick12(event) {
    this.delId = event;
  }
  onClick(event) {
    this.contentService.deleteSlider(event).subscribe(
      (res: any) => {
        // tslint:disable-next-line: semicolon
        this.delete = res.status;
        this.toaster.success('Slider deleted successfully.')
        this.loadSlider();
      },
      error => {
        // tslint:disable-next-line: quotemark
        console.log("ERROR");
      }
    );
  }
}
