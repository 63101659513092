<div class="wrapper">
  <div id="content-page" class="content-page">
    <!-- <div class="col-lg-12"> -->
    <div class="iq-card iq-card-block iq-card-stretch iq-card-height p-2">
      <app-loader *ngIf="showLoader"></app-loader>
      <!-- <input
          style="display: block"
          type="file"
          (change)="onSelectFile($event)"
          #file
      /> -->
      <div *ngIf="!showLoader" class="__container">
        <div *ngIf="isCurrentUser && !coverImageLoading" class="edit-container">
        </div>
        <div class="cover_image-container" *ngIf="authordata.cover_image && !coverImageLoading">
          <img
            class="profile-banner"
            [src]="baseimage + authordata.cover_image"
            alt=""
          />
          <label class="upload-photo-label">
            <img loading="lazy" _ngcontent-mnc-c425="" alt="" src="../../assets/Slider/images/icons/Edit pencil@2x.svg" class="edit-chapter-icon __web-inspector-hide-shortcut__">
            <input
                type="file"
                class="upload-photo"
                (change)="onSelectFile($event, 'cover')"
                #cover_image
            />
          </label>
        </div>
        <ng-container *ngIf="coverImageLoading">
          <app-loader type="placeholder" height="287px"></app-loader>
        </ng-container>
        <div class="cover_image-container" *ngIf="!authordata.cover_image">
          <img
            class="profile-banner"
            src="https://blogs.sas.com/content/sastraining/files/2015/03/black_background.png"
            alt=""
          />
          <label class="upload-photo-label">
            <img loading="lazy" _ngcontent-mnc-c425="" alt="" src="../../assets/Slider/images/icons/Edit pencil@2x.svg" class="edit-chapter-icon __web-inspector-hide-shortcut__">
            <input
                type="file"
                class="upload-photo"
                (change)="onSelectFile($event, 'cover')"
                #cover_image
            />
          </label>
        </div>
        <div class="profile-user-info-block">
          <div class="user-image-container" style="z-index: 1000">
            <label *ngIf="isCurrentUser" class="upload-photo-label-container">
              <img loading="lazy" _ngcontent-mnc-c425="" alt="" src="../../assets/Slider/images/icons/Edit pencil@2x.svg"
                 class="edit-chapter-icon icon-edit-user-data __web-inspector-hide-shortcut__">
              <input type="file" class="upload-photo" (change)="onSelectFile($event, 'profile')" #profile_image  />
           </label>
           <img
             *ngIf="authordata.image && !profileImageLoading"
             class="user__image rounded-circle"
             [src]="baseimage + authordata.image"
             onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
             alt=""
           />

           <div class="user__image rounded-circle" *ngIf="profileImageLoading" >
            <!-- [count]="1" -->
              <ngx-skeleton-loader appearance="circle" [theme]="{
                'background-color': '#f0f0f0',
                    width: '195px',
                    height: '195px'
                  }">
              </ngx-skeleton-loader>
           </div>

           <!-- 215px -->

           <!-- <img
             *ngIf="!authordata.image"
             class="user__image rounded-circle"
             src="https://cdn2.iconfinder.com/data/icons/avatars-99/62/avatar-370-456322-512.png"
             alt=""
           /> -->
          </div>
          <div class="user-metadata d-flex justify-content-between">
            <div class="mb-5">
              <span class="user__username">@{{ authordata.username }}</span>
              <br /><br /><br />
              <ng-container *ngIf="!isCurrentUser">
                <button
                  *ngIf="isFollowed"
                  (click)="follow(2)"
                  class="following__unfollow font-italic"
                  style="font-size: 16px"
                >
                  Unfollow
                </button>
                <button
                  *ngIf="!isFollowed"
                  (click)="follow(1)"
                  class="following__unfollow font-italic"
                  style="font-size: 16px"
                >
                  Follow
                </button>
              </ng-container>
            </div>

            <div
              class="followers-following-books d-flex justify-content-between"
            >
              <div class="user__following">
                <span>{{ authordata.noOfFollowing }}</span>
                <span class="following">following</span>
              </div>
              <div class="user__followers">
                <span>{{ authordata.noOfFollower }}</span>
                <span class="followers">followers</span>
              </div>
              <div class="user__books">
                <span>{{ authordata.myStories.length }}</span>
                <span class="books">books</span>
              </div>
            </div>
          </div>

          <div class="user__profile__details">
            <div class="author__profile">
              <div></div>
              <div style="margin-top: 20px">
                <mat-tab-group
                  class="tab-menu"
                  dynamicHeight
                  dynamicLength
                  mat-align-tabs="center"
                  [(selectedIndex)]="selectedIndex"
                  (selectedIndexChange)="onMatTabChanged()"
                >
                  <mat-tab label="About">
                    <app-about [authorData]="authordata"></app-about>
                  </mat-tab>
                  <mat-tab label="Wall">
                    <app-wall [authorData]="authordata"></app-wall>
                  </mat-tab>
                  <mat-tab label="Edit Profile" *ngIf="isCurrentUser">
                    <app-profile [userId]="userdata?.data?._id"></app-profile>
                  </mat-tab>
                  <mat-tab label="Following">
                    <app-following [authorData]="authordata"></app-following>
                  </mat-tab>
                  <mat-tab label="Followers">
                    <app-followers [authorData]="authordata"></app-followers>
                  </mat-tab>
                </mat-tab-group>
              </div>
              <!-- <div class="media-carousel-container">
                <div *ngIf="selectedIndex === 1">
                  <div class="swiper-container border myMedias">
                    <div class="swiper-wrapper">
                      <ng-container *ngFor="let data of authordata.myPosts">
                        <div
                          *ngIf="data.image"
                          class="swiper-slide p-3"
                          (click)="openImage(data.image)"
                        >
                          <img
                            style="
                              height: 100px;
                              width: 100%;
                              object-fit: cover;
                            "
                            src="{{ baseimage }}{{ data.image }}"
                            class="border media-carousel"
                          />
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>

<app-footer></app-footer>
