<div class="wrapper">
  <div class="wrapper">
    <div id="content-page" class="content-page">
      <!-- <app-breadcrumbs></app-breadcrumbs> -->
      <!-- <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb> -->

      <!-- <app-breadcrumbs></app-breadcrumbs> -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer></app-footer>
