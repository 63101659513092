<div class="wrapper">
  <div id="content-page" class="content-page">
    <div class="col-lg-12 mt-5">
      <img
        src="assets/images/feedback.png"
        width="100%"
      />
    </div>
    <div class="col-lg-12">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
        <div class="wraper">
          <section>
            <div class="container">
              <div class="about_heading follower change_password feedback mt-0">
                <h2 class="feedback-and-suggestions text-center">
                  Feedback and Suggestions
                </h2>
                <form
                  #reportSubmit="ngForm"
                  class="issue_report all_custom_placeholders"
                  (ngSubmit)="feedbackSubmit(reportSubmit)"
                >
                  <div class="feedback-input-container">
                    <div class="feedback-subject">
                      <input
                        class="chapter-title feedback-input"
                        type="text"
                        ngModel
                        name="title"
                        #title="ngModel"
                        placeholder="Enter the subject(max 100 characters)"
                        maxlength="100"
                      />
                    </div>
                    <div *ngIf="(title.touched && !title.valid) || (submitted && !title.value)">
                      <small class="text-danger pl-3">Title is required</small>
                   </div>
                  </div>
                  <div class="label_input">
                    <textarea
                      cols="30"
                      class="chapter-title"
                      rows="5"
                      ngModel
                      name="message"
                      #message="ngModel"
                      placeholder="Enter your feedback and suggestions"
                      maxlength="1000"
                      (onchange)=onChangeTextAria()
                    ></textarea>
                    <div *ngIf="(message.touched && !message.valid) || (submitted && !message.value)">
                      <small class="text-danger pl-3">Feedback and suggestions content is required.</small>
                   </div>
                  </div>
                  <div class="login_btn">
                    <button class="site_btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
