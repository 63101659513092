import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseUrl } from '../base-url';
import { GlobalService } from '../global.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  constructor() {}

  private user = new BehaviorSubject<any>(Object);
  public userData = this.user.asObservable();

  public updateBookShelf = new Subject<void>();
  public refetchNotification = new Subject<void>();

  // getUserInfo(): Observable<Object> {
  //   const u = this.helper.getUserData();

  //   this.globalService.setglobalUserData(u);
  //   this.user.next(u);
  //   return this.userData;
  // }
}
