import { BaseUrl } from "./../../../base-url";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class EditUsersService {
  usersdata: any;
  usersimage: any;
  updateuser: any;
  baseimage: any;
  base: any;
  constructor(private http: HttpClient) {
    this.baseimage = BaseUrl.imageApi;
    this.base = BaseUrl.admin;
  }

  getUserData(id) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(
      this.base + `/getUserData`,
      { user_id: id },
      {
        headers
      }
    );
  }

  user_image(image) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": token
    });
    return this.http.post(this.baseimage + `/user_image`, image, {
      headers
    });
  }

  cover_image(image) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": token
    });
    return this.http.post(this.baseimage + `/cover_image`, image, {
      headers
    });
  }

  updateUser(data) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    // tslint:disable-next-line: align
    return this.http.post(this.base + `/updateUser`, data, {
      headers
    });
  }
}
