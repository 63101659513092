import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseUrl } from 'src/app/base-url';

@Component({
  selector: 'app-image-post',
  templateUrl: './image-post.component.html',
  styleUrls: ['./image-post.component.sass'],
})
export class ImagePostComponent implements OnInit {
  baseimage: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.baseimage = BaseUrl.image;
  }

  ngOnInit(): void {
    console.log('data image post', this.data);
    if (!this.data.uploadedImage) {
      this.data.uploadedImage = false;
    }
  }
}
