import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { CreateStoriesService } from '../create-stories/create-stories.service';
import { EditstoriesService } from '../edit-stories/editstories.service';

@Component({
  selector: 'app-upload-book',
  templateUrl: './upload-book.component.html',
  styleUrls: ['./upload-book.component.sass']
})
export class UploadBookComponent implements OnInit {

  constructor(
    private createStoriesService: CreateStoriesService,
    private activatedRoute: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private editStoriesService: EditstoriesService,
    private router: Router) { }

  fileName: string;
  loadBtn: boolean = false;
  bookData: any;
  book_id: string;



  ngOnInit(): void {
    this.book_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.bookData = localStorage.getItem('mybooks');
  }

  readURL(file) {
    this.loadBtn = true
    let input = file.srcElement;
    let fileName = input.files[0].name;
    this.fileName = 'File Name : ' + fileName;
    this.createStoriesService.upload_file(file, this.book_id).subscribe((result: any) => {
      let new_tags:string[] = []
      for(let i = 0; i<result.data.tags.length; i++ ){
        new_tags.push(result.data.tags[i])
      }
      console.log(!result.data.isUploadedBook)
      const new_book_data = {...result.data, isUploadedBook: !result.data.isUploadedBook, is_published: 3, book_id: this.book_id, not_for_below_eighteen: `${result.data.not_for_below_eighteen}`}
      this.editStoriesService.updateStory(new_book_data, new_tags).subscribe(
        (res: any) => {
         localStorage.setItem('path', res.data.uploadedBook)
         $('.btn-close').trigger('click')
          this.router.navigate([`/read-book/${this.book_id}`]);
        },
        (error) => {
          console.log('error', error);
        }
      );
      // console.log('new_book_data', new_book_data)
      // console.log('result', result)
      // console.log('result.data.tags', result.data.tags.length)
    }, (err) => {
      this._snackbar.open(err.message, 'Ok', { duration: 2000 });
    });

  }

}
