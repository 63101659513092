import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from '../base-url';
import { HelperService } from '../helper.service';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class EditProfileInfoService {
  userdata: any;
  baseurl: any;
  usersimage: any;
  baseapi: any;
  headers;
  usersdata: any;
  _baseimage;
  base: any;
  constructor(private http: HttpClient, private helper: HelperService) {
    this.baseurl = BaseUrl.frontend;
    this._baseimage = BaseUrl.imageApi;
    this.headers = this.helper.getHeader();
  }

  private author = new BehaviorSubject<Object>(null);
  public authorData = this.author.asObservable();

  setAuthorData(data) {
    this.author.next(data);
  }
  getUserData(id) {
    this.usersdata = localStorage.getItem('currentUser');
    let token = JSON.parse( crypto.AES.decrypt(this.usersdata, environment.secretKey).toString( crypto.enc.Utf8 ) ).data.token

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": token
    });
    return this.http.post(
      this.base + `/getUserData`,
      { user_id: id },
      {
        headers
      }
    );
  }
  getAuthor(author_id: string) {
    return this.http.post(
      this.baseurl + `/getAuthorProfile`,
      { author_id },
      { headers: this.headers }
    );
  }

  getAuthorCount(author_id: string) {
    return this.http.post(
      this.baseurl + `/getAuthorStoriesCount`,
      { author_id },
      { headers: this.headers }
    );
  }

  followAuther(data) {

    return this.http.post(this.baseurl + `/followUser`, data, {
      headers: this.headers,
    });
  }

  savePost(data) {
    return this.http.post(this.baseurl + `/savePost`, data, {
      headers: this.headers,
    });
    // .pipe(map(this.setAuthorData));
  }

  post_image(image) {
    var userdata = localStorage.getItem('currentUser');
    const decryptUser = JSON.parse(
      crypto.AES.decrypt(userdata, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );

    const headers = new HttpHeaders({
      'x-access-token': decryptUser.data.token,
      'image': image
    });
    headers.delete('Content-Type');
    return this.http.post(this._baseimage + `post_image`, image, {
      headers,
    });
    // .pipe(map(this.setAuthorData));
  }

  bacground_image(image) {
    this.usersimage = JSON.parse(localStorage.getItem("currentUser"));
    const headers = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": this.usersimage.data.token
    });
    return this.http.post(this.baseapi + `user_image`, image, {
      headers
    });
  }

  getFollower(userId: string) {
    return this.http.post(
      `${this.baseurl}/getAuthorFollower`,
      { userId: userId },
      {
        headers: this.headers,
      }
    );
  }

  getFollowing(userId: string) {
    return this.http.post(
      `${this.baseurl}/getAuthorFollowing`,
      { userId: userId },
      {
        headers: this.headers,
      }
    );
  }
}
