<div class="page">
    <div *ngIf="showLoader" class="loader"><img loading="lazy" src="/assets/img/loader.svg"></div>
<div class="page-main">
    <!-- Sidebar menu-->
    <div class="app-content  my-3 my-md-5" *ngIf="getChapter">
        <div class="page-container">
                <div class="page-header">
                <ol class="breadcrumb mb-3">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" >Dashboard</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                           Notification
                        </li>
                    </ol>
                </div>
                <div class="">
                        <div class="card p-4">
                            <div class="row mt-6">
                                    <div class="col-md-12 pl-6 pr-6">
                                        <div class="row carousel_slides over_flow pt-6">
                                            <div class="col-md-4 text-center carousel_slides_img">
                                                <img loading="lazy" class="admin_image" *ngIf="getChapter.book_image !== ''"
                                                    src="{{ baseimage }}{{ getChapter.book_image }}"
                                                    alt=""
                                                    />
                                                    <img loading="lazy" class="admin_image" *ngIf="getChapter.book_image == ''"
                                                    src="assets/img/rectangle1.svg"
                                                    alt=""
                                                />
                                           </div>
                                           <div class="col-md-8 carousel_slides_txt p-0 m-0">
                                               <div class="start_reading"><h1> {{ getChapter.book_name }}</h1></div>
                                                <div class="carousel_slides_txt_profile_img">
                                                    <img loading="lazy" class="admin_image1" *ngIf="getChapter.user_image !== ''"
                                                    src="{{ baseimage }}{{ getChapter.user_image }}"
                                                    alt=""
                                                  />
                                                  <img loading="lazy" class="admin_image1" *ngIf="getChapter.user_image == ''"
                                                    src="assets/images/img_user1.svg"
                                                    alt=""
                                                />
                                                <a [routerLink]="[
                                                '/edit-users',
                                                getChapter.created_by
                                                ]"><span>By</span> {{ getChapter.user_name }}</a>
                                               </div>

                                               <div class="txt_silde_profile txt_silde_profile_top">
                                                    <br><p>Last updated on: {{ getChapter.updated_at | date }} </p>
                                               </div>
                                               <div class="slide_botton_txt mt-3">
                                                    <p  [innerHTML]="getChapter.book_desc | safeHtml: 'html'"></p>
                                               </div>
                                            </div>
                                            </div>
                                        </div>
                                <div class="col-md-12">
                                        <div class="promocode_txt text-center m-0 mt-4">
                                            <template *ngFor="let chapter of getChapter.all_chapters; let i = index">

                                                <br><br><br><br><br><br><h1 *ngIf="chapter._id === getChapter._id">Chapter {{i+1}}: {{ getChapter.chapter_name }}</h1>
                                            </template>
                                           </div>
                                        <div class="cover_img2" *ngIf="getChapter.image">
                                                <div class="cover_img_upload2">
                                                    <label for="edit_cover_img_upload">
                                                        <img loading="lazy" src="{{ baseimage }}{{ getChapter.image }}" alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-12" *ngIf="getChapter.video">
                                                <div class="promocode_txt">
                                                    <!-- <h2>Video</h2> -->
                                                    <iframe width="600" height="420"
                                                    [src]='this.safeUrl'>
                                                    </iframe>
                                                </div>
                                            </div>
                                    <div class="promocode_txt promocode_txt pl-6 pr-6">
                                        <p  id="tester_class" [innerHTML]="getChapter.content | safeHtml: 'html'"></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
        </div>
    </div>
</div>
</div>
