<div class="wrapper">
  <div id="content-page" class="content-page ">
    <div class="row align-self-end m-0">
      <div class="w-100">
        <app-loader *ngIf="showLoader"></app-loader>
        <div
          *ngIf="!showLoader"
          class="
            iq-card iq-card-block iq-card-stretch iq-card-height
            myStories__container
          "
        >
          <img
            class="myStories__banner"
            src="assets/images/myStories.png"
            alt=""
          />

          <div class="myStores__main__container mt-5">
            <div class="myStories__main d-flex flex-column justify-content-center w-100">
              <div class="myStories__title__container">
                <div class="myStories__title">
                  <div><h1>Continue Writing?</h1></div>
                  <span>Don't stop, give us a masterpiece</span>
                </div>
                <hr />
              </div>
              <!-- <div class="myStrories__write__container"> -->
              <div class="d-flex justify-content-center">
                <div class="myStrories__write__container create-write">
                  <div class="myStories__write">
                      <div
                        routerLink="/write"
                        class="
                          myStories__add_image
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        [style.backgroundImage]="'url(assets/images/dummy.png)'"
                      >
                        <img
                          class="myStories__plus_icon"
                          src="assets/images/Plus graident.svg"
                          alt=""
                          style="filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);"
                        />
                      </div>
                      <div class="add__new__book">
                        <div>
                          <h1 class="font-italic">ADD NEW BOOK</h1>
                          <p class="font-italic myStories__description">
                            Let's Start with your masterpiece
                          </p>
                        </div>
                        <div class="add-book-button">
                          <button routerLink="/write" class="btn-cyan fw-600 add-new-book-button"> Add </button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="myStories__stories_container mt-5">
                <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onTabChange($event)" animationDuration="0ms">
                  <mat-tab [label]="'Published (' + total_published + ')'">
                    <ng-container *ngIf="is_book_loading" [ngTemplateOutlet]="book_loading"></ng-container>
                    <ng-container *ngIf="!is_book_loading">
                      <div
                      class="d-flex justify-content-center"
                      *ngFor="let data of publishedStories | paginate: { id: 'published-books-pagination', itemsPerPage: 10, currentPage: page_no, totalItems: total_published} ; let i = index"
                    >
                      <div
                        class="myStrories__write__container"
                        [style.background]="backgroundColor[i % 2]"
                      >
                        <div class="myStories__write">
                          <div>
                            <a
                              *ngIf="data.cover_image !== ''"
                              (click)="gotToBookDetails('Published', data._id)"
                              [routerLink]="[
                                '../profile/mybookdetails',
                                data._id
                              ]"
                            >
                              <img
                                *ngIf="data.cover_image !== ''"
                                class="myStories__add_image"
                                src="{{ baseimage }}{{ data.cover_image }}"
                                alt=""
                              />
                            </a>
                            <a
                              *ngIf="data.cover_image === ''"
                              (click)="gotToBookDetails('Published', data._id)"
                              [routerLink]="[
                                '../profile/mybookdetails',
                                data._id
                              ]"
                            >
                              <img
                                *ngIf="data.cover_image == ''"
                                class="myStories__add_image"
                                src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle1.svg"
                                alt=""
                              />
                              <!-- https://api-dev-v1.librums.com/uploads/assets/img/rectangle1.svg -->
                            </a>
                          </div>
                          <div class="book-short-info-container">
                            <div class="book-short-info">
                              <div>
                                <h1 class="font-italic myStories__published-drafted">
                                  {{ data.title }}
                                </h1>
                                <span class="font-italic myStories__published-drafted"
                                  ><< {{ data.genre }} >></span
                                >
                                <div class="myStories__published-drafted">
                                  <h5 class="d-flex align-items-center" routerLink="/profile/pro">
                                    <img
                                      class="myStories__author_image rounded-circle"
                                      [src]="baseimage + userData.image"
                                      onerror="this.src = 'https://api-prod-v1.librums.com/uploads/assets/img/profile-placeholder.jpg'"
                                    />
                                    <h5
                                      class="
                                        ml-2
                                        d-flex
                                        align-items-center
                                        font-italic
                                        cursor-pointer
                                        text-underline-on-hover
                                        text-center
                                        author-name
                                      "
                                    >
                                      @{{ userData.username }}
                                    </h5>
                                  </h5>
                                </div>
                                <div
                                  class="
                                    font-italic
                                    myStories__description
                                    myStories__published-drafted
                                  "
                                >
                                  {{ data.description }}
                                </div>
                              </div>
                              <div class="ratings-container d-flex mt-4">
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../assets/images/add_story_eye.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.views }}</span
                                  >
                                </div>
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../../../assets/images/like_heard.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.likes }}</span
                                  >
                                </div>
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../assets/Slider/images/Share.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.no_of_shares }}</span
                                  >
                                </div>
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../assets/Slider/images/icons/open-book.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.no_of_chapter }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </ng-container>

                    <div class="my-5 d-flex justify-content-center text-right">
                      <pagination-controls class="pagintion-data" id="published-books-pagination" (pageChange)="onPageChange(selectedIndex + 1, $event)">
                      </pagination-controls>
                    </div>
                  </mat-tab>
                  <mat-tab [label]="'Drafted (' + total_drafted + ')'">
                    <ng-container *ngIf="is_book_loading" [ngTemplateOutlet]="book_loading"></ng-container>
                    <ng-container *ngIf="!is_book_loading">
                      <div
                       class="d-flex justify-content-center"
                        *ngFor="let data of draftedStories | paginate: { id: 'drafted-book-pagination', itemsPerPage: 10, currentPage: page_no, totalItems: total_drafted}; let i = index">
                      <div
                        class="myStrories__write__container"
                        [style.background]="backgroundColor[i % 2]"
                      >
                        <div class="myStories__write">
                          <div>
                            <a
                              *ngIf="data.cover_image !== ''"
                              (click)="gotToBookDetails('Drafted', data._id)"
                              [routerLink]="[
                                '../profile/mybookdetails',
                                data._id
                              ]"
                            >
                              <img
                                *ngIf="data.cover_image !== ''"
                                class="myStories__add_image"
                                src="{{ baseimage }}{{ data.cover_image }}"
                                alt=""
                              />
                            </a>
                            <a
                              *ngIf="data.cover_image === ''"
                              (click)="gotToBookDetails('Drafted', data._id)"
                              [routerLink]="[
                                '../profile/mybookdetails',
                                data._id
                              ]"
                            >
                              <img
                                *ngIf="data.cover_image == ''"
                                class="myStories__add_image"
                                src="https://api-dev-v1.librums.com/uploads/assets/img/rectangle1.svg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="book-short-info-container">
                            <div class="book-short-info">
                              <div>
                                <h1 class="font-italic myStories__published-drafted">
                                  {{ data.title }}
                                </h1>
                                <span class="font-italic myStories__published-drafted"
                                  ><< {{ data.genre }} >></span
                                >
                                <div class="myStories__published-drafted">
                                  <h5 class="d-flex  align-items-center" routerLink="/profile/pro">
                                    <img
                                      class="myStories__author_image rounded-circle"
                                      src="{{ baseimage }}{{ userData.image }}"
                                      alt=""
                                    />
                                    <h5
                                      class="
                                        ml-2
                                        d-flex
                                        align-items-center
                                        font-italic
                                        cursor-pointer
                                        text-underline-on-hover
                                        text-center
                                        author-name
                                      "
                                    >
                                      @{{ userData.username }}
                                    </h5>
                                  </h5>
                                </div>
                                <div
                                  class="
                                    font-italic
                                    myStories__description
                                    myStories__published-drafted
                                  "
                                >
                                  {{ data.description }}
                                </div>
                              </div>
                              <div class="ratings-container d-flex mt-4">
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../assets/images/add_story_eye.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.views }}</span
                                  >
                                </div>
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../../../assets/images/like_heard.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.likes }}</span
                                  >
                                </div>
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../assets/Slider/images/Share.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.no_of_shares }}</span
                                  >
                                </div>
                                <div class="book-interation mr-4">
                                  <img
                                    class="book-details-icons mr-3"
                                    src="../../assets/Slider/images/icons/open-book.svg"
                                    alt=""
                                  />
                                  <span
                                    class="
                                      myStories__published-drafted
                                      views-hearts-shares-bookshelves
                                      font-italic
                                    "
                                    >{{ data.no_of_chapter }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </ng-container>
                    <div class="my-3 text-right">
                      <pagination-controls id="drafted-book-pagination" (pageChange)="onPageChange(selectedIndex + 1, $event)">
                      </pagination-controls>
                    </div>
                  </mat-tab>
                  <mat-tab label="Uploaded Book">
                    <div class="d-flex justify-content-center" *ngFor="let data of uplodedBooksList; let i = index">
                      <div
                        class="myStrories__write__container"
                        [style.background]="backgroundColor[i % 2]"
                      >
                        <div class="myStories__write">
                          <div>
                            <a
                              *ngIf="data.cover_image !== ''"
                              (click)="readBook(data._id)"
                              [routerLink]="[
                                '../profile/mybookdetails',
                                data._id
                              ]"
                            >
                              <img
                                *ngIf="data.cover_image !== ''"
                                class="myStories__add_image"
                                src="{{ baseimage }}{{ data.cover_image }}"
                                alt=""
                              />
                            </a>
                            <a
                              *ngIf="data.cover_image === ''"
                              (click)="readBook(data._id)"
                              [routerLink]="[
                                '../profile/mybookdetails',
                                data._id
                              ]"
                            >
                              <div
                                *ngIf="data.cover_image == ''"
                                class="
                                  image-tile
                                  patahani-cover
                                  myStories__add_image
                                "
                              >
                                <h2 class="patahani-h2">{{ data.title }}</h2>
                              </div></a
                            >
                          </div>
                          <div class="ml-5 d-flex flex-column justify-content-between">
                            <div class="d-flex flex-column">
                              <h1 class="font-italic myStories__published-drafted">
                                {{ data.title }}
                              </h1>
                              <span class="font-italic myStories__published-drafted"
                                ><< {{ data.genre }} >></span
                              >
                              <div class="myStories__published-drafted">
                                <h5 class="d-flex" routerLink="/profile/pro">
                                  <img
                                    class="myStories__author_image rounded-circle"
                                    src="{{ baseimage }}{{ userData.image }}"
                                    alt=""
                                  />
                                  <h5
                                    class="
                                      ml-3
                                      mt-1
                                      font-italic
                                      text-underline-on-hover
                                      cursor-pointer
                                    "
                                  >
                                    @{{ userData.username }}
                                  </h5>
                                </h5>
                              </div>
                              <p
                                class="
                                  font-italic
                                  myStories__description
                                  myStories__published-drafted
                                "
                              >
                                {{ data.description }}
                              </p>
                            </div>
                            <div class="ratings-container d-flex mt-4">
                              <div class="mr-4">
                                <button (click)="readBook(data._id)" class="btn-cyan fw-600 add-new-book-button">
                                  Browse
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #book_loading>
  <div class="my-5" align="center">
    <div class="spinner-border text-primary" style="height: 5rem; width: 5rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<app-footer></app-footer>
