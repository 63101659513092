import { Component, OnInit } from "@angular/core";
import { ChangePasswordService } from "./change-password.service";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from './must-match';
declare var $: any;
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.sass"]
})
export class ChangePasswordComponent implements OnInit {
  changepasswrodForm: any;
  loading = false;
  submitted = false;
  showMsg = false;
  errorMsg = "";
  showLoader = false
  constructor(
    private changepassword: ChangePasswordService,
    private formbuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    // this.showLoader = true;
    this.changepasswrodForm = this.formbuilder.group({
      old_password: ["", Validators.required],
      new_password: ["", Validators.required],
      confirm_password: ["", Validators.required]
    },
    {
      validator: MustMatch('new_password', 'confirm_password')
    });
  }

  get f() {
    return this.changepasswrodForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.changepasswrodForm.invalid) {
      return;
    }
    this.loading = true;

    this.changepassword.changePassword(this.changepasswrodForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          // console.log(res.status);
          // this.router.navigate(["/dashboard"]);
          // this.showMsg = true;
          this.toaster.success('Password updated successfully.')
          this.reset();
        } else {
          this.toaster.error(res.message)
          this.changepasswrodForm.reset();
        }
      },
      error => {
        this.toaster.error(error)
        this.loading = false;
      }
    );
  }

  private reset() {
    this.changepasswrodForm = this.formbuilder.group({
      old_password: [""],
      new_password: [""],
      confirm_password: [""]
    });
    // $('#old_password').val('');
    // $('#new_password').val('');
    // $('#con_password').val('');
    // this.changepasswrodForm.value.old_password = "";
    // this.changepasswrodForm.value.new_password = "";
    // this.changepasswrodForm.value.confirm_password = "";
  }
}
