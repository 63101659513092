import { BaseUrl } from "./../../../base-url";
import { Component, OnInit } from "@angular/core";
import { ViewchapterService } from "./viewchapter.service";
import { Router, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-viewchapter',
  templateUrl: './viewchapter.component.html',
  styleUrls: ['./viewchapter.component.sass']
})
export class ViewchapterComponent implements OnInit {

  getChapter: any;
  data: any;
  chapter_id: string;
  baseimage: any;
  safeUrl: any;
  showLoader = false;

  constructor(
    private chapterservice: ViewchapterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer  
  ) {
    this.baseimage = BaseUrl.image;
    this.sanitizer = sanitizer;
  }

  ngOnInit() {
    this.showLoader = true;
    this.chapter_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.loadChapter();
  }

  getTrustedUrl(url:any){ 
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log(this.safeUrl);
   }

  loadChapter() {
    this.chapterservice.getChapterDetail(this.chapter_id).subscribe(
      (res: any) => {
        const mapped = Object.entries(res.data).map(([type, value]) => ({
          type,
          value
        }));
        this.getChapter = res.data;
        this.getTrustedUrl('https://www.youtube.com/embed/'+this.getChapter.video);
        console.log(res.data);
        this.showLoader = false;
      },
      () => {
        console.log("ERROR");
      }
    );
  }

}

