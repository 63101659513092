import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';
import { User } from './_models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrl } from './base-url';
import { map } from 'rxjs/operators';
import { HelperService } from './helper.service';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  base
  constructor(private http: HttpClient) {
    this.base = BaseUrl.frontend
  }

  checkUserStatus(): any | Observable<any> {
    const currentUser = localStorage.getItem('currentUser');
    if(!currentUser) return of({});
    const dencryptedUser = JSON.parse(
      crypto.AES.decrypt(currentUser, environment.secretKey).toString(
        crypto.enc.Utf8
      )
    );
    if (dencryptedUser && dencryptedUser.status) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': dencryptedUser.data.token,
      });
      return this.http.post(
        BaseUrl.frontend + `/getUserProfile`, null,
        {
          headers: headers,
        }
      );
    }
  }

  
  // forget(email: string) {
  //   return this.http
  //     .post<any>(this.base + `/forgotpassword`, { email })
  //     .pipe(
  //       map(user => {
  //         // login successful if there's a jwt token in the response
  //         if (user.status) {
  //           return user;
  //         } else {
  //           return user;
  //         }
  //       })
  //     );
  // }

  // private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User>;
  // base: any;
  // userdata: any;
  // constructor(
  //   private http: HttpClient,
  //   private route: ActivatedRoute,
  //   private router: Router,
  //   private helper: HelperService
  // ) {
  //   this.currentUserSubject = new BehaviorSubject<User>(
  //     JSON.parse(localStorage.getItem('currentUser'))
  //   );
  //   this.currentUser = this.currentUserSubject.asObservable();

  //   this.base = BaseUrl.frontend;
  // }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }

  // checkUserStatus(): Observable<any> {
  //   // this.userdata = JSON.parse(localStorage.getItem('currentUser'));
  //   // if (this.userdata) {
  //   // let headers = new HttpHeaders({
  //   //   'Content-Type': 'application/json',
  //   //   'x-access-token': this.userdata.data.token,
  //   // });
  //   let headers = this.helper.getHeader();
  //   return this.http.post(this.base + `/getUserProfile`, null, {
  //     headers: headers,
  //   });
  //   // }
  // }

  // // tslint:disable-next-line: variable-name
  // login(email: string, password: string, device_token: string) {
  //   return this.http
  //     .post<any>(this.base + `/login`, {
  //       email,
  //       password,
  //       device_token,
  //     })
  //     .pipe(
  //       map((user) => {
  //         // login successful if there's a jwt token in the response
  //         if (user.status) {
  //           // store user details and jwt token in local storage to keep user logged in between page refreshes
  //           localStorage.setItem('currentUser', JSON.stringify(user));
  //           this.currentUserSubject.next(user);
  //           return user;
  //         } else {
  //           return user;
  //         }
  //       })
  //     );
  // }

  // forget(email: string) {
  //   return this.http.post<any>(this.base + `/forgotpassword`, { email }).pipe(
  //     map((user) => {
  //       // login successful if there's a jwt token in the response
  //       if (user.status) {
  //         return user;
  //       } else {
  //         return user;
  //       }
  //     })
  //   );
  // }

  // logout() {
  //   // remove user from local storage to log user out
  //   localStorage.removeItem('currentUser');
  //   localStorage.setItem('role', 'guset');
  //   // window.location.href = "login";
  //   return;
  // }
}
